const getCurrentRole = (userRole) => {
  const user = userRole?.role_type === "teacher" ? "teacher" : userRole?.role_type === "student" ? "student" : userRole?.role_type === "school_admin" ? "admin" : "";

  return user;
};

const isAdmin = (userRole) => userRole?.role_type === "school_admin";

const hasPermission = (userRole, permissionName) => {
  return (
    isAdmin(userRole) ||
    userRole?.permissions?.split(",").includes(permissionName)
  );
};

// School Plans Access
const accessAllSchoolPlans = (userRole) => hasPermission(userRole, "view_all_school_plans");
const accessSchoolPlanDetails = (userRole) => hasPermission(userRole, "view_school_plan_details");
const accessSubjectTeacherByClass = (userRole) => hasPermission(userRole, "view_teacher_subjects_by_class");
const accessSubjectTeacher = (userRole) => hasPermission(userRole, "view_all_teacher_subjects");
const accessTeacherClasses = (userRole) => hasPermission(userRole, "view_teacher_classes");

// Leave Options Access
const accessLeaveOptions = (userRole) => hasPermission(userRole, "view_all_leave_options");

// Application Process Permissions
const accessRegisterApplicant = (userRole) => hasPermission(userRole, "register_applicant");
const accessViewAllApplicants = (userRole) => hasPermission(userRole, "get_all_applicants");
const accessViewApplicantProfile = (userRole) => hasPermission(userRole, "get_application_by_id");
const accessUpdateApplicant = (userRole) => hasPermission(userRole, "update_applicant");

// Student Permissions
const accessViewAllStudents = (userRole) => hasPermission(userRole, "view_all_students");
const accessCreateStudent = (userRole) => hasPermission(userRole, "create_student");
const accessViewStudentDetails = (userRole) => hasPermission(userRole, "view_student_details");
const accessUpdateStudent = (userRole) => hasPermission(userRole, "update_student");
const accessDeleteStudent = (userRole) => hasPermission(userRole, "delete_student");
const accessViewStudentAcademicRecord = (userRole) => hasPermission(userRole, "view_student_academic_record");

// Assignments Permissions
const accessAllAssignment = (userRole) => hasPermission(userRole, "view_all_assignment");
const accessCreateAssignment = (userRole) => hasPermission(userRole, "create_assignment");
const accessViewAllAssignmentsInSchool = (userRole) => hasPermission(userRole, "view_all_assignments_in_sch");
const accessViewAssignmentsGivenByTeacher = (userRole) => hasPermission(userRole, "view_assignments_given_by_teacher");
const accessViewAssignmentById = (userRole) => hasPermission(userRole, "view_assignment_by_id");
const accessUpdateAssignment = (userRole) => hasPermission(userRole, "update_assignment");
const accessDeleteAssignment = (userRole) => hasPermission(userRole, "delete_assignment");

// Access Control Permissions
const accessRevokeAccessCode = (userRole) => hasPermission(userRole, "revoke_access_code");
const accessCreateAccessCode = (userRole) => hasPermission(userRole, "create_access_code");
const accessViewAllAccessCodes = (userRole) => hasPermission(userRole, "get_all_access_code");
const accessViewAccessCodeByUser = (userRole) => hasPermission(userRole, "get_access_code_by_user");
const accessDeleteAccessCode = (userRole) => hasPermission(userRole, "delete_access_code");
const accessViewAccessLog = (userRole) => hasPermission(userRole, "get_access_log");
const accessClockAccessCode = (userRole) => hasPermission(userRole, "clock_access_code");

// Welfare Permissions
const accessViewLeaveOptionDetails = (userRole) => hasPermission(userRole, "view_leave_option_details");
const accessViewAllLeaveOptions = (userRole) => hasPermission(userRole, "view_all_leave_options");
const accessApplyForLeave = (userRole) => hasPermission(userRole, "apply_for_leave");
const accessViewAllLeaveRequests = (userRole) => hasPermission(userRole, "view_all_leave_requests");
const accessViewLeaveRequestDetails = (userRole) => hasPermission(userRole, "view_leave_request_details");
const accessUpdateLeaveStatus = (userRole) => hasPermission(userRole, "update_leave_status");
const accessUpdateLeaveRequest = (userRole) => hasPermission(userRole, "update_leave_request");
const accessViewAllKnowledgebaseInfo = (userRole) => hasPermission(userRole, "view_knowledgebase_info");

// Knowledgebase Permissions
const accessViewKnowledgebaseInfoDetails = (userRole) => hasPermission(userRole, "view_knowledgebase_info_details");

// Class Management Permissions
const accessViewClassDetailsById = (userRole) => hasPermission(userRole, "view_class_details_by_id");
const accessViewSubjectsByClass = (userRole) => hasPermission(userRole, "view_subjects_by_class");
const accessViewStudentsInClass = (userRole) => hasPermission(userRole, "view_students_in_class");

// Attendance Permissions
const accessMarkAttendance = (userRole) => hasPermission(userRole, "mark_attendance");
const accessViewClassAttendance = (userRole) => hasPermission(userRole, "view_class_attendance");

// Lesson Note Permissions
const accessCreateLessonNote = (userRole) => hasPermission(userRole, "create_lesson_note");
const accessUpdateLessonNote = (userRole) => hasPermission(userRole, "update_lesson_note");
const accessViewAllLessonNotes = (userRole) => hasPermission(userRole, "view_all_lesson_notes");
const accessViewLessonNoteDetails = (userRole) => hasPermission(userRole, "view_lesson_note_details");
const accessDeleteLessonNote = (userRole) => hasPermission(userRole, "delete_lesson_note");

// Guardian Permissions
const accessCreateGuardian = (userRole) => hasPermission(userRole, "create_guardian");
const accessViewAllGuardians = (userRole) => hasPermission(userRole, "view_all_guardians");
const accessViewAllGuardianWards = (userRole) => hasPermission(userRole, "view_all_guardian_wards");
const accessViewSpecificWard = (userRole) => hasPermission(userRole, "view_specific_ward");
const accessViewGuardianDetails = (userRole) => hasPermission(userRole, "view_guardian_details");
const accessDeleteGuardian = (userRole) => hasPermission(userRole, "delete_guardian");
const accessUpdateGuardian = (userRole) => hasPermission(userRole, "update_guardian");

// Teacher Permissions
const accessViewAllTeachers = (userRole) => hasPermission(userRole, "view_all_teachers");
const accessViewTeacherDetails = (userRole) => hasPermission(userRole, "view_teacher_details");
const accessUpdateTeacher = (userRole) => hasPermission(userRole, "update_teacher");
const accessSoftDeleteTeacher = (userRole) => hasPermission(userRole, "soft_delete_teacher");

// Staff Permissions
const accessViewAllEmployees = (userRole) => hasPermission(userRole, "view_all_employees");
const accessViewEmployeeDetails = (userRole) => hasPermission(userRole, "view_employee_details");
const accessUpdateEmployee = (userRole) => hasPermission(userRole, "update_employee");
const accessDeleteEmployee = (userRole) => hasPermission(userRole, "delete_employee");

// Procurement Permissions
const accessCreateProcurement = (userRole) => hasPermission(userRole, "create_procurement");
const accessUpdateProcurement = (userRole) => hasPermission(userRole, "update_procurement");
const accessViewAllProcurements = (userRole) => hasPermission(userRole, "view_all_procurements");
const accessViewProcurementDetails = (userRole) => hasPermission(userRole, "view_procurement_details");
const accessDeleteProcurement = (userRole) => hasPermission(userRole, "delete_procurement");

// Inventory Permissions
const accessCreateInventoryCategory = (userRole) => hasPermission(userRole, "create_inventory_category");
const accessViewAllInventoryCategories = (userRole) => hasPermission(userRole, "view_all_inventory_categories");
const accessViewInventoryCategoryDetails = (userRole) => hasPermission(userRole, "view_inventory_category_details");
const accessUpdateInventoryCategory = (userRole) => hasPermission(userRole, "update_inventory_category");
const accessDeleteInventoryCategory = (userRole) => hasPermission(userRole, "delete_inventory_category");
const accessAddInventoryItem = (userRole) => hasPermission(userRole, "add_inventory_item");
const accessViewInventoryItemDetails = (userRole) => hasPermission(userRole, "view_inventory_item_details");
const accessViewAllInventoryItems = (userRole) => hasPermission(userRole, "view_all_inventory_items");
const accessUpdateInventoryItem = (userRole) => hasPermission(userRole, "update_inventory_item");
const accessDeleteInventoryItem = (userRole) => hasPermission(userRole, "delete_inventory_item");

// Integration Permissions
const accessAddIntegration = (userRole) => hasPermission(userRole, "add_integration");
const accessUpdateIntegration = (userRole) => hasPermission(userRole, "update_integration");
const accessDeleteIntegration = (userRole) => hasPermission(userRole, "delete_integration");

// School Payment Permissions
const accessGetAllSchoolPayments = (userRole) => hasPermission(userRole, "get_all_school_payments");

// Enrolment Permissions
const accessCreateEnrolment = (userRole) => hasPermission(userRole, "create_enrolment");
const accessViewEnrolmentApplicants = (userRole) => hasPermission(userRole, "view_enrolment_applicants");
const accessViewApplicant = (userRole) => hasPermission(userRole, "view_applicant");
const accessViewAllApplications = (userRole) => hasPermission(userRole, "view_all_applications");
const accessUpdateEnrolment = (userRole) => hasPermission(userRole, "update_enrolment");
const accessBatchUpdateApplicants = (userRole) => hasPermission(userRole, "batch_update_applicants");
const accessGetAllEnrolments = (userRole) => hasPermission(userRole, "get_all_enrolments");
const accessCreateEvent = (userRole) => hasPermission(userRole, "create_event");
const accessUpdateEvent = (userRole) => hasPermission(userRole, "update_event");
const accessDeleteEvent = (userRole) => hasPermission(userRole, "delete_event");
const accessGetEventRSVP = (userRole) => hasPermission(userRole, "get_event_rsvp");
export {
  getCurrentRole,
  accessAllSchoolPlans,
  accessSchoolPlanDetails,
  accessSubjectTeacherByClass,
  accessSubjectTeacher,
  accessTeacherClasses,
  accessAllAssignment,
  accessLeaveOptions,
  accessRegisterApplicant,
  accessViewAllApplicants,
  accessViewApplicantProfile,
  accessUpdateApplicant,
  accessViewAllStudents,
  accessCreateStudent,
  accessViewStudentDetails,
  accessUpdateStudent,
  accessDeleteStudent,
  accessViewStudentAcademicRecord,
  accessCreateAssignment,
  accessViewAllAssignmentsInSchool,
  accessViewAssignmentsGivenByTeacher,
  accessViewAssignmentById,
  accessUpdateAssignment,
  accessDeleteAssignment,
  accessRevokeAccessCode,
  accessCreateAccessCode,
  accessViewAllAccessCodes,
  accessViewAccessCodeByUser,
  accessDeleteAccessCode,
  accessViewAccessLog,
  accessClockAccessCode,
  accessViewLeaveOptionDetails,
  accessViewAllLeaveOptions,
  accessApplyForLeave,
  accessViewAllLeaveRequests,
  accessViewLeaveRequestDetails,
  accessUpdateLeaveStatus,
  accessUpdateLeaveRequest,
  accessViewAllKnowledgebaseInfo,
  accessViewKnowledgebaseInfoDetails,
  accessViewClassDetailsById,
  accessViewSubjectsByClass,
  accessViewStudentsInClass,
  accessMarkAttendance,
  accessViewClassAttendance,
  accessUpdateLessonNote,
  accessViewAllLessonNotes,
  accessViewLessonNoteDetails,
  accessDeleteLessonNote,
  accessCreateLessonNote,
  accessCreateGuardian,
  accessViewAllGuardians,
  accessViewAllGuardianWards,
  accessViewSpecificWard,
  accessViewGuardianDetails,
  accessDeleteGuardian,
  accessUpdateGuardian,
  accessViewAllTeachers,
  accessViewTeacherDetails,
  accessUpdateTeacher,
  accessSoftDeleteTeacher,
  accessViewAllEmployees,
  accessViewEmployeeDetails,
  accessUpdateEmployee,
  accessDeleteEmployee,
  accessCreateProcurement,
  accessUpdateProcurement,
  accessViewAllProcurements,
  accessViewProcurementDetails,
  accessDeleteProcurement,
  accessCreateInventoryCategory,
  accessViewAllInventoryCategories,
  accessViewInventoryCategoryDetails,
  accessUpdateInventoryCategory,
  accessDeleteInventoryCategory,
  accessAddInventoryItem,
  accessViewInventoryItemDetails,
  accessViewAllInventoryItems,
  accessUpdateInventoryItem,
  accessDeleteInventoryItem,
  accessAddIntegration,
  accessUpdateIntegration,
  accessDeleteIntegration,
  accessGetAllSchoolPayments,
  accessCreateEnrolment,
  accessViewEnrolmentApplicants,
  accessViewApplicant,
  accessViewAllApplications,
  accessUpdateEnrolment,
  accessBatchUpdateApplicants,
  accessGetAllEnrolments,
  accessCreateEvent,
  accessUpdateEvent,
  accessDeleteEvent,
  accessGetEventRSVP
};
