import Layout from "../../components/Layout";
import TabContext from "@mui/lab/TabContext";
import PageTitle from "../../components/PageTitle";
import TabPanel from "@mui/lab/TabPanel";
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import CreateEnrolment from "./components/CreateEnrolment";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Table";
import Badge from "../../components/Badge";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import useValidator from "../../hooks/useValidator";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import Select from "react-select";

const ENROLMENT_ENDPOINT = "/api/v1/enrolment";

const ALL_ENROLMENT_EXAM = "/api/v1/enrolment-exams";

const Index = () => {
  const classes = useStyles();
  // const [value, setValue] = useState("1");
  const [enrolmentData, setEnrolmentData] = useState([]);
  const [enrolmentExamData, setAllEnrollmenteExamData] = useState([]);
  const [isEnrolmentFetching, setIsEnrolmentFetching] = useState(false);
  const [editEnrolmentModal, setEditEnrolmentModal] = useState(false);
  const [assignValidator, showAssignValidator] = useValidator();
  const [isEditing, setIsEditing] = useState(false);
  const [isAttaching, setIsAttaching] = useState(false);
  const [currentEnrolment, setCurrentEnrolment] = useState({});
  const [addExamModal, setAddExamModal] = useState(false);
  const navigate = useNavigate();

  const [assignForm, setAssignForm] = useState({
    exam_id: []
  });

  // eslint-disable-next-line no-console
  console.log(currentEnrolment, "currentEnrolment");
  const handleEditEnrolmentModal = (row) => {
    setEditEnrolmentModal(!editEnrolmentModal);
    setCurrentEnrolment(row);
  };

  const handleCloseEditEnrolmentModal = () => {
    setEditEnrolmentModal(false);
    setCurrentEnrolment({});
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const handleMultipleAccessChange = (selectedOptions) => {
    // eslint-disable-next-line no-console
    console.log(selectedOptions, "selectedOptions");
    const selectedValues = selectedOptions.map((option) => option?.id);
    setAssignForm({ ...assignForm, exam_id: selectedValues });
    // eslint-disable-next-line no-console
    console.log(selectedValues, "selectedValues");
  };

  const handleADdExamModal = (row) => {
    // eslint-disable-next-line no-console
    console.log(row, "Selected Row");
    setCurrentEnrolment(row);
    setAddExamModal(!addExamModal);
  };

  const getAllEnrolmentExam = () => {
    httprequest.get(ALL_ENROLMENT_EXAM).then((res) => {
      setAllEnrollmenteExamData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCloseConvertExam = () => {
    setAddExamModal(false);
  };

  const handleAttachEnrolmentExam = () => {
    if (assignValidator.allValid()) {
      setIsAttaching(true);
      httprequest
        .post(`${ENROLMENT_ENDPOINT}/${currentEnrolment?.enrolment_id}/exams/attach`, { ...assignForm })
        .then((res) => {
          setIsAttaching(false);
          toast.success(res?.data?.message);
          handleCloseConvertExam();
        })
        .catch((err) => {
          setIsAttaching(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showAssignValidator(true);
    }
  };

  useEffect(() => {
    getAllEnrolmentExam();
  }, []);
  const columns = [
    {
      name: "Closing Date",
      selector: (row) => row?.closing_date?.substring(0, 10)
    },
    {
      name: "Classes Open",
      selector: (row) => row?.classes?.map((clss) => (
        <span className="class_div" key={clss?.clss_id}>
          {clss?.class_name}
        </span>
      ))
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Status",
      cell: (row) => <Badge status={row?.open} />,
      center: "true"
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link
            className="table-action-icon"
            to={`/enrolment-preview/${row?.id} `}
            target="_blank"
          >
            <Icon icon="majesticons:open" />
          </Link>
          <Link to={`/enrolments/${row?.id}`}>
            <button className="table-action-icon">
              <Icon icon="fluent:eye-16-regular" />
            </button>
          </Link>
          <Link to={`edit/${row?.id}`}
            className="table-action-icon"
          >
            <Icon icon="lucide:edit" />
          </Link>
          <button
            onClick={() => handleADdExamModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="healthicons:i-exam-multiple-choice-outline" width="22" height="22" style={{ color: "#858585" }} />
          </button>
          <button
            className="table-action-icon"
            onClick={() => handleEditEnrolmentModal(row)}
          >
            <Icon icon="material-symbols:convert-to-text-outline-rounded" />
          </button>
        </div>
      )
    }
  ];

  const getAllEnrolments = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .get(ENROLMENT_ENDPOINT)
      .then((res) => {
        setIsEnrolmentFetching(false);
        setEnrolmentData(res?.data?.data);
      })
      .catch((err) => {
        setIsEnrolmentFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const handleEditEnrolment = () => {
    setIsEnrolmentFetching(true);
    httprequest
      .patch(`${ENROLMENT_ENDPOINT}/${currentEnrolment?.id}`, {
        open: !currentEnrolment?.open
      })
      .then(() => {
        setIsEditing(false);
        setCurrentEnrolment({});
        setEditEnrolmentModal(false);
        getAllEnrolments();
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    getAllEnrolments();
  }, []);

  const formattedEnrolmentData = enrolmentExamData?.map((exam) => ({
    ...exam,
    label: `${exam?.subject} | ${exam?.classes.map((a_class) => a_class?.name).join(", ")} | ${exam?.duration} minutes`
  }));

  return (
    <Layout children={
      <>
        <PageTitle
          title="Enrolments"
          subtitle="Manage all students applications and enroments in your school"
          button={
            <div className="flex gap-10">
              <Button onClick={() => {
                navigate("/enrolments/applications");
              } } variant="primaryoutline" buttonSize="fluid" color="btnfontprimary" buttonName="View Applications"
              />
              <Button onClick={() => {
                navigate("/enrolments/create");
              } } variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create Enrolment"
              />
            </div>
          }
        />
        <div className="page-content">
          <TabContext>
            <TabPanel className="tabpanel-overide">
              <Table
                data={enrolmentData}
                columns={columns}
                pagination
                isLoading={isEnrolmentFetching}
              />
            </TabPanel>
            <TabPanel value="2" className="tabpanel-overide">
              <CreateEnrolment />
            </TabPanel>
          </TabContext>

          {/* Edit Enrolment (Close/Open Enrolment) */}
          <Modal
            title="Open or Close the Enrolment Process"
            subtitle={
              <div>
                  Note that opening/closing this manages your users access to
                  the application page.
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {currentEnrolment?.open ? (
                        <Button
                          variant="danger"
                          buttonSize="full"
                          color="btndefault"
                          disabled={!currentEnrolment?.open}
                          buttonName="Close this Enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          buttonSize="full"
                          color="btndefault"
                          disabled={currentEnrolment?.open}
                          buttonName="Re-Open this enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            }
            isopen={editEnrolmentModal}
            closeModal={handleCloseEditEnrolmentModal}
          />
        </div>
        {/* Add Exam MOdal */}
        <Modal
          title="Add Examination to an Enrolment"
          subtitle={
            <div>
            Are you sure you want to add Examination to
              <span className="modal-name-highlight">{" "}
                {currentEnrolment?.classes?.map((clss, index) => (
                  <span className="" key={clss?.class_id}>
                    {clss?.class_name}
                    {index < currentEnrolment.classes.length - 1 ? ", " : ""}
                  </span>
                )) || " these classes"}
              </span>{" "}
              Enrolment?
            </div>
          }
          modalContent={
            <>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="height300">
                    <div className="multiple-select .height-min-200">
                      <p>Select Enrolment Exam</p>
                      <Select isMulti name="exam_id"
                        closeMenuOnSelect={true} options={formattedEnrolmentData} onChange={handleMultipleAccessChange}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                  </div>
                  {assignValidator.message(
                    "exam_id",
                    assignForm?.exam_id,
                    "required"
                  )}
                </Grid>
              </Grid>
            </>}
          modalFooter={
            <>
              <Button
                variant="primary"
                buttonName="Attach Examination"
                buttonSize="full"
                color="btndefault"
                onClick={handleAttachEnrolmentExam}
                isLoading={isAttaching}
              />
            </>
          }
          isopen={addExamModal}
          closeModal={handleCloseConvertExam}
        />
        <ToastContainer />
      </>
    }
    />
  );
};

export default Index;
