import useStyles from "../styles";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { httprequest } from "../../../data/api";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Calendar from "../../../components/Calendar";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Select from "react-select";
import NotFounditem from "../../error/NotFound";
import nosearch from "../../../assets/images/no-search.svg";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PageLoader from "../../../components/PageLoader";
import useWindowSize from "../../../hooks/useWindow";

import Button from "../../../components/Button";

const SCHEDULE_EXAM = "/api/v1/exam-schedule";
const SCHEDULE_EXAM_DETAILS = "/api/v1/exam-schedule/details";
const ALL_EXAM = "/api/v1/school-exams";
const CLASSES_ENDPOINT = "/api/v1/class";
const CLASS_SUBJECT = "/api/v1/class-subject/";

const ExamCalendar = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [aSchoolSchedule, setASchoolSchedule] = useState([]);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [classesData, setClassData] = useState([]);
  const [selectClassData, setSelectClassData] = useState([]);
  const [currentEvent, setCurrentEvent] = useState({});
  const [scheduleExamModal, setSchduleExamModal] = useState(false);
  const [allExams, setAllExams] = useState([]);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [selectedExamDuration, setSelectedExamDuration] = useState("");
  const [editSelectedExamDuration, setEditSelectedExamDuration] = useState("");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleting, setIsDeleting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sheduleExamCalendarForm, setScheduleExamCalendarForm] = useState({
    class_id: [],
    exam_schedule_id: id,
    exam_id: "",
    exam_date: "",
    subject_id: "",
    start_date: "",
    end_time: "",
    exam_duration: ""
  });

  const [editSheduleExamCalendarForm, setEditScheduleExamCalendarForm] = useState({
    class_id: [],
    exam_schedule_id: id,
    exam_id: "",
    exam_date: "",
    subject_id: "",
    start_date: "",
    end_time: "",
    exam_duration: ""
  });

  const width = useWindowSize();
  const mobile = width < 576;

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  const handleCloseCreateSchdule = () => {
    setSchduleExamModal(false);
    setScheduleExamCalendarForm({
      class_id: [],
      exam_schedule_id: id,
      exam_id: "",
      exam_date: "",
      start_date: "",
      end_time: "",
      exam_duration: "",
      subject_id: ""
    });
  };

  const getAllClasses = () => {
    httprequest
      .get(CLASSES_ENDPOINT)
      .then((res) => {
        const physicalClasses = res?.data?.data?.filter(
          (data) => data?.class_type === "physical"
        );
        setClassData(physicalClasses);
        setSelectClassData(
          res?.data?.data.map((a_class) => ({
            value: a_class?.id,
            label: a_class?.class_name
          }))
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // --| THIS IS TO GET AN ASSIGNMENT BY CLASS
  const getScheduleByClass = (class_id) => {
    setIsPageFetching(true);
    httprequest
      .get(`${SCHEDULE_EXAM}/${id}?class_id=${class_id}`)
      .then((res) => {
        setIsPageFetching(false);
        setASchoolSchedule(res?.data?.data);
        // setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        setIsPageFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const handleCloseEditModal = () => {
    setEditScheduleExamCalendarForm({
      class_id: [],
      exam_schedule_id: id,
      exam_id: "",
      exam_date: "",
      start_date: "",
      end_time: "",
      exam_duration: "",
      subject_id: ""
    }
    );
    setEditSelectedExamDuration("");
    setOpenEditModal(false);
  };

  // handle Create Exam Schedule
  const handleCreateExamScheduleDetails = () => {
    setIsCreating(true);
    httprequest
      .post(SCHEDULE_EXAM_DETAILS, sheduleExamCalendarForm)
      .then((res) => {
        setIsCreating(false);
        toast.success(res?.data?.message);
        getScheduleByClass(value);
        handleCloseCreateSchdule();
      })
      .catch((err) => {
        setIsCreating(false);
        toast.error(err?.response?.data);
      });
  };

  const getAllSubjectForClass = async (class_value) => {
    try {
      const res = await httprequest.get(`${CLASS_SUBJECT}${class_value}`);
      setAllClassSubject(res?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // Get All Exams
  const getAllExams = () => {
    httprequest
      .get(ALL_EXAM)
      .then((res) => {
        setAllExams(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const handleScheduleExamModal = () => {
    setSchduleExamModal(!scheduleExamModal);
  };

  const events = aSchoolSchedule?.map((schedule) => {
    const startDateTime = `${schedule?.exam_date.split("T")[0]}T${schedule?.start_time}`;
    const endDateTime = `${schedule?.exam_date.split("T")[0]}T${schedule?.end_time}`;

    return {
      title: `${" "}${schedule?.subject?.name} | ${schedule?.exam_duration} mins`,
      start: startDateTime,
      end: endDateTime,
      exam_duration: schedule?.exam_duration,
      exam_id: schedule?.exam_id,
      details_id: schedule?.id,
      subject: schedule?.subject,
      classes: schedule?.classes,
      exam_date: schedule?.exam_date,
      start_time: schedule?.start_time,
      end_time: schedule?.end_time
    };
  });

  const handleEventClick = (args) => {
    setOpenEditModal(!openEditModal);
    setCurrentEvent(args?.event);
    setEditScheduleExamCalendarForm({
      class_id: args?.event?.extendedProps?.classes?.map((cls) => cls?.id) || [],
      exam_schedule_id: id,
      exam_id: args?.event?.extendedProps?.exam_id,
      exam_date: args?.event?.extendedProps?.exam_date,
      subject_id: args?.event?.extendedProps?.subject?.id,
      end_time: args?.event?.extendedProps?.end_time,
      exam_duration: args?.event?.extendedProps?.exam_duration,
      start_time: args?.event?.extendedProps?.start_time
    });
  };

  // Hanle all input or select change
  const handleTitleChange = (e) => {
    const { name, value } = e.target;

    if (name === "exam_id") {
      const selectedExam = allExams.find((exam) => exam?.id === value);

      // Update form with both exam_id and exam_duration in one call
      setScheduleExamCalendarForm({
        ...sheduleExamCalendarForm,
        [name]: value,
        exam_duration: selectedExam?.duration || ""
      });

      // Update selected exam duration if needed elsewhere
      setSelectedExamDuration(selectedExam);
    } else if (name === "start_time") {
      // Calculate end time based on start time and exam duration
      const durationInMinutes = selectedExamDuration?.duration || 0;

      // Format start_time to include seconds
      const startTime = `${value}:00`;

      if (durationInMinutes) {
        // Calculate end time by adding duration to start time
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
        const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: startTime,
          end_time: formattedEndTime
        });
      } else {
        setScheduleExamCalendarForm({
          ...sheduleExamCalendarForm,
          [name]: startTime
        });
      }
    } else {
      // For other input changes, update form as usual
      setScheduleExamCalendarForm({
        ...sheduleExamCalendarForm,
        [name]: value
      });
    }
  };

  const handleEditTitleChange = (e) => {
    const { name, value } = e.target;

    if (name === "exam_id") {
      const editSelectedExam = allExams.find((exam) => exam?.id === value);
      const startTime = editSheduleExamCalendarForm?.start_time || "15:00:00";
      const durationInMinutes = editSelectedExam?.duration || 0;

      // Update The end time
      const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
      const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
      const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

      // Update form state
      setEditScheduleExamCalendarForm({
        ...editSheduleExamCalendarForm,
        [name]: value,
        exam_duration: durationInMinutes,
        end_time: formattedEndTime
      });

      setEditSelectedExamDuration(editSelectedExam);
    } else if (name === "start_time") {
      const durationInMinutes = editSelectedExamDuration?.duration || currentEvent?.extendedProps?.exam_duration || 0;
      const startTime = `${value}:00`;

      if (durationInMinutes) {
        const startTimeDate = new Date(`1970-01-01T${startTime}Z`);
        const endTimeDate = new Date(startTimeDate.getTime() + durationInMinutes * 60000);
        const formattedEndTime = endTimeDate.toISOString().split("T")[1].slice(0, 8);

        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: startTime,
          end_time: formattedEndTime
        });
      } else {
        setEditScheduleExamCalendarForm({
          ...editSheduleExamCalendarForm,
          [name]: startTime
        });
      }
    } else {
      setEditScheduleExamCalendarForm({
        ...editSheduleExamCalendarForm,
        [name]: value
      });
    }
  };
  // Handle Select Classes when Created
  const handleMultipleAccessChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option?.id);
    setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, class_id: selectedValues });
  };

  // Handle Edit Multiple Classes
  const handleEditMultipleAccessChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option?.value);
    setEditScheduleExamCalendarForm({ ...editSheduleExamCalendarForm, class_id: selectedValues });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };

  // Edit Schedule Details
  const handleEditDetails = () => {
    setIsEditing(true);

    // const edit_period_data = {
    //   start_time: editTimeTable.start_time,
    //   end_time: editTimeTable.end_time
    // };

    httprequest.patch(`${SCHEDULE_EXAM_DETAILS}/${currentEvent?.extendedProps?.details_id}`, editSheduleExamCalendarForm)
      .then((res) => {
        setIsEditing(false);
        toast.success(res?.data?.message);
        handleCloseEditModal();
        getScheduleByClass(value);
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err.response?.data?.message);
      });
  };

  // Delete a Schedule Detials
  const handleDeleteDetails = () => {
    setIsDeleting(true);
    httprequest.delete(`${SCHEDULE_EXAM_DETAILS}/${currentEvent?.extendedProps?.details_id}`).then((res) => {
      setIsDeleting(false);
      handleCloseEditModal();
      toast.success(res?.data?.message);
      getScheduleByClass(value);
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err.response?.data?.message);
    });
  };

  // eslint-disable-next-line no-console
  console.log(sheduleExamCalendarForm, "sheduleExamCalendarForm");

  // eslint-disable-next-line no-console
  console.log(editSheduleExamCalendarForm, "editSheduleExamCalendarForm");

  useEffect(() => {
    if (classesData?.length > 0 && !value) {
      const class_value = classesData[0]?.id;
      setValue(class_value);
      getScheduleByClass(class_value);
      setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, class_id: [class_value] });
      getAllSubjectForClass(class_value);
    }
  }, [classesData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getScheduleByClass(newValue);
    getAllSubjectForClass(newValue);
    setScheduleExamCalendarForm({ ...sheduleExamCalendarForm, class_id: [newValue] });
  };

  useEffect(() => {
    getAllClasses();
    getAllExams();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one={"/school-examination/exam-schedule"}
                link_one_name="School Exam"
                active="Examination Schedules"
                description="See the overview of Scheduled Examination"
              />
            </div>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={handleScheduleExamModal}
                buttonName="Add Exam to calender "
              />
            </div>
          </div>
          <div className="page-content">
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label=""
                >
                  {classesData?.map((data, i) => {
                    return (
                      <Tab key={i} value={data?.id} label={data?.class_name} />
                    );
                  })}
                </Tabs>
              </div>
              {classesData?.map((data, i) => {
                return (
                  <TabPanel
                    className="tabpanel-overide"
                    key={i}
                    value={data?.id}
                  >
                    {isPageFetching ? (
                      <PageLoader />
                    ) : aSchoolSchedule.length > 0 ? (
                      <Calendar
                        events={events}
                        renderEventContent={renderEventContent}
                        defaultView={renderView()}
                        handleEventClick={handleEventClick}
                      />
                    ) : (
                      <div className={classes.notfound}>
                        <NotFounditem
                          img={nosearch}
                          title="No Exam Schedule Found"
                          subtitle="No Schedule has been added to the list."
                        />
                      </div>
                    )}
                  </TabPanel>
                );
              })}

            </TabContext>
            <div className={classes.calendar}>
              {/* <Calendar events={events} renderEventContent={renderEventContent} defaultView="dayGridMonth" handleEventClick={handleEventClick} /> */}
            </div>
          </div>
          {/* Schedule Examination */}
          <Modal
            title="Schedule An Examination"
            subtitle={<div>Create an Examination Schedule</div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Class</p>
                      <Select isMulti name="class_id"
                        closeMenuOnSelect={false}
                        options={classesData}
                        defaultValue={classesData?.length > 0 ? classesData.find((cls) => cls.id === value) ? [classesData.find((cls) => cls.id === value)] : [] : []}
                        onChange={handleMultipleAccessChange}
                        getOptionLabel={(option) => option.class_name}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Exam"
                      required
                      children={
                        <select
                          name="exam_id"
                          onChange={handleTitleChange}
                        >
                          <option value="">--- Select ---</option>

                          {allExams?.map((exam) => {
                            const classNames = exam?.classes.map((cls) => cls.name).join(" - ");

                            return (
                              <option key={exam?.id} value={exam?.id}>
                                {classNames} | {exam.subject?.name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Subject"
                      required
                      children={
                        <select
                          name="subject_id"
                          onChange={handleTitleChange}
                        >
                          <option value="">--- Select ---</option>

                          {allClassSubject?.map((subject) => {
                            return (
                              <option key={subject?.subject_id} value={subject?.subject_id}>
                                {subject?.subject_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Date"
                      margin="normal"
                      inputType="date"
                      required
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="exam_date"
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Start Time"
                      margin="normal"
                      inputType="time"
                      required
                      inputName="start_time"
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="End Time"
                      margin="normal"
                      inputType="time"
                      required
                      disabled
                      value={sheduleExamCalendarForm?.end_time || ""}
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="end_time"
                      onChange={handleTitleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Duration"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="exam_duration"
                      disabled
                      value={`${selectedExamDuration?.duration} Mins`}
                      onChange={handleTitleChange}
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div>
                  {/* <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add Another Period" /> */}
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isCreating}
                    onClick={handleCreateExamScheduleDetails}
                    buttonName="Create Exam Schedule"
                  />
                </div>
              </>
            }
            isopen={scheduleExamModal}
            closeModal={handleCloseCreateSchdule}
          />
          {/* Edit */}
          <Modal
            title="Edit Scheduled Examination Details"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Class</p>
                      <Select isMulti name="class_id"
                        closeMenuOnSelect={false}
                        options={selectClassData}
                        defaultValue={currentEvent?.extendedProps?.classes?.map(item => ({ label: item?.name, value: item?.id }))}
                        onChange={handleEditMultipleAccessChange}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Exam"
                      required
                      children={
                        <select
                          defaultValue={currentEvent?.extendedProps?.exam_id}
                          name="exam_id"
                          onChange={handleEditTitleChange}
                        >
                          <option value="">--- Select ---</option>

                          {allExams?.map((exam) => {
                            const classNames = exam?.classes.map((cls) => cls.name).join(" - ");

                            return (
                              <option key={exam?.id} value={exam?.id}>
                                {classNames} | {exam.subject?.name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Subject"
                      required
                      children={
                        <select
                          defaultValue={currentEvent?.extendedProps?.subject?.id}
                          name="subject_id"
                          onChange={handleEditTitleChange}
                        >
                          <option value="">--- Select ---</option>

                          {allClassSubject?.map((subject) => {
                            return (
                              <option key={subject?.subject_id} value={subject?.subject_id}>
                                {subject?.subject_name}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Date"
                      margin="normal"
                      inputType="date"
                      required
                      defaultValue={currentEvent?.extendedProps?.exam_date.substring(0, 10)}
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="exam_date"
                      onChange={handleEditTitleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Start Time"
                      margin="normal"
                      inputType="time"
                      required
                      inputName="start_time"
                      defaultValue={currentEvent?.extendedProps?.start_time}
                      onChange={handleEditTitleChange}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="End Time"
                      margin="normal"
                      inputType="time"
                      required
                      disabled
                      value={editSheduleExamCalendarForm?.end_time || currentEvent?.extendedProps?.end_time}
                      inputName="end_time"
                      onChange={handleEditTitleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Exam Duration"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="exam_duration"
                      disabled
                      value={`${editSelectedExamDuration?.duration || currentEvent?.extendedProps?.exam_duration} Mins`}
                      onChange={handleEditTitleChange}
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="danger" buttonSize="full" color="btndefault" buttonName="Delete Details" onClick={handleDeleteDetails} isLoading={deleting} />
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isEditing}
                    onClick={handleEditDetails}
                    buttonName="Edit Details"
                  />
                </div>
              </>
            }
            isopen={openEditModal}
            closeModal={handleCloseEditModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default ExamCalendar;
