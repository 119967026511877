import { TableContainer } from "@mui/material";
import { useState, useEffect } from "react";
import BreadCrumb from "../../../components/BreadCrumb";
// import { FilterSelect } from "../../../components/Table/TableActions";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/system";
// import { FilterSelect } from "../../../components/Table/TableActions";
// import Present from "../../../assets/images/present.svg";
// import Absent from "../../../assets/images/absent.svg";
import useStyles from "../styles";
import { Icon } from "@iconify/react/dist/iconify.js";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import PageLoader from "../../../components/PageLoader";
import nosearch from "../../../assets/images/no-search.svg";
import NotFounditem from "../../error/NotFound";
import Button from "../../../components/Button";

const CLASS_ENDPOINT = "/api/v1/class";
const CLASS_ATTENDANCE = "/api/v1/attendance";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_SESSIONS = "/api/v1/school-plan/session";

const AdminAttendance = () => {
  const classes = useStyles();
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isClassFetching, setIsClassFetching] = useState(false);
  const [classData, setClassData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [allSessions, setAllSessions] = useState([]);
  const [value, setValue] = useState("");
  const [termsData, setTermsData] = useState([]);
  const [termValue, setTermValue] = useState("");
  // const [aselectedSession, setASelectedSession] = useState("");
  const [aselectedTerm, setASelectedTerm] = useState("");

  const class_id_value = value;
  // const [checked, setChecked] = useState(attendanceData?.present || false);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [updateAttendance, setUpdateAttendance] = useState({
    class_id: "",
    term_id: "",
    attendance: []
  });

  // Handles the checkbox change event when a student’s attendance is toggled true/false
  const handleCheckboxChange = (studentId, present, day, date, weekNo) => {
    // Update the attendance data for the selected day and student
    setAttendanceData((prevAttendanceData) => prevAttendanceData.map((weekRecord) => ({
      ...weekRecord,
      records: weekRecord.records.map((dayRecord) => {
        if (dayRecord?.date === date) {
          const updatedAttendance = dayRecord?.attendance.map((att) => att?.id === studentId ? { ...att, present: !present } : att
          );

          return {
            ...dayRecord,
            attendance: updatedAttendance
          };
        }

        return dayRecord;
      })
    })));

    // Store or update the selected student’s attendance for multiple days or weeks
    setUpdateAttendance((prevUpdateAttendance) => {
      const updatedDayRecord = {
        day,
        date,
        week: weekNo,
        students: [
          { id: studentId, present: !present }
        ]
      };

      // Check if this day and student already exist in the update list
      const existingRecord = prevUpdateAttendance?.attendance.find(
        (record) => record?.date === date && record?.students.some((s) => s.id === studentId)
      );

      if (existingRecord) {
        // Update the existing record for the student
        return {
          ...prevUpdateAttendance,
          attendance: prevUpdateAttendance?.attendance.map((record) => record?.date === date
            ? {
              ...record,
              students: record?.students.map((s) => s?.id === studentId ? { ...s, present: !present } : s
              )
            }
            : record
          )
        };
      } else {
        // Add a new record for this day and student
        return {
          ...prevUpdateAttendance,
          attendance: [...prevUpdateAttendance.attendance, updatedDayRecord]
        };
      }
    });

    // Show the update button to indicate changes need to be saved
    setShowUpdateButton(true);
  };

  // get All Classes Attendance
  const getClassAttendance = (classId, termId) => {
    httprequest.get(`${CLASS_ATTENDANCE}/${classId}?term_id=${termId}`).then((res) => {
      setAttendanceData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // update Attendance
  const handleUpdateAttendance = () => {
    const AttendanceInfo = {
      ...updateAttendance,
      class_id: class_id_value,
      term_id: termValue
    };
    httprequest.post(CLASS_ATTENDANCE, AttendanceInfo)
      .then((res) => {
        toast.success(res?.data?.message);
        setShowUpdateButton(false);
        getClassAttendance(class_id_value, termValue);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // get All Classes for Attendance Marking
  const getAllClasses = () => {
    setIsClassFetching(true);
    httprequest.get(CLASS_ENDPOINT).then((res) => {
      setIsClassFetching(false);

      setClassData(res?.data?.data);
    }).catch((err) => {
      setIsClassFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setTermsData(res?.data?.data);
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      });
    // .catch(() => {});
  };

  // Handle Get All Classes
  const getAllSessions = async () => {
    try {
      const res = await httprequest.get(GET_SESSIONS);
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    } catch (err) {
      toast.error(err?.response?.message);
    }
  };
    // Find Active Term in an Current Session
  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  // Handles the change event when a new tab is selected
  const handleChange = (event, newValue) => {
    setValue(newValue);

    const selectedClass = classData.find((cls) => cls?.id === newValue);

    if (selectedClass) {
      const classId = selectedClass?.id;
      const termId = active_term_session?.id;

      getClassAttendance(classId, termId);
    }
  };

  // Handles the change event when a new tab is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;

    setTermValue(selectedTermId);
    setASelectedTerm(selectedTermId);
    getClassAttendance(class_id_value, selectedTermId);
    if (classData?.length > 0) {
      // const params = {
      //   term_id: selectedTermId
      // };
    }
  };

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    // setASelectedSession(e.target.value);
    getAllTerms(Session_ID);
  };

  // On mount renders all classes
  useEffect(() => {
    getAllClasses();
    getAllSessions();
    getAllTerms();
  }, []);

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session.id);
    }
  }, [active_term_session]);

  // on mount render the class of the class tab selected
  useEffect(() => {
    if (classData?.length > 0 && active_term_session?.id) {
      setValue(classData[0]?.id);
      setTermValue(active_term_session.id);

      const params = {
        term_id: active_term_session.id
      };

      getClassAttendance(classData[0]?.id, params.term_id);
    }
  }, [classData, active_term_session]);
  useEffect(() => {
    if (activeSession?.id) {
      setSelectedSession(activeSession?.id);
      getAllTerms(activeSession?.id);
      // eslint-disable-next-line no-console
      console.log(selectedSession, "selectedSession");
    }
  }, [activeSession]);

  // Extract each student names from the data
  const schStudentNames = attendanceData && attendanceData?.length > 0 ? [
    ...new Set(
      attendanceData.flatMap((week) => week?.records?.flatMap((day) => day?.attendance?.map((att) => att.name) || []
      ) || []
      )
    )
  ] : [];

  // Checks the number of weeks
  const numberOfWeeks = attendanceData?.length;

  return (
    <>
      <div className="breadcrumb-container">
        <div>
          <BreadCrumb
            link_one="/class-activities/classes"
            link_one_name="My Classes"
            link_two="/users/students"
            link_two_name="My Students"
            active="Attendance"
            description="See the details of the students in your care."
          />
        </div>
      </div>
      {/* flex align-items-end justify-content-between flex-wrap-items-600 margin-top-10-v600 */}
      <div className={`${classes.attcontent} page-content-mh-83`}>
        <TabContext value={value}>
          <div className={classes.attendanceheader}>
            <div className="tablist-container">
              <Tabs
                indicatorColor="none"
                className="overide-tablist"
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                {/* renders The Tabs For Each Of The Classes in a School e.g: JSS3, JSS2, JSS1 etc */}
                {classData?.map((data) => {
                  return (
                    <Tab key={data?.id} value={data?.id} label={data?.class_name} />
                  );
                })}
              </Tabs>
            </div>
            {/* conditionally Renders The Update Attendance Button When Any Student Attendance is instantly Checked */}
            <div>
              {showUpdateButton && (
                <div className={classes.updateattendance}>
                  <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Update Attendance" onClick={handleUpdateAttendance} />
                </div>
              )}
              <div className="flex gap-10 align-items-end margin-top-5">
                <div>
                  {activeSession?.id && (
                    <SelectGroup
                      children={
                        <select
                          defaultValue={activeSession?.id}
                          onChange={handleSessionChange}
                          name="session"
                        >
                          <option value="">---Select---</option>
                          {allSessions?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                  {/* <FilterSelect
                    onChange={handleSessionChange}
                    selectName="session"
                    selectIcon="ei:calendar"
                    optionsDefaultValue={activeSession?.id}
                    optionsDefaultName={activeSession?.session}
                    selectValue={aselectedSession}
                    optionsArray={allSessions?.map((sesh) => ({
                      value: sesh?.id,
                      name: sesh?.session
                    }))}
                  /> */}
                </div>
                <div>
                  {active_term_session?.id && (
                    <SelectGroup
                      children={
                        <select onChange={handleTermChange} name="term" value={aselectedTerm}>
                          <option value="">---Select---</option>
                          {selectedSessionTerms?.map((data) => {
                            return (
                              <option key={data?.id} value={data?.id}>
                                {data?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  )}
                  {/* <FilterSelect
                    onChange={handleTermChange}
                    selectName="term"
                    selectIcon="ei:calendar"
                    optionsDefaultValue={active_term_session?.id}
                    optionsDefaultName={active_term_session?.term}
                    selectValue={termValue}
                    optionsArray={selectedSessionTerms?.map((terms) => ({
                      value: terms?.id,
                      name: terms?.term
                    }))}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          {/* Renders Attendance For Each Of The Classes In a School */}
          {classData?.map((data) => {
            return (
              <TabPanel sx={{ border: "1px solid #98A2B3", borderRadius: "8px", marginTop: "16px" }} key={data?.id} value={data?.id}>
                {isClassFetching ? (
                  <PageLoader />
                ) : schStudentNames?.length === 0 || attendanceData?.length === 0 ? (
                  <div className={classes.notfound}>
                    <NotFounditem img={nosearch} title="No Attendance Found" subtitle="No attendance has been added to the list." />
                  </div>
                ) : (
                  <>
                    <Box sx={{ overflowX: "auto", overflowY: "auto" }}>
                      <TableContainer component={Paper} sx={{ display: "table", width: "auto" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            {/* First row of the table head */}
                            <TableRow>
                              {/* First cell in the first row, which is the "Attendance" header */}
                              <TableCell sx={{ minWidth: 200, borderBottom: "1px solid #F5F6F7", position: "sticky", left: 0, backgroundColor: "white", zIndex: 3, "@media (max-width: 500px)": { minWidth: "150px" } }}>
                      Attendance
                              </TableCell>
                              {/* Generates table cells for each week */}
                              {attendanceData.map((a_week) => (
                                <TableCell align="center" sx={{ borderBottom: "none", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} colSpan={daysOfWeek.length} key={`week-${a_week?.week}`}>
                        Week {a_week?.week}
                                </TableCell>
                              ))}
                            </TableRow>
                            {/* Second row of the table head */}
                            <TableRow>
                              <TableCell sx={{ position: "sticky", left: 0, borderBottom: "1px solid #F5F6F7", backgroundColor: "white", zIndex: 3 }}>
                      Student
                              </TableCell>
                              {/* Generates table cells for each day of the week for each week */}
                              {Array.from({ length: numberOfWeeks }).map((_, weekIndex) => daysOfWeek.map((day, dayIndex) => (
                                <TableCell align="center" sx={{ borderBottom: "none", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={`day-${weekIndex}-${day}-${dayIndex}`}>
                                  {day}
                                </TableCell>
                              )))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* Iterates and renders the list of student names under the student row */}
                            {schStudentNames.map((studentName) => (
                              <TableRow
                                key={studentName}
                                sx={{
                                  "&:nth-of-type(even)": { backgroundColor: "#F9F9FB" }
                                }}
                              >
                                {/* First cell containing the student name, styled to be sticky for proper scrolling */}
                                <TableCell component="th" scope="row" sx={{ position: "sticky", left: 0, backgroundColor: "white", zIndex: 2 }}>
                                  {studentName}
                                </TableCell>
                                {/* Iterate over attendance data for each week and day */}
                                {attendanceData.flatMap((week, weekIndex) => daysOfWeek.map((day) => {
                                  // Find the attendance record for the specific day(e.g Monday, Tuesday, Thursday etc) converts to lowercase to know which is true from daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri"]
                                  const dayRecord = week?.records.find((record) => record?.day?.toLowerCase()?.startsWith(day?.toLowerCase()));

                                  // If no record exists for the day, render a disabled checkbox
                                  if (!dayRecord) {
                                    return (
                                      <TableCell align="center" sx={{ borderBottom: "0px", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={`empty-${weekIndex}-${day}`}>
                                        {/* <Checkbox
                                          {...label}
                                          defaultChecked
                                          disabled
                                          sx={{
                                            "&.Mui-checked": {
                                              color: "#98A2B3"
                                            }
                                          }}
                                        /> */}
                                        <Icon icon="bxs:checkbox" width="20" height="20" style={{ color: "#98A2B3" }} />
                                      </TableCell>
                                    );
                                  }
                                  // Find the attendance record for a specific student
                                  const attendance = dayRecord?.attendance.find((att) => att?.name === studentName);
                                  // eslint-disable-next-line no-console
                                  // console.log(dayRecord);

                                  // Renders checkbox for the student's attendance, styled based on presence
                                  return (
                                    <TableCell align="center" sx={{ borderBottom: "0px", "&:not(:last-child)": { borderRight: "0.5px solid #6C737F" } }} key={attendance ? `att-${day}-${weekIndex}-${attendance?.id}-${attendance?.name}` : `empty-${weekIndex}-${day}`}>
                                      <Checkbox
                                        {...label}
                                        checked={attendance?.present === true || attendance?.present === false}
                                        disabled={termsData.find(term => term?.id === termValue && !term?.is_current)}
                                        onChange={() => {
                                          // eslint-disable-next-line no-console
                                          console.log("Checkbox clicked:", {
                                            studentId: attendance?.id,
                                            classId: classData[0]?.id,
                                            present: attendance?.present,
                                            day: dayRecord?.day,
                                            date: dayRecord?.date,
                                            week: week?.week
                                          });
                                          handleCheckboxChange(
                                            attendance?.id,
                                            attendance?.present,
                                            dayRecord?.day,
                                            dayRecord?.date,
                                            week?.week
                                          );
                                        }}
                                        sx={{
                                          size: "16px",
                                          color: "inherit",
                                          "&.Mui-checked": {
                                            color: attendance?.present === true ? "#3FAC64" : attendance?.present === false ? "#D42620" : "inherit"
                                          },
                                          "& .MuiSvgIcon-root": {
                                            fontSize: "16px"
                                          }
                                        }}
                                      />
                                    </TableCell>
                                  );
                                }))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                )}
              </TabPanel>
            );
          })}

        </TabContext>

        {/* If Student Attendance is available Legend Key is displayed else null */}
        {schStudentNames?.length > 0 ? (
          <div>
            <div className={`${classes.legends}`}>
              {/* <div className={`${classes.attcounter}`}>
                <p className="margin-bottom-10">Attendance Counter</p>
                <div className={`${classes.attlegend} flex gap-10 align-items-center`}>
                  <h5>Week1 <span><Icon icon="iconamoon:arrow-down-2-thin" width="10px" height="10px" style={{ color: "#0065FF" }} /></span></h5>
                  <h6>100/500</h6>
                </div>
              </div> */}
              <div className={classes.terminfo}>
                <p className="text-align-left">Current Term: {active_term_session?.term || "No active term has been created"}</p>
                <div className="flex gap-10 justify-content-between flex-wrap-items-v430">
                  <p>Starts On: {active_term_session?.start_date?.substring(0, 10) || "---|---"}</p>
                  <p>Ends on: {active_term_session?.end_date?.substring(0, 10) || "---|---"}</p>
                </div>
              </div>
              <div className={`${classes.systemkey}`}>
                <p className="margin-bottom-10">System Key</p>
                <div className={`${classes.attlegend} flex gap-10 align-items-center flex-wrap-items-v430`}>
                  <div className="flex gap-5 align-items-center">
                    <h6>Unselected</h6>
                    <Icon icon="ci:checkbox-unchecked" width="15" height="15" style={{ color: "#98A2B3" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>No Record</h6>
                    <Icon icon="bxs:checkbox" width="17" height="17" style={{ color: "#98A2B3" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Present</h6>
                    <Icon icon="bxs:checkbox-checked" width="17" height="17" style={{ color: "#3FAC64" }} />
                  </div>
                  <div className="flex gap-5 align-items-center">
                    <h6>Absent</h6>
                    <Icon icon="bxs:checkbox-checked" width="17" height="17" style={{ color: "#D42620" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          null
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminAttendance;
