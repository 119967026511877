import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    marginTop: 0,
    gap: 20,
    [theme.breakpoints.down("md")]: {
      flexWrap: "wrap",
      gap: 10
    }
  },
  typo: {
    textAlign: "left"
  },
  title: {
    color: "black",
    fontSize: 20,
    marginBottom: 0,
    letterSpacing: -0.2,
    marginTop: 0,
    fontWeight: 500
  },
  subtitle: {
    color: "#667185",
    fontWeight: 400,
    fontSize: 16,
    marginTop: 0,
    marginBottom: 0
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide
    }
  }
}));
