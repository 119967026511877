/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import ButtonCount from "../../components/ButtonCount";
import useStyles from "./styles";
import Badge from "../../components/Badge";
import { Grid } from "@mui/material";
import Table from "../../components/Table/Table";
// import { AgreedScore, StaffScore, ManagerScore, Employees, GoalCategory } from "../../utils/DropDown";
import { TableSearch } from "../../components/Table/TableActions";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import useValidator from "../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../data/api";
import { useUserState } from "../../context/UserContext";
import { getCurrentRole } from "../../data/roles";

import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";

const GOAL_CATEGORY_ENDPOINT = "/api/v1/goalcategory";
const GOAL_ENDPOINT = "/api/v1/goals";
const EMPLOYEES_ENDPOINT = "/api/v1/employees";

const PerfEval = () => {
  const { userRole } = useUserState();
  const currentRole = getCurrentRole(userRole);

  const IS_ADMIN = currentRole === "admin";
  const classes = useStyles();
  const navigate = useNavigate();
  const [goalCategoryModal, setGoalCategoryModal] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [goalsData, setGoalsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [editInfoModal, setEditInfoModal] = useState(false);
  const [addscoreModal, setAddScoreModal] = useState(false);
  const [createGoalModal, setCreateGoalModal] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [employeeData, setEmployeesData] = useState([]);

  const [categoryCount, setCategoryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoalsFetching, setIsGoalsFetching] = useState(false);

  const [goalCategoryValidator, showGoalCategoryValidator] = useValidator();
  const [goalValidator, showGoalValidator] = useValidator();

  const [goalCategory, setGoalCategory] = useState({
    category: "",
    percentage: ""
  });

  const [goalForm, setGoalForm] = useState({
    goal: "",
    assign_to: "",
    due_date: "",
    state: "",
    category: ""
  });

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setGoalCategory({ ...goalCategory, [name]: value });
  };

  const handleGoalChange = (e) => {
    const { name, value } = e.target;
    setGoalForm({ ...goalForm, [name]: value });
  };

  const handleGoalCategoryModal = () => {
    setGoalCategoryModal(!goalCategoryModal);
  };

  const handleCreateGoalModal = () => {
    setCreateGoalModal(!createGoalModal);
  };

  const handleCloseCreateGoalModal = () => {
    setCreateGoalModal(false);
  };

  const getAllEmployees = () => {
    httprequest.get(EMPLOYEES_ENDPOINT).then((res) => {
      setEmployeesData(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllGoalCategory = () => {
    httprequest.get(GOAL_CATEGORY_ENDPOINT).then((res) => {
      setCategoryData(res?.data?.data?.goalCategories);
      setCategoryCount(res?.data?.data?.count);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllGoals = () => {
    setIsGoalsFetching(true);
    httprequest.get(GOAL_ENDPOINT).then((res) => {
      setIsGoalsFetching(false);

      setGoalsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsGoalsFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleAddscoreModal = (row) => {
    setAddScoreModal(!addscoreModal);
    setCurrentRow(row);
  };

  const handleEditInfoModal = (row) => {
    setEditInfoModal(!editInfoModal);
    setCurrentRow(row);
  };

  const handleCloseEditInfo = () => {
    setEditInfoModal(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCreateGoal = () => {
    if (goalValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(GOAL_ENDPOINT, goalForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllGoals();
        setGoalCategory({ ...goalForm, goal: "", assign_to: "", due_date: "", state: "", category: "" });
        handleCreateGoalModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showGoalValidator(true);
    }
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    getAllGoals();
    getAllGoalCategory();
    getAllEmployees();
  }, []);
  const columns = [
    {
      name: "Name",
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Goal",
      selector: (row) => row?.goal
    },
    {
      name: "Role",
      selector: (row) => row?.role
    },
    {
      name: "Reporting To",
      selector: (row) => row?.reportingto
    },
    {
      name: "Weight",
      selector: (row) => row?.weight
    },
    {
      name: "Point Average",
      selector: (row) => row?.pointAve
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.status} />)
    },

    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            onClick={() => {
              handleAddscoreModal(row);
            }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <button className="table-action-icon" onClick={() => {
            handleEditInfoModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const handleCreateGoalCategory = () => {
    if (goalCategoryValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(GOAL_CATEGORY_ENDPOINT, goalCategory).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllGoalCategory();
        setGoalCategory({ ...goalCategory, category: "", percentage: "" });
        handleGoalCategoryModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showGoalCategoryValidator(true);
    }
  };

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = goalsData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                    data?.name
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                    data?.goal
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { category, percentage } = goalCategory;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Performance Evaluation"
            subtitle="You can create Organisation goals and track goals of your employees"
            button={
              <div className="flex gap-10">
                {IS_ADMIN && (
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={handleGoalCategoryModal}
                    buttonName="Create Goal Category"
                  />
                )}
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={handleCreateGoalModal}
                  buttonName="Create Goals"
                />
              </div>
            }
          />
          <div className="page-content">
            {IS_ADMIN && (
              <div className="button-count-container">
                <ButtonCount func={() => navigate("/manage/goal-category")} title="Goal Category Count" count={categoryCount} />
              </div>
            )}
            <Table
              data={searchResult}
              columns={columns}
              subHeader={true}
              pagination
              subHeaderComponent={
                <TableSearch
                  placeholder="Search here..."
                  searchTableFunc={handleSearchTeacher}
                  isLoading={isGoalsFetching}
                />
              }
            />
          </div>

          {/* Create Goal Modal */}
          <Modal
            title="Goal Planning"
            subtitle={
              <div>Set Goals and Assign to your teachers to track progress.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Goal"
                      margin="normal"
                      required
                      placeHolder="Enter the goal"
                      inputType="text"
                      inputName="goal"
                      value={goalForm?.goal}
                      onChange={handleGoalChange}
                    />
                    {goalValidator.message("goal", goalForm?.goal, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="Category" children={
                      <select name="category" value={goalForm?.category} onChange={handleGoalChange} defaultValue={goalForm?.category}>
                        <option value="">---Select ---</option>
                        {categoryData?.map((label) => {
                          return (
                            <option key={label.id} value={label?.id}>{label?.category}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {goalValidator.message("goal category", goalForm?.category, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="State" children={
                      <select name="state" defaultValue={goalForm?.state} onChange={handleGoalChange}>
                        <option value="">---Select ---</option>
                        <option value="active">Active</option>
                        <option value="inactive">InActive</option>
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="Assign to" children={
                      <select name="assign_to" onChange={handleGoalChange} value={goalForm?.assign_to}>
                        <option value="">--- Select ---</option>
                        {employeeData?.map((employee) => {
                          return (
                            <option key={employee?.employee_id} value={employee?.employee_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {goalValidator.message("assign to", goalForm?.assign_to, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Due Date"
                      margin="normal"
                      required
                      inputType="date"
                      inputName="due_date"
                      onChange={handleGoalChange}
                      value={goalForm?.due_date}
                    />
                    {goalValidator.message("due date", goalForm?.due_date, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Create Goal" buttonSize="full" color="btndefault" onClick={handleCreateGoal} isLoading={isLoading} />
              </>
            }
            isopen={createGoalModal}
            closeModal={handleCloseCreateGoalModal}
          />

          {/* SCORE MODAL */}
          <Modal
            title="Review and Performance Evaluation."
            subtitle={
              <div>Score and employee based off the progress of the goal.
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Goal"
                      required
                      margin="normal"
                      placeHolder="Enter the goal"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup required label="Category" children={
                      <select>
                        <option value="">--- Select ---</option>
                        {categoryData?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <div className={classes.paddingleftmodal}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Manager's Score"
                        margin="normal"
                        required
                        placeHolder=""
                        type="number"
                        inputName="manager_score"
                        value=""
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Staff's Score"
                        margin="normal"
                        placeHolder=""
                        required
                        type="number"
                        inputName="staff_score"
                        value=""
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Agreed Score"
                        margin="normal"
                        required
                        placeHolder=""
                        type="number"
                        inputName="agreed_score"
                        value=""
                      />
                    </Grid>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Continue" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={addscoreModal}
            closeModal={handleAddscoreModal}
          />

          {/* Edit Goals Modal */}
          <Modal
            title={`Goal Planning ${currentRow?.title}`}
            subtitle={
              <div>Set Goals and Assign to your teachers to track progress. {currentRow?.title}
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Goal"
                      required
                      margin="normal"
                      placeHolder="Enter the goal"
                      inputType="text"
                      defaultValue={currentRow?.title}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup required label="Category" children={
                      <select name="category">
                        <option value="">--- Select ---</option>
                        {categoryData?.map((label) => {
                          return (
                            <option key={label.key}>{label?.text}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup required label="Assign to" children={
                      <select name="assign_to" onChange={handleGoalChange}>
                        <option value="">--- Select ---</option>
                        {employeeData?.map((employee) => {
                          return (
                            <option key={employee?.member_id} value={employee?.member_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                          );
                        })}
                      </select>
                    }
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Due Date"
                      margin="normal"
                      inputType="date"
                      required
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Continue" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={editInfoModal}
            closeModal={handleCloseEditInfo}
          />

          {/* Delete GoalsModal */}
          <Modal
            title="Delete Performance Evaluation"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If deleted, employee will no longer have access to this information.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Evaluation" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />

          <Modal
            title="Add New Goal Category"
            subtitle="Create a category for your goals to classify them"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="category" type="text" label="Category Name" value={category} onChange={handleCategoryChange} />
                    {goalCategoryValidator.message("Category", category, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="percentage" type="number" label="Percentage" value={percentage} onChange={handleCategoryChange} />
                    {goalCategoryValidator.message("Percentage", percentage, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleCreateGoalCategory}
                  variant="primary"
                  buttonName="Create Goal Category"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isLoading}
                />
              </>
            }
            isopen={goalCategoryModal}
            closeModal={handleGoalCategoryModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default PerfEval;
