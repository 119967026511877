import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    width: "90% !important",
    margin: "auto",
    borderRadius: 4,
    fontWeight: "bold",
    alignItems: "center",
    position: "relative",
    boxSizing: "border-box",
    // backgroundColor: "transparent",
    outline: 0,
    border: 0,
    cursor: "pointer",
    verticalAlign: "middle",
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "left",
    paddingTop: "6px!important",
    paddingBottom: "6px!important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important",
    "&:hover": {
      backgroundColor: theme.palette.ash.main
    },
    "&:focus, &:active": {
      backgroundColor: theme.palette.background.light
    }
  },
  display: {
    display: "none!important"
  },
  externalLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none"
  },
  linkActive: {
    backgroundColor: theme.palette.background.light
  },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  linkIcon: {
    color: `${theme.palette.color.black500}!important`,
    transition: theme.transitions.create("color"),
    minWidth: "40px !important",
    fontSize: "18px !important",
    display: "flex",
    justifyContent: "center",
    letterSpacing: -2,
    fontWeight: "bold"
  },
  activeParent: {
    backgroundColor: theme.palette.secondary.main
  },
  linkIconActive: {
    color: `${theme.palette.primary.main}!important`
  },
  linkText: {
    padding: 0,
    color: theme.palette.color.black500,
    transition: theme.transitions.create(["opacity", "color"]),
    letterSpacing: -0.5,
    fontWeight: "500!important",
    fontSize: "14px!important"
  },
  linkTextActive: {
    color: theme.palette.primary.main
  },
  linkTextHidden: {
    // opacity: 0
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880"
  }
}));
