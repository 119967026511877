import useStyles from "../../leavemgt/styles";

const AssignmentDetailText = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailstext}>
      <div className={classes.subtitle}>{subtitle}</div>
      <div className={classes.title}>{title}</div>
    </div>
  );
};

export default AssignmentDetailText;
