// import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import TabContext from "@mui/lab/TabContext";
import { ToastContainer, toast } from "react-toastify";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Layout from "../../../components/Layout";
// import BreadCrumb from "../../../components/BreadCrumb";
// import Inactive from "../component/Inactive";
// import Active from "../component/Active";
import PageTitle from "../../../components/PageTitle";
import ButtonCount from "../../../components/ButtonCount";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import Modal from "../../../components/Modal";
import PageLoader from "../../../components/PageLoader";
import Badge from "../../../components/Badge";
// import AllStatus from "../component/AllStatus";
import { httprequest } from "../../../data/api";
import ReactPlayer from "react-player";
// import useStyles from "../styles";
import { accessCreateAccessCode } from "../../../data/roles";
import { useUserState } from "../../../context/UserContext";

const GET_ALL_ACCESS = "/api/v1/access";
const ACCESS_CODES_ENDPOINT = "/api/v1/access_code";

const AccessControl = () => {
  const navigate = useNavigate();
  const { userRole } = useUserState();

  // const classes = useStyles();

  const [searchResult, setSearchResult] = useState([]);
  const [value, setValue] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [revokeModal, setRevokeModal] = useState(false);
  const [isAccessFetching, setIsAccessFetching] = useState(false);
  const [allaccessData, setAllAccessData] = useState([]);
  const [isRevoking, setIsRevoking] = useState(false);
  // const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [selectedVideo, setSelectedVideo] = useState("");
  const [helpModal, setHelpModal] = useState(false);

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  // const videos = [
  //   { url: "https://youtu.be/xtL0JyYCgds", label: "Access Codes" },
  //   { url: "https://youtu.be/TyiwWLcpu6k", label: "Access Logs" },
  //   { url: "https://youtu.be/fccZPQN-sVk", label: "Validate Code System" }
  // ];

  // const openVideoModal = (url) => {
  //   setSelectedVideo(url);
  //   setModalIsOpen(true);
  // };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  const handleRevokeModal = (row) => {
    setCurrentRow(row);
    setRevokeModal(true);
  };

  const handleCloseRevokeModal = () => {
    setCurrentRow({});
    setRevokeModal(false);
  };

  const getAllAccessCodes = () => {
    setIsAccessFetching(true);
    httprequest.get(GET_ALL_ACCESS).then((res) => {
      setIsAccessFetching(false);
      setAllAccessData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsAccessFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleRevokeCode = () => {
    setIsRevoking(true);
    httprequest.patch(`${ACCESS_CODES_ENDPOINT}/${currentRow?.access_code}`).then((res) => {
      setIsRevoking(false);
      toast.success(res?.data?.message);
      getAllAccessCodes();
      handleCloseRevokeModal();
    }).catch((err) => {
      setIsRevoking(false);
      toast.error(err?.response?.data?.message);
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "active") {
      // setAllAccessData(allaccessData?.filter((access) => access?.status === "active"));
      setSearchResult(allaccessData?.filter((access) => access?.status === "active"));
    } else if (newValue === "inactive") {
      // setAllAccessData(allaccessData?.filter((access) => access?.status === "inactive"));
      setSearchResult(allaccessData?.filter((access) => access?.status !== "active"));
    } else {
      setSearchResult(allaccessData);
    }
  };

  useEffect(() => {
    getAllAccessCodes();
    setValue("all");
  }, []);

  // HAndle copy passcode
  const handleCopyPasscode = (row) => {
    setCurrentRow(row);
    navigator.clipboard.writeText(row?.access_code)
      .then(() => {
        toast.success(`${row?.access_code} has been copied`);
      })
      .catch((err) => {
        toast.error("Failed to copy access code", err);
      });
  };

  const columns = [
    {
      name: "Visitor's Name",
      selector: (row) => row?.visitor_name
    },
    {
      name: "Child Name",
      selector: (row) => row?.student?.name
    },
    {
      name: "Access Type",
      selector: (row) => (<Badge status={row?.type} />)
    },
    {
      name: "Issued By",
      selector: (row) => row?.issuer?.name
    },
    {
      name: "Access Code",
      selector: (row) => row?.access_code
    },
    {
      name: "Expiry Date",
      selector: (row) => row?.end_date?.substring(0, 10) || "N/A"
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/accesscontrol/codes/${row?.access_code}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <button className="table-action-icon" onClick={() => handleCopyPasscode(row)}>
            <Icon icon="ph:copy" width="16" height="16" />
          </button>
          {row?.status === "active" ? (
            <button
              className="table-action-icon delete-icon" onClick={() => handleRevokeModal(row)}
            >
              <Icon icon="mdi:link-lock" />
            </button>
          ) :
            <button
              className="table-action-icon" disabled
            >
              <Icon icon="mdi:link-lock" />
            </button>}
        </div>
      )
    }
  ];

  useEffect(() => {
    // The data will be from the Email get api endpint
    setSearchResult(allaccessData);
  }, []);

  // --| Filter Shelf21 table using name, email and class
  const handleAccessControl = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allaccessData?.filter((data) => valArray?.every(
      (word) => data?.visitor_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                      data?.email
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                      data?.student?.name
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                        data?.access_code
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                          data?.issuer?.name
                                            ?.toLowerCase()
                                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  // --| Filter Shelf21 table using name, email and class
  const handleSearchActive = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allaccessData?.filter((access) => (access?.status === "active"))?.filter((data) => valArray?.every(
      (word) => data?.visitor_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                        data?.student?.name
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                        data?.issuer?.name
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                          data?.type
                                            ?.toLowerCase()
                                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  // const handleCloseLeaveModal = () => {
  //   setLeaveModal(false);
  // };

  return (
    <Layout
      children={
        <div>
          <PageTitle
            title="All Access Codes"
            subtitle="See a list of all the access codes that has been shared throughout the organisation."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="?"
                  onClick={handleHelpModal}
                />
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="Validate a code"
                  onClick={() => navigate("/accesscontrol/validate")}
                />
                {accessCreateAccessCode(userRole) && (
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={() => navigate("/accesscontrol/codes/create")}
                    buttonName="Create New Code"
                  />
                )}
              </div>
            }
          />
          <div className="page-content">
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="All Access" value="all" />
                  <Tab label="Active" value="active" />
                  <Tab label="Inactive" value="inactive" />
                </Tabs>
              </div>
              <>
                <TabPanel value="all" className="tabpanel-overide">
                  <div>
                    {isAccessFetching ? (
                      <PageLoader />
                    ) : (
                      <>
                        <div className="button-count-container">
                          <ButtonCount
                            title="All Access"
                            count={searchResult?.length}
                          />
                        </div>
                        <Table
                          data={searchResult}
                          columns={columns}
                          subHeader={true}
                          pagination
                          subHeaderComponent={
                            <TableSearch
                              placeholder="Search here..."
                              searchTableFunc={handleAccessControl}
                            />
                          }
                        />
                      </>
                    )}
                    {/* Revoke Modal */}
                    <Modal
                      title="Revoke Access Code"
                      subtitle={
                        <div>
                          Are you sure you want to Revoke{" "}
                          <span className="modal-name-highlight">
                            {currentRow?.access_code}
                          </span>
                          . from one of your Activities? Revoking it will stop
                          the code from working.`
                        </div>
                      }
                      modalContent={<></>}
                      modalFooter={
                        <>
                          <div className="flex gap-10">
                            <Button
                              variant="dangeroutline"
                              buttonSize="full"
                              color="btndanger"
                              buttonName="Cancel"
                              onClick={handleCloseRevokeModal}
                            />
                            <Button
                              variant="danger"
                              buttonName="Revoke Access Code"
                              buttonSize="full"
                              color="btndefault"
                              onClick={handleRevokeCode}
                              isLoading={isRevoking}
                            />
                          </div>
                        </>
                      }
                      isopen={revokeModal}
                      closeModal={handleCloseRevokeModal}
                    />
                  </div>
                </TabPanel>
                <TabPanel value="active" className="tabpanel-overide">
                  {isAccessFetching ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="button-count-container">
                        <ButtonCount
                          title="Active Access"
                          count={searchResult?.length}
                        />
                      </div>
                      <Table
                        data={searchResult}
                        columns={columns}
                        subHeader={true}
                        pagination
                        subHeaderComponent={
                          <TableSearch
                            placeholder="Search active access here..."
                            searchTableFunc={handleSearchActive}
                          />
                        }
                      />
                    </>
                  )}
                </TabPanel>
                <TabPanel value="inactive" className="tabpanel-overide">
                  {isAccessFetching ? (
                    <PageLoader />
                  ) : (
                    <>
                      <div className="button-count-container">
                        <ButtonCount
                          title="Inactive Access"
                          count={searchResult?.length}
                        />
                      </div>
                      <Table
                        data={searchResult}
                        columns={columns}
                        subHeader={true}
                        pagination
                        subHeaderComponent={
                          <TableSearch
                            placeholder="Search here..."
                            searchTableFunc={handleAccessControl}
                          />
                        }
                      />
                    </>
                  )}
                </TabPanel>
              </>
            </TabContext>
            {/* <Modal
              title="Need Help?"
              subtitle="Watch the Video Below"
              modalContent={
                <>
                  <div className="help_video">
                    <div className="help_video">
                      {videos?.map((video, index) => (
                        <div key={index} onClick={() => openVideoModal(video?.url)} className={classes.helpurl}>
                          <p>
                            {video?.label}
                          </p>
                          <Icon icon="ph:video" width="15" height="15" style={{ color: "black" }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              }
              modalFooter={<></>}
              isopen={leaveModal}
              closeModal={handleCloseLeaveModal}
            /> */}
            <Modal
              title="Need Help?"
              subtitle="Watch the Video Below on Access Codes"
              modalContent={
                <>
                  <ReactPlayer
                    url="https://youtu.be/xtL0JyYCgds"
                    playing={true}
                    controls={true}
                    width="100%"
                  />
                </>
              }
              modalFooter={
                <>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    onClick={handleCloseVideoModal}
                    buttonName="Close Video"
                  />
                </>}
              isopen={helpModal}
              closeModal={handleCloseVideoModal}
            />
          </div>
          <ToastContainer />
        </div>
      }
    />
  );
};

export default AccessControl;
