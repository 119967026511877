import Layout from "../../components/Layout";
import Table from "../../components/Table/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../components/Table/TableActions";
import BreadCrumb from "../../components/BreadCrumb";
import { Icon } from "@iconify/react";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import { useUserState } from "../../context/UserContext";
import { getCurrentRole } from "../../data/roles";
import Permission from "../error/Permission";

const LABEL_ENDPOINT = "/api/v1/labels";

const ResourceLabel = () => {
  const { userRole } = useUserState();
  const currentRole = getCurrentRole(userRole);

  const IS_ADMIN = currentRole === "admin";
  // const IS_USER = currentRole === "user";
  const [labelModal, setLabelModal] = useState(false);
  const [editLabelModal, setEditLabelModal] = useState(false);
  const [deleteLabelModal, setDeleteLabelModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isLabelsFetching, setIsLabelFetching] = useState(false);
  const [labelsData, setLabelsData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [labelsValidator, showLabelsValidator] = useValidator();
  const [editLabelsValidator, showEditLabelValidator] = useValidator();
  const [labelForm, setLabelForm] = useState({
    label: ""
  });

  const [editLabelForm, setEditLabelForm] = useState({
    label: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLabelForm({ ...labelForm, [name]: value });
  };

  // eslint-disable-next-line no-console
  console.log(labelForm);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditLabelForm({ ...editLabelForm, [name]: value });
  };

  const handleDeleteModal = (row) => {
    setDeleteLabelModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteLabelModal(false);
    setCurrentDelete({});
  };

  const handleLabelModal = () => {
    setLabelModal(!labelModal);
  };

  const handleEditLabelModal = (row) => {
    setCurrentRow(row);
    setEditLabelForm({ ...editLabelForm, label: row?.label });
    setEditLabelModal(true);
  };

  const handleCloseEditLabelModal = () => {
    setCurrentRow({});
    setEditLabelForm({ ...editLabelForm, label: "" });
    setEditLabelModal(false);
  };

  const getAlLabels = () => {
    setIsLabelFetching(true);
    httprequest.get(LABEL_ENDPOINT).then((res) => {
      setIsLabelFetching(false);
      setLabelsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsLabelFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateLabel = () => {
    if (labelsValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(LABEL_ENDPOINT, labelForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAlLabels();
        setLabelForm({ ...labelForm, label: "" });
        handleLabelModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showLabelsValidator(true);
    }
  };

  const handleDeleteLabel = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${LABEL_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Label Deleted Successfully");
      getAlLabels();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditLabel = () => {
    if (editLabelsValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${LABEL_ENDPOINT}/${currentRow?.id}`, editLabelForm).then(() => {
        setIsEditLoading(false);
        getAlLabels();
        toast.success("Labels Edited");
        setEditLabelForm({ ...editLabelForm, label: "" });
        handleCloseEditLabelModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditLabelModal();
      });
    } else {
      showEditLabelValidator(true);
    }
  };

  // --| Filter Materials Label table using label
  const handleSearchLabel = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = labelsData?.filter((data) => valArray?.every(
      (word) => data?.label
        ?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAlLabels();
  }, []);

  const columns = [
    {
      name: "Labels",
      selector: (row) => row?.label
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditLabelModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { label } = labelForm;

  return (
    <>
      {IS_ADMIN ? (
        <Layout
          children={
            <>
              <div className="breadcrumb-container">
                <div>
                  <BreadCrumb
                    link_one="/manage/knowledgebase"
                    link_one_name="Resources"
                    active="Material Labels"
                    description="See the label tags of your emp."
                  />
                </div>
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleLabelModal}
                  buttonName="Add Label Tag"
                />
              </div>

              <Table
                data={searchResult}
                columns={columns}
                subHeader={true}
                isLoading={isLabelsFetching}
                subHeaderComponent={<TableSearch placeholder="Search here..." searchTableFunc={handleSearchLabel} />}
                pagination
              />
              <Modal
                title="Add New Label tag "
                subtitle="Adding label tags ensure proper classification on your materials."
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup inputName="label" type="text" label="Label" value={label} onChange={handleChange} />
                        {labelsValidator.message("Label", label, "required")}
                      </Grid>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      onClick={handleCreateLabel}
                      variant="primary"
                      buttonName="Create Label tag"
                      buttonSize="full"
                      color="btndefault"
                      isLoading={isLoading}
                    />
                  </>
                }
                isopen={labelModal}
                closeModal={handleLabelModal}
              />
              <Modal
                title="Delete Current Label Tag"
                subtitle={`Are you sure you want to delete this  ${currentDelete?.label} label tag?. If you delete it, it becomes unavaiable for the whole school.`}
                modalContent={
                  <>
                    <div>
                    </div>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      variant="danger"
                      buttonName="Delete Leave Request"
                      buttonSize="full"
                      color="btndefault"
                      onClick={handleDeleteLabel}
                      isLoading={isDeleteLoading}
                    />
                  </>
                }
                isopen={deleteLabelModal}
                closeModal={handleCloseDeleteModal}
              />

              {/* Modal to edit label tag */}
              <Modal
                title="Edit Label Tag"
                subtitle="Edit your current label tag"
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <InputGroup inputName="type" type="text" label="Leave Type" placeholder="e.g Annual Leave" defaultValue={editLabelForm?.label} onChange={handleEditChange} />
                        {editLabelsValidator.message("Label", editLabelForm?.label, "required")}
                      </Grid>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <Button
                      type="submit"
                      onClick={handleEditLabel}
                      variant="primary"
                      buttonName="Edit Label Tag"
                      buttonSize="full"
                      color="btndefault"
                      isLoading={isEditLoading}
                    />
                  </>
                }
                isopen={editLabelModal}
                closeModal={handleCloseEditLabelModal}
              />
              <ToastContainer />
            </>
          }
        />
      ) : <Permission /> }
    </>

  );
};

export default ResourceLabel;
