import Layout from "../../components/Layout";
// import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Badge from "../../components/Badge";
import { Icon } from "@iconify/react";
import BreadCrumb from "../../components/BreadCrumb";
// import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
// import useStyles from "./styles";

// --| Dummy data
import archivedInventoryData from "../../dummy_data/archivedinventory.json";

const ArchivedInventory = () => {
  // const classes = useStyles();
  // const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    // The data will be from the knowledgebase get api endpint
    setSearchResult(archivedInventoryData);
  }, []);
  const columns = [
    {
      name: "Name",
      selector: row => row?.name
    },
    {
      name: "Description",
      selector: row => row?.description
    },
    {
      name: "Issue No.",
      selector: row => row?.issueNo
    },
    {
      name: "Category",
      selector: row => row?.category
    },
    {
      name: "Date",
      selector: row => row?.date
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon">
            <Icon icon="mage:reload" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = archivedInventoryData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                      data?.title
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                      data?.label
                        ?.toLowerCase()
                        .includes(word.toLowerCase()) ||
                        data?.type
                          ?.toLowerCase()
                          .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <BreadCrumb
            link_one="/manage/inventory"
            link_one_name="Inventory"
            active="Archived Inventory"
            description="View all Archived inventory."
          />
          {/* <PageTitle
            title="Inventory"
            subtitle="See the inventory of all items gotten."
          /> */}
          <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
            <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
          }
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Performance Evaluation"
            subtitle={
              <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If deleted, employee will no longer have access to this Inventory.`
              </div>}
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                <Button variant="danger" buttonName="Delete Evaluation" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
        </>
      }
    />
  );
};

export default ArchivedInventory;
