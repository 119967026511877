import useStyles from "./styles";
import PropTypes from "prop-types";
// import { useState } from "react";
import Button from "../../components/Button";

const IntegrationCard = ({ logo, title, type, handleIntegrateModal, handleSchIntegration, isIntegrated }) => {
  const classes = useStyles();

  return (
    <div className={classes.maincontainer}>
      <div className={classes.subcontainer}>
        <div><img src={logo} alt="logo" height="25px" /></div>
        <h5>{title}</h5>
        <h6 style={{ textTransform: "capitalize" }}>Category: {type}</h6>
        <div className={classes.integratebtn}>
          <Button
            variant={isIntegrated ? "dangeroutline" : "secondaryoutline"}
            buttonSize="full"
            color={isIntegrated ? "btndanger" : "btnblack"}
            onClick={() => {
              if (isIntegrated) {
                // Handle disconnection directly
                handleSchIntegration({ title });
              } else {
                // Show modal for integration
                handleIntegrateModal({ title, type });
              }
            }}
            buttonName={isIntegrated ? "Disconnect" : "Integrate"}
          />
        </div>
      </div>
    </div>
  );
};
IntegrationCard.propTypes = {
  logo: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string
};

export default IntegrationCard;
