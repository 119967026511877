import useStyles from "./styles";

// --| CardCount takes 3 props title, icon and count
const CardCount = ({ title, icon, count, leftSubText, rightSubText, leftSubValue, rightSubValue }) => {
  const classes = useStyles();

  return (
    <div className={classes.overallcont}>
      <div className={classes.cardcount}>
        <div className={classes.left}>
          <h6 className={classes.title}>{title}</h6>
          <p className={classes.count}>{count}</p>
        </div>
        <div className={classes.icon}>{icon}</div>
      </div>
      <hr />
      <div className={classes.bottomcont}>
        <div className="flex gap-5">
          <p>{leftSubText}</p> <p className="font-800">{leftSubValue}</p>
        </div>
        <div className="flex gap-5">
          <p>{rightSubText}</p> <p className="font-800">{rightSubValue}</p>
        </div>
      </div>
    </div>
  );
};

export default CardCount;
