import { ListItem, ListItemIcon, ListItemText, Collapse, List } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import classNames from "classnames";
import useStyles from "./styles";
import Dot from "../Dot";

const SidebarLink = ({
  label,
  link,
  icon,
  children = [],
  isSidebarOpened,
  openDropdown,
  access,
  handleToggleDropdown,
  activeLink,
  setActiveLink,
  isChild = false
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const isActive = activeLink === link || children.some(child => activeLink.startsWith(child.link));
  const isParentActive = activeLink.startsWith(link);

  const handleClick = () => {
    if (children.length) {
      handleToggleDropdown(label);
    } else {
      setActiveLink(link);
      navigate(link);
    }
  };

  return (
    <>
      <ListItem
        // button
        className={classNames(classes.link, { [classes.activeParent]: isParentActive && !isChild }, { [classes.display]: access === false })}
        onClick={handleClick}
      >
        <ListItemIcon className={classNames(classes.linkIcon, { [classes.linkIconActive]: isActive })}>
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classNames(classes.linkText, {
              [classes.linkTextActive]: isActive,
              [classes.linkTextHidden]: !isSidebarOpened
            })
          }}
          primary={label}
        />
        {children.length > 0 ? (openDropdown === label ? <ExpandLess /> : <ExpandMore />) : null}
      </ListItem>
      {children.length > 0 && (
        <Collapse in={openDropdown === label} timeout="auto" unmountOnExit className={classes.nestedList}>
          <List component="div" disablePadding>
            {children.map(child => (
              <SidebarLink
                key={child.label}
                {...child}
                isSidebarOpened={isSidebarOpened}
                openDropdown={openDropdown}
                handleToggleDropdown={handleToggleDropdown}
                icon={<Dot color={(isActive && activeLink === child.link) && "primary" } />}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                isChild
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default SidebarLink;
