import Layout from "../../components/Layout";
import { Grid } from "@mui/material";
import useStyles from "./styles";
import Button from "../../components/Button";
import { useState, useEffect } from "react";
import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import BreadCrumb from "../../components/BreadCrumb";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";

const INVENTORY_ENDPOINT = "/api/v1/inventory";
const INVENTORY_CATEGORY_ENDPOINT = "/api/v1/inventory/category";
const EMPLOYEES_ENDPOINT = "/api/v1/employees";

const CreateInventory = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [inventoryCategory, setInventoryCategory] = useState([]);
  const [inventoryValidator, showInventoryValidator] = useValidator();
  const [employeeData, setEmployeesData] = useState([]);

  const [inventoryForm, setInventoryForm] = useState({
    name: "",
    description: "",
    category: "",
    employee_in_charge: "",
    quantity: "",
    condition: ""
  });

  const getAllInventoryCategory = () => {
    httprequest.get(INVENTORY_CATEGORY_ENDPOINT).then((res) => {
      setInventoryCategory(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllEmployees = () => {
    httprequest.get(EMPLOYEES_ENDPOINT).then((res) => {
      setEmployeesData(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCreateInventory = () => {
    if (inventoryValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(INVENTORY_ENDPOINT, inventoryForm).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        setInventoryForm({ ...inventoryForm, name: "", description: "", category: "", employee_in_charge: "", quantity: "", condition: "" });
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showInventoryValidator(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInventoryForm({ ...inventoryForm, [name]: value });
  };

  useEffect(() => {
    getAllInventoryCategory();
    getAllEmployees();
  }, []);

  const { name, description, category, employee_in_charge, quantity, condition } = inventoryForm;

  return (
    <Layout children={
      <>
        <BreadCrumb
          link_one="/manage/inventory"
          link_one_name="Inventory"
          active="Create Inventory"
          description="Fill in the details to create a new inventory."
        />
        <Grid container spacing={2} my={4} className={classes.createFormContainer}>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Product Name"
              margin="normal"
              required
              placeHolder="Enter product name"
              inputType="text"
              inputName="name"
              value={name}
              onChange={handleChange}
            />
            {inventoryValidator.message("Product Name", name, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Description"
              margin="normal"
              required
              inputType="text"
              inputName="description"
              value={description}
              onChange={handleChange}
            />
            {inventoryValidator.message("Description", description, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup required label="Category" children={
              <select onChange={handleChange} value={category} name="category">
                <option value="">--- Select ---</option>
                {inventoryCategory?.map((data) => {
                  return (
                    <option key={data?.id} value={data?.id}>{data?.category}</option>
                  );
                })}
              </select>
            }
            />
            {inventoryValidator.message("Category", category, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <InputGroup
              label="Quantity"
              margin="normal"
              required
              placeHolder=""
              inputType="number"
              inputName="quantity"
              value={quantity}
              onChange={handleChange}
            />
            {inventoryValidator.message("Quantity", quantity, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup required label="Condition" children={
              <select name="condition" onChange={handleChange} value={condition}>
                <option value="">--- Select ---</option>
                <option value="new">New</option>
                <option value="used">Used</option>
              </select>
            }
            />
            {inventoryValidator.message("Condition", condition, "required")}
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <SelectGroup required label="Teacher in Charge" children={
              <select onChange={handleChange} name="employee_in_charge" value={employee_in_charge}>
                <option value="">--- Select ---</option>
                {employeeData?.map((employee) => {
                  return (
                    <option key={employee?.member_id} value={employee?.member_id}>{employee?.first_name}{" "}{employee?.last_name}</option>
                  );
                })}
              </select>
            }
            />
            {inventoryValidator.message("Employee", employee_in_charge, "required")}
          </Grid>
        </Grid>
        <Button variant="primary" buttonSize="medium" color="btndefault" buttonName="Create Inventory" onClick={handleCreateInventory} isLoading={isLoading} />
        <ToastContainer />
      </>
    }
    />
  );
};

export default CreateInventory;
