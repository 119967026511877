import styles from "../settings.module.css";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { toast } from "react-toastify";
import Table from "../../../components/Table/Table";
import PageLoader from "../../../components/PageLoader";
import Button from "../../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";

const ROLE_ENDPOINT = "/api/v1/roles";
// const ALL_PERMISSIONS = "/api/v1/permissions";
const ViewRoles = () => {
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const addRole = () => {
    navigate("/settings/addRole");
  };

  // Get All roles
  const getRoles = () => {
    setIsLoading(true);
    httprequest.get(ROLE_ENDPOINT).then((res) => {
      const roleList = res?.data?.data;
      setRoles(roleList);
      setIsLoading(false);
    }).catch((err) => {
      toast.error(err?.res.data.message);
    });
  };

  useEffect(() => {
    getRoles();
  }, []);

  const columns = [
    {
      name: "Roles",
      selector: row => row?.role_name
    },
    {
      name: "Role Type",
      selector: row => row?.role_type
    },
    {
      name: "Users",
      selector: row => row?.user_count
    },
    {
      name: "Action",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.role_id} className="table-action-container">
          {row.role_name === "School Admin" ? (
            null
          ) : (
            <Link to={`/settings/updaterole/${row.role_id}`} className="table-action-icon">
              <Icon icon="lucide:edit" />
            </Link>
          )}
        </div>
      )
    }
  ];

  return (
    <div className={`${styles.allcontent}`}>
      <div className="text-align" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <h4 className={`${styles.changepassword}`}>Roles and Permission</h4>
          <p className={`${styles.passwordtext}`}>Manage Roles and Permissions in your organisation.</p>
        </div>
        <div className="flex gap-10">
          <Button onClick={addRole} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Add Role" />
        </div>
      </div>
      <div className="margin-top-30">
        {isLoading ? (
          <PageLoader />
        ) : (
          <Table
            data={roles}
            columns={columns}
            pagination
          />
        )}
      </div>
    </div>
  );
};

export default ViewRoles;
