/* eslint-disable prefer-template */
import Layout from "../../../../components/Layout";
import useStyles from "../../styles";
import Button from "../../../../components/Button";
import PageTitle from "../../../../components/PageTitle";
import BreadCrumb from "../../../../components/BreadCrumb";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import Badge from "../../../../components/Badge";
import { httprequest } from "../../../../data/api";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WidgetTitle from "../../../dashboard/components/WidgetTitle/WidgetTitle";
import Table from "../../../../components/Table/Table";
import { TableSearch } from "../../../../components/Table/TableActions";
import AssignmentDetailText from "../../../assignment/components/AssignmentDetailText";

const ENROLMENT_EXAM_ENDPOINT = "/api/v1/enrolment-exams";

const EnrolmentExaminationDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [enrolmentExamData, setEnrolmentExamData] = useState([]);
  const [value, setValue] = useState("1");
  const [assignmentAttendees, setAssignmentAttendees] = useState([]);
  const [attendeeFilter, setAttendeeFilter] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const columns = [
    {
      name: "Student Name",
      selector: row => row?.student?.name
    },
    {
      name: "Class Name",
      selector: row => row?.class?.name
    },
    {
      name: "Status",
      selector: row => <Badge status={row?.status} />
    },
    {
      name: "Submission",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.submission_id} className="table-action-container">
          <Link to={`/assignment/submission/${row?.submission_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
        </div>
      )
    }
  ];

  const getEnrolmentExaminationById = () => {
    httprequest.get(`${ENROLMENT_EXAM_ENDPOINT}/${id}`).then((res) => {
      setEnrolmentExamData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(enrolmentExamData, "enrolmentExamData");

  const getEnrolmentExaminationRecipient = () => {
    httprequest.get(`${ENROLMENT_EXAM_ENDPOINT}/students/${id}`).then((res) => {
      setAssignmentAttendees(res?.data?.data);
      setAttendeeFilter(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // --| Filter Attendees table using name
  const handleSearchEnrolExam = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = assignmentAttendees?.filter((data) => valArray?.every(
      (word) => data?.student_name
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setAttendeeFilter(result);
  };

  useEffect(() => {
    if (value === "1") {
      getEnrolmentExaminationById();
    } else {
      getEnrolmentExaminationRecipient();
    }
  }, [value]);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/enrolments/enrolment-examination"
                link_one_name="Enrolment Examination"
                active={`${enrolmentExamData?.subject} Enrolment Exam`}
                description="See the details of this Enrolment Examination"
              />
            </div>
          </div>
          <div className="page-content">
            <PageTitle className={classes.inline}
              title={`${enrolmentExamData?.title}`}
              subtitle={`Created By:${enrolmentExamData?.created_by?.name} | Class: ${enrolmentExamData?.classes?.map((class_names) => class_names?.name).join(", ")} `}
              button={
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={() => navigate(`/enrolments/edit-enrolment-examination/${id}`)}
                    buttonName="Edit Enrolment Examination"
                  />
                </div>
              }
            />
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                  <Tab label="Assignment Details" value="1" />
                  {/* <Tab label="Attendees" value="2" /> */}
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                <Grid container spacing={1}>
                  <div className="key-pair-container">
                    <Grid container spacing={1}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Subject Name" subtitle={enrolmentExamData?.subject || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Created By" subtitle={enrolmentExamData?.created_by?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Created at" subtitle={enrolmentExamData?.created_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Title" subtitle={enrolmentExamData?.title || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Updated At" subtitle={enrolmentExamData?.updated_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText
                          title="Status"
                          subtitle={
                            <div className="subtitle-container">
                              {<Badge status={enrolmentExamData?.status} /> || "-----"}
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item lg={2} md={4} sm={6} xs={12}>
                        <AssignmentDetailText title="Instruction" subtitle={enrolmentExamData?.instruction || "-----"} />
                      </Grid>
                    </Grid>
                  </div>
                  {/* <div className="key-pair-container">
                    <div className="key-pair-content">
                      <p className="key-pair-name">Status</p>
                      <h3 className="key-pair-value"> <Badge status={enrolmentExamData?.status} /></h3>
                    </div>
                  </div> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <WidgetTitle
                      title="Questions"
                    />
                    <Grid container spacing={2}>
                      {enrolmentExamData?.questions?.map((item, i) => {
                        const hasImageOption = item?.options?.some(option => typeof option === "object" && option.file_url);

                        return (
                          <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                            <div className="question-preview-container">
                              <h3 className="question-preview-heading">
                                Question {item?.question_index}
                              </h3>
                              <div className={classes.question}>
                                <h3 className="question-preview-heading">
                                  {item?.question} {item?.required ? <span className="input-required">*</span> : null}
                                  {item?.file && (
                                    <div className={classes.quesdisplay}>
                                      <img src={item?.file?.file_url} alt="" />
                                    </div>
                                  )}
                                </h3>

                                {item?.type === "multiple_choice" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="radio" checked={enrolmentExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="radio" checked={enrolmentExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : item?.type === "checkbox" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="checkbox" checked={enrolmentExamData?.questions[item?.question_index]?.answer?.includes(i)} name={item?.question_index} />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="checkbox" checked={enrolmentExamData?.questions[item?.question_index]?.answer?.includes(i)} name={item?.question_index} />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </Grid>
                        );
                      })}

                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Table data={attendeeFilter} columns={columns} subHeader={true} pagination subHeaderComponent={
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchEnrolExam} />
                }
                />
              </TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default EnrolmentExaminationDetails;
