import useStyles from "./styles";
import { Icon } from "@iconify/react/dist/iconify.js";

const NoticeBoardCard = ({ title, date, viewClick, priority }) => {
  const classes = useStyles({ title });

  return (
    <div className={classes.noticecard}>
      <div className="text-align-left">
        <h5>{title}</h5>
        <p style={{ fontSize: "12px", padding: "7px 0" }}><Icon icon="uil:calender" width="10px" height="10px" style={{ color: "black" }} /> Added on: {date}</p>
        <span style={{ color: priority === "high" ? "red" : priority === "medium" ? "#0291d9" : "#0F973D", fontSize: "12px", display: "flex", alignItems: "center", textTransform: "capitalize", fontWeight: "600" }}>
          {priority} priority
          <span style={{ marginLeft: "6px", display: "flex", alignItems: "center", color: priority === "high" ? "red" : priority === "medium" ? "#0291d9" : "#0F973D" }}><Icon icon="prime:flag-fill" /></span>
        </span>
      </div>
      <div onClick={viewClick} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px", color: "#013EBA" }} className="text-align-right">
        {/* <p style={{ fontSize: "12px" }}>Added By:  {teacher}</p> */}
        <h4 style={{ width: "fit-content", textDecoration: "underline", color: "#013EBA", fontWeight: "600" }}>View Notice</h4>  <Icon icon="majesticons:open" />
      </div>
    </div>
  );
};

export default NoticeBoardCard;
