/* eslint-disable prefer-template */
import Layout from "../../../../components/Layout";
import useStyles from "../../styles";
import Button from "../../../../components/Button";
import PageTitle from "../../../../components/PageTitle";
import BreadCrumb from "../../../../components/BreadCrumb";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid } from "@mui/material";
import Badge from "../../../../components/Badge";
import { httprequest } from "../../../../data/api";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import WidgetTitle from "../../../dashboard/components/WidgetTitle/WidgetTitle";
import AssignmentDetailText from "../../../assignment/components/AssignmentDetailText";

const SCH_EXAM_ENDPOINT = "/api/v1/school-exams";

const SchoolExamDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [schoolExamData, setSchoolExamData] = useState([]);
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getEnrolmentExaminationById = () => {
    httprequest.get(`${SCH_EXAM_ENDPOINT}/${id}`).then((res) => {
      setSchoolExamData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(schoolExamData, "schoolExamData");

  useEffect(() => {
    if (value === "1") {
      getEnrolmentExaminationById();
    }
  }, [value]);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/school-examination/examination"
                link_one_name="School Examinations"
                active={`${schoolExamData?.subject?.name} Examination`}
                description={`See Details of ${schoolExamData?.subject?.name} Examination`}
              />
            </div>
          </div>
          <div className="page-content">
            <PageTitle className={classes.inline}
              title={`${schoolExamData?.title}`}
              subtitle={`Created By: ${schoolExamData?.created_by?.name} | Class: ${schoolExamData?.classes
                ?.map((clss) => clss?.name)
                .join(", ")}`}
              button={
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={() => navigate(`/school-examination/edit-examination/${id}`)}
                    buttonName="Edit School Examination"
                  />
                </div>
              }
            />
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                  <Tab label="School Examination Details" value="1" />
                  {/* <Tab label="Attendees" value="2" /> */}
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                <Grid container spacing={1}>
                  <div className="key-pair-container">
                    <Grid container spacing={1}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Subject Name" subtitle={schoolExamData?.subject?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <AssignmentDetailText title="Created By" subtitle={schoolExamData?.created_by?.name || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Created at" subtitle={schoolExamData?.created_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Total Grade" subtitle={schoolExamData?.total_grade || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Duration" subtitle={`${schoolExamData?.duration || "-----"} mins`} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText title="Updated At" subtitle={schoolExamData?.updated_at?.substring(0, 10) || "-----"} />
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={6}>
                        <AssignmentDetailText
                          title="Status"
                          subtitle={
                            <div className="subtitle-container">
                              {<Badge status={schoolExamData?.status} /> || "-----"}
                            </div>
                          }
                        />
                      </Grid>
                      <Grid item lg={2} md={4} sm={6} xs={12}>
                        <AssignmentDetailText title="Instruction" subtitle={schoolExamData?.instruction || "-----"} />
                      </Grid>
                    </Grid>
                  </div>
                  {/* <div className="key-pair-container">
                    <div className="key-pair-content">
                      <p className="key-pair-name">Status</p>
                      <h3 className="key-pair-value"> <Badge status={schoolExamData?.status} /></h3>
                    </div>
                  </div> */}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <WidgetTitle
                      title="Questions"
                    />
                    <Grid container spacing={2}>
                      {schoolExamData?.questions?.map((item, i) => {
                        const hasImageOption = item?.options?.some(option => typeof option === "object" && option.file_url);

                        return (
                          <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
                            <div className="question-preview-container">
                              <h3 className="question-preview-heading">
                                Question {item?.question_index}
                              </h3>
                              <div className={classes.question}>
                                <h3 className="question-preview-heading">
                                  {item?.question} {item?.required ? <span className="input-required">*</span> : null}
                                  {item?.file && (
                                    <div className={classes.quesdisplay}>
                                      <img src={item?.file?.file_url} alt="" />
                                    </div>
                                  )}
                                </h3>

                                {item?.type === "multiple_choice" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={6} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="radio" checked={schoolExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="radio" checked={schoolExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : item?.type === "checkbox" ? (
                                  hasImageOption ? (
                                    <Grid container spacing={2}>
                                      {item?.options?.map((option, i) => {
                                        const isImageOption = typeof option === "object" && option.file_url;

                                        return (
                                          <Grid item lg={6} md={6} sm={6} xs={12} key={i}>
                                            <div className="options-preview">
                                              <input type="checkbox" checked={schoolExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                              {isImageOption ? (
                                                <div className={classes.optiondisplay}>
                                                  <img src={option.file_url} alt={`Option ${i + 1}`} />
                                                </div>
                                              ) : (
                                                <label>{option}</label>
                                              )}
                                            </div>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  ) : (
                                    <>
                                      {item?.options?.map((option, i) => (
                                        <div key={i} className="options-preview">
                                          <input type="checkbox" checked={schoolExamData?.questions[item?.question_index - 1]?.answer?.includes(i)} name={item?.question_index} />
                                          <label>{option}</label>
                                        </div>
                                      ))}
                                    </>
                                  )
                                ) : null}
                              </div>
                            </div>
                          </Grid>
                        );
                      })}

                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default SchoolExamDetails;
