/* eslint-disable no-constant-condition */
/* eslint-disable no-console */
import { useState, useEffect } from "react";
import {
  Grid,
  // Switch,
  // FormControlLabel,
  Menu,
  MenuItem,
  IconButton,
  useMediaQuery
} from "@mui/material";
import InputGroup from "../../../../components/FormComponent/InputGroup";
import { Icon } from "@iconify/react";
import SelectGroup from "../../../../components/FormComponent/SelectGroup";
import TextAreaGroup from "../../../../components/FormComponent/TextAreaGroup";
import { httprequest } from "../../../../data/api";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import useValidator from "../../../../hooks/useValidator";
import Layout from "../../../../components/Layout";
import BreadCrumb from "../../../../components/BreadCrumb";
import useStyles from "../../styles";
import { useUserState } from "../../../../context/UserContext";
import Select from "react-select";
import WidgetTitle from "../../../dashboard/components/WidgetTitle/WidgetTitle";

const TEACHER_CLASS_ENDPOINT = "/api/v1/teacher/classes";
// const TEACHER_SUBJECT_ENDPOINT = "/api/v1/teacher/subjects";
const ADMIN_CLASS_ENDPOINT = "/api/v1/class";
// const ADMIN_SUBJECT_ENDPOINT = "/api/v1/subjects";
const SCH_EXAM_EXNDPOINT = "/api/v1/school-exams";
const CLASS_SUBJECT = "/api/v1/class-subject/";
const UPLOAD_ENDPOINT = "/api/v1/upload";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";

const CreateExam = () => {
  // const [file, setFile] = useState();
  // const [select, setSelect] = useState("");
  const styles = useStyles();
  const { userRole } = useUserState();
  const navigate = useNavigate();
  const [classData, setClassData] = useState([]);
  // const [subjectData, setSubjectData] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isDrafting, setIsDrafting] = useState(false);
  const [allClassSubject, setAllClassSubject] = useState([]);
  const [currentAnchor, setCurrentAnchor] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [createExamValidator, showCreateExamValidator] = useValidator();
  // const [selectedClassSubjects, setSelectedClassSubjects] = useState([]);
  const [disabledInputs, setDisabledInputs] = useState({});
  const [activeSession, setActiveSession] = useState({});
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [aSession, setASession] = useState("");
  const [questionImages, setQuestionImages] = useState({});
  const [totalPoints, setTotalPoints] = useState(0);
  const [allTerm, setAllTerms] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [optionimages, setOptionImages] = useState([]);
  const [descimages, setDescImages] = useState([]);

  const isSmallScreen = useMediaQuery("(max-width: 700px)");

  // Handles Description Image View
  const handleDescImageChange = (event) => {
    if (event?.target?.files?.length > 0) {
      const selectedImage = URL.createObjectURL(event?.target?.files[0]);
      setDescImages([selectedImage]);
    }
  };

  const [questionArr, setQuestionArr] = useState([
    {
      question: "",
      file: {},
      question_index: 1,
      type: "",
      grade_point: 0,
      answer: "",
      required: false,
      options: []
    }
  ]);

  const [schoolExaminationForm, setSchoolExamForm] = useState({
    subject_id: "",
    term_id: "",
    title: "",
    class_id: [],
    description: "",
    total_grade: 0,
    instruction: "",
    duration: 0,
    // published_date: "",
    questions: [
      {
        question_index: 1
      }
    ]
  });

  // get All Sessions
  const getAllSession = () => {
    httprequest
      .get(SESSION_ENDPOINT)
      .then((res) => {
        setSessionData(res?.data?.data);
        setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
      })
      .catch(() => {});
  };
  console.log(activeSession, "activeSession");

  // handle Get all Terms
  const getAllTerms = (Session_id) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setAllTerms(res?.data?.data.filter((session_term) => session_term?.session_id === Session_id));
        // setActiveTerm(
        //   res?.data?.data.filter(
        //     (active_term) => active_term?.is_current === true
        //   )
        // );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  const handleEditSessionChange = (e) => {
    const { value } = e.target;
    setASession(value);
    getAllTerms(value);
  };

  // Handle image upload and update the specific question
  const handleQuestionImageChange = async (event, queInd) => {
    console.log(queInd, "Question clicked");
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      // Prepare to send the image to the upload endpoint
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response?.data?.data?.[0]?.upload_id,
            file_url: response?.data?.data?.[0]?.file_url
          };

          // Update questionArr with the uploaded image details
          const updatedQuestionArr = questionArr.map((question, index) => index === queInd
            ? { ...question, file: uploadedImageDetails }
            : question
          );
          setQuestionArr(updatedQuestionArr);

          // Update schoolExaminationForm questions with the new questionArr
          setSchoolExamForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestionArr
          }));

          // Add image URL to questionImages state for preview
          const imageUrl = URL.createObjectURL(file);
          setQuestionImages((prevImages) => ({
            ...prevImages,
            [queInd]: imageUrl
          }));
          toast.success(`Image added to question ${queInd + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  const handleOptionImageChange = async (event, optionIndex, questionIndex) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      setDisabledInputs((prevDisabled) => ({
        ...prevDisabled,
        [questionIndex]: {
          ...prevDisabled[questionIndex],
          [optionIndex]: true
        }
      }));

      // Prepare to send the image to the upload endpoint
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response.data.data[0].upload_id,
            file_url: response.data.data[0].file_url
          };

          // Update the specific option with only upload_id and file_url
          const updatedQuestionArr = questionArr.map((question, qIndex) => {
            if (qIndex === questionIndex) {
              const updatedOptions = question.options.map((option, oIndex) => oIndex === optionIndex
                ? uploadedImageDetails
                : option
              );

              return { ...question, options: updatedOptions };
            }

            return question;
          });

          setQuestionArr(updatedQuestionArr);

          // Update schoolExaminationForm questions with the new questionArr
          setSchoolExamForm((prevForm) => ({
            ...prevForm,
            questions: updatedQuestionArr
          }));

          const imageUrl = URL.createObjectURL(file);
          setOptionImages((prevImages) => ({
            ...prevImages,
            [`${questionIndex}-${optionIndex}`]: imageUrl
          }));

          toast.success(`Image added to option ${optionIndex + 1} in question ${questionIndex + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  // Delete a Question Image
  const removeQueImage = async (queInd) => {
    const upload_id = questionArr[queInd]?.file?.upload_id;

    if (!upload_id) {
      toast.error("No upload ID found for this question");

      return;
    }

    try {
      // This makes a DELETE request to remove the image from the database
      const response = await httprequest.delete(`${UPLOAD_ENDPOINT}/${upload_id}`);

      if (response?.status === 200) {
        // Image successfully removed, also updates the questionArr to remove the image for the specific question
        setQuestionArr((prevQuestions) => prevQuestions.map((question, index) => index === queInd
          ? {
            ...question,
            file: { upload_id: "", file_url: "" }
          }
          : question
        )
        );

        // Remove image preview from the state
        setQuestionImages((prevImages) => {
          const updatedImages = { ...prevImages };
          delete updatedImages[queInd];

          return updatedImages;
        });
      } else {
        toast.error(response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.message);
    }
  };

  // Delete a Question Option Imagge
  const removeOptionImage = async (i, queInd) => {
    const question = questionArr[queInd];
    const option = question?.options?.[i];
    const upload_id = option?.upload_id;

    if (!question || !option) {
      toast.error("Question or option not found");

      return;
    }

    if (!upload_id) {
      toast.error("No upload ID found for this option");

      return;
    }

    try {
      // DELETE request to remove the image of the option
      const response = await httprequest.delete(`${UPLOAD_ENDPOINT}/${upload_id}`);

      if (response?.status === 200) {
        console.log("OptionImages before deletion:", optionimages);

        // Image haes been removed, update the questionArr to remove the image for the specific option
        setQuestionArr((prevQuestions) => prevQuestions.map((question, index) => {
          if (index === queInd) {
            const updatedOptions = question.options.map((option, optionIndex) => optionIndex === i ? { ...option, upload_id: "", file_url: "" } : option
            );

            return { ...question, options: updatedOptions };
          }

          return question;
        })
        );

        // Remove image preview from the state
        setOptionImages((prevImages) => {
          const updatedImages = { ...prevImages };
          delete updatedImages[`${queInd}-${i}`];

          return updatedImages;
        });

        console.log("OptionImages after deletion:", optionimages);

        toast.success(`Option ${i + 1} of Question ${queInd + 1} image removed successfully`);
      } else {
        toast.error(response?.data);
      }
    } catch (error) {
      toast.error(error?.response?.message);
    }
  };

  useEffect(() => {
    console.log("Updated OptionImages:", optionimages);
  }, [optionimages]);

  const handleIconClick = (queInd) => {
    document.getElementById(`imageInput-${queInd}`).click();
  };
  const handleIconOptionClick = (i, queInd) => {
    document.getElementById(`imageInput-${i}${queInd}`).click();
  };

  // This handles the Assignment Description input
  const handleAssignmentChange = (e) => {
    const { name, value } = e.target;

    setSchoolExamForm({ ...schoolExaminationForm, [name]: value });
  };

  const handleCreateAssignment = () => {
    if (createExamValidator.allValid()) {
      setIsCreating(true);
      const updatedClassIds = schoolExaminationForm?.class_id.map((a_class) => a_class?.id);
      httprequest
        .post(SCH_EXAM_EXNDPOINT, { ...schoolExaminationForm, status: "published", class_id: updatedClassIds })
        .then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          navigate("/school-examination/examination");
        })
        .catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showCreateExamValidator(true);
    }
  };

  // handle Create Draft
  const handleCreateDraftExam = () => {
    if (createExamValidator.allValid()) {
      setIsDrafting(true);
      const updatedClassIds = schoolExaminationForm?.class_id.map((a_class) => a_class?.id);
      httprequest
        .post(SCH_EXAM_EXNDPOINT, { ...schoolExaminationForm, status: "draft", class_id: updatedClassIds })
        .then((res) => {
          setIsDrafting(false);
          toast.success(res?.data?.message);
          navigate("/school-examination/examination");
        })
        .catch((err) => {
          setIsDrafting(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showCreateExamValidator(true);
    }
  };

  console.log(schoolExaminationForm, "schoolExaminationForm");

  // const handleCreateAssignment = () => {
  //   handlePostAssignment("published");
  // };

  const handleClick = (event, queInd) => {
    setAnchorEl(event.currentTarget);
    setCurrentAnchor(queInd);
  };
  const handleAdditionalQueClose = () => {
    setAnchorEl(null);
  };

  /**
   *
   * @param {event parameter passed on handleChange} e
   * @param {*} index
   * @param {*} queInd
   */
  const handleMultipleChoiceChange = (e, index, queInd) => {
    const { value } = e.target;

    const options = questionArr[queInd]["options"];
    const newOptionArr = [...options];

    // Update the specific option as a string when typing
    newOptionArr[index] = value;

    const newQueArr = [...questionArr];
    newQueArr[queInd]["options"] = newOptionArr;

    setQuestionArr(newQueArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQueArr });
  };

  /**
   *
   * @param {event parameter passed on handleChange} e
   * @param {*} index
   * @param {*} queInd
   */
  const handleCorrectChoiceChange = (e, index, queInd) => {
    const { checked, type } = e.target;

    if (type === "radio") {
      const newQuestionArr = [...questionArr];
      newQuestionArr[queInd]["answer"] = [index];
      setQuestionArr(newQuestionArr);
      setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
    } else if (type === "checkbox") {
      const newQueArr = [...questionArr];
      const answers = newQueArr[queInd]["answer"] || [];

      if (checked) {
        // Add the index to the answers array if the checkbox is checked
        if (!answers.includes(index)) {
          answers.push(index);
        }
      } else {
        // Remove the index from the answers array if the checkbox is unchecked
        const newAnswerArr = answers.filter((_item) => _item !== index);
        newQueArr[queInd]["answer"] = newAnswerArr;
      }

      newQueArr[queInd]["answer"] = answers;
      setQuestionArr(newQueArr);
      setSchoolExamForm({ ...schoolExaminationForm, questions: newQueArr });
    }
  };

  // This Updates the Question of a specific question based on user input.
  const handleQueChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = value;
    setQuestionArr(newQuestionArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  };

  // This Updates description of a specific question based on user input.
  const handleQueDescChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = value;
    setQuestionArr(newQuestionArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  };

  // This Updates the Question Point of a specific question based on user input.
  const handleQuePointChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = value;
    setQuestionArr(newQuestionArr);

    const newTotalPoints = newQuestionArr.reduce((sum, question) => {
      return sum + (parseFloat(question?.grade_point) || 0);
    }, 0);

    setSchoolExamForm({
      ...schoolExaminationForm,
      questions: newQuestionArr,
      total_grade: newTotalPoints
    });

    setTotalPoints(newTotalPoints);
  };

  // This Updates the question type of a specific question based on user input.
  const handleQueTypeChange = (e, index) => {
    const { name, value } = e.target;
    const newQuestionArr = [...questionArr];
    newQuestionArr[index][name] = value;
    setQuestionArr(newQuestionArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  };

  const toggleQueDescriptionTrue = () => {
    const newQuestionArr = [...questionArr];
    newQuestionArr[currentAnchor]["description"] = "";
    setQuestionArr(newQuestionArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  };

  const removeQueDescription = (index) => {
    const newQuestionArr = [...questionArr];
    delete newQuestionArr[index]["description"];
    setQuestionArr(newQuestionArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  };

  // This Updates the value on the required field on each question.
  // const handleReqQueChange = (e, index) => {
  //   const { name, checked } = e.target;
  //   const newQuestionArr = [...questionArr];
  //   newQuestionArr[index][name] = checked;
  //   setQuestionArr(newQuestionArr);
  //   setSchoolExamForm({ ...schoolExaminationForm, questions: newQuestionArr });
  // };

  // --| Add additional option on each question
  const handleAdditionalOptions = (queInd) => {
    const newQueArr = [...questionArr];
    const newRadioArr = [...questionArr[queInd]["options"], [" "]];
    newQueArr[queInd]["options"] = newRadioArr;
    setQuestionArr(newQueArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQueArr });
  };

  // This creates a new question
  const handleAdditionalQuestion = () => {
    const newQue = {
      question: "",
      question_index: schoolExaminationForm?.questions?.length + 1,
      type: "",
      answer: "",
      required: false,
      grade_point: "",
      options: [""]
    };

    // Update questionArr
    const updatedQuestionArr = [...questionArr, newQue];
    setQuestionArr(updatedQuestionArr);

    // Update schoolExaminationForm with new the question
    setSchoolExamForm((prevForm) => ({
      ...prevForm,
      questions: updatedQuestionArr
    }));
  };

  // --| Remove Additional Question that has been added
  const removeAdditionalQue = (index) => {
    const newQueArr = questionArr.filter((_item, ind) => ind !== index);
    setQuestionArr(newQueArr);
  };

  // --| Remove additional choice added as an option in a question
  const removeAdditionalChoice = (index, queInd) => {
    const newQueArr = [...questionArr];
    const newChoiceArr = newQueArr[queInd]["options"]?.filter(
      (_item, ind) => ind !== index
    );
    newQueArr[queInd]["options"] = newChoiceArr;
    setQuestionArr(newQueArr);
    setSchoolExamForm({ ...schoolExaminationForm, questions: newQueArr });
  };

  // --| Get all my classes as a SUBJECT teacher
  const getAllTeacherClasses = () => {
    httprequest
      .get(TEACHER_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      })
      .catch(() => {});
  };

  // --| Get all classes as a school admin
  const getAllAdminClasses = () => {
    httprequest
      .get(ADMIN_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(res?.data?.data);
      }).catch(() => {});
  };

  // const getAllSubjectForClass = async (class_value) => {
  //   try {
  //     const res = await httprequest.get(`${CLASS_SUBJECT}${class_value}`);
  //     setAllClassSubject(res?.data?.data);
  //   } catch (err) {
  //     toast.error(err?.response?.data);
  //   }
  // };
  const getSubjectsForClasses = async (classValues) => {
    try {
      // Start with existing subjects in a temporary array
      const newSubjects = [...allClassSubject];

      // Loop through each selected class to fetch subjects
      for (const { id: classId, name: className } of classValues) {
        const res = await httprequest.get(`${CLASS_SUBJECT}${classId}`);
        const classSubjects = res?.data?.data;

        // Append class name to each subject for display
        const subjectsWithClassName = classSubjects.map((subject) => ({
          ...subject,
          displayName: `${subject.subject_name} | ${className}`
        }));

        // This adds only unique subjects from this class to the newSubjects array
        subjectsWithClassName.forEach((newSub) => {
          if (!newSubjects.some((sub) => sub?.subject_id === newSub?.subject_id)) {
            newSubjects.push(newSub);
          }
        });
      }

      // Update state with the accumulated unique subjects
      setAllClassSubject(newSubjects);
    } catch (err) {
      toast.error(err?.response?.data);
    }
  };

  // eslint-disable-next-line no-console
  console.log(allClassSubject, "allClassSubject");

  // Handle Class Change and Get Subject for their respective Classes
  const handleMultipleAccessChange = async (selectedOptions) => {
    // Extract selected class ids and names
    const selectedValues = selectedOptions.map((option) => ({
      id: option?.id,
      name: option?.class_name
    }));

    // Update selected class IDs in state
    setSchoolExamForm((prevForm) => ({
      ...prevForm,
      class_id: selectedValues
    }));

    console.log("Selected values:", selectedValues);

    // Fetch and update subjects for all selected classes
    getSubjectsForClasses(selectedValues);
  };

  useEffect(() => {
    if (activeSession?.id) {
      getAllTerms(activeSession?.id);
    }
  }, [activeSession]);

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session?.id);
    }
  }, [active_term_session]);

  // For Session
  useEffect(() => {
    if (activeSession?.id) {
      setASession(activeSession?.id);
    }
  }, [activeSession?.id]);

  const handleTermChange = (event) => {
    const { name, value } = event.target.value;
    setASelectedTerm(value);

    setSchoolExamForm({ ...schoolExaminationForm, [name]: value });
  };
  // const handleClassChange = (e) => {
  //   const { name, value } = e.target;
  //   setSchoolExamForm({ ...schoolExaminationForm, [name]: value });
  //   getAllSubjectForClass(value);
  // };

  const options = [
    // {
    //   value: "paragraph",
    //   text: "Paragraph",
    //   icon: <Icon icon="carbon:text-short-paragraph" width="25" height="25" />
    // },
    {
      value: "multiple_choice",
      text: "Multiple Choice",
      icon: <Icon icon="mdi:radiobox-marked" width="25" height="25" />
    },
    {
      value: "checkbox",
      text: "Checkbox",
      icon: <Icon icon="ci:checkbox-check" width="25" height="25" />
    }
    // {
    //   value: "dropdown",
    //   text: "Dropdown",
    //   icon: <Icon icon="tabler:select" width="25" height="25" />
    // },
    // {
    //   value: "file_upload",
    //   text: "File Upload",
    //   icon: <Icon icon="basil:cloud-upload-outline" width="25" height="25" />
    // },
    // {
    //   value: "date",
    //   text: "Date",
    //   icon: (
    //     <Icon
    //       icon="material-symbols-light:date-range-sharp"
    //       width="25"
    //       height="25"
    //     />
    //   )
    // },
    // {
    //   value: "time",
    //   text: "Time",
    //   icon: <Icon icon="mingcute:time-line" width="25" height="25" />
    // }
  ];

  useEffect(() => {
    if (userRole?.role === "school_admin") {
      getAllAdminClasses();
      getAllTerms();
      getAllSession();
      // getAllAdminSubjects();
    } else {
      getAllTeacherClasses();
      getAllTerms();
      // getAllTeacherSubjects();
    }
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/school-examination/examination"
                link_one_name="All Examinations"
                active="Create Examinatiion"
                description="Create a new examination"
              />
            </div>
            <div className="flex gap-10">
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                isLoading={isDrafting}
                onClick={handleCreateDraftExam}
                buttonName="Save as Draft"
              />
              <Button
                variant="primary"
                buttonSize="fluid"
                color="btndefault"
                buttonName="Create Examination"
                isLoading={isCreating}
                onClick={handleCreateAssignment}
              />
            </div>
          </div>
          <div className="page-content">
            <div className={styles.assignmentcontainerform}>
              <WidgetTitle
                title="Class Information"
                // subtitle="View Metrics for all student attendance in your school"
              />
              <div className={styles.assignmentsection}>
                <Grid container spacing={2}>
                  <Grid item lg={9} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Class</p>
                      <Select isMulti name="class_id"
                        closeMenuOnSelect={false}
                        options={classData}
                        onChange={handleMultipleAccessChange}
                        getOptionLabel={(option) => option.class_name}
                        getOptionValue={(option) => option.id}
                      />
                    </div>
                    {createExamValidator.message(
                      "class_id",
                      schoolExaminationForm?.class_id,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={3} md={6} sm={6} xs={12}>
                    <SelectGroup
                      label="Select Subject"
                      required
                      children={
                        <select
                          name="subject_id"
                          onChange={handleAssignmentChange}
                        >
                          <option value="">--- Select ---</option>

                          {allClassSubject?.map((sub) => (
                            <option key={sub?.subject_id} value={sub?.subject_id}>
                              {sub?.displayName}
                            </option>
                          ))}
                        </select>
                      }
                    />
                    {createExamValidator.message(
                      "subject_id",
                      schoolExaminationForm?.subject_id,
                      "required"
                    )}
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={12} xs={12}>
                    <InputGroup
                      label="Submission Date"
                      margin="normal"
                      inputType="date"
                      inputName="submission_date"
                      inputMin={new Date().toISOString().split("T")[0]}
                      onChange={handleAssignmentChange}
                      required
                    />
                    {createExamValidator.message(
                      "submission_date",
                      schoolExaminationForm?.submission_date,
                      "required"
                    )}
                  </Grid> */}
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    {/* {activeSession?.id && (

                    )} */}
                    <SelectGroup
                      label="Select Session"
                      required
                      children={
                        <select
                          value={aSession}
                          onChange={handleEditSessionChange}
                        >
                          <option value="">---Select---</option>
                          {sessionData?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {/* {createExamValidator.message("Session", schoolExaminationForm?.session, "required")} */}
                  </Grid>
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Term"
                      required
                      children={
                        <select
                          value={aselectedTerm}
                          name="term_id"
                          onChange={handleTermChange}
                        >
                          <option value="">--- Select ---</option>

                          {allTerm?.map((term) => {
                            return (
                              <option key={term?.id} value={term?.id}>
                                {term?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {createExamValidator.message(
                      "term_id",
                      schoolExaminationForm?.term_id,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={4} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Duration"
                      margin="normal"
                      inputType="text"
                      inputName="duration"
                      placeHolder="Enter Duration in minutes"
                      onChange={handleAssignmentChange}
                      required
                    />
                    {createExamValidator.message(
                      "duration",
                      schoolExaminationForm?.duration,
                      "required"
                    )}
                  </Grid>
                </Grid>
              </div>
              <WidgetTitle
                title="Examination Information"
                // subtitle="View Metrics for all student attendance in your school"
              />
              <div className={styles.assignmentsection}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Examination Title"
                      margin="normal"
                      inputType="text"
                      inputName="title"
                      onChange={handleAssignmentChange}
                      required
                    />
                    {createExamValidator.message(
                      "title",
                      schoolExaminationForm?.title,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Total Grade"
                      margin="normal"
                      inputType="text"
                      inputName="total_grade"
                      value={totalPoints}
                      disabled
                      onChange={handleAssignmentChange}
                      required
                    />
                    {createExamValidator.message(
                      "total_grade",
                      schoolExaminationForm?.total_grade,
                      "required"
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={styles.inputmargin}>
                      <TextAreaGroup
                        label="Examination Instruction"
                        inputName="instruction"
                        margin="normal"
                        placeholder=""
                        onChange={handleAssignmentChange}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={styles.inputmargin}>
                      <TextAreaGroup
                        label="Description"
                        inputName="description"
                        margin="normal"
                        placeholder=""
                        onChange={handleAssignmentChange}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="margin-top">
                <Grid container spacing={2}>
                  {questionArr?.map((que, queInd) => (
                    <>
                      <Grid item lg={12} md={12} sm={12} xs={12} key={queInd}>
                        <div className={`${styles.assignmentsection}`}>
                          <Grid container spacing={2}>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <div className={styles.quesaction}>
                                <label className={styles.quelabel}>Enter Question  {queInd + 1}</label>
                                <div>
                                  {questionArr?.length > 1 ? (
                                    <>
                                      <Icon
                                        icon="material-symbols:delete-outline"
                                        width="20"
                                        height="20"
                                        className="delete-icon"
                                        onClick={() => removeAdditionalQue(queInd)}
                                      />
                                    </>
                                  ) : null}
                                </div>
                              </div>
                              {/* <label className={styles.enterque}> Question {que?.question_index}<span className="input-required"> *</span></label> */}
                              <div className={styles.textandimgmain}>
                                <div className={styles.textandimg}>
                                  <input
                                    type="text"
                                    placeholder={questionImages[queInd] ? "" : "Type Question"}
                                    name="question"
                                    // disabled={disabledInputs[queInd]}
                                    onChange={(e) => {
                                      handleQueChange(e, queInd);
                                    }}
                                  />
                                  {createExamValidator.message(
                                    "question",
                                    questionArr[queInd]["question"],
                                    "required"
                                  )}
                                  <div onClick={() => handleIconClick(queInd)} className={styles.queimg}>
                                    <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id={`imageInput-${queInd}`}
                                      multiple
                                      onChange={(e) => handleQuestionImageChange(e, queInd)}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  {/* Image preview */}
                                  <div className={styles.preiewcont}>
                                    {questionImages[queInd] && (
                                      <>
                                        <img
                                          src={questionImages[queInd]}
                                          alt={`Selected for question ${queInd}`}
                                        />
                                        <Icon
                                          icon="material-symbols:delete-outline"
                                          width="20"
                                          height="20"
                                          className="delete-icon"
                                          onClick={() => removeQueImage(queInd)}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <SelectGroup
                                label="Add Answer Type"
                                required
                                children={
                                  <select
                                    name="type"
                                    onChange={(e) => handleQueTypeChange(e, queInd)}
                                  >
                                    <option value="">--- Select ---</option>
                                    {options?.map((label) => {
                                      return (
                                        <option
                                          key={label?.key}
                                          value={label?.value}
                                        >
                                          <Icon
                                            className={`${styles.add_question_color}`}
                                            icon="flat-color-icons:plus"
                                            width="24"
                                            height="24"
                                          />
                                          {label?.text}
                                        </option>
                                      );
                                    })}
                                  </select>
                                }
                              />
                              {createExamValidator.message(
                                "question type",
                                questionArr[queInd]["type"],
                                "required"
                              )}
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <InputGroup
                                className={styles.pointsInput}
                                label="Question Point"
                                inputType="number"
                                inputMin={1}
                                inputName="grade_point"
                                placeHolder="Enter Question Points"
                                onChange={(e) => {
                                  handleQuePointChange(e, queInd);
                                }}
                              />
                              {createExamValidator.message(
                                "points",
                                questionArr[queInd]["grade_point"],
                                "required"
                              )}
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              {"description" in que ? (
                                <div className="margin-top full-width">
                                  <div className=" flex gap-10 justify-items-space">
                                    <label className={styles.enterque}>Question Description</label>
                                    <div>
                                      <div
                                        className="delete-icon"
                                        onClick={() => removeQueDescription(queInd)}
                                      >
                                        <Icon
                                          icon="ooui:close"
                                          width="20"
                                          height="20"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.textandimgmain}>
                                    <div className={styles.textandimg}>
                                      <input
                                        type="text"
                                        placeHolder="Enter Description"
                                        name="description"
                                        onChange={(e) => handleQueDescChange(e, queInd)}
                                      />
                                      <></>
                                      <div onClick={handleIconClick} className={styles.queimg}>
                                        <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                        <input
                                          type="file"
                                          accept="image/*"
                                          id="imageInput"
                                          disabled
                                          onChange={handleDescImageChange}
                                          style={{ display: "none" }}
                                        />
                                      </div>
                                      {/* Image preview */}
                                      <div className={styles.preiewoptioncont}>
                                        {descimages.map((descimage, index) => (
                                          <>
                                            <img
                                              key={index}
                                              src={descimage}
                                              alt={`Selected ${index}`}
                                            />
                                            <>
                                              <Icon
                                                icon="material-symbols:delete-outline"
                                                width="20"
                                                height="20"
                                                className="delete-icon"
                                                onClick={() => removeAdditionalQue(queInd)}
                                              />
                                            </>
                                          </>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="width-full">
                                <InputGroup
                                  label="Question Description"
                                  margin="normal"
                                  placeHolder="Enter Description"
                                  inputType="text"
                                  value={question?.description}
                                  inputName="description"
                                  onChange={(e) => handleQueDescChange(e, queInd)}
                                />
                              </div> */}
                                </div>
                              ) : null}
                            </Grid>
                          </Grid>
                          {/* Multiple Choice */}
                          {que?.type === "multiple_choice" ? (
                            <>
                              <Grid container spacing={2} mt={1}>
                                {que?.options?.map((radio, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1}<span className="input-required"> *</span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {que?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              disabled={disabledInputs[queInd]?.[i]}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            {createExamValidator.message(
                                              "option choice",
                                              questionArr[queInd]["options"][i],
                                              "required"
                                            )}
                                            <></>
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {optionimages[`${queInd}-${i}`] && optionimages[`${queInd}-${i}`] !== "" && (
                                                <>
                                                  <img
                                                    src={optionimages[`${queInd}-${i}`]}
                                                    alt={`Option ${i + 1} for Question ${queInd + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>

                                          </div>
                                        </div>
                                        <div
                                          className="flex gap-10 align-items-center justify-items-space"
                                          style={{ marginTop: "5px" }}
                                        >
                                          <div className={styles.correctcontainer}>
                                            <input
                                              type="radio"
                                              name={`answer-${queInd}`}
                                              value={i}
                                              defaultChecked={
                                                que.type === "multiple_choice"
                                                  ? que.answer === i
                                                  : que.answer.includes(i)
                                              }
                                              onChange={(e) => handleCorrectChoiceChange(
                                                e,
                                                i,
                                                queInd
                                              )
                                              }
                                            />
                                            <label
                                              className={styles.correctlabel}
                                              htmlFor="Is Correct"
                                            >
                                          is correct
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another option</p>
                              </div>
                            </>
                          ) : null}
                          {/* Checkbox */}
                          {que?.type === "checkbox" ? (
                            <>
                              <Grid container spacing={2} mt={1}>
                                {que?.options?.map((checkbox, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1}<span className="input-required"> *</span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {que?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              // value={checkbox}
                                              disabled={disabledInputs[queInd]?.[i]}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            {/* {createExamValidator.message(
                                            "option choice",
                                            questionArr[queInd]["options"][i + 1],
                                            "required"
                                          )} */}
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {optionimages[`${queInd}-${i}`] && optionimages[`${queInd}-${i}`] !== "" && (
                                                <>
                                                  <img
                                                    src={optionimages[`${queInd}-${i}`]}
                                                    alt={`Option ${i + 1} for Question ${queInd + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="flex gap-10 align-items-center justify-items-space"
                                          style={{ marginTop: "5px" }}
                                        >
                                          <div className={styles.correctcontainer}>
                                            <input
                                              type="checkbox"
                                              name="answer"
                                              value={i}
                                              onChange={(e) => handleCorrectChoiceChange(
                                                e,
                                                i,
                                                queInd
                                              )
                                              }
                                            />
                                            <label
                                              className={styles.correctlabel}
                                              htmlFor="Is Correct"
                                            >
                                          is correct
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another checkbox</p>
                              </div>
                            </>
                          ) : null}
                          <div className={styles.questionfooter}>
                            <div className="flex gap-10 align-items-center justifyRight">
                              {!que?.description && (
                                <IconButton
                                  aria-label="more"
                                  id="long-button"
                                  aria-controls={open ? "long-menu" : null}
                                  aria-expanded={open ? "true" : null}
                                  aria-haspopup="true"
                                  onClick={(e) => handleClick(e, queInd)}
                                >
                                  <Icon
                                    icon="radix-icons:dots-vertical"
                                    width="20"
                                    height="20"
                                  />
                                  <label className={styles.enterque}>More</label>
                                </IconButton>
                              )}
                            </div>
                          </div>
                          <Menu
                            id="basic-menu"
                            MenuListProps={{
                              "aria-labelledby": "basic-button"
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleAdditionalQueClose}
                          >
                            <MenuItem
                              onClick={() => {
                                toggleQueDescriptionTrue();
                                handleAdditionalQueClose();
                              }}
                            >
                          Add Description on Question
                            </MenuItem>
                          </Menu>
                        </div>
                      </Grid>
                    </>
                  ))}
                </Grid>
              </div>
              <div
                className={styles.addque}
              >
                <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                <p onClick={handleAdditionalQuestion}>Add question</p>
              </div>
              <div>
                <div className={styles.btnwrapper}>
                  <Button
                    variant="primaryoutline"
                    buttonSize={isSmallScreen ? "full" : "fluid"}
                    color="btnfontprimary"
                    isLoading={isDrafting}
                    onClick={handleCreateDraftExam}
                    buttonName="Save as Draft"
                  />
                  <Button
                    variant="primary"
                    buttonSize={isSmallScreen ? "full" : "fluid"}
                    color="btndefault"
                    buttonName="Create Examination"
                    isLoading={isCreating}
                    onClick={handleCreateAssignment}
                  />
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default CreateExam;
