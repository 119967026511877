import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Teachers from "./Teachers";
import NonTeaching from "./NonTeaching";
import AllEmployees from "./AllEmployees";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { Grid } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import useValidator from "../../hooks/useValidator";
// import TerminatedEmployees from "./TerminatedEmployees";
import PageLoader from "../../components/PageLoader";
import BulkEmployee from "../../components/BulkEmployeeCard";
// import { useUserState } from "../../context/UserContext";

const INVITE_EMPLOYEE_SIGNUP = "api/v1/employee-invite";
const BATCH_EMPLOYEE = "/api/v1/employee-invite/batch";
const EMPLOYEES_ENDPOINT = "api/v1/employees";
const ROLES_ENDPOINT = "api/v1/roles";

const Employees = () => {
  // const { userRole } = useUserState();
  const [value, setValue] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [employeeValidator, showValidationMessage] = useValidator();
  const [bulkModal, setBulkModal] = useState(false);

  const [inviteForm, setInviteForm] = useState({
    email: "",
    role: "",
    job_title: ""
    // org_id: userRole?.org_id
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateChange = (e) => {
    const { name, value } = e.target;
    setInviteForm({ ...inviteForm, [name]: value });
  };

  const handleCloseCreateModal = () => {
    setCreateModal(false);
    setInviteForm({ ...inviteForm, email: "", role: "", job_title: "" });
  };

  const handleCreateBulkModal = () => {
    setBulkModal(!bulkModal);
  };

  const handleCreateModal = () => {
    setCreateModal(!createModal);
  };

  const getEmployeeData = () => {
    setIsPageLoading(true);
    httprequest.get(`${EMPLOYEES_ENDPOINT}?staff=${value}`).then((res) => {
      setEmployeesData(res?.data?.data?.employees);
      setIsPageLoading(false);
    }).catch((err) => {
      setIsPageLoading(false);
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(employeesData, "employeesData");

  // const activeEmployees = employeesData?.filter(employee => employee?.is_active === true);

  const handleCreateBatchEmployee = async (file) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await httprequest.post(BATCH_EMPLOYEE, formData);
      toast.success(res?.data?.message);
      getEmployeeData();
      setBulkModal(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateEmployee = () => {
    if (employeeValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(INVITE_EMPLOYEE_SIGNUP, { recipients: [inviteForm] }).then(() => {
        setIsLoading(false);
        toast.success("Invite sent successfully");
        handleCloseCreateModal();
        getEmployeeData();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  const getRoles = () => {
    httprequest.get(`${ROLES_ENDPOINT}`).then((res) => {
      const updatedEmployee = res?.data?.data?.filter((newrole) => newrole?.role_type !== "school_admin" && newrole?.role_type !== "guardian" && newrole?.role_type !== "student");
      setRolesData(updatedEmployee);
    }).catch(() => {

    });
  };
  // eslint-disable-next-line no-console
  console.log(rolesData, "Role Data");

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (value !== "terminated") {
      getEmployeeData();
    }
  }, [value]);

  const { job_title, email, role } = inviteForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Employees"
            subtitle="See all the employees in your organisation."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={handleCreateBulkModal}
                  buttonName="Add Batch Employee"
                />
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  buttonName="Add an Employee"
                  onClick={handleCreateModal}
                />
              </div>
            }
          />
          <div className="page-content">
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="All" value="" />
                  <Tab label="Active" value="active" />
                  <Tab label="Inactive" value="inactive" />
                  {/* <Tab label="Terminated Staff" value="terminated" /> */}
                </Tabs>
              </div>
              {isPageLoading ? (
                <PageLoader />
              ) : (
                <>
                  <TabPanel value="" className="tabpanel-overide">
                    <AllEmployees data={employeesData} />
                  </TabPanel>
                  <TabPanel value="active" className="tabpanel-overide"> <Teachers data={employeesData?.filter(active => active?.is_active === true)} /></TabPanel>
                  <TabPanel value="inactive" className="tabpanel-overide"> <NonTeaching data={employeesData?.filter(active => active?.is_active === false)} /></TabPanel>
                  {/* <TabPanel value="terminated" className="tabpanel-overide"> <TerminatedEmployees /></TabPanel> */}
                </>
              )}
            </TabContext>
            <Modal
              title="Create Employee"
              subtitle={
                <div>Add this Employee into your school, on create, they will receive an email to create that password.
                </div>}
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Job Title"
                        margin="normal"
                        inputType="text"
                        inputName="job_title"
                        inputValue={job_title}
                        onChange={handleCreateChange}
                        required
                      />
                      {employeeValidator.message("job_title", job_title, "required")}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <InputGroup
                        label="Email Address"
                        margin="normal"
                        inputType="email"
                        inputName="email"
                        inputValue={email}
                        onChange={handleCreateChange}
                        required
                      />
                      {employeeValidator.message("email", email, "required")}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <SelectGroup label="Role" required children={
                        <select onChange={handleCreateChange} value={role} name="role">
                          <option value="">--- Select ---</option>
                          {rolesData?.map((role) => {
                            return (
                              <option key={role?.role} value={role?.role_id}>{role?.role_name}</option>
                            );
                          })}
                        </select>
                      }
                      />
                      {employeeValidator.message("role", role, "required")}
                    </Grid>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <Button variant="primary" buttonName="Create Employee" buttonSize="full" color="btndefault" onClick={handleCreateEmployee} isLoading={isLoading} />
                </>
              }
              isopen={createModal}
              closeModal={handleCloseCreateModal}
            />
            {/* CREATE BULK EMPLOYEE */}
            <Modal
              title="Create Batch Employee"
              subtitle={
                <div>Create multiple employees at the same time by importing a csv file.
                </div>}
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={10} md={12} sm={12} xs={12}>
                      <p className="key-pair-name">1. Download sample .csv file here.</p>
                      <p className="key-pair-name">2. Fill in all columns. All fields are mandatory.</p>
                      {/* <p className="key-pair-name">3. Allowed staff types are academic teaching staffs.</p> */}
                      <p className="key-pair-name">3. Do not include extra columns nor rename columns in the document.</p>
                      <p className="key-pair-name">4. Upload the completed excel document</p>
                    </Grid>
                    <BulkEmployee
                      createClick={(file) => handleCreateBatchEmployee(file)}
                      isLoading={isLoading}
                    />
                  </Grid>
                </>
              }
              modalFooter={
                <>
                </>
              }
              isopen={bulkModal}
              closeModal={handleCreateBulkModal}
            />
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default Employees;
