import { Grid } from "@mui/material";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import { useNavigate } from "react-router-dom";
// import { httprequest } from "../../../data/api";
import InputGroup from "../../../components/FormComponent/InputGroup";
import Button from "../../../components/Button";
import useValidator from "../../../hooks/useValidator";
import styles from "../settings.module.css";
import { Icon } from "@iconify/react";

const CHANGE_PASSWORD = "/api/v1/change-password";

const Password = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [currentPasswordType, setCurrentPasswordType] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordValidator, showValidationMessage] = useValidator();
  const [isLoading, setIsLoading] = useState(false);
  const [changePasswordForm, setChangePasswordForm] = useState({
    new_password: "",
    old_password: ""
  });

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setChangePasswordForm({ ...changePasswordForm, [name]: value });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (passwordValidator.allValid()) {
      setIsLoading(true);
      httprequest.patch(`${CHANGE_PASSWORD}`, changePasswordForm).then(() => {
        setIsLoading(false);
        toast.success("Password Changed Successfully");
        setTimeout(() => navigate("/auth/login"), 2000);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showValidationMessage(true);
    }
  };

  const { old_password, new_password } = changePasswordForm;

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  const toggleCurrentPassword = () => {
    if (currentPasswordType === "password") {
      setCurrentPasswordType("text");
    } else {
      setCurrentPasswordType("password");
    }
  };

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
    } else {
      setConfirmPasswordType("password");
    }
  };

  const checkEqualPassword = () => {
    if (new_password !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`${styles.allcontent}`}>
      <div className="text-align">
        <h4 className={`${styles.changepassword}`}>Change Password</h4>
        <p className={`${styles.passwordtext}`}>Change the password for better security to this application.</p>
      </div>
      <div className={`${styles.nopadding}`}>
        <Grid className="input-padding" container spacing={2}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <InputGroup
              label="Current Password"
              margin="normal"
              required
              placeHolder="***********"
              inputName="old_password"
              inputType={currentPasswordType}
              onChange={handlePasswordChange}
            />
            <div className="password-eye-90">
              {currentPasswordType === "password" ? (
                <Icon icon="fluent:eye-16-regular" onClick={toggleCurrentPassword} />
              ) : (
                <Icon icon="iconamoon:eye-off-light" onClick={toggleCurrentPassword} />
              )}
            </div>
            {passwordValidator.message("Current Password", old_password, "required")}
          </Grid>
        </Grid>
        <Grid className="input-padding" container spacing={2}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <InputGroup
              label="New Password"
              margin="normal"
              required
              value={new_password}
              placeHolder="***********"
              inputType={passwordType}
              inputName="new_password"
              onChange={handlePasswordChange}
            />
            <div className="password-eye-90">
              {passwordType === "password" ? (
                <Icon icon="fluent:eye-16-regular" onClick={togglePassword} />
              ) : (
                <Icon icon="iconamoon:eye-off-light" onClick={togglePassword} />
              )}
            </div>
            {passwordValidator.message("New Password", new_password, "required")}
          </Grid>
        </Grid>
        <Grid className="input-padding" container spacing={2}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <InputGroup
              label="Confirm Password"
              onChange={handleChange}
              required
              placeHolder="***********"
              margin="normal"
              inputType={confirmPasswordType}
            />
            <div className="password-eye-90">
              {confirmPasswordType === "password" ? (
                <Icon icon="fluent:eye-16-regular" onClick={toggleConfirmPassword} />
              ) : (
                <Icon icon="iconamoon:eye-off-light" onClick={toggleConfirmPassword} />
              )}
            </div>
            {passwordValidator.message("Confirm Password", confirmPassword, "required")}
          </Grid>
        </Grid>
      </div>
      <div className="input-padding">
        <Button
          variant="primary"
          buttonSize="small"
          color="btndefault"
          buttonName="Save Changes"
          isLoading={isLoading}
          disabled={checkEqualPassword()}
          onClick={handleChangePassword}
        />
      </div>
      {checkEqualPassword() ? <span className="text-danger">Password and confirm password not equal</span> : null}
      <ToastContainer />
    </div>
  );
};

export default Password;
