import useStyles from "../styles";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
// import Button from "../../../components/Button/Button";
import OrganizationChart from "@dabeng/react-orgchart";
import MyNode from "../../../components/OrgNode";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import SelectGroup from "../../../components/FormComponent/SelectGroup";

const AdminOrganogram = () => {
  const [currentOrg, setCurrentOrg] = useState({});
  const [reassignModal, setReassignModal] = useState(false);
  const [getAllEmployees, setAllEmployees] = useState([]);
  const [firstSelected, setFirstSelected] = useState("");
  const [secondSelected, setSecondSelected] = useState("");
  const [reportEmployee, setReportEmployee] = useState([]);
  const [supervisorEmployees, setSuperVisorEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ORGANOGRAM_ENDPOINT = "/api/v1/organogram";
  const SUPERVISE_ENDPOINT = "/api/v1/supervision";
  const EMPLOYEES_ENDPOINT = "/api/v1/employees";
  const classes = useStyles();

  const getCompanyOrganogram = () => {
    httprequest.get(ORGANOGRAM_ENDPOINT).then((res) => {
      setCurrentOrg(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };

  const handleReassignModal = () => {
    setReassignModal(!reassignModal);
  };

  const closeReassignmodal = () => {
    setReassignModal(false);
    setFirstSelected("");
    setSecondSelected("");
  };

  const fetchAllEmployees = () => {
    httprequest.get(EMPLOYEES_ENDPOINT).then((res) => {
      setAllEmployees(res?.data?.data?.employees);
      setSuperVisorEmployees(res?.data?.data?.employees);
      setReportEmployee(res?.data?.data?.employees);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleReassign = () => {
    setIsLoading(true);
    httprequest.patch(`${SUPERVISE_ENDPOINT}/${secondSelected}`, { memberId: firstSelected }).then((res) => {
      setIsLoading(false);
      toast.success(res?.data?.message);
      closeReassignmodal();
      getCompanyOrganogram();
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.response?.data);
    });
  };

  useEffect(() => {
    getCompanyOrganogram();
    fetchAllEmployees();
  }, []);

  const handleSelectOne = (e) => {
    const { value } = e.target;
    setFirstSelected(value);

    const nonSelected = getAllEmployees.filter((employee) => employee?.member_id !== value);

    setSuperVisorEmployees(nonSelected);
  };

  const handleSelectTwo = (e) => {
    const { value } = e.target;

    setSecondSelected(value);

    const nonSelected = getAllEmployees.filter((employee) => employee?.employee_id !== value);

    setReportEmployee(nonSelected);
  };

  return (
    <Grid container spacing={4}>
      <Grid item lg={3} md={4} sm={12} xs={12}>
        <div className="left-container">
          <h4>Personal Information</h4>
          <h6>Update your personal details here</h6>
          <div className={classes.downloadbutton}>
            <Button variant="primary" color="btndefault" buttonSize="full" buttonName="Reassign Staff" onClick={handleReassignModal} />
          </div>
        </div>
      </Grid>
      <Grid item lg={9} md={8} sm={12} xs={12}>
        <div className="right-container">
          <OrganizationChart
            datasource={currentOrg}
            chartClass="myChart"
            NodeTemplate={MyNode}
            pan={true}
          />
        </div>
      </Grid>
      <Modal
        title="Reassign Employee"
        subtitle="Reassign who reports to another in the orgamisation"
        modalContent={
          <>
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectGroup label="Select Employee" children={
                  <select onChange={handleSelectOne}>
                    <option value="">--Select Employee --</option>
                    {reportEmployee?.map((employee, i) => {
                      return (
                        <option key={i} value={employee?.employee_id}>{`${employee?.first_name} ${employee?.last_name}` }</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <SelectGroup label="Reassign To" children={
                  <select onChange={handleSelectTwo} value={secondSelected}>
                    <option value="">--Select Employee --</option>
                    <option value="">Admin (Me)</option>
                    {supervisorEmployees?.map((employee, i) => {
                      return (
                        <option key={i} value={employee?.employee_id}>{`${employee?.first_name} ${employee?.last_name}` }</option>
                      );
                    })}
                  </select>
                }
                />
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <Button variant="primary" buttonName="Reassign Employee" buttonSize="full" color="btndefault" onClick={handleReassign} isLoading={ isLoading } />
          </>
        }
        isopen={reassignModal}
        closeModal={closeReassignmodal}
      />
      <ToastContainer />
    </Grid>
  );
};

export default AdminOrganogram;
