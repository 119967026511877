import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import { ToastContainer, toast } from "react-toastify";
import LessonNoteCard from "./LessonNoteCard";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import VerticalLine from "../../../assets/images/verticallines.svg";
import Nosearch from "../../../assets/images/no-search.svg";
import { CardSearch } from "../../../components/CardSearch";
import { useNavigate, useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import Modal from "../../../components/Modal";
import NotFounditem from "../../error/NotFound";

const ALL_LESSON_NOTES = "/api/v1/lesson-notes";
const NOTE_API = "/api/v1/lesson-outlines";

const ViewLessonNote = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [lessonNotes, setLessonNotes] = useState([]);
  const [lessonNoteDetails, setLessonNoteDetails] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState("");
  const [timeline, setTimeline] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);

  // Get Class Parameter
  const class_id = lessonNoteDetails?.class?.id;

  // Delete Note http request and delete modal
  const deleteNote = async (lesson_outline_id) => {
    try {
      await httprequest.delete(`${NOTE_API}/${lesson_outline_id}`);
      toast.success("Lesson note deleted successfully");
      setLessonNotes((prevNotes) => prevNotes.filter((note) => note.lesson_outline_id !== lesson_outline_id));
      setIsDeleteModalOpen(!isDeleteModalOpen);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDeleteModal = (noteId) => {
    setNoteToDelete(noteId);
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // eslint-disable-next-line no-console
  console.log(lessonNoteDetails, "lessonNoteDetails");

  // Fetch all LessonNotes
  const fetchLessonNotes = () => {
    setIsLoading(true);
    httprequest
      .get(`${ALL_LESSON_NOTES}/${id}`)
      .then((res) => {
        setLessonNoteDetails(res?.data?.data);
        setLessonNotes(res?.data.data?.lesson_outlines);
        setSearchResult(res?.data.data?.lesson_outlines);
        setTimeline(res?.data?.data?.update_timeline);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    fetchLessonNotes();
  }, []);

  // LessonNoteCard Edit Navigation
  const editFunction = (noteID) => {
    navigate(`/lesson-notes/edit-lesson-note/${noteID}`);
  };

  // Search Bar Function
  const handleNoteSearch = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = lessonNotes?.filter((data) => valArray?.every(
      (word) => data?.topic?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/lesson-notes"
                link_one_name="Lesson Notes"
                active="View all Lesson Outlines"
                description={`View all the lesson outlines for ${lessonNoteDetails?.subject?.name}`}
              />
            </div>
            <div className="flex gap-10 margin-top-16-items-426">
              <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={() => navigate(`/lesson-notes/${id}/create-lesson-outline?subject_id=${lessonNoteDetails?.subject?.id}&class_id=${class_id}`)
                }
                buttonName="Create Lesson Outline"
              />
              <Button
                variant="primary"
                buttonSize="medium"
                color="btndefault"
                onClick={() => navigate(`/lesson-notes/drafts/${id}`)}
                buttonName="View all Drafts"
              />
            </div>
          </div>
          {searchResult.length > 0 ? (
            <div>
              <div className="margin-bottom-10 margin-top-30">
                <CardSearch placeholder="Search Lesson Outline" onChange={handleNoteSearch} />
              </div>
              <Grid
                container
                spacing={4}
                sx={{
                  "@media (max-width: 1000px)": {
                    flexWrap: "wrap-reverse"
                  }
                }}
              >
                <Grid item lg={8} md={8} sm={12} xs={12}>
                  <div className={`${classes.paddingcont}`}>
                    <div className="left-container">
                      <div>
                        {searchResult?.map((lesson, index) => {
                          return (
                            <LessonNoteCard
                              onClick={() => navigate(`/lesson-notes/lesson-outlines/${lesson?.lesson_outline_id}`)}
                              key={index}
                              title={lesson?.topic}
                              subtitle={parse(lesson?.lesson_outline)}
                              date={lesson?.created_at.substring(0, 10)}
                              status={lesson?.status}
                              viewRoute={`/lesson-notes/lesson-outlines/${lesson?.lesson_outline_id}`}
                              handleDelete={() => handleDeleteModal(lesson?.lesson_outline_id)}
                              editPath={{ pathname: `/lesson-notes/edit-lesson-outline/${lesson?.lesson_outline_id}` }}
                              editState={lesson}
                              handleEdit={() => editFunction(lesson?.lesson_outline_id)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <div className={`${classes.paddingcont}`}>
                    <div className={`${classes.rightcont}`}>
                      <div className={`${classes.notedetails}`}>
                        {timeline?.map((update, i) => {
                          return (
                            <div key={i}>
                              <div
                                className="flex gap-10 align-items-center margin-bottom-10"
                              >
                                <Icon
                                  icon="lets-icons:check-fill"
                                  width="32"
                                  height="32"
                                  style={{ color: "#3FAC64" }}
                                />
                                <div>
                                  <h5>Updated at</h5>
                                  <p>{update?.updated_at?.substring(0, 10)} | {update?.updated_at?.substring(11, 16)}</p>
                                </div>
                              </div>
                              <div className={classes.verticalline}>
                                <img src={VerticalLine} alt="" />
                              </div>
                            </div>
                          );
                        })}
                        {searchResult && (
                          <div className="flex gap-10 align-items-center margin-bottom-10">
                            <Icon
                              icon="mdi:cloud-clock-outline"
                              width="25"
                              height="25"
                              style={{
                                color: "#0e9504",
                                border: "1px solid #0e9504",
                                borderRadius: "20px",
                                padding: "5px",
                                marginLeft: "5px"
                              }}
                            />
                            <div>
                              <h5>Created at</h5>
                              <p>{searchResult?.created_at}</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className={`${classes.exportbtn}`}>
                        <Button
                          variant="primaryoutline"
                          color="btnfontprimary"
                          buttonSize="full"
                          buttonName="Export All Lesson Outlines"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <ToastContainer />
              </Grid>
            </div>
          ) : (<NotFounditem title="No Lesson Notes Outline Created" subtitle="You have not created any lesson note outline yet." img={Nosearch} />)}
          <Modal
            title="Delete Lesson Outline"
            subtitle="Are you sure you want to permanently delete this lesson outline?"
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="danger"
                    buttonName="Delete Lesson Outline"
                    buttonSize="full"
                    color="btndefault"
                    onClick={() => deleteNote(noteToDelete)}
                    isLoading={isLoading}
                  />
                </div>
              </>
            }
            isopen={isDeleteModalOpen}
            closeModal={handleDeleteModal}
          />
        </>
      }
    />
  );
};

export default ViewLessonNote;
