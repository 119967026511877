import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import useStyles from "../styles";
import DetailsText from "../../leavemgt/components/DetailsText";
import { useParams } from "react-router-dom";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const GET_A_LIBRARY_ITEM = "/api/v1/library/";

const ViewLibraryBook = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [aLibItem, setaLibItem] = useState({});
  const [isPdfModalOpen, setPdfModalOpen] = useState(false);

  // Handle button click to show/hide PDF modal
  const handleViewPdfClick = () => {
    setPdfModalOpen(true);
  };

  const closePdfModal = () => {
    setPdfModalOpen(false);
  };

  const handleGetLibraryItem = () => {
    httprequest.get(`${GET_A_LIBRARY_ITEM}${id}`).then((res) => {
      setaLibItem(res?.data?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(aLibItem, "aLibItem");

  useEffect(() => {
    handleGetLibraryItem();
  }, []);

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/library"
                link_one_name="School Library"
                active={aLibItem?.title}
                description={aLibItem?.description}
              />
            </div>
            <div>
              {aLibItem?.file?.file_url && (
                <Button buttonSize="medium" buttonName="View Book" onClick={handleViewPdfClick} variant="primary" color="btndefault" />
              )}
            </div>
          </div>
          <hr className={classes.pagedivider} />
          <div className="page-content padding-top-30">
            <Grid container spacing={4}>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title={`${aLibItem?.type} Name`} subtitle={aLibItem?.title} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Created at" subtitle={aLibItem?.created_at?.substring(0, 10)} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Updated at" subtitle={aLibItem?.updated_at?.substring(0, 10)} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Library Item Type" subtitle={aLibItem?.type} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText title="Description" subtitle={aLibItem?.description} />
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <DetailsText
                  title="Code"
                  subtitle={aLibItem?.code}
                />
              </Grid>
              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl="https://res.cloudinary.com/dk701zyrg/raw/upload/v1726242333/32936b7f16d5bd231726242333020.pdf" />;
              </Worker> */}
            </Grid>
          </div>
          <Modal
            title="View PDF"
            subtitle="Preview the PDF document"
            modalContent={
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={aLibItem?.file?.file_url} />
                </Worker>
              </Grid>
            }
            modalFooter={
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Close"
                onClick={closePdfModal}
              />
            }
            isopen={isPdfModalOpen}
            closeModal={closePdfModal}
          />
        </>
      }
    />
  );
};

export default ViewLibraryBook;
