import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  pageContent: {
    backgroundColor: "white",
    width: "100%",
    // height: "100%",
    borderRadius: "10px 10px 0px 0px",
    boxSizing: "border-box",
    paddingBlock: "10px",
    paddingInline: "16px"
  },
  inline: {
    paddingInline: "20px"
  },
  contentpadding: {
    paddingLeft: 10
  },
  paddingleftmodal: {
    paddingLeft: "3%",
    marginBlock: "21px 26px",
    display: "flex",
    gap: "10px",
    width: "100%"
  },
  ExamItems: {
    marginLeft: "30px",
    marginBottom: "24px"
  },
  addextra: {
    gap: 4,
    color: theme.palette.primary.main,
    fontWeight: "500"
  },
  remove: {
    color: "red",
    gap: 4,
    fontWeight: "bold"
  },
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    height: 60,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20
  },
  label: {
    fontWeight: "500",
    fontSize: "16px"
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: theme.palette.primary.main,
    width: 86,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadbuttons: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    cursor: "pointer",
    marginLeft: "1rem",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 3,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0

  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      flexWrap: "wrap"
    }
  },
  inputwrapper: {
    textAlign: "left"
  }
}));
