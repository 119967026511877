import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0
  },
  notfound: {
    maxWidth: "300px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh"
  },
  legends: {
    background: "white",
    paddingTop: "16px",
    zIndex: "100",
    position: "absolute",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    bottom: "20px",
    width: "100%",
    right: "20px",
    paddingLeft: "16px",
    left: "0px",
    "@media (max-width: 700px)": {
      flexWrap: "wrap"
    }
  },
  attlegend: {
    padding: "8px 16px",
    backgroundColor: "#F5F6F7",
    borderRadius: "6px",
    cursor: "pointer",
    "& h6": {
      marginBlock: 0
    },
    "& p": {
      marginBlock: "0px",
      fontSize: "14px"
    }
  },
  systemkey: {
    textAlign: "right",
    marginRight: "35px",
    "@media (max-width: 500px)": {
      textAlign: "left"
    }
  },
  attcounter: {
    "& h5": {
      color: "#0065FF",
      marginBlock: "0px",
      fontWeight: "700"
    },
    "& h6": {
      marginBlock: "0px",
      fontSize: "14px",
      fontWeight: "500"
    }
  },
  attendanceheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 1125px)": {
      flexWrap: "wrap"
    }
  },
  updateattendance: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "right",
    "@media (max-width: 1125px)": {
      justifyContent: "left"
    }
  },
  terminfo: {
    padding: "5px",
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    marginRight: "35px",
    "& p": {
      fontWeight: "500",
      marginBlock: "0px",
      fontSize: "14px",
      color: theme.palette.primary.main
    }
  }
}));
