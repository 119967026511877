/* eslint-disable no-unused-vars */
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { getAllISOCodes } from "iso-country-currency";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { httprequest } from "../../../data/api";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";

const CONTRACTOR_ENDPOINT = "/api/v1/contractor";

const Product = () => {
  const classes = useStyles();
  const [uploadForm, setUploadForm] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [contractorData, setContractorData] = useState([]);
  const [customAField, setCustomAField] = useState({
    title: "",
    price: ""
  });
  const [customBField, setCustomBField] = useState({
    title: "",
    price: ""
  });
  const [product, setProduct] = useState({
    amount: "",
    product_name: "",
    price: "",
    quantity: "",
    custom_a: customAField,
    custom_b: customBField
  });
  const [productOrder, setProductOrder] = useState({
    order_title: "",
    type: "service",
    order_id: "",
    contractor: "",
    creation_date: "",
    supply_date: "",
    products: [product],
    description: ""
  });

  const allCurrency = getAllISOCodes();

  const getAllContractors = () => {
    httprequest
      .get(CONTRACTOR_ENDPOINT)
      .then((res) => {
        setContractorData(res?.data?.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllContractors();
  }, []);

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleCustomChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleOrderChange = (e) => {
    const { name, value } = e.target;
    setProductOrder({ ...productOrder, [name]: value });
  };

  const handleRemoveFile = () => setSelectedFile("");

  return (
    <Grid container spacing={2} className="input-padding">
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <p className="text-align-left no-margin-block">
          <strong>Upload Image</strong>
          <span className="input-required">*</span>
        </p>
        <div className={classes.pickercontainer}>
          <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="image/jpeg,image/jpe,image/png,application/pdf" />

          <div className={classes.uploadflex}>
            <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
            {selectedFile?.name ? (
              <div>
                <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
              </div>
            ) :
              <div>
                <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                </h6>
              </div>}
          </div>
          <div className={classes.rightpicker}>
            {selectedFile?.name ? (
              <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
            ) : null}
          </div>
        </div>
      </Grid>
      <Grid container spacing={2} className={classes.containerpadding}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <InputGroup
            label="Order Title"
            margin="normal"
            inputType="text"
            required
            // onChange={handleChange}
            inputName="name"
          //   value={name}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <InputGroup
            label="Order ID"
            margin="normal"
            required
            //   onChange={handleChange}
            inputType="email"
            inputName="email"
          //   value={email}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <InputGroup
            label="Creation Date"
            margin="normal"
            required
            inputType="date"
            inputName="creation_date"
            // onChange={handleOrderChange}
            // value={creation_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Supply Date"
            margin="normal"
            inputType="date"
            inputName="supply_date"
            required
            // onChange={handleOrderChange}
            // value={supply_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <SelectGroup
            label="Contractor"
            required
            children={
              <select
                name="contractor"
                // value={contractor}
                // onChange={handleOrderChange}
              >
                <option value="">---Select Contractor ---</option>
                {contractorData?.map((data) => {
                  return (
                    <option key={data?.id} value={data?.id}>
                      {data?.name}
                    </option>
                  );
                })}
              </select>
            }
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <SelectGroup
            label="Currency"
            required
            children={
              <select
                name="currency"
                defaultValue="GBP"
                onChange={handleOrderChange}
              >
                <option value="">---Select Currency ---</option>
                {allCurrency?.map((data, i) => {
                  return (
                    <option key={i} value={data?.currency}>
                      {data?.currency} {data?.countryName}
                    </option>
                  );
                })}
              </select>
            }
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <InputGroup
            label="Product Name"
            margin="normal"
            required
            inputType="date"
            inputName="creation_date"
            // onChange={handleOrderChange}
            // value={creation_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Price"
            margin="normal"
            required
            inputType="number"
            // onChange={handleOrderChange}
            // value={amount}
            inputName="amount"
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Quantity"
            margin="normal"
            inputType="number"
            required
            // onChange={handleOrderChange}
            // value={amount}
            inputName="amount"
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Amount"
            margin="normal"
            required
            inputType="number"
            // onChange={handleOrderChange}
            // value={amount}
            inputName="amount"
          />
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <InputGroup
            label="Additional Charge Title"
            margin="normal"
            inputType="text"
            inputName="creation_date"
            // onChange={handleOrderChange}
            // value={creation_date}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <InputGroup
            label="Additional Charge Price"
            margin="normal"
            inputType="number"
            required
            // onChange={handleOrderChange}
            // value={amount}
            inputName="amount"
          />
        </Grid>
      </Grid>
      <div
        className={`${classes.addproduct} flex align-items-center`}
      >
        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
        <p>Add another Product</p>
      </div>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextAreaGroup
          label="Description"
          required
          margin="normal"
          placeholder="Enter the message you will like to share with the parents."
          // value={description}
          inputName="description"
          // onChange={handleOrderChange}
        />
      </Grid>
    </Grid>
  );
};

export default Product;
