import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20,
    marginTop: 15
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 0,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  eventdetailsheader: {
    height: 200,
    width: "100%",
    overflow: "hidden",
    borderRadius: "8px 8px 0px 0px",
    position: "relative"
  },
  libcardcont: {
    position: "relative",
    maxWidth: "475px",
    height: "300px",
    borderRadius: "12px",
    border: "1px solid #EDEDF2",
    textDecoration: "none"
  },
  imageheader: {
    "& img": {
      width: "100%",
      borderRadius: "12px 12px 0px 0px"
    }
  },
  liblink: {
    textDecoration: "none"
  },
  libcard: {
    paddingInline: "16px",
    "& h6": {
      fontWeight: "700",
      fontSize: "16px",
      marginBlock: "0px",
      textAlign: "left",
      textDecoration: "none"
    },
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      position: "relative",
      // height: "50px",
      maxWidth: "405px",
      marginBlock: "0px",
      textAlign: "left",
      textDecoration: "none"
    },
    "& a": {
      fontWeight: "500",
      fontSize: "16px",
      color: "#0A17A7",
      textAlign: "left !important",
      textDecoration: "none"
    }
  },
  loweritems: {
    position: "absolute",
    bottom: "16px",
    width: "100%",
    left: "0px",
    paddingLeft: "16px",
    paddingRight: "8px"
  },
  detailstext: {
    textAlign: "left"
  },
  pagedivider: {
    marginTop: 40,
    marginBottom: 40,
    color: "#DFE2E7"
  },
  fordownloadcont: {
    position: "relative"
  },
  fordownload: {
    width: "100%",
    margin: "auto",
    position: "sticky",
    top: "0",
    zIndex: "100",
    paddingBlock: "10px",
    marginBottom: "15px",
    borderRadius: "5px",
    backgroundColor: "#0065FF",
    "& a": {
      color: "white",
      width: "100%",
      textDecoration: "none",
      margin: "auto",
      display: "Block",
      textAlign: "center"
    }
  },
  videodesccont: {
    height: "70px",
    background: "#fcfcfc",
    paddingTop: "8px",
    borderRadius: "8px",
    position: "relative"
  },
  videodesc: {
    padding: "0px 8px",
    borderRadius: "8px",
    height: "55px",
    background: "#fcfcfc",
    overflow: "scroll"
  }
}));
