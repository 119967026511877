import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  addextra: {
    gap: 4,
    color: theme.palette.primary.main,
    fontWeight: "500"
  },
  uploadbutton: {
    backgroundColor: theme.palette.primary.main,
    width: 86,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  shelflogo: {
    textAlign: "left",
    display: "flex",
    alignItems: "center"
  },
  environment: {
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 6,
    color: "white",
    fontWeight: "bold",
    marginLeft: 5,
    textTransform: "uppercase",
    background: theme.palette.primary.main
  },
  sitecontent: {
    paddingBlock: "48px",
    paddingInline: "30px",
    "& h4": {
      fontWeight: 500,
      fontSize: "18px",
      textAlign: "left !important"
    },
    "& a": {
      textAlign: "left",
      display: "Block",
      fontWeight: 500,
      fontSize: "14px",
      color: theme.palette.primary.main,
      marginBlock: 16
    },
    "@media (max-width: 450px)": {
      paddingInline: "24px"
    }
  },
  sitemapheader: {
    // marginBottom: "30px",
    "& p": {
      textAlign: "left",
      fontWeight: 400,
      fontSize: "14px",
      maxWidth: "465px",
      marginBlock: "0px"
    }
  },
  sitemapsubheader: {
    marginTop: 30
  },
  container: {
    "@media (min-width: 1366px)": {
      width: "1200px",
      margin: "auto"
    }
  }
}));
