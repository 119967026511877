import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Badge from "../../components/Badge";

const Teachers = ({ data }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [teacherData, setTeacherData] = useState([]);

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    setTeacherData(data);
    setSearchResult(data);
  }, [data]);

  const columns = [
    // {
    //   name: "Code",
    //   selector: row => row?.code
    // },
    {
      name: "Name",
      // eslint-disable-next-line prefer-template
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Role",
      selector: row => row?.role?.name
    },
    {
      name: "Active",
      cell: (row) => (<Badge status={row?.is_active} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <Link to={`/users/employees/${row?.employee_id}`} className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </Link>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon"
          >
            {row?.is_active === true ? <div className="delete-icon"><Icon icon="mingcute:delete-2-line" /></div>
              : <div className="approve-icon"><Icon icon="mdi:checkbox-marked-circle-outline" /></div>}
          </button>
        </div>
      )
    }
  ];

  // --| Filter Teacher table using name, email and class
  const handleSearchTeacher = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = teacherData?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
        data?.first_name
          ?.toLowerCase()
          .includes(word.toLowerCase()) ||
                    data?.email
                      ?.toLowerCase()
                      .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <>
      <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
        <TableSearch placeholder="Search here..." searchTableFunc={handleSearchTeacher} />
      }
      />
      <Modal
        title={currentRow?.is_active === true ? "Off board employee" : "Re-activate Employee"}
        subtitle={
          currentRow?.is_active === true
            ? (
              <div>
                Are you sure you want to off board
                <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                If deleted, the employee will no longer have access to this platform.
              </div>
            )
            : (
              <div>
                Are you sure you want to re-activate
                <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                If re-activated, the employee will have access to this platform.
              </div>
            )
        }
        modalContent={
          <>

          </>
        }
        modalFooter={
          <>
            {currentRow?.is_active === true ? <Button variant="danger" buttonName="Off board Employee" buttonSize="full" color="btndefault" />
              : <Button variant="primary" buttonName="Re-activate Employee" buttonSize="full" color="btndefault" />}
          </>
        }
        isopen={deleteModal}
        closeModal={handleCloseDeleteModal}
      />
    </>
  );
};

export default Teachers;
