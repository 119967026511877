// import Layout from "../../components/Layout/Layout";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
// import useStyles from "./styles";
import { useState, useEffect } from "react";
import TabContext from "@mui/lab/TabContext";
import { useNavigate } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Tabs from "@mui/lab/TabList";
import AllSubject from "./AllSubject";
import { ToastContainer, toast } from "react-toastify";
import { httprequest } from "../../../data/api";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import { useUserState } from "../../../context/UserContext";
import { accessViewAssignmentById } from "../../../data/roles";

const CLASS_ENDPOINT = "/api/v1/teacher/classes";
const SUBJECT_IN_CLASS_ENDPOINT = "api/v1/teacher/class-subject";

const EmployeeSubject = () => {
  const { userRole } = useUserState();
  // const classes = useStyles();
  const navigate = useNavigate();
  const [value, setValue] = useState("1");
  const [classesData, setAllClassesData] = useState([]);
  const [subjectByClass, setSubjectByClass] = useState([]);
  const [subjectResultByClass, setSubjectResultByClass] = useState([]);

  const subjectColumns = [
    {
      name: "Subject",
      selector: row => row?.subject_name
    },
    {
      name: "Class",
      selector: () => ""
    },
    {
      name: "Date Created",
      selector: row => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: row => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon">
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }

  ];

  // --| This is to get all the classes i am teaching as a teacher.
  // --| This is useful for populating the tabs and the tab panel
  const getClassByTeacher = () => {
    httprequest.get(`${CLASS_ENDPOINT}`).then((res) => {
      setAllClassesData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // --| This is to get a teacher's subject by class
  const getSubjectsByClass = (param) => {
    httprequest.get(`${SUBJECT_IN_CLASS_ENDPOINT}/${param}`).then((res) => {
      setSubjectByClass(res?.data?.data);
      setSubjectResultByClass(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleChange = (_event, newValue) => {
    setValue(newValue);
    getSubjectsByClass(newValue);
  };

  useEffect(() => {
    getClassByTeacher();
  }, []);

  // --| Filter Subject table using name, email and class
  const handleSearchSubjectByClass = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = subjectByClass?.filter((data) => valArray?.every(
      (word) => data?.subject_name
        ?.toLowerCase()
        .includes(word.toLowerCase())
    )
    );
    setSubjectResultByClass(result);
  };

  return (
    <>
      <PageTitle
        title="My Subjects"
        subtitle="See the details of the Subject you take."
        button={
          accessViewAssignmentById(userRole) &&
            <Button onClick={() => navigate("/subjects/assignment/:id")} variant="primary" buttonSize="small" color="btndefault" buttonName="Assignments" />

        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs
              indicatorColor="none"
              className="overide-tablist"
              onChange={handleChange}
              aria-label=""
            >
              <Tab label="All" value="1" />
              {classesData?.map((data) => {
                return (
                  <Tab key={data?.class?.id} value={data?.class_id} label={data?.class?.name} />
                );
              })}
            </Tabs>
          </div>
          <TabPanel value="1" className="tabpanel-overide"><AllSubject /></TabPanel>
          {classesData?.map((data) => {
            return (
              <TabPanel key={data?.class_id} value={data?.class_id}>
                <Table data={subjectResultByClass} columns={subjectColumns} subHeader={true} pagination subHeaderComponent={
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchSubjectByClass} />
                }
                />
              </TabPanel>
            );
          })}
        </TabContext>
      </div>
      <ToastContainer />
    </>
  );
};

export default EmployeeSubject;
