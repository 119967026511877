import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  rightcont: {
    position: "relative",
    backgroundColor: "white",
    height: "calc(100vh - 300px)",
    boxSizing: "border-box",
    padding: "24px 15px 0px 15px",
    overflow: "scroll"
  },
  exportbtn: {
    // maxWidth: "190px",
    // position: "absolute",
    position: "sticky",
    bottom: "0px",
    left: "18px",
    right: "19px",
    margin: "0 auto"
  },
  buttons: {
    maxWidth: "300px",
    margin: "18px auto"
  },
  savebtn: {
    maxWidth: "150px",
    margin: "18px auto"
  },
  notedetails: {
    height: "100%",
    overflow: "scroll",
    "& h4": {
      fontWeight: "700",
      marginBlock: "0px",
      fontSize: "14px",
      color: "black",
      textAlign: "left"
    },
    "& h5": {
      fontWeight: "700",
      marginBlock: "0px",
      fontSize: "11.33px",
      color: "#6C737F",
      textAlign: "left"
    },
    "& p": {
      fontWeight: 400,
      fontSize: "9.71px",
      color: "#6C737F",
      marginBlock: "0px",
      textAlign: "left"
    },
    "@media (max-width: 900px)": {
      height: "100%"
    }
  },
  notespan: {
    marginTop: "10px",
    marginBottom: "10px",
    "& h5": {
      fontWeight: "700",
      marginBlock: "0px",
      fontSize: "15px",
      color: "#6C737F",
      textAlign: "left"
    },
    "& p": {
      fontWeight: 400,
      fontSize: "12px",
      color: "#6C737F",
      marginBlock: "0px",
      textAlign: "left"
    }
  },
  textarea: {
    border: "none",
    height: "70px",
    width: "100%"
  },
  fortextarea: {
    border: "1px solid #F0F2F5",
    padding: "16px",
    borderRadius: "9px",
    marginTop: "5px"
  },
  commentarea: {
    textAlign: "left",
    padding: "16px",
    border: "1px solid #F0F2F5",
    fontSize: "14px"
  },
  verticalline: {
    paddingLeft: 15,
    paddingBottom: 5,
    textAlign: "left"
  },
  parsercont: {
    textAlign: "left",
    "& ul": {
      listStyle: "initial",
      paddingLeft: "24px"
    }
  },
  overallcont: {
    paddingLeft: "11px",
    cursor: "pointer",
    "& h3": {
      fontWeight: "500",
      fontSize: "18px",
      letterSpacing: "-0.3px",
      marginBlock: "0px",
      textAlign: "left",
      textDecoration: "none"
    },
    "& hr": {
      width: "90%",
      border: "0.1px solid #BAC1CC",
      float: "left",
      marginBlock: "26px 18px"
    }
  },
  cardcontent: {
    textAlign: "left",
    fontSize: "14px",
    maxHeight: "70px",
    overflow: "hidden",
    "& h1": {
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "left",
      marginBlock: "0px"
    },
    "& p": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#667185",
      textAlign: "left",
      marginBlock: "0px"
    },
    "& h3": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#667185",
      textAlign: "left",
      marginBlock: "0px"
    },
    "& h2": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#667185",
      textAlign: "left",
      marginBlock: "0px"
    }
  },
  submitreview: {
    "& p": {
      fontWeight: 500,
      fontSize: "12px",
      color: "#0065FF"
    }
  },
  paddingcont: {
    position: "relative",
    background: "white",
    paddingBlock: "32px"
  },
  cardfooter: {
    marginTop: "18px"
  },
  loweritems: {
    height: "50px"
  },
  reload: {
    position: "absolute",
    right: "16px",
    top: "35px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  }
}));
