/* eslint-disable no-console */
import Layout from "../../../../components/Layout";
import BreadCrumb from "../../../../components/BreadCrumb";
import Button from "../../../../components/Button";
import { useEffect, useState } from "react";
import useStyles from "../../styles";
import useValidator from "../../../../hooks/useValidator";
import { ToastContainer, toast } from "react-toastify";
import SelectGroup from "../../../../components/FormComponent/SelectGroup";
import { Grid, Menu, MenuItem, IconButton } from "@mui/material";
import InputGroup from "../../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../../data/api";
import { useParams } from "react-router-dom";
import WidgetTitle from "../../../dashboard/components/WidgetTitle/WidgetTitle";
import { Icon } from "@iconify/react/dist/iconify.js";
import TextAreaGroup from "../../../../components/FormComponent/TextAreaGroup";
import PageLoader from "../../../../components/PageLoader";
import Select from "react-select";

const ADMIN_CLASS_ENDPOINT = "/api/v1/class";
const AN_ENROLMENT_EXAM = "/api/v1/enrolment-exams";
// const ASSIGNMENT_DRAFT = "/api/v1/assignment/draft/";
const UPLOAD_ENDPOINT = "/api/v1/upload";

const EditEnrolmentExam = () => {
  const [classData, setClassData] = useState([]);
  const { id } = useParams();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // const [isDraftSaving, setIsDraftSaving] = useState(false);
  const [currentAnchor, setCurrentAnchor] = useState("");
  const [disabledInputs, setDisabledInputs] = useState({});
  // const [questionImages, setQuestionImages] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editEnrolExamValidator, showEditEnrolExamValidator] = useValidator();
  const [totalGrade, setTotalGrade] = useState(0);

  const handleClick = (event, queInd) => {
    setAnchorEl(event.currentTarget);
    setCurrentAnchor(queInd);
  };
  const handleAdditionalQueClose = () => {
    setAnchorEl(null);
  };

  const [editEnrolmentExam, setEDitEnrolmentExam] = useState({
    title: "",
    class_id: [],
    description: "",
    total_grade: 0,
    instruction: "",
    duration: 0,
    questions: [
      {
        question_index: 1

      }
    ]
  });

  // This creates a new question
  const handleAdditionalQuestion = () => {
    const newQue = {
      question: "",
      question_index: editEnrolmentExam?.questions?.length + 1,
      type: "",
      answer: [],
      file: {},
      required: false,
      grade_point: "",
      options: []
    };

    setEDitEnrolmentExam((prevForm) => ({
      ...prevForm,
      questions: [...(prevForm.questions || []), newQue]
    }));
  };

  // --| Remove additional choice added as an option in a question
  const removeAdditionalChoice = (index, queInd) => {
    const newQueArr = [...editEnrolmentExam.questions];
    const newChoiceArr = newQueArr[queInd]["options"]?.filter(
      (_item, ind) => ind !== index
    );
    newQueArr[queInd]["options"] = newChoiceArr;
    setEDitEnrolmentExam({ ...editEnrolmentExam, questions: newQueArr });
  };

  const handleMultipleChoiceChange = (e, index, queInd) => {
    const { value } = e.target;

    const options = editEnrolmentExam.questions[queInd]["options"];
    const newOptionArr = [...options];

    newOptionArr[index] = value;
    const newQueArr = [...editEnrolmentExam.questions];
    newQueArr[queInd]["options"] = newOptionArr;
    setEDitEnrolmentExam({ ...editEnrolmentExam, questions: newQueArr });
  };

  const calculateTotalGrade = () => {
    const total = editEnrolmentExam?.questions.reduce((sum, question) => {
      return sum + (parseFloat(question.grade_point) || 0);
    }, 0);
    setTotalGrade(total);
  };

  const removeQueDescription = (index) => {
    const newQuestionArr = [...editEnrolmentExam.questions];
    delete newQuestionArr[index]["description"];
    setEDitEnrolmentExam({ ...editEnrolmentExam, questions: newQuestionArr });
  };

  // Edit Correct Option Check of a Specific Question
  const handleCorrectChoiceChange = (e, optionIndex, queInd) => {
    const newQuestionArr = [...editEnrolmentExam.questions];

    // Check the type for the specific question based on (queInd)
    if (newQuestionArr[queInd]?.type === "multiple_choice") {
      // Update the state with the new questions array
      newQuestionArr[queInd]["answer"] = [optionIndex];
    } else if (newQuestionArr[queInd]?.type === "checkbox") {
      // For checkbox ( one or multiple correct answers allowed)
      const answers = newQuestionArr[queInd]["answer"] || [];

      if (e.target.checked) {
        // Add the option index if checked
        answers.push(optionIndex);
      } else {
        // Remove the option index if unchecked
        const answerIndex = answers.indexOf(optionIndex);
        if (answerIndex > -1) {
          answers.splice(answerIndex, 1);
        }
      }

      // Update answer in the question array
      newQuestionArr[queInd]["answer"] = answers;
    }

    // Set the updated questions array
    setEDitEnrolmentExam({ ...editEnrolmentExam, questions: newQuestionArr });
  };

  // This Updates the value a specific question based on user input.
  const handleQueTypeChange = (e, index) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editEnrolmentExam.questions];
    updatedQuestions[index][name] = value;

    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });
  };

  const toggleQueDescriptionTrue = () => {
    const updatedQuestions = [...editEnrolmentExam.questions];
    updatedQuestions[currentAnchor]["description"] = "";
    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });
  };

  const handleQueChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editEnrolmentExam.questions];
    updatedQuestions[queInd][name] = value;

    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });
  };

  // Handle Input of Question Point of a Specific Question
  const handleQuePointChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editEnrolmentExam.questions];
    updatedQuestions[queInd][name] = value;

    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });

    calculateTotalGrade();
  };

  // Handle Question Description of a Specific Question
  const handleQueDescChange = (e, queInd) => {
    const { name, value } = e.target;
    const updatedQuestions = [...editEnrolmentExam.questions];
    updatedQuestions[queInd][name] = value;

    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });
  };

  // Handle class select
  const handleMultipleAccessChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => ({ id: option?.value, name: option?.label }));
    setEDitEnrolmentExam((prev_class) => (
      { ...prev_class, class_id: selectedValues }
    ));
  };

  // This adds an additional option to a specific question
  const handleAdditionalOptions = (queInd) => {
    const updatedQuestions = [...editEnrolmentExam.questions];

    updatedQuestions[queInd].options = [...updatedQuestions[queInd].options, [" "]];

    setEDitEnrolmentExam({
      ...editEnrolmentExam,
      questions: updatedQuestions
    });
  };

  // Get An Assignment By ID
  const getEnrolmentExamById = () => {
    setIsLoading(true);
    httprequest.get(`${AN_ENROLMENT_EXAM}/${id}`).then((res) => {
      // setAssignmentData(res?.data?.data);
      const data = res?.data?.data;
      setEDitEnrolmentExam({
        ...editEnrolmentExam, subject_id: data?.subject,
        title: data?.title,
        class_id: data?.classes,
        description: data?.description,
        total_grade: data?.total_grade,
        instruction: data?.instruction,
        questions: data?.questions,
        duration: data?.duration
      });
      // setEditdableData(res?.data?.data);
      setIsLoading(false);
    }).catch((err) => {
      toast.error(err?.response?.data);
      setIsLoading(false);
    });
  };

  // Handle Edit an Assignemnt
  const handleEditAssignment = () => {
    if (editEnrolExamValidator.allValid()) {
      setIsEditing(true);
      const updatedClassIds = editEnrolmentExam?.class_id.map((a_class) => a_class?.id);
      httprequest.patch(`${AN_ENROLMENT_EXAM}/${id}`, { ...editEnrolmentExam, class_id: updatedClassIds }).then((res) => {
        setIsEditing(false);
        toast.success(res?.data?.message);
        getEnrolmentExamById();
      }).catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showEditEnrolExamValidator(true);
    }
  };

  // const handleDraftAssignment = () => {
  //   setIsDraftSaving(true);
  //   httprequest.post(`${ASSIGNMENT_DRAFT}${id}`, editEnrolmentExam).then((res) => {
  //     setIsDraftSaving(false);
  //     toast.success(res?.data?.message);
  //     getEnrolmentExamById();
  //   }).catch((err) => {
  //     setIsDraftSaving(false);
  //     showEditEnrolExamValidator(true);
  //     toast.error(err?.response?.data?.message);
  //   });
  // };

  // --| Remove Additional Question that has been added
  const handleDeleteQuestion = (question_ind) => {
    const allquestions = editEnrolmentExam?.questions;
    const questionToDelete = allquestions?.find((item) => item?.question_index === question_ind);
    const newQuestions = allquestions?.filter((item) => item?.question_index !== questionToDelete?.question_index);
    setEDitEnrolmentExam((prev) => ({
      ...prev,
      questions: newQuestions
    }));
  };

  const handleIconClick = (queInd) => {
    document.getElementById(`imageInput-${queInd}`).click();
  };

  const handleIconOptionClick = (i, queInd) => {
    document.getElementById(`imageInput-${i}${queInd}`).click();
  };

  // Handle image upload and update the specific question
  const handleQuestionImageChange = async (event, queInd) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      // Prepare the image for upload
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response?.data?.data?.[0]?.upload_id,
            file_url: response?.data?.data?.[0]?.file_url
          };

          // Create a copy of the current question
          const updatedQuestion = {
            ...editEnrolmentExam?.questions[queInd],
            file: uploadedImageDetails
          };

          // Send only the updated question to the backend
          // await httprequest.patch(`${AN_ENROLMENT_EXAM}/${id}`, {
          //   ...editEnrolmentExam,
          //   questions: [updatedQuestion] // This update the modified question
          // });

          // Update local state after successful upload
          setEDitEnrolmentExam((prevForm) => ({
            ...prevForm,
            questions: prevForm.questions.map((question, index) => index === queInd ? updatedQuestion : question
            )
          }));

          toast.success(`Image added to question ${queInd + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  // Remove a Question Image
  const removeQueImage = (queInd) => {
    // Update editAssignmentForm to remove the image for the specific question without making an API call
    setEDitEnrolmentExam((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question, index) => index === queInd
        ? {
          ...question,
          file: {}
        }
        : question
      )
    }));
    // Temporarily remove the image preview from the state
    toast.success(`Image removed from question ${queInd + 1}`);
  };

  // Handle Upload Image to an Option
  const handleOptionImageChange = async (event, optionIndex, questionIndex) => {
    if (event?.target?.files?.length > 0) {
      const file = event.target.files[0];

      // Disable the input while the file is being uploaded
      setDisabledInputs((prevDisabled) => ({
        ...prevDisabled,
        [questionIndex]: {
          ...prevDisabled[questionIndex],
          [optionIndex]: true
        }
      }));

      // Prepare to send the image to the upload endpoint
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the image
        const response = await httprequest.post(UPLOAD_ENDPOINT, formData);

        if (response?.status === 200 && response?.data?.data?.[0]?.file_url) {
          const uploadedImageDetails = {
            upload_id: response.data.data[0].upload_id,
            file_url: response.data.data[0].file_url
          };

          // Update only the specific option in the question at questionIndex
          const updatedOptions = editEnrolmentExam.questions[questionIndex].options.map(
            (option, oIndex) => oIndex === optionIndex ? uploadedImageDetails : option
          );

          const updatedQuestion = {
            ...editEnrolmentExam.questions[questionIndex],
            options: updatedOptions
          };

          // Update the editEnrolmentExam state with the modified question
          setEDitEnrolmentExam((prevForm) => ({
            ...prevForm,
            questions: prevForm.questions.map((question, index) => index === questionIndex ? updatedQuestion : question
            )
          }));

          // API call to update only the specific option of the particular question
          // await httprequest.patch(`${AN_ENROLMENT_EXAM}/${id}`, {
          //   ...editEnrolmentExam,
          //   questions: [updatedQuestion]
          // });

          toast.success(`Image added to option ${optionIndex + 1} in question ${questionIndex + 1}`);
        } else {
          toast.error(response?.data);
        }
      } catch (error) {
        toast.error(error?.response?.message);
      }
    }
  };

  // Delete an Image of an Option
  const removeOptionImage = (optionIndex, questionIndex) => {
    // Remove the image details from the specific option
    const updatedOptions = editEnrolmentExam?.questions[questionIndex].options.map(
      (option, oIndex) => oIndex === optionIndex ? { ...option, upload_id: "", file_url: "" } : option
    );
    // Create the updated question object with the modified option
    const updatedQuestion = {
      ...editEnrolmentExam?.questions[questionIndex],
      options: updatedOptions
    };
    // Update local state after the image is removed
    setEDitEnrolmentExam((prevForm) => ({
      ...prevForm,
      questions: prevForm.questions.map((question, index) => index === questionIndex ? updatedQuestion : question
      )
    }));
    toast.success(`Image removed from option ${optionIndex + 1} in question ${questionIndex + 1}`);
  };

  // --| Get all classes as a school admin
  const getAllAdminClasses = () => {
    httprequest
      .get(ADMIN_CLASS_ENDPOINT)
      .then((res) => {
        setClassData(
          res?.data?.data.map((a_class) => ({
            value: a_class?.id,
            label: a_class?.class_name
          }))
        );
      })
      .catch(() => {});
  };

  // This handles the Assignment Description input
  const handleEditEnrolmentExam = (e) => {
    const { name, value } = e.target;

    setEDitEnrolmentExam({ ...editEnrolmentExam, [name]: value });
  };

  useEffect(() => {
    calculateTotalGrade();
  }, [editEnrolmentExam?.questions]);

  useEffect(() => {
    getAllAdminClasses();
    getEnrolmentExamById();
  }, []);

  const options = [
    // {
    //   value: "paragraph",
    //   text: "Paragraph",
    //   icon: <Icon icon="carbon:text-short-paragraph" width="25" height="25" />
    // },
    {
      value: "multiple_choice",
      text: "Multiple Choice",
      icon: <Icon icon="mdi:radiobox-marked" width="25" height="25" />
    },
    {
      value: "checkbox",
      text: "Checkbox",
      icon: <Icon icon="ci:checkbox-check" width="25" height="25" />
    }
    // {
    //   value: "dropdown",
    //   text: "Dropdown",
    //   icon: <Icon icon="tabler:select" width="25" height="25" />
    // },
    // {
    //   value: "file_upload",
    //   text: "File Upload",
    //   icon: <Icon icon="basil:cloud-upload-outline" width="25" height="25" />
    // },
    // {
    //   value: "date",
    //   text: "Date",
    //   icon: (
    //     <Icon
    //       icon="material-symbols-light:date-range-sharp"
    //       width="25"
    //       height="25"
    //     />
    //   )
    // },
    // {
    //   value: "time",
    //   text: "Time",
    //   icon: <Icon icon="mingcute:time-line" width="25" height="25" />
    // }
  ];

  // const { questions } = editEnrolmentExam;

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/enrolments/enrolment-examination"
                link_one_name="All Enrolment Examination"
                active="Edit Enrolment Examination"
                description="Edit an Enrolment Examination"
              />
            </div>
            <div className="flex gap-10">
              {/* <Button
                variant="primaryoutline"
                buttonSize="fluid"
                color="btnfontprimary"
                onClick={handleDraftAssignment}
                buttonName="Save as Draft"
                isLoading={isDraftSaving}
              /> */}
              <Button
                variant="primary"
                buttonSize="fluid"
                color="btndefault"
                buttonName="Edit Enrolment Examination"
                isLoading={isEditing}
                onClick={handleEditAssignment}
              />
            </div>
          </div>
          <div className="page-content">
            {isLoading ? (
              <PageLoader />
            ) :
              <>
                <WidgetTitle
                  title="Class Information"
                />
                <div className={`${styles.assignmentsection} margin-bottom-14`}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="multiple-select">
                        <p>Class</p>
                        <Select
                          isMulti
                          name="class_id"
                          closeMenuOnSelect={true}
                          options={classData}
                          onChange={handleMultipleAccessChange}
                          defaultValue={editEnrolmentExam?.class_id?.map(item => ({ label: item?.name, value: item?.id }))}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                        />
                      </div>
                      {editEnrolExamValidator.message(
                        "class_id",
                        editEnrolmentExam?.class_id,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <InputGroup
                        label="Subject"
                        margin="normal"
                        inputType="text"
                        inputName="subject"
                        defaultValue={editEnrolmentExam?.subject_id}
                        onChange={handleEditEnrolmentExam}
                        required
                      />
                      {editEnrolExamValidator.message(
                        "subject",
                        editEnrolmentExam?.subject_id,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <InputGroup
                        label="Duration"
                        margin="normal"
                        inputType="number"
                        inputName="duration"
                        defaultValue={editEnrolmentExam?.duration}
                        placeHolder="Set Duration in Minutes"
                        onChange={handleEditEnrolmentExam}
                        required
                      />
                      {editEnrolExamValidator.message(
                        "Duration",
                        editEnrolmentExam?.duration,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <InputGroup
                        label="Total Grade"
                        margin="normal"
                        inputType="number"
                        inputName="total_grade"
                        placeHolder="Set Duration in Minutes"
                        value={totalGrade}
                        disabled
                        onChange={handleEditEnrolmentExam}
                        required
                      />
                      {editEnrolExamValidator.message(
                        "Total Grade",
                        editEnrolmentExam?.total_grade,
                        "required"
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className={`${styles.assignmentsection} margin-bottom-14`}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Enrolment Examination Title"
                        margin="normal"
                        inputType="text"
                        defaultValue={editEnrolmentExam?.title}
                        inputName="title"
                        onChange={handleEditEnrolmentExam}
                        required
                      />
                      {editEnrolExamValidator.message(
                        "title",
                        editEnrolmentExam?.title,
                        "required"
                      )}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={styles.inputmargin}>
                        <TextAreaGroup
                          label="Enrolment Examiantion Instruction"
                          inputName="instruction"
                          margin="normal"
                          defaultValue={editEnrolmentExam?.instruction}
                          placeholder=""
                          onChange={handleEditEnrolmentExam}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={styles.inputmargin}>
                        <TextAreaGroup
                          label="Description"
                          inputName="description"
                          defaultValue={editEnrolmentExam?.description}
                          margin="normal"
                          placeholder=""
                          onChange={handleEditEnrolmentExam}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={styles.assignmentsection}>
                  <Grid container spacing={2}>
                    {editEnrolmentExam?.questions?.map((question, queInd) => (
                      <>
                        <Grid item lg={12} md={12} sm={12} xs={12} key={queInd}>
                          <div className={styles.quesaction}>
                            <label className={styles.enterque}> Question {queInd + 1}<span className="input-required"> *</span></label>
                            <div>
                              {editEnrolmentExam?.questions?.length > 1 ? (
                                <>
                                  <Icon
                                    icon="material-symbols:delete-outline"
                                    width="20"
                                    height="20"
                                    className="delete-icon"
                                    onClick={() => handleDeleteQuestion(question?.question_index, queInd)}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>
                          <div className={styles.textandimgmain}>
                            <div className={styles.textandimg}>
                              <input
                                type="text"
                                placeholder="Type Question"
                                name="question"
                                value={question?.question}
                                onChange={(e) => {
                                  handleQueChange(e, queInd);
                                }}
                              />
                              {/* {assignmentValidator.message(
                        "question",
                        questionArr[queInd]["question"],
                        "required"
                      )} */}
                              <div onClick={() => handleIconClick(queInd)} className={styles.queimg}>
                                <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                <input
                                  type="file"
                                  accept="image/*"
                                  id={`imageInput-${queInd}`}
                                  multiple
                                  onChange={(e) => handleQuestionImageChange(e, queInd)}
                                  style={{ display: "none" }}
                                />
                              </div>
                              {/* Image preview */}
                              <div className={styles.preiewcont}>
                                {editEnrolmentExam?.questions[queInd]?.file?.file_url && (
                                  <>
                                    <img
                                      src={editEnrolmentExam?.questions[queInd]?.file?.file_url}
                                      alt={`Selected for question ${queInd + 1}`}
                                    />
                                    <Icon
                                      icon="material-symbols:delete-outline"
                                      width="20"
                                      height="20"
                                      className="delete-icon"
                                      onClick={() => removeQueImage(queInd)}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </Grid>
                        {/* For Question Description */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {"description" in question ? (
                            <div className="margin-top full-width">
                              <div className=" flex gap-10 justify-items-space">
                                <label className={styles.enterque}>Question Description</label>
                                <div>
                                  <div
                                    className="delete-icon"
                                    onClick={() => removeQueDescription(queInd)}
                                  >
                                    <Icon
                                      icon="ooui:close"
                                      width="20"
                                      height="20"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.textandimgmain}>
                                <div className={styles.textandimg}>
                                  <input
                                    type="text"
                                    placeHolder="Enter Description"
                                    name="description"
                                    value={question?.description}
                                    onChange={(e) => handleQueDescChange(e, queInd)}
                                  />
                                  <></>
                                  <div onClick={handleIconClick} className={styles.queimg}>
                                    <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                    <input
                                      type="file"
                                      accept="image/*"
                                      id="imageInput"
                                      multiple
                                      disabled
                                      onChange={handleOptionImageChange}
                                      style={{ display: "none" }}
                                    />
                                  </div>
                                  {/* Image preview */}
                                  {/* <div className={styles.preiewoptioncont}>
                                    {optionimages.map((optionimage, index) => (
                                      <img
                                        key={index}
                                        src={optionimage}
                                        alt={`Selected ${index}`}
                                      />
                                    ))}
                                  </div> */}
                                </div>
                              </div>
                              {/* <div className="width-full">
                                <InputGroup
                                  label="Question Description"
                                  margin="normal"
                                  placeHolder="Enter Description"
                                  inputType="text"
                                  value={question?.description}
                                  inputName="description"
                                  onChange={(e) => handleQueDescChange(e, queInd)}
                                />
                              </div> */}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            className={styles.pointsInput}
                            label="Question Point"
                            inputType="number"
                            inputMin={1}
                            inputName="grade_point"
                            placeHolder="Enter Question Points"
                            value={question?.grade_point}
                            onChange={(e) => {
                              handleQuePointChange(e, queInd);
                            }}
                          />
                          {/* {assignmentValidator.message(
                      "points",
                      questionArr[queInd]["grade_point"],
                      "required"
                    )} */}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <SelectGroup
                            label="Add Answer Type"
                            required
                            children={
                              <select
                                name="type"
                                value={question?.type}
                                onChange={(e) => handleQueTypeChange(e, queInd)}
                              >
                                <option value="">--- Select ---</option>
                                {options?.map((label) => {
                                  return (
                                    <option
                                      key={label?.key}
                                      value={label?.value}
                                    >
                                      <Icon
                                        className={`${styles.add_question_color}`}
                                        icon="flat-color-icons:plus"
                                        width="24"
                                        height="24"
                                      />
                                      {label?.text}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                          {/* {assignmentValidator.message(
                      "question type",
                      questionArr[queInd]["type"],
                      "required"
                    )} */}
                        </Grid>
                        {/* Checkbox */}
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          {question?.type === "checkbox" ? (
                            <>
                              <Grid container spacing={2} mt={1}>
                                {question?.options?.map((checkbox, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1}<span className="input-required"> *</span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {question?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              value={checkbox?.file_url ? "" : checkbox}
                                              disabled={checkbox?.file_url || disabledInputs[queInd]?.[i]}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            {/* {assignmentValidator.message(
                                              "option choice",
                                              questionArr[queInd]["options"][i + 1],
                                              "required"
                                            )} */}
                                            {/* {que?.type === "paragraph" ? (
                                              <div className={styles.inputmargin}>
                                                <TextAreaGroup
                                                  margin="normal"
                                                  placeholder=""
                                                // disabled
                                                />
                                              </div>
                                            ) : null} */}
                                            <></>
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {checkbox?.file_url && (
                                                <>
                                                  <img
                                                    src={checkbox.file_url}
                                                    alt={`Option ${i + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="flex gap-10 align-items-center justify-items-space margin-top-5 "
                                        >
                                          <div className={styles.correctcontainer}>
                                            <input
                                              type="checkbox"
                                              name={`answer-${queInd}`}
                                              value={i}
                                              id={`correct-${queInd}-${i}`}
                                              defaultChecked={editEnrolmentExam?.questions[queInd]?.answer?.includes(i)}
                                              onChange={(e) => handleCorrectChoiceChange(e, i, queInd)}
                                            />
                                            <label
                                              className={styles.correctlabel}
                                              htmlFor={`correct-${queInd}-${i}`}
                                            >
                                              is correct
                                            </label>
                                          </div>
                                        </div>

                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another checkbox</p>
                              </div>
                            </>
                          ) :
                            <>
                              {/* Type === multiple choice */}
                              <Grid container spacing={2} mt={1}>
                                {question?.options?.map((radio, i) => {
                                  return (
                                    <Grid
                                      item
                                      lg={6}
                                      md={6}
                                      sm={12}
                                      xs={12}
                                      key={i + 1}
                                    >
                                      <div>
                                        <div className=" flex gap-10 justify-items-space">
                                          <label className={styles.enterque}>Option {i + 1} <span className="input-required"> * </span></label>
                                          {/* if checkbox option is more thatn 1, show close*/}
                                          {question?.options?.length > 1 ? (
                                            <Icon
                                              onClick={() => {
                                                removeAdditionalChoice(i, queInd);
                                              }}
                                              className="delete-icon"
                                              icon="ooui:close"
                                              width="20"
                                              height="20"
                                            />
                                          ) : null}
                                        </div>
                                        <div className={styles.textandimgmain}>
                                          <div className={styles.textandimg}>
                                            <input
                                              type="text"
                                              placeholder="Enter option"
                                              name="question"
                                              disabled={radio?.file_url || disabledInputs[queInd]?.[i]}
                                              value={radio?.file_url ? "" : radio}
                                              onChange={(e) => handleMultipleChoiceChange(e, i, queInd)}
                                            />
                                            {/* {assignmentValidator.message(
                                  "option choice",
                                  questionArr[queInd]["options"][i],
                                  "required"
                                )} */}
                                            <></>
                                            <div onClick={() => handleIconOptionClick(i, queInd)} className={styles.queimg}>
                                              <Icon icon="basil:image-outline" width="20" height="20" style={{ color: "#858585" }} />
                                              <input
                                                type="file"
                                                accept="image/*"
                                                id={`imageInput-${i}${queInd}`}
                                                // multiple
                                                onChange={(e) => handleOptionImageChange(e, i, queInd)}
                                                style={{ display: "none" }}
                                              />
                                            </div>
                                            {/* Image preview */}
                                            <div className={styles.preiewoptioncont}>
                                              {radio?.file_url && (
                                                <>
                                                  <img
                                                    src={radio.file_url}
                                                    alt={`Option ${i + 1}`}
                                                  />
                                                  <Icon
                                                    icon="material-symbols:delete-outline"
                                                    width="20"
                                                    height="20"
                                                    className="delete-icon"
                                                    onClick={() => removeOptionImage(i, queInd)}
                                                  />
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="flex gap-10 align-items-center justify-items-space margin-top-5 "
                                        >
                                          <div className={styles.correctcontainer}>
                                            <input
                                              type="radio"
                                              name={`answer${queInd}`}
                                              value={i}
                                              id={`correct-${queInd}-${i}`}
                                              defaultChecked={editEnrolmentExam?.questions[queInd]?.answer?.includes(i)}
                                              onChange={(e) => handleCorrectChoiceChange(e, i, queInd)}
                                            />
                                            <label
                                              className={styles.correctlabel}
                                              htmlFor={`correct-${queInd}-${i}`}
                                            >
                                          is correct
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                              <div
                                className={styles.addoption}
                                onClick={() => handleAdditionalOptions(queInd)}
                              >
                                <Icon
                                  icon="octicon:feed-plus-16"
                                  width="14"
                                  height="14"
                                />
                                <p>Add another option</p>
                              </div>
                            </>}
                        </Grid>
                        <div className={styles.editquestionfooter}>
                          <div className="flex gap-10 align-items-center justifyRight">
                            {/* Conditionally render the IconButton only if there is no description */}
                            {!question?.description && (
                              <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-controls={open ? "long-menu" : null}
                                aria-expanded={open ? "true" : null}
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, queInd)}
                              >
                                <Icon
                                  icon="radix-icons:dots-vertical"
                                  width="20"
                                  height="20"
                                />
                                <label className={styles.enterque}>More</label>
                              </IconButton>
                            )}
                          </div>
                        </div>
                        <Menu
                          id="basic-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button"
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleAdditionalQueClose}
                        >
                          <MenuItem
                            onClick={() => {
                              toggleQueDescriptionTrue();
                              handleAdditionalQueClose();
                            }}
                          >
                          Add Description on Question
                          </MenuItem>
                        </Menu>
                      </>
                    ))}
                  </Grid>
                  <div
                    className={styles.addque}
                  >
                    <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                    <p onClick={handleAdditionalQuestion}>Add question</p>
                  </div>
                  <div className={styles.wrapper}>
                    <Button
                      variant="primary"
                      color="btndefault"
                      buttonSize="full"
                      buttonName="Edit Enrolment Examination"
                      onClick={handleEditAssignment}
                      isLoading={isEditing}
                    />
                  </div>
                </div>
              </>
            }
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default EditEnrolmentExam;
