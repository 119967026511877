import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import TabContext from "@mui/lab/TabContext";
import { ToastContainer, toast } from "react-toastify";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import Inactive from "../component/Inactive";
// import Active from "../component/Active";
import ButtonCount from "../../../components/ButtonCount";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
import Modal from "../../../components/Modal";
import PageLoader from "../../../components/PageLoader";
import Badge from "../../../components/Badge";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import ReactPlayer from "react-player";

// import AllStatus from "../component/AllStatus";
import { httprequest } from "../../../data/api";
import useStyle from "../component/styles";

const GET_ALL_LOGS = "/api/v1/access-logs";

const AccessControl = (props) => {
  const navigate = useNavigate();
  const classes = useStyle(props);
  // const {  } = useStyle(props);
  const [searchResult, setSearchResult] = useState([]);
  const [value, setValue] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [isAccessFetching, setIsAccessFetching] = useState(false);
  const [allaccessData, setAllAccessData] = useState([]);
  const [helpModal, setHelpModal] = useState(false);

  const handleViewModal = (row) => {
    setCurrentRow(row);
    setViewModal(!viewModal);
  };

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  const getAllActivityLogs = () => {
    setIsAccessFetching(true);
    httprequest.get(GET_ALL_LOGS).then((res) => {
      setIsAccessFetching(false);
      setAllAccessData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsAccessFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleChange = (event, newValue) => {
    // eslint-disable-next-line no-console
    console.log(newValue);
    setValue(newValue);
    if (newValue === "In") {
      // setAllAccessData(allaccessData?.filter((access) => access?.status === "active"));
      setSearchResult(allaccessData?.filter((access) => access?.action === "clock_in"));
    } else if (newValue === "Out") {
      // setAllAccessData(allaccessData?.filter((access) => access?.status === "inactive"));
      setSearchResult(allaccessData?.filter((access) => access?.action === "clock_out"));
    } else {
      setSearchResult(allaccessData);
    }
  };

  useEffect(() => {
    getAllActivityLogs();
    setValue("all");
  }, []);

  // HAndle copy passcode
  const handleCopyPasscode = (row) => {
    setCurrentRow(row);
    navigator.clipboard.writeText(row?.access_code)
      .then(() => {
        toast.success("Passcode copied");
      })
      .catch((err) => {
        toast.error("Error Copying passcode", err);
      });
  };

  const columns = [
    {
      name: "",
      cell: (row) => row?.action === "clock_out" ? <div className={classes.tagin} style={{ backgroundColor: "#EDCBCB", color: "#B50C0C" }}><Icon icon="icon-park-outline:login" /></div> : <div className={classes.tagin} style={{ backgroundColor: "rgb(136, 248, 174)", color: "rgb(9, 145, 55)" }}><Icon icon="line-md:login" /></div>,
      width: "80px"
    },
    {
      name: "Visitor's Name",
      selector: (row) => row?.visitor_name
    },
    {
      name: "Child Name",
      selector: (row) => row?.student?.name
    },
    {
      name: "Issued By",
      selector: (row) => row?.issuer?.name
    },
    {
      name: "Access Code",
      selector: (row) => row?.access_code
    },
    {
      name: "Clock In",
      selector: (row) => row?.clock_in?.slice(0, -8).replace("T", " ")
    },
    {
      name: "Clock Out",
      selector: (row) => row?.clock_out?.slice(0, -8).replace("T", " ")
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button onClick={() => handleViewModal(row)} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button className="table-action-icon" onClick={() => handleCopyPasscode(row)}>
            <Icon icon="ph:copy" width="16" height="16" />
          </button>
          {/* <button
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button> */}
        </div>
      )
    }
  ];

  useEffect(() => {
    // The data will be from the Email get api endpint
    setSearchResult(allaccessData);
  }, []);

  // eslint-disable-next-line no-console
  console.log(searchResult);

  // --| Filter Shelf21 table using name, email and class
  const handleAccessControl = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allaccessData?.filter((data) => valArray?.every(
      (word) => data?.visitor_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                      data?.email
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                      data?.student?.name
                                        ?.toLowerCase()
                                        .includes(word.toLowerCase()) ||
                                        data?.access_code
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                          data?.issuer?.name
                                            ?.toLowerCase()
                                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  // --| Filter Shelf21 table using name, email and class
  const handleSearchActive = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allaccessData?.filter((access) => (access?.status === "active"))?.filter((data) => valArray?.every(
      (word) => data?.visitor_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                                        data?.student?.name
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                        data?.issuer?.name
                                          ?.toLowerCase()
                                          .includes(word.toLowerCase()) ||
                                          data?.type
                                            ?.toLowerCase()
                                            .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  return (
    <div>
      <PageTitle
        title="Access Logs"
        subtitle="See a usage of all access code in your school."
        button={
          <div className="flex gap-10">
            <Button
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="?"
              onClick={handleHelpModal}
            />
            <Button
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="Validate a code"
              onClick={() => navigate("/accesscontrol/validate")}
            />
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={() => navigate("/accesscontrol/codes")}
              buttonName="Access Codes"
            />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="All Activities" value="all" />
              <Tab label="In" value="In" />
              <Tab label="Out" value="Out" />
            </Tabs>
          </div>
          <>
            <TabPanel value="all" className="tabpanel-overide">
              <div>
                {isAccessFetching ? (
                  <PageLoader />
                ) : (
                  <>
                    <div className="button-count-container">
                      <ButtonCount title="All Activities" count={searchResult?.length} />
                    </div>
                    <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                      <TableSearch placeholder="Search here..." searchTableFunc={handleAccessControl} />
                    }
                    />
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value="In" className="tabpanel-overide">
              {isAccessFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount title="Log Ins" count={searchResult?.length} />
                  </div>
                  <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                    <TableSearch placeholder="Search active access here..." searchTableFunc={handleSearchActive} />
                  }
                  />
                </>
              )}
            </TabPanel>
            <TabPanel value="Out" className="tabpanel-overide">
              {isAccessFetching ? (
                <PageLoader />
              ) : (
                <>
                  <div className="button-count-container">
                    <ButtonCount title="Log Outs" count={searchResult?.length} />
                  </div>
                  <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
                    <TableSearch placeholder="Search here..." searchTableFunc={handleAccessControl} />
                  }
                  />
                </>
              )}
            </TabPanel>
          </>
        </TabContext>
        {/* View Modal */}
        <Modal
          title="View Access Log"
          subtitle={ ""
            // <div>Are you sure you want to remove <span className="modal-name-highlight">{currentRow?.title}</span>. from one of your Activities? Removing it will permanently clear this activity.`
            // </div>
          }
          modalContent={
            <>
              <Grid container spacing={2} mt={2} className={classes.profileform}>
                <Grid item lg={6} md={6} sm={6} xs={12} justifyContent="center"
                  alignItems="center" display="flex"
                >
                  <div className={classes.dummyimgviewer}>
                    {currentRow?.display?.file_url ? (
                      <img src={currentRow?.display?.file_url} />
                    ) : <Icon icon="et:profile-male" />}

                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className={classes.forcode}>
                    <p>{currentRow?.access_code}</p>
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Visitor's Name"
                    inputName="visitor_name"
                    value={currentRow?.visitor_name}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Student Name"
                    inputName="contact"
                    value={currentRow?.student?.name}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="email"
                    label="Visitor's Email"
                    inputName="email"
                    value={currentRow?.email}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="email"
                    label="Visitor's Gender"
                    inputName="email"
                    value={currentRow?.email}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="tel"
                    label="Visitor's Contact"
                    inputName="contact"
                    value={currentRow?.contact}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Issued By"
                    inputName="contact"
                    value={currentRow?.issuer?.name}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Clocked In"
                    inputName=""
                    value={currentRow?.clock_in?.slice(0, -8).replace("T", " ")}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Clocked Out"
                    inputName=""
                    value={currentRow?.clock_out?.slice(0, -8).replace("T", " ")}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <InputGroup
                    inputType="text"
                    label="Validated By"
                    inputName=""
                    value={currentRow?.validated_by}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          }
          modalFooter={
            <>
              <div className="flex gap-10">
                <Button variant="default" buttonName="Close" buttonSize="full" color="btndefault" onClick={handleViewModal} />
              </div>
            </>
          }
          isopen={viewModal}
          closeModal={handleViewModal}
        />
        <Modal
          title="Need Help?"
          subtitle="Watch the Video Below on Access Logs"
          modalContent={
            <>
              <ReactPlayer
                url="https://youtu.be/TyiwWLcpu6k"
                playing={true}
                controls={true}
                width="100%"
              />
            </>
          }
          modalFooter={
            <>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                onClick={handleCloseVideoModal}
                buttonName="Close Video"
              />
            </>}
          isopen={helpModal}
          closeModal={handleCloseVideoModal}
        />
        <ToastContainer />
      </div>
    </div>
  );
};

export default AccessControl;
