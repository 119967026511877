import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import useStyles from "../../styles";

const ClassPeriod = ({ start_time, end_time, period_subject, period_class }) => {
  const classes = useStyles();
  const [isEndTimePassed, setIsEndTimePassed] = useState(false);

  useEffect(() => {
    const currentTime = new Date();
    const endTime = new Date();
    const [endHours, endMinutes, endSeconds] = end_time.split(":");
    endTime.setHours(endHours, endMinutes, endSeconds);

    if (currentTime > endTime) {
      setIsEndTimePassed(true);
    } else {
      setIsEndTimePassed(false);
    }
  }, [end_time]);

  return (
    <div className={classes.periodcont}>
      <div
        className={`${classes.timecont} flex gap-5 align-items-center`}
        style={{
          backgroundColor: isEndTimePassed ? "#DD514D" : "#099137"
        }}
      >
        <Icon icon="ph:clock-bold" width="15" height="15" style={{ color: "#ffffff" }} />
        <div className="flex gap-10">
          <p>{start_time} - {end_time}</p>
        </div>
      </div>
      <h4>{period_class} - {period_subject}</h4>
    </div>
  );
};

export default ClassPeriod;
