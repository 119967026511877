/* eslint-disable max-len */
import AccessComponent from "../../../components/AccesControl/AccessComponent";
import { Grid } from "@mui/material";
import { httprequest } from "../../../data/api";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { TableSearch } from "../../../components/Table/TableActions";
import PageLoader from "../../../components/PageLoader";
import nosearch from "../../../assets/images/no-search.svg";
import NotFounditem from "../../error/NotFound";

const ACCESS_CODES_ENDPOINT = "/api/v1/access_code";
const AccessControl = () => {
  const [accessControl, setAccessControl] = useState([]);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const [selectedAccessItem, setSelectedAccessItem] = useState(null);
  const [filteredAccessControl, setFilteredAccessControl] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleRevokeModal = (item) => {
    setSelectedAccessItem(item);
    setIsRevokeModalOpen(true);
  };

  const handleCloseRevokeModal = () => {
    setIsRevokeModalOpen(false);
    setSelectedAccessItem(null);
  };
  const getAccessControl = async () => {
    setIsLoading(true);
    try {
      const res = await httprequest.get("/api/v1/access/issuer");
      const data = res?.data?.data || [];
      setAccessControl(data);
      setFilteredAccessControl(data);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to load access controls.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAccessControl();
  }, []);

  const handleRevokeCode = async () => {
    if (!selectedAccessItem) {
      toast.error("No access code selected.");

      return;
    }

    setIsRevoking(true);
    try {
      const response = await httprequest.patch(`${ACCESS_CODES_ENDPOINT}/${selectedAccessItem.access_code}`);
      toast.success(response?.data?.message || "Access code revoked successfully.");
      getAccessControl();
      handleCloseRevokeModal();
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to revoke access code.");
    } finally {
      setIsRevoking(false);
    }
  };

  const handleSearchVisitor = (e) => {
    const { value } = e.target;

    if (!value.trim()) {
      setFilteredAccessControl(accessControl);

      return;
    }

    const searchValue = value.toLowerCase().trim();
    const valArray = searchValue.split(" ");

    const result = accessControl.filter((item) => valArray.every((word) => (item?.visitor_name?.toLowerCase()?.includes(word))
    )
    );

    setFilteredAccessControl(result);
  };

  return (
    <div>
      <Grid container>
        <div className="margin-bottom-14">
          <TableSearch placeholder="Search here..." searchTableFunc={handleSearchVisitor} />
        </div>
        {isLoading ? (
          <PageLoader />
        ) : (
          <Grid container spacing={2}>
            {filteredAccessControl.length > 0 ? (
              filteredAccessControl.map((item) => (
                <Grid item lg={4} md={6} sm={12} xs={12} key={item.id}>
                  <AccessComponent
                    name={item.visitor_name}
                    usecase={`Unique Code generated for ${item.visitor_name} is ${item.access_code}`}
                    accessCode={item.access_code}
                    mailaddress={item.email}
                    accesscontrol={item.access_control}
                    phonenumber={item.contact}
                    codestatus={item.status.toLowerCase()}
                    displayPic={item.display_pic}
                    onRevoke={() => handleRevokeModal(item)}
                  />
                </Grid>
              ))
            ) : (
              <div className="not_found">
                <NotFounditem img={nosearch} title="No Access Control Found" />
              </div>
            )}
          </Grid>
        )}
      </Grid>

      {isRevokeModalOpen && selectedAccessItem && (
        <Modal
          title="Revoke Access Code"
          subtitle={
            <div>
            Are you sure you want to revoke the access code{" "}
              <span className="modal-name-highlight">{selectedAccessItem.access_code}   </span>
            from <span className="modal-name-highlight"> {selectedAccessItem.visitor_name}</span>? Revoking it will stop the code from working.
            </div>
          }
          modalContent={<></>}
          modalFooter={
            <>
              <div className="flex gap-10">
                <Button
                  variant="dangeroutline"
                  buttonSize="full"
                  color="btndanger"
                  buttonName="Cancel"
                  onClick={handleCloseRevokeModal}
                />
                <Button
                  variant="danger"
                  buttonName="Revoke Access Code"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleRevokeCode}
                  isLoading={isRevoking}
                />
              </div>
            </>
          }
          isopen={isRevokeModalOpen}
          closeModal={handleCloseRevokeModal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default AccessControl;
