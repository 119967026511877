import Layout from "../../../components/Layout";
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import { TableSearch } from "../../../components/Table/TableActions";
import ButtonCount from "../../../components/ButtonCount";
import Table from "../../../components/Table/Table";
// import { Link } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import Badge from "../../../components/Badge";
import { useTruncate } from "../../../hooks/useTruncate";
import useValidator from "../../../hooks/useValidator";
import useStyles from "../styles";
import { Priority, NoticeInfo } from "../../../utils/DropDown";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import { useUpload, useEditUpload } from "../../../hooks/useUpload";

const NOTICE_ENDPOINT = "/api/v1/notice-board/";
const UPLOAD_ENDPOINT = "/api/v1/upload";

const AdminNoticeBoard = () => {
  const classes = useStyles();
  const [notice, setNotice] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [noticeValidator, showNoticeValidator] = useValidator();
  const [editnoticeValidator, showEditNoticeValidator] = useValidator();
  const [uploadForm, setUploadForm] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isDeleting, setisDeleting] = useState(false);
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [editselectedFile, setEditSelectedFile] = useState("");
  const [editNoticeModal, setEditNoticeModal] = useState(false);
  const [viewNoticeModal, setViewNoticeModal] = useState(false);

  const [noticeForm, setNoticeForm] = useState({
    title: "",
    description: "",
    end_date: "",
    image: {},
    status: "",
    priority: ""
  });

  const [editnoticeForm, setEditNoticeForm] = useState({
    title: "",
    description: "",
    end_date: "",
    image: {},
    status: "",
    priority: ""
  });

  const handleCloseEditNoticeModal = () => {
    setCurrentRow({});
    setEditNoticeModal(!editNoticeModal);
    setEditNoticeForm({
      ...editnoticeForm,
      title: "",
      description: "",
      end_date: "",
      image: {},
      status: "",
      priority: ""
    });
  };

  const handleEditNoticeitemModal = (row) => {
    setEditNoticeModal(!editNoticeModal);
    setEditNoticeForm({
      ...editnoticeForm,
      title: row?.title,
      description: row?.description,
      end_date: row?.end_date,
      image: {
        upload_id: row?.image?.upload_id,
        file_url: row?.image?.file_url
      },
      status: row?.status,
      priority: row?.priority
    });
    setCurrentRow(row);
  };

  const handleCloseEditNoticeItemModal = () => {
    setEditNoticeModal(false);
  };

  const handleEditRemoveFile = async () => {
    try {
      if (currentRow?.image?.upload_id) {
        const response = await httprequest.delete(
          `${UPLOAD_ENDPOINT}/${currentRow?.image?.upload_id}`
        );
        if (response.status === 200) {
          setEditNoticeForm((prevForm) => ({
            ...prevForm,
            image: {
              upload_id: "",
              file_url: ""
            }
          }));
          setIsFileRemoved(true);
          toast.success("Image removed successfully.");
        } else {
          toast.error(response?.data?.message);
        }
      } else {
        toast.error("No file found to remove.");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({
      ...uploadForm,
      file: e.target.files[0].name.replaceAll(" ", "-")
    });
  };
  const handleRemoveFile = () => setSelectedFile("");

  const handleEditFileChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setEditSelectedFile(file);
    }
  };

  const handleCreateNoticeModal = () => {
    setAddInfoModal(!addInfoModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleViewNoticeModal = (row) => {
    setCurrentRow(row);
    setViewNoticeModal(!viewNoticeModal);
  };

  const handleCloseViewNoticeModal = (row) => {
    setCurrentRow(row);
    setViewNoticeModal(!viewNoticeModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNoticeForm({ ...noticeForm, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditNoticeForm({ ...editnoticeForm, [name]: value });
  };

  const handleCloseAddInfoModal = () => {
    setAddInfoModal(false);
    setNoticeForm({
      ...noticeForm,
      title: "",
      description: "",
      end_date: "",
      image: {},
      status: "",
      priority: ""
    });
    showNoticeValidator(false);
  };

  const handleGetAllNotice = () => {
    httprequest
      .get(NOTICE_ENDPOINT)
      .then((res) => {
        setNotice(res?.data?.date);
        setSearchResult(res?.data?.date);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  //   Handle Create Notice
  const handleCreateNotice = async () => {
    if (!noticeValidator.allValid()) {
      showNoticeValidator(true);

      return;
    }

    setIsLoading(true);

    try {
      let uploadData = {};

      // Handle file upload if there's a selected file
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await httprequest.post(`${UPLOAD_ENDPOINT}`, formData);

        if (response.status === 200 && response?.data?.data) {
          const { upload_id, file_url } = response.data.data[0];
          uploadData = { image: { upload_id, file_url } };
        }
      }

      // ready final data object for post
      const data = { ...noticeForm, ...uploadData };

      // Submit the notice data
      const noticeResponse = await httprequest.post(NOTICE_ENDPOINT, data);

      toast.success(noticeResponse?.data?.message);
      handleGetAllNotice();

      // Reset form state
      setNoticeForm({
        title: "",
        description: "",
        end_date: "",
        status: "",
        priority: "",
        image: {}
      });
      setSelectedFile("");

      handleCreateNoticeModal();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditNotice = async () => {
    if (editnoticeValidator.allValid()) {
      setEditLoading(true);
      try {
        let updatedEventForm = { ...editnoticeForm };

        // Handle file upload if the file has been removed and a new file to selected
        if (isFileRemoved && editselectedFile) {
          const response = await useUpload(editselectedFile);

          if (response?.status === 200 && response?.data?.data) {
            updatedEventForm = {
              ...updatedEventForm,
              image: {
                upload_id: response?.data?.data?.[0]?.upload_id,
                file_url: response?.data?.data?.[0]?.file_url
              }
            };
          } else {
            toast.error(response?.data?.message);
            setEditLoading(false);

            return;
          }
        } else if (!isFileRemoved && editselectedFile) {
          // Handle file update when a new file is selected without removing the current file
          const response = currentRow?.image?.upload_id
            ? await useEditUpload(editselectedFile, currentRow?.image?.upload_id)
            : await useUpload(editselectedFile);

          if (response?.status === 200 && response?.data?.data) {
            updatedEventForm = {
              ...updatedEventForm,
              image: {
                upload_id: response?.data?.data?.[0]?.upload_id,
                file_url: response?.data?.data?.[0]?.file_url
              }
            };
          } else {
            toast.error(response?.data?.message);
            setEditLoading(false);

            return;
          }
        } else {
          // If no file change keep the current image values
          updatedEventForm = {
            ...updatedEventForm,
            image: {
              upload_id: currentRow?.image?.upload_id,
              file_url: currentRow?.image?.file_url
            }
          };
        }

        const res = await httprequest.patch(`${NOTICE_ENDPOINT}${currentRow?.id}`, updatedEventForm);

        if (res.status === 200) {
          setEditLoading(false);
          toast.success(res?.data?.message);
          setEditNoticeForm({
            title: "",
            description: "",
            end_date: "",
            image: {},
            status: "",
            priority: ""
          });
          handleGetAllNotice();
          handleCloseEditNoticeItemModal();
          setIsFileRemoved(false);
        } else {
          setEditLoading(false);
          toast.error("Failed to update the event.");
        }
      } catch (err) {
        setEditLoading(false);
        toast.error(err?.response?.data?.message);
      }
    } else {
      showEditNoticeValidator(true);
    }
  };

  // Delete Admin Notice Board
  const handleDeleteNotice = () => {
    setisDeleting(true);
    httprequest
      .delete(`${NOTICE_ENDPOINT}${currentRow?.id}`)
      .then((res) => {
        setisDeleting(false);
        toast.success(res?.data?.message);
        handleCloseDeleteModal();
        handleGetAllNotice();
      })
      .catch((err) => {
        setisDeleting(false);
        toast.error(err?.response?.data?.message);
      });
  };

  // eslint-disable-next-line no-console
  console.log(notice, "notice");

  const columns = [
    {
      name: "Title",
      selector: (row) => row?.title
    },
    {
      name: "Description",
      selector: (row) => useTruncate(row?.description, 100, "...") || "------------"
    },
    {
      name: "Added By",
      selector: (row) => row?.added_by?.name
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "End Date",
      selector: (row) => row?.end_date?.substring(0, 10)
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      center: "true"
    },
    {
      name: "Priority",
      center: "true",
      cell: (row) => <Badge status={row?.priority} />
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => handleViewNoticeModal(row)}>
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button
            className="table-action-icon"
            onClick={() => handleEditNoticeitemModal(row)}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            className="table-action-icon delete-icon"
            onClick={() => handleDeleteModal(row)}
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  // --| Filter Notice table using title, description, priority who can access
  const handleSearchNotice = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = notice?.filter((data) => valArray?.every(
      (word) => data?.title?.toLowerCase().includes(word.toLowerCase()) ||
          data?.description?.toLowerCase().includes(word.toLowerCase()) ||
          data?.priority?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    handleGetAllNotice();
  }, []);

  const { title, description, priority, end_date, status } = noticeForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Notice Board"
            subtitle="create and view all notice in your school."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleCreateNoticeModal}
                  buttonName="Create Notice"
                />
              </div>
            }
          />
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount title="Notices" count={notice?.length} />
            </div>
            <Table
              data={searchResult}
              columns={columns}
              subHeader={true}
              subHeaderComponent={
                <TableSearch
                  placeholder="Search by title..."
                  searchTableFunc={handleSearchNotice}
                />
              }
              pagination
            />
          </div>
          {/* Create a Notice */}
          <Modal
            title="Add a Notice"
            subtitle={
              <div>
                Add a notice for your employees & students to have access to.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      onChange={handleChange}
                    />
                    {noticeValidator.message("Title", title, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={classes.quelabel}>Description <span className="input-required"> *</span></label>
                    <div className={classes.textandimg}>
                      <textarea
                        type="text"
                        placeholder="Enter notice description"
                        name="description"
                        onChange={handleChange}
                      />
                      {noticeValidator.message(
                        "Description",
                        description,
                        "required"
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      onChange={handleChange}
                      inputType="date"
                      required
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="end_date"
                    />
                    {noticeValidator.message("End date", end_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Priority"
                      required
                      children={
                        <select onChange={handleChange} name="priority">
                          <option value="">---Select Labels ---</option>
                          {Priority?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {noticeValidator.message("Priority", priority, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Type"
                      required
                      children={
                        <select onChange={handleChange} name="status">
                          <option value="">---Select Labels ---</option>
                          {NoticeInfo?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {noticeValidator.message("Status", status, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <p className="text-align-left no-margin-block">
                      <strong>Upload Image</strong>
                      <span className="input-required">*</span>
                    </p>
                    <div className={classes.pickercontainer}>
                      <input
                        type="file"
                        required
                        className={classes.picker}
                        onChange={handleFileChange}
                        name="file"
                        accept=".png, .jpg"
                      />

                      <div className={classes.uploadflex}>
                        <div>
                          <Icon
                            icon="icon-park-outline:upload-two"
                            className={classes.uploadicon}
                          />
                        </div>
                        {selectedFile?.name ? (
                          <div>
                            <h2 className={classes.uploadtitle}>
                              {selectedFile?.name}
                            </h2>
                          </div>
                        ) : (
                          <div>
                            <h2 className={classes.uploadtitle}>
                              Tap to Upload
                            </h2>
                            <h6 className={classes.uploadsubtitle}>
                              JPG/PNG| 10MB max.
                            </h6>
                          </div>
                        )}
                      </div>
                      <div className={classes.rightpicker}>
                        {selectedFile?.name ? (
                          <div
                            className={classes.uploadbutton}
                            onClick={handleRemoveFile}
                          >
                            <Icon icon="zondicons:minus-solid" /> Remove File
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* {noticeValidator.message("file", selectedFile, "required")} */}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  variant="primary"
                  buttonName="Create Notice"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleCreateNotice}
                  isLoading={isLoading}
                />
              </>
            }
            isopen={addInfoModal}
            closeModal={handleCloseAddInfoModal}
          />
          {/* Edit Notice */}
          <Modal
            title="Edit a Notice"
            subtitle={
              <div>
                Edit a notice for your employees & students to have access to.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  {editnoticeForm?.image?.file_url && (
                    <div
                      className={classes.deleteBanner}
                      style={{
                        backgroundImage: `url(${editnoticeForm?.image?.file_url})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                      }}
                    >
                      <div
                        className={classes.deleteButton}
                        onClick={handleEditRemoveFile}
                      >
                        <Icon icon="zondicons:minus-solid" /> Remove Image
                      </div>
                    </div>
                  )}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      onChange={handleEditChange}
                      defaultValue={editnoticeForm?.title}
                    />
                    {editnoticeValidator.message("title", editnoticeForm?.title, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Description"
                      margin="normal"
                      required
                      placeHolder="Notice Description"
                      inputType="text"
                      inputName="description"
                      onChange={handleEditChange}
                      defaultValue={editnoticeForm?.description}
                    />
                    {editnoticeValidator.message("description", editnoticeForm?.description, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      onChange={handleEditChange}
                      inputType="date"
                      required
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="end_date"
                      defaultValue={editnoticeForm?.end_date?.substring(0, 10)}
                    />
                    {editnoticeValidator.message("end_date", editnoticeForm?.end_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Priority"
                      required
                      children={
                        <select
                          onChange={handleEditChange}
                          name="priority"
                          defaultValue={editnoticeForm?.priority}
                        >
                          <option value="">---Select Labels ---</option>
                          {Priority?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {editnoticeValidator.message("priority", editnoticeForm?.priority, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Status"
                      required
                      children={
                        <select
                          onChange={handleEditChange}
                          name="Type"
                          defaultValue={editnoticeForm?.status}
                        >
                          <option value="">---Select Labels ---</option>
                          {NoticeInfo?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {editnoticeValidator.message("Type", editnoticeForm?.status, "required")}
                  </Grid>
                  {!editnoticeForm?.image?.file_url ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.pickercontainer}>
                        <input
                          type="file"
                          className={classes.picker}
                          onChange={handleEditFileChange}
                          name="file"
                          accept="image/jpeg,image/jpe,image/png"
                        />
                        <div className={classes.uploadflex}>
                          <div>
                            <Icon
                              icon="icon-park-outline:upload-two"
                              className={classes.uploadicon}
                            />
                          </div>

                          {editselectedFile ? (
                            // Display the new selected file name
                            <div>
                              <h2 className={classes.uploadtitle}>
                                {editselectedFile.name}
                              </h2>
                              <h6 className={classes.uploadsubtitle}>
                                Selected file for upload
                              </h6>
                            </div>
                          ) : currentRow?.image?.file_url && !isFileRemoved ? (
                            // Display the existing file if no new file is selected and the file is not removed
                            <div>
                              <h2 className={classes.uploadtitle}>
                                {currentRow?.image?.file_url.split("/").pop()}
                              </h2>
                              <h6 className={classes.uploadsubtitle}>
                                SVG, PNG, JPG, GIF | 10MB max.
                              </h6>
                            </div>
                          ) : (
                            // Default "Tap to Upload" message if no file exists
                            <div>
                              <h2 className={classes.uploadtitle}>
                                Tap to Upload
                              </h2>
                              <h6 className={classes.uploadsubtitle}>
                                SVG, PNG, JPG, GIF | 10MB max.
                              </h6>
                            </div>
                          )}
                        </div>

                        <div className={classes.rightpicker}>
                          {(editselectedFile || currentRow?.image?.file_url) &&
                          !isFileRemoved ? (
                              <div
                                className={classes.uploadbutton}
                                onClick={handleEditRemoveFile}
                              >
                                <Icon icon="zondicons:minus-solid" /> Remove File
                              </div>
                            ) : null}
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonName="Cancel"
                    buttonSize="full"
                    color="btnfontprimary"
                    onClick={handleCloseEditNoticeItemModal}
                  />
                  <Button
                    variant="primary"
                    buttonName="Edit Notice"
                    buttonSize="full"
                    color="btndefault"
                    onClick={handleEditNotice}
                    isLoading={editLoading}
                  />
                </div>
              </>
            }
            isopen={editNoticeModal}
            closeModal={handleCloseEditNoticeModal}
          />
          {/* View Notice */}
          <Modal
            title="View a Notice"
            subtitle={
              <div>
                View a notice set for your employees & students to have access to.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {currentRow?.image?.file_url && (
                      <div
                        className={classes.viewBanner}
                        style={{
                          backgroundImage: `url(${currentRow?.image?.file_url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat"
                        }}
                      >
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      disabled="disabled"
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      onChange={handleEditChange}
                      defaultValue={currentRow?.title}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      disabled="disabled"
                      onChange={handleEditChange}
                      inputType="date"
                      required
                      inputMin={new Date().toISOString().split("T")[0]}
                      inputName="end_date"
                      defaultValue={currentRow?.end_date?.substring(0, 10)}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.textandimg}>
                      <textarea
                        type="text"
                        disabled
                        placeholder="Enter notice description"
                        name="description"
                        defaultValue={currentRow?.description}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <InputGroup
                      label="Description"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice Description"
                      inputType="text"
                      inputName="description"
                      onChange={handleEditChange}
                      defaultValue={currentRow?.description}
                    /> */}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Priority"
                      required
                      children={
                        <select
                          onChange={handleEditChange}
                          name="priority"
                          defaultValue={currentRow?.priority}
                          disabled
                        >
                          <option value="">---Select Labels ---</option>
                          {Priority?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Type"
                      required
                      children={
                        <select
                          onChange={handleEditChange}
                          name="type"
                          defaultValue={currentRow?.status}
                          disabled
                        >
                          <option value="">---Select Labels ---</option>
                          {NoticeInfo?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonName="Cancel"
                    buttonSize="full"
                    color="btnfontprimary"
                    onClick={handleCloseViewNoticeModal}
                  />
                </div>
              </>
            }
            isopen={viewNoticeModal}
            closeModal={handleCloseViewNoticeModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete A Notice"
            subtitle={
              <div>
                Are you sure you want to remove{" "}
                <span className="modal-name-highlight">
                  {currentRow?.title}
                </span>
                . from one of your Notice Board? Removing it will permanently
                clear this Notice.`
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                    onClick={handleCloseDeleteModal}
                  />
                  <Button
                    variant="danger"
                    buttonName="Remove Notice"
                    buttonSize="full"
                    color="btndefault"
                    onClick={handleDeleteNotice}
                    isLoading={isDeleting}
                  />
                </div>
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default AdminNoticeBoard;
