import Layout from "../../components/Layout";
import AdminEnrolmentExam from "./admin/AdminEnrolmentExam";

const EnrolmentExam = () => {
  return (
    <Layout children={
      <AdminEnrolmentExam />
    }
    />
  );
};

export default EnrolmentExam;
