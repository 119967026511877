import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  reload: {
    position: "absolute",
    right: "16px",
    top: "35px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    "& svg": {
      color: theme.palette.primary.main,
      fill: theme.palette.primary.main
    }
  },
  selectcont: {
    textAlign: "left !important"
  },
  schedulecont: {
    maxWidth: "100%",
    marginTop: "16px",
    padding: "24px 16px",
    border: "1px solid #D0D5DD",
    borderRadius: "16px",
    TextAlign: "left !important",
    "& h1": {
      fontWeight: "700",
      fontSize: "16px",
      color: "#101928",
      marginBlock: "0px",
      TextAlign: "left"
    },
    "& h3": {
      fontWeight: "700",
      color: "#013EBA",
      fontSize: "14px"
    },
    "& p": {
      color: "#667185",
      fontSize: "14px",
      marginBlock: "8px 0px"
    },
    "& a": {
      textDecoration: "none"
    }
  },
  schdulecard: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  firstitem: {
    fontWeight: "700"
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center"
  }
}));
