import Layout from "../../../components/Layout";
import Table from "../../../components/Table/Table";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { TableSearch } from "../../../components/Table/TableActions";
import { Icon } from "@iconify/react";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../../hooks/useValidator";
import Badge from "../../../components/Badge";
import PageTitle from "../../../components/PageTitle";
import ButtonCount from "../../../components/ButtonCount";
import { useNavigate } from "react-router-dom";

const TERM_ENDPOINT = "/api/v1/school-plan/term";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";

const SchoolTerm = () => {
  const [addTermModal, setAddTermModal] = useState(false);
  const [editTermModal, setEditTermModal] = useState(false);
  const [deleteTermModal, setDeleteTermModal] = useState(false);
  const [currentDelete, setCurrentDelete] = useState("");
  const [currentRow, setCurrentRow] = useState({});
  const [isTermsFetching, setIsTermsFetching] = useState(false);
  const [termsData, setTermsData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const [termValidator, showTermValidator] = useValidator();
  const [editSessionValidator, showEditSessionValidator] = useValidator();
  const [sessionData, setSessionData] = useState([]);
  const [termForm, setTermForm] = useState({
    term: "",
    session_id: "",
    start_date: "",
    end_date: "",
    is_current: false
  });

  const [editTerm, setEditTerm] = useState({
    term: "",
    session_id: "",
    start_date: "",
    end_date: "",
    is_current: false
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTermForm({ ...termForm, [name]: value });
  };

  const handleIsCurrent = (e) => {
    const { name, checked } = e.target;
    setTermForm({ ...termForm, [name]: checked });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditTerm({ ...editTerm, [name]: value });
  };

  const handleEditIsCurrent = (e) => {
    const { name, checked } = e.target;
    setEditTerm({ ...editTerm, [name]: checked });
  };

  const handleDeleteModal = (row) => {
    setDeleteTermModal(true);
    setCurrentDelete(row);
  };

  const handleCloseDeleteModal = () => {
    setDeleteTermModal(false);
    setCurrentDelete({});
  };

  const handleAddSessionModal = () => {
    setAddTermModal(!addTermModal);
  };

  const handleEditOptionsModal = (row) => {
    setCurrentRow(row);
    setEditTerm({ ...editTerm, term: row?.term, session_id: row?.session_id, start_date: row?.start_date, end_date: row?.end_date, is_current: row?.is_current });
    setEditTermModal(true);
  };

  const handleCloseEditOptionsModal = () => {
    setCurrentRow({});
    setEditTerm({ ...editTerm, term: "", session_id: "", start_date: "", end_date: "" });
    setEditTermModal(false);
  };

  const getAllSession = () => {
    httprequest.get(SESSION_ENDPOINT).then((res) => {
      setSessionData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAllTerms = () => {
    setIsTermsFetching(true);
    httprequest.get(TERM_ENDPOINT).then((res) => {
      setIsTermsFetching(false);
      setTermsData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsTermsFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const handleCreateTerm = () => {
    if (termValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(TERM_ENDPOINT, { terms: [termForm] }).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllTerms();
        setTermForm({ ...termForm, term: "", session_id: "", start_date: "", end_date: "", is_current: false });
        handleAddSessionModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showTermValidator(true);
    }
  };

  const handleDeleteTerm = () => {
    setIsDeleteLoading(true);
    httprequest.delete(`${TERM_ENDPOINT}/${currentDelete?.id}`).then(() => {
      setIsDeleteLoading(false);
      toast.success("Term Deleted Successfully");
      getAllTerms();
      handleCloseDeleteModal();
    }).catch((err) => {
      setIsDeleteLoading(false);
      toast.error(err?.response?.data);
    });
  };

  const handleEditSession = () => {
    if (editSessionValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${TERM_ENDPOINT}/${currentRow?.id}`, editTerm).then(() => {
        setIsEditLoading(false);
        toast.success("Session Edited");
        getAllTerms();
        setEditTerm({ ...editTerm, term: "", session_id: "", end_date: "", start_date: "" });
        handleCloseEditOptionsModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditOptionsModal();
      });
    } else {
      showEditSessionValidator(true);
    }
  };

  // --| Filter Term table using type, number of days, who can access
  const handleSearchTerm = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = termsData?.filter((data) => valArray?.every(
      (word) => data?.type
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
            data?.term?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  useEffect(() => {
    getAllSession();
    getAllTerms();
  }, []);

  const columns = [
    {
      name: "Term Name",
      selector: (row) => row?.term
    },
    {
      name: "Session name",
      selector: (row) => row?.session
    },
    {
      name: "Term Start Date",
      selector: (row) => row?.start_date?.substring(0, 10)
    },
    {
      name: "Term End Date",
      selector: (row) => row?.end_date?.substring(0, 10)
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: " ",
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button className="table-action-icon" onClick={() => {
            handleEditOptionsModal(row);
          }}
          >
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  const { session_id, term, start_date, end_date } = termForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="School Terms"
            subtitle="Create and manage your school terms"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleAddSessionModal}
                  buttonName="Add School Term"
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={() => {
                    navigate("/schoolsession");
                  }}
                  buttonName="View Sessions"
                />
              </div>
            }
          />
          <div className="button-count-container">
            <Grid container spacing={2} className="input-padding">
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <ButtonCount
                  title="All School Terms"
                  count={termsData?.length}
                />
              </Grid>
            </Grid>
          </div>
          <Table
            data={searchResult}
            columns={columns}
            subHeader={true}
            isLoading={isTermsFetching}
            subHeaderComponent={<TableSearch placeholder="Search here..." searchTableFunc={handleSearchTerm} />}
            pagination
          />
          <Modal
            title="Create Term"
            subtitle="Create a term to an existing session."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="term" type="text" label="Term Name" placeHolder="e.g 1st Term" value={term}
                      onChange={handleChange}
                    />
                    {termValidator.message("Term", term, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup label="Select Session" children={
                      <select onChange={handleChange} name="session_id" value={session_id}>
                        <option value="">---Select---</option>
                        {sessionData?.map((sesh) => {
                          return (
                            <option key={sesh?.id} value={sesh?.id}>{sesh?.session}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {termValidator.message("Session", session_id, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="start_date" inputType="date" label="Start Date" value={start_date} onChange={handleChange} />
                    {termValidator.message("Start Date", start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="end_date" inputType="date" label="End Date" value={end_date} onChange={handleChange} />
                    {termValidator.message("Start Date", end_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <input type="checkbox" name="is_current" id="current_term" onChange={handleIsCurrent} />
                    <label className="checkbox-label" for="current_term">Current Term</label>
                  </Grid>

                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleCreateTerm}
                  variant="primary"
                  buttonName="Create New Term"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isLoading}
                />
              </>
            }
            isopen={addTermModal}
            closeModal={handleAddSessionModal}
          />
          <Modal
            title="Delete Current Term"
            subtitle={`Are you sure you want to delete this  ${currentDelete?.term} term?. If you delete it, it becomes unavaiable for the whole school.`}
            modalContent={
              <>
                <div>
                </div>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  variant="danger"
                  buttonName="Delete Selected Term"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleDeleteTerm}
                  isLoading={isDeleteLoading}
                />
              </>
            }
            isopen={deleteTermModal}
            closeModal={handleCloseDeleteModal}
          />

          {/* Modal to edit term */}
          <Modal
            title="Edit Term Information"
            subtitle="Edit your current Term"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup required label="Select Session" children={
                      <select onChange={handleEditChange} name="session_id" defaultValue={editTerm?.session_id}>
                        <option value="">---Select---</option>
                        {sessionData?.map((sesh) => {
                          return (
                            <option key={sesh?.id} value={sesh?.id}>{sesh?.session}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {editSessionValidator.message("Session", editTerm?.session_id, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="term" type="text" label="Term" defaultValue={editTerm?.term} onChange={handleEditChange} />
                    {editSessionValidator.message("Term", editTerm?.term, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="start_date" inputType="date" label="Start Date" defaultValue={editTerm?.start_date?.slice(0, 10)} onChange={handleEditChange} />
                    {editSessionValidator.message("Start Date", editTerm?.start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup required inputName="end_date" inputType="date" defaultValue={editTerm?.end_date?.slice(0, 10)} label="End Date" onChange={handleEditChange} />
                    {editSessionValidator.message("Start Date", editTerm?.end_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <input type="checkbox" name="is_current" id="current_term" defaultChecked={editTerm?.is_current} onChange={handleEditIsCurrent} />
                    <label className="checkbox-label" for="current_term">Current Term</label>
                  </Grid>

                </Grid>
              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleEditSession}
                  variant="primary"
                  buttonName="Edit Session"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isEditLoading}
                />
              </>
            }
            isopen={editTermModal}
            closeModal={handleCloseEditOptionsModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default SchoolTerm;
