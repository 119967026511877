import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import { Grid } from "@mui/material";
import CardCount from "../../components/CardCount";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import InputGroup from "../../components/FormComponent/InputGroup";
import TextAreaGroup from "../../components/FormComponent/TextAreaGroup";
import WelfareCard from "./components/WelfareCard";
import {
  WifiOff,
  DataSaverOff,
  DoNotDisturb,
  FlightTakeoff
} from "@mui/icons-material";
import Modal from "../../components/Modal/index";
import { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import { httprequest } from "../../data/api";
import { toast, ToastContainer } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import ButtonCount from "../../components/ButtonCount";
import { useNavigate } from "react-router-dom";
// import { useUserState } from "../../context/UserContext";
import Badge from "../../components/Badge";

const LEAVE_OPTIONS_ENDPOINT = "/api/v1/leaveoptions";
const APPLY_LEAVE_ENDPOINT = "/api/v1/leave/apply";
const LEAVE_ENDPOINT = "/api/v1/leave";

const Welfare = () => {
  const navigate = useNavigate();
  const [welfareForm, setWelfareForm] = useState({
    leave_type: "",
    start_date: "",
    end_date: "",
    comment: ""
  });
  const [allLeaveOptions, setAllOptions] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [allLeaveData, setAllLeaveData] = useState([]);
  const [viewOptionsModal, setViewOptionsModal] = useState(false);
  const [createLeaveValidator, showCreateLeaveValidator] = useValidator();
  const [daysCount, setDaysCount] = useState("");

  // const { userRole } = useUserState();

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "end_date") {
  //     setDaysCount(0);
  //   }
  //   setWelfareForm({
  //     ...welfareForm, [name]: value
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWelfareForm({
      ...welfareForm,
      [name]: value
    });
    if (name === "start_date" || name === "end_date") {
      const startDate = new Date(welfareForm.start_date);
      const endDate = new Date(value);
      if (startDate && endDate && startDate <= endDate) {
        let count = 0;
        const currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          const dayOfWeek = currentDate.getDay();
          if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 = Sunday, 6 = Saturday
            count++;
          }
          currentDate.setDate(currentDate.getDate() + 1);
        }
        const selectedLeaveOption = allLeaveOptions.find(option => option.id === welfareForm.leave_type);
        if (selectedLeaveOption) {
          const totalDaysLeft = selectedLeaveOption.total_days_left;
          if (count > totalDaysLeft) {
            toast.error(`You cannot request more than ${totalDaysLeft} days for this leave type`);
            setWelfareForm({
              ...welfareForm,
              end_date: "" // Reset the end date field
            });
            setDaysCount(0); // Reset the days count
          } else {
            setDaysCount(count); // Update days count only if valid
          }
        } else {
          setDaysCount(0); // Reset days count if no leave type is selected
        }
      } else {
        setDaysCount(0); // Reset days count if dates are invalid
      }
    }
  };
  const getAllLeaveOptions = () => {
    httprequest
      .get(LEAVE_OPTIONS_ENDPOINT)
      .then((res) => {
        setAllOptions(res?.data?.data);
      })
      .catch(() => {});
  };

  const getAllMyLeave = () => {
    httprequest
      .get(`${LEAVE_ENDPOINT}/employee`)
      .then((res) => {
        setAllLeaveData(res?.data?.data);
      })
      .catch(() => {});
  };

  const [leaveModal, setLeaveModal] = useState(false);

  const handleLeaveModal = () => {
    setLeaveModal(!leaveModal);
  };

  const handleCloseLeaveModal = () => {
    setLeaveModal(false);
    setWelfareForm({ ...welfareForm, leave_type: "", start_date: "", end_date: "", comment: "" });
  };

  const handleViewOptions = () => {
    setViewOptionsModal(!viewOptionsModal);
  };

  const handleCloseViewOptions = () => {
    setViewOptionsModal(false);
  };

  // const handleApplyLeave = () => {
  //   if (createLeaveValidator.allValid()) {
  //     setIsCreating(true);
  //     httprequest.post(APPLY_LEAVE_ENDPOINT, welfareForm).then((res) => {
  //       setIsCreating(false);
  //       toast.success(res?.data?.message);
  //       handleCloseLeaveModal();
  //       getAllMyLeave();
  //       setWelfareForm({ ...welfareForm, leave_type: "", start_date: "", end_date: "", comment: "" });
  //     }).catch((err) => {
  //       setIsCreating(false);
  //       toast.error(err?.response?.data?.message);
  //     });
  //   } else {
  //     showCreateLeaveValidator(true);
  //   }
  // };

  const handleApplyLeave = () => {
    const selectedLeaveOption = allLeaveOptions.find(option => option.id === welfareForm.leave_type);
    if (!selectedLeaveOption) {
      toast.error("Please select a valid leave type.");
    }
    const totalDaysRequested = daysCount;
    if (totalDaysRequested > selectedLeaveOption.total_days_left) {
      toast.error(`You cannot request more than ${selectedLeaveOption.total_days_left} days for this leave type.`);
    }

    if (selectedLeaveOption.total_days_left >= selectedLeaveOption.total_days_requested) {
      toast.error("You have already requested the maximum number of days for this leave type.");
    }

    if (createLeaveValidator.allValid()) {
      setIsCreating(true);
      httprequest.post(APPLY_LEAVE_ENDPOINT, welfareForm).then(res => {
        setIsCreating(false);
        toast.success(res?.data?.message);
        handleCloseLeaveModal();
        getAllMyLeave();
        setWelfareForm({ leave_type: "", start_date: "", end_date: "", comment: "" });
      }).catch(err => {
        setIsCreating(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showCreateLeaveValidator(true);
    }
  };

  const columns = [
    {
      name: "Leave Type",
      selector: (row) => row?.type
    },
    {
      name: "Number of Days",
      selector: (row) => row?.number_of_days
    },
    {
      name: "Request Date",
      selector: (row) => row?.start_date?.substring(0, 10)
    },
    {
      name: "End Date",
      selector: (row) => row?.end_date?.substring(0, 10)
    },
    {
      name: "Status",
      selector: (row) => <Badge status={row?.leave_status} />,
      center: true
    }
  ];

  useEffect(() => {
    getAllLeaveOptions();
    getAllMyLeave();
  }, []);

  const { leave_type, start_date, end_date, comment } = welfareForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Welfare"
            subtitle="Request for leave, days off and any other time off work from the organization."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="small"
                  color="btnfontprimary"
                  onClick={() => navigate("/leave/team-calendar")}
                  buttonName="Leave calender"
                />
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={handleLeaveModal}
                  buttonName="Request Timeoff"
                />
              </div>
            }
          />
          <Grid container spacing={4} marginBottom={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Days off"
                count={allLeaveData?.totalDaysOff}
                icon={<WifiOff />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Days Taken"
                count={allLeaveData?.totalDaysTaken}
                icon={<FlightTakeoff />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Days Rolled Over"
                count={0}
                icon={<DataSaverOff />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Mandatory Off Days"
                count={0}
                icon={<DoNotDisturb />}
              />
            </Grid>
          </Grid>
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount title="Leave Options" count={allLeaveOptions?.length} func={() => handleViewOptions()} />
            </div>
            <Table data={allLeaveData?.leaveRequests} columns={columns} pagination />
          </div>
          <Modal
            title="Leave Request"
            subtitle="Fill in the details to request for the a leave from your organization."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <SelectGroup label="Leave Type" children={
                      <select value={leave_type} name="leave_type" onChange={handleChange}>
                        <option value="">---Select Leave Options ---</option>
                        {allLeaveOptions?.map((data) => {
                          return (
                            <option key={data?.id} value={data?.id}>{data?.type}</option>
                          );
                        })}
                      </select>
                    }
                    />
                    {createLeaveValidator.message("Leave Type", leave_type, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Start Date"
                      margin="normal"
                      onChange={handleChange}
                      inputType="date"
                      inputName="start_date"
                      value={start_date}
                    />
                    {createLeaveValidator.message("Start Date", start_date, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      onChange={handleChange}
                      inputType="date"
                      inputName="end_date"
                      value={end_date}
                    />
                    {createLeaveValidator.message("End Date", end_date, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextAreaGroup
                      label="Comment"
                      margin="normal"
                      placeHolder="Enter any additional comment you would like to make on your request."
                      value={comment}
                      inputName="comment"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <p>Number of Days : {daysCount}</p>

              </>
            }
            modalFooter={
              <>
                <Button
                  type="submit"
                  onClick={handleApplyLeave}
                  variant="primary"
                  buttonName="Apply for Leave"
                  buttonSize="full"
                  color="btndefault"
                  isLoading={isCreating}
                />
              </>
            }
            isopen={leaveModal}
            closeModal={handleCloseLeaveModal}
          />

          {/* View all leave options available in your organisation */}
          <Modal
            title="Leave Options"
            subtitle="See all the leave options available in your organisation"
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  {allLeaveOptions?.filter((opt) => opt?.status === "active")?.map((option, i) => {
                    return (
                      <Grid item lg={6} md={6} sm={12} xs={12} key={i}>
                        <WelfareCard title={option?.type} subtitle={`${option?.duration} Days`} date={` Updated At: ${option?.updated_at?.substring(0, 10)}`} />
                      </Grid>
                    );
                  })}
                </Grid>

              </>
            }
            modalFooter={
              <>
              </>
            }
            isopen={viewOptionsModal}
            closeModal={handleCloseViewOptions}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default Welfare;
