/* eslint-disable no-console */
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InputGroup from "../../../components/FormComponent/InputGroup";
import PageTitle from "../../../components/PageTitle";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import Button from "../../../components/Button";
import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Grid } from "@mui/material";
import useStyles from "../styles";
import { axiosrequest } from "../../../data/api";
import { useUpload } from "../../../hooks/useUpload";
import nocomment from "../../../assets/images/nocomment.png";

const COMMENTS_ENDPOINT = "/api/v1/application";

const ApplicantDashboard = ({ profile }) => {
  const classes = useStyles();
  // const [uploadForm, setUploadForm] = useState({});
  const [profilePic, setProfilePic] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [profileForm, setProfileForm] = useState({});
  const [comments, setComments] = useState([]);
  const [replyComment, setReplyComment] = useState("");
  const [replyToCommentId, setReplyToCommentId] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayPic, setDisplayPic] = useState(null);
  const APPLICANT_PROFILE_ENDPOINT = "/api/v1/applicant/profile";
  const [uploads, setUploads] = useState([
    { file: null, title: "" } // Initial upload section
  ]);
  // FOR FILE UPLOAD
  const commentsEndRef = useRef(null);
  const scrollToBottom = () => {
    if (commentsEndRef.current) {
      commentsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const updatedUploads = [...uploads];
      updatedUploads[index].file = file;
      setUploads(updatedUploads);
    }
  };

  const handleUploadTitleChange = (event, index) => {
    const { value } = event.target;
    const updatedUploads = [...uploads];
    updatedUploads[index].title = value;
    setUploads(updatedUploads);
  };

  const handleAddFileSection = () => {
    setUploads([...uploads, { file: null, title: "" }]);
  };

  const handleRemoveFileSection = (index) => {
    setUploads(uploads.filter((_, idx) => idx !== index));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileForm({ ...profileForm, [name]: value });
  };
  // const handleFileChange = (e) => {
  //   // const { name } = e.target;
  //   if (e.target.files) setSelectedFile(e.target.files[0]);
  //   setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  // };

  const handleGuardianChange = (e) => {
    const { name, value } = e.target;

    let guardian_info = {};
    guardian_info = { ...guardian_info, [name]: value };

    setProfileForm({ ...profileForm, guardian_info });
  };

  const handleProfileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDisplayPic(file);
      setProfilePic(URL.createObjectURL(file));
    }
  };

  const fetchComments = () => {
    axiosrequest
      .get(`${COMMENTS_ENDPOINT}/${profile?.id}/comments`)
      .then((res) => {
        const fetchedComments = res?.data?.data || [];

        // Combine comments and replies into a single array
        const combinedCommentsAndReplies = fetchedComments.flatMap(comment => {
          const commentEntry = { ...comment, type: "comment" };
          const replyEntries = comment.replies.map(reply => ({ ...reply, type: "reply", parentCommentId: comment.id }));

          return [commentEntry, ...replyEntries];
        });

        // Sort by the created_at field (chronologically)
        combinedCommentsAndReplies.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );

        setComments(combinedCommentsAndReplies);
        scrollToBottom();
      })
      .catch((err) => {
        toast.error("Failed to fetch comments");
        console.error(err);
      });
  };

  useEffect(() => {
    if (profile?.id) {
      fetchComments(); // Fetch comments when the profile is available
    }
  }, [profile]);
  useEffect(() => {
    if (profile?.uploads && Array.isArray(profile.uploads)) {
      const existingUploads = profile.uploads.map(upload => ({
        file: null, // Files cannot be preloaded for security reasons
        title: upload.title,
        upload_id: upload.upload_id,
        file_url: upload.file_url
      }));
      setUploads(existingUploads);
    }
  }, [profile]);

  const handleEditProfile = async () => {
    setIsUpdating(true);

    try {
      const data = { ...profileForm };

      // 1. Handle Display Picture Upload
      if (displayPic) {
        const displayResponse = await useUpload(displayPic);
        if (displayResponse?.status === 200 && displayResponse.data.data) {
          const display_pic = displayResponse.data?.data?.[0];
          data.display_pic = {
            upload_id: display_pic.upload_id,
            file_url: display_pic.file_url
          };
        } else {
          toast.error("Unable to upload profile picture");

          return;
        }
      }

      // 2. Handle Multiple File Uploads
      const uploadedFiles = [];
      for (let i = 0; i < uploads.length; i++) {
        const upload = uploads[i];
        if (upload.upload_id) {
          // Existing upload, include as is
          uploadedFiles.push({
            upload_id: upload.upload_id,
            file_url: upload.file_url,
            title: upload.title
          });
        } else if (upload.file) {
          // New upload, upload the file first
          const uploadResponse = await useUpload(upload.file);
          if (uploadResponse?.status === 200 && uploadResponse.data.data) {
            const uploadedFileData = uploadResponse.data?.data?.[0];
            uploadedFiles.push({
              upload_id: uploadedFileData.upload_id,
              file_url: uploadedFileData.file_url,
              title: upload.title
            });
          } else {
            toast.error(`Unable to upload file: ${upload.file.name}`);

            return;
          }
        }
      }

      if (uploadedFiles.length > 0) {
        data.uploads = uploadedFiles;
      }

      await axiosrequest.patch(APPLICANT_PROFILE_ENDPOINT, data);

      toast.success("Profile updated successfully!");
      // Optionally, you can refetch the profile details here
      // Or update the state accordingly
      // For simplicity, we'll reload the page
      window.location.reload();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || "Failed to update profile.");
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
  }, [profile]);

  const handleAddComment = () => {
    const applicationId = profile.id;
    if (!applicationId) {
      toast.error("No applications selected to add a comment.");

      return;
    }
    if (!newComment.trim()) {
      toast.error("Comment cannot be empty.");

      return;
    }

    setIsSubmitting(true); // Start submitting
    const payload = { comment: newComment, application_id: applicationId };

    axiosrequest
      .post("/api/v1/application/comments/applicant", payload)
      .then(() => {
        toast.success("Comment added successfully");
        setNewComment("");
        fetchComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add comment");
      })
      .finally(() => {
        setIsSubmitting(false); // End submitting
      });
  };

  const handleReplyComment = () => {
    const replyId = replyToCommentId;
    if (!replyId || !replyComment.trim()) {
      toast.error("No comment or reply text provided.");

      return;
    }

    setIsSubmitting(true); // Start submitting
    const payload = { reply: replyComment, comment_id: replyId };

    axiosrequest.patch("/api/v1/application/comments/applicant", payload)
      .then(() => {
        toast.success("Reply added successfully");
        setReplyComment("");
        setReplyToCommentId(null);
        fetchComments();
      })
      .catch((err) => {
        toast.error(err?.response?.data || "Failed to add reply");
      })
      .finally(() => {
        setIsSubmitting(false); // End submitting
      });
  };

  const handleReplyClick = (commentId) => {
    setReplyToCommentId(commentId);
    setReplyComment("");
  };
  const handleCancelReply = () => {
    setReplyToCommentId(null);
    setReplyComment("");
  };

  const formatTime = (dateString) => {
    const dateObject = new Date(dateString);

    const day = dateObject.getUTCDate().toString().padStart(2, "0");
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const hours = dateObject.getUTCHours().toString().padStart(2, "0");
    const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}  ${month}/${day}`; // Format: DD/MM
  };

  return (
    <div className={`${classes.border} page-content`}>
      <PageTitle
        title={`${profile?.first_name}'s Dashboard`}
        subtitle="These are some of the details on your application form"
        button={
          <div className="flex gap-10 btn-margin-top-10">
            <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Save Changes" onClick={handleEditProfile} isLoading={isUpdating} />
          </div>
        }
      />
      <Grid container spacing={4}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <div className={`${classes.border} left-container`}>
            <h4>Display Picture</h4>
            <div className={classes.profilecontainer}>
              <div className={classes.imageviewer} style={{ backgroundImage: `url(${profilePic || profile?.display_pic?.file_ur})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <img src={profilePic || profile.display_pic?.file_url} alt="" />
                <div className={`${classes.pickercontainer} profile_icon`}>
                  <input type="file" name="profile_picture" className={classes.profilepicker} onChange={handleProfileChange} />
                  <CameraAltIcon className={classes.camera} />
                </div>
              </div>
            </div>
            <div className={classes.applicationdetails}>
              <h6>Application Number</h6>
              <h4>{profile.application_no}</h4>
            </div>
          </div>
        </Grid>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          <div className={`${classes.border} right-container`}>
            <Grid container spacing={2} className={classes.profileform}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="First name" inputName="first_name" defaultValue={profile?.first_name} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Last name" inputName="last_name" defaultValue={profile?.last_name} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="email" disabled label="Email address" inputName="email" defaultValue={profile?.email} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Class Applying to" defaultValue={profile?.class?.name} disabled />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="tel" label="Contact" inputName="phone" defaultValue={profile?.phone} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Home Address" inputName="address" defaultValue={profile?.address} onChange={handleChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian First Name" inputName="first_name" defaultValue={profile?.guardian_info?.first_name} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian Last Name" inputName="last_name" defaultValue={profile?.guardian_info?.last_name} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="email" label="Guardian Email" inputName="email" defaultValue={profile?.guardian_info?.email} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="text" label="Guardian Address" inputName="address" defaultValue={profile?.guardian_info?.address} onChange={handleGuardianChange} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputGroup inputType="tel" label="Guardian Phone Number" inputName="phone" defaultValue={profile?.guardian_info?.phone} onChange={handleGuardianChange} />
              </Grid>
            </Grid>
            <ToastContainer />
          </div>
        </Grid>
      </Grid>
      <div className="margin-top-40 text-align-left ">
        <div className="applicant_comment">
          <div className="text-align-left flex">
            <Icon
              icon="iconamoon:comment-fill"
              width="20"
              height="20"
              style={{ color: "#111111" }}
            />
            <div>
              <h4>Comments</h4>
            </div>
          </div>

          <>
            {Array.isArray(comments) && comments.length > 0 ? (
              <div className="comment_div">
                {comments.map((item, index) => (
                  <div key={index} className={`admin_comment ${item.ref_type}`}>
                    {item.type === "comment" ? (
                      <div className="comment_main">

                        <div className="comment_container">
                          <p className=" no_margin">
                            <strong>{item.user.name}</strong>
                          </p>
                          <span className="admin_comment_span">{item.comment}
                            <div className="comment_time justify_end">{formatTime(item.created_at)}</div>

                          </span>
                          {item.ref_type === "admin" && (
                            <span
                              className="reply"
                              onClick={() => handleReplyClick(item.id)}
                            >
                         Reply
                            </span>

                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={`reply-container_${comments.find(comment => comment.id === item.parentCommentId)?.ref_type}`}>
                        <div className="reply_main">
                          <p className="reply-user  no_margin">
                            <strong>{item.user.name}</strong>
                          </p>
                          <div className="original-comment">
                            <div className="reply_com">
                              <p className="original-comment-user no_margin">
                                <strong>{comments.find(comment => comment.id === item.parentCommentId)?.user.name}</strong>
                              </p>
                              <span>
                                {comments.find(comment => comment.id === item.parentCommentId)?.comment}
                              </span>
                            </div>
                            <span>
                              {item.reply}
                            </span>
                            <div className="comment_time">{formatTime(item.created_at)}</div>
                          </div>
                          {comments.find(comment => comment.id === item.parentCommentId)?.ref_type === "applicant" && (
                            <span
                              className="reply justify_end"
                              onClick={() => handleReplyClick(comments.find(comment => comment.id === item.parentCommentId)?.id)}
                            >
            Reply
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="no_upload">
                <img src={nocomment} />
                <p>No Comment available</p>
              </div>
            )}

            {/* Default comment submission area, hidden when replying */}
            {!replyToCommentId && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextAreaGroup
                  margin="normal"
                  inputType="text"
                  inputName="newComment"
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)} // Handle input change
                />
                <button disabled={isSubmitting || !newComment.trim()}
                  className="upload_comment flex justify_end" onClick={handleAddComment}
                >
          Submit Comment <Icon icon="zondicons:send" />
                </button>
              </Grid>
            )}

            {/* Reply form, shown only when replying to a comment */}
            {replyToCommentId && (
              <div className="reply_div">
                <div className="selected-comment-to-reply">
                  <div>
                    <span>
                      {comments.find(comment => comment.id === replyToCommentId)?.user.name}
                    </span>
                    <p className="no_margin">
                      {comments.find(comment => comment.id === replyToCommentId)?.comment}
                    </p>
                  </div>
                  <button className="cancel_reply" onClick={handleCancelReply}>
                    <Icon icon="ic:outline-cancel" />
                  </button>
                </div>

                {/* Reply text area */}
                <TextAreaGroup
                  margin="normal"
                  inputType="text"
                  inputName="replyComment"
                  value={replyComment}
                  onChange={(e) => setReplyComment(e.target.value)}
                />
                <button disabled={isSubmitting || !replyComment.trim()}
                  className="upload_comment flex justify_end" onClick={handleReplyComment}
                >
          Reply Comment <Icon icon="zondicons:send" />
                </button>
              </div>
            )}
          </>
        </div>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <div className="text-align-left flex">
                <Icon
                  icon="material-symbols:upload"
                  width="20"
                  height="20"
                  style={{ color: "#111111" }}
                />
                <div>
                  <h4>Uploads</h4>
                </div>
              </div>

              {uploads.map((upload, index) => (
                <div key={index}>
                  {upload.upload_id ? (
                    <div className={classes.pickercontain}>
                      <div className={classes.uploadflexs}>
                        <h2 className={classes.uploadtitles}>{upload.title}</h2>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.newUpload}>
                      <InputGroup
                        label={"Enter Title"}
                        margin="normal"
                        inputType="text"
                        inputName={`upload_title_${index}`}
                        value={upload.title}
                        onChange={(e) => handleUploadTitleChange(e, index)}
                      />

                      {/* File Input */}
                      <div className={classes.pickercontain}>
                        <input
                          type="file"
                          className={classes.pickers}
                          onChange={(e) => handleFileChange(e, index)}
                          name={`file_${index}`}
                          accept="image/jpeg,image/jpe,image/png,application/pdf"
                        />
                        <div className={classes.uploadflexs}>
                          <div>
                            <Icon
                              icon="icon-park-outline:upload-two"
                              className={classes.uploadicons}
                            />
                          </div>
                          {upload.file?.name ? (
                            <div>
                              <h2 className={classes.uploadtitles}>{upload.file.name}</h2>
                            </div>
                          ) : (
                            <div>
                              <h2 className={classes.uploadtitles}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitles}>
                        PDF, PNG, JPG | 10MB max.
                              </h6>
                            </div>
                          )}
                        </div>
                        <div className={classes.rightpicker}>
                          {upload.file?.name && (
                            <div
                              className={classes.uploadbuttons}
                              onClick={() => handleRemoveFileSection(index)}
                            >
                              <Icon icon="zondicons:minus-solid" /> Remove File
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="margin-top-16">
                        <Button
                          variant="dangeroutline"
                          buttonSize="small"
                          color="btndanger"
                          buttonName="Remove"
                          onClick={() => handleRemoveFileSection(index)}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="margin-top-16">
                <Button
                  variant="primaryoutline"
                  buttonSize="small"
                  color="btnfontprimary"
                  buttonName="Add Upload"
                  onClick={handleAddFileSection}
                />
              </div>

            </div>
          </Grid>
        </Grid>

      </div>
      <div className="text-center">
        <Button variant="primary" buttonSize="medium" color="btndefault" buttonName="Save Changes" onClick={handleEditProfile} isLoading={isUpdating} />
      </div>
    </div>
  );
};

export default ApplicantDashboard;
