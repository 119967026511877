/* eslint-disable no-console */
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Button from "../../components/Button";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, School as GraduateIcon, Class as ClassIcon, EmojiPeople as TeacherIcon, AddCircle } from "@mui/icons-material";
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import InputGroup from "../../components/FormComponent/InputGroup";
import Modal from "../../components/Modal/index";
import useStyles from "./styles";
import Table from "../../components/Table/Table";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { useNavigate } from "react-router-dom";
// import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import QuickCard from "../../components/QuickCard";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import AttendanceCard from "../../components/AttendanceCard";
import { Icon } from "@iconify/react/dist/iconify.js";
import NoticeBoardCard from "./components/NoticeBoardCard/NoticeBoardCard";
import AdminLeaveCard from "./components/AdminLeaveCard/AdminLeaveCard";
import { useUserState } from "../../context/UserContext";
// import StepContent from "@mui/material/StepContent";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DetailsText from "../leavemgt/components/DetailsText";
import useWindowSize from "../../hooks/useWindow";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Priority, NoticeInfo } from "../../utils/DropDown";
import NoEvent from "../../../src/assets/images/nofeatureicons/noupcomingevent.svg";
import NoNotice from "../../../src/assets/images/nofeatureicons/nonotice.svg";
import NoPendingLeave from "../../../src/assets/images/nofeatureicons/nopendingrequest.svg";
import NoAttendance from "../../../src/assets/images/nofeatureicons/noattendance.png";
import NoRecentUsers from "../../../src/assets/images/nofeatureicons/norecentuser.svg";
// import NotFounditem from "../../pages/error/NotFound";
import NoData from "./components/NotFoundCard/NoData";
// import { Chrono } from "react-chrono";

ChartJS.register(ArcElement, Tooltip, Legend);

const OVERVIEW_ENDPOINT = "/api/v1/overview/school-admin";
const GET_SESSIONS = "/api/v1/school-plan/session";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const GET_ATTENDANCE_METRIC = "/api/v1/attendance/metric";
const LEAVE_MGT_ENDPOINT = "/api/v1/leave";

const AdminDashboard = () => {
  const { profile } = useUserState();
  console.log(profile, "profile");
  const classes = useStyles();
  const navigate = useNavigate();
  const [modalisopen, setModalIsOpen] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [isApproving, setIsApproving] = useState(false);
  const [allSessions, setAllSessions] = useState([]);
  const [activeSession, setActiveSession] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [overviewData, setOverViewData] = useState([]);
  const [attmetric, setAttMetric] = useState([]);
  const [currentLeave, setCurrentLeave] = useState({});
  const [selectedSessionTerms, setSelectedSessionTerms] = useState([]);
  const [isDeclining, setIsDeclining] = useState(false);
  const [actionType, setActionType] = useState("");
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [currentNotice, setCurrentNotice] = useState({});
  const [viewNoticeModal, setViewNoticeModal] = useState(false);

  const splitData = overviewData?.recent_users?.slice(0, 3);
  const width = useWindowSize();
  const mobile = width < 650;

  // const splitLeave = leaveData?.slice(0, 3);

  const handleGetOverview = () => {
    httprequest.get(OVERVIEW_ENDPOINT).then((res) => {
      setEvents(res?.data?.data?.events);
      setOverViewData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const handleCloseViewNoticeModal = () => {
    // setCurrentNotice(row);
    setViewNoticeModal(!viewNoticeModal);
  };

  const handleViewNoticeModal = (notice) => {
    console.log(notice, "Notice");
    setCurrentNotice(notice);
    setViewNoticeModal(!viewNoticeModal);
  };

  const getAllSessions = () => {
    httprequest.get(GET_SESSIONS).then((res) => {
      setAllSessions(res?.data?.data);
      setActiveSession(res?.data?.data?.find((active_session) => active_session?.current_session === true));
    }).catch((err) => {
      toast.error(err?.response?.message);
    });
  };
  console.log(events, "events");

  const active_term_session = activeSession?.terms?.find((found_active_term) => found_active_term?.is_current === true);

  const getAllTerms = (Session_ID) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setTermsData(res?.data?.data);
        setSelectedSessionTerms(res?.data?.data.filter((selected_term) => selected_term?.session_id === Session_ID));
      })
      .catch(() => {});
  };
  console.log(termsData, "termsData");

  const handleCloseApproveModal = () => {
    setApproveModal(false);
  };

  const handleGetAttendanceMetric = (params) => {
    httprequest.get(GET_ATTENDANCE_METRIC, { params }).then((res) => {
      setAttMetric(res?.data?.data);
    }).catch((err) => {
      const errorMessage = err?.response?.data?.message;
      if (errorMessage !== "Current term not set, please select a term") {
        toast.error(errorMessage);
      }
    });
  };

  console.log(attmetric, "attmetric");

  // Handle Session Change
  const handleSessionChange = (e) => {
    const Session_ID = e.target.value;
    getAllTerms(Session_ID);
  };
    // Handles the change event when a new tab is selected
  const handleTermChange = (event) => {
    const selectedTermId = event.target.value;
    setASelectedTerm(selectedTermId);

    const params = {
      term_id: selectedTermId
    };

    handleGetAttendanceMetric(params);
  };

  const handleApproveLeave = (leave_id) => {
    setIsApproving(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "approved" })
      .then((res) => {
        setIsApproving(false);
        handleCloseApproveModal();
        handleGetOverview();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        setIsApproving(false);
        toast.error(err?.response?.data?.message);
      });
  };

  const handleDeclineLeave = (leave_id) => {
    setIsDeclining(true);
    httprequest
      .patch(`${LEAVE_MGT_ENDPOINT}/status/${leave_id}`, { leave_status: "declined" })
      .then(() => {
        setIsDeclining(false);
        handleCloseApproveModal();
        handleGetOverview();
        toast.success("Leave status has been declined");
      })
      .catch((err) => {
        setIsDeclining(false);
        toast.error(err?.response?.data?.message);
      });
  };

  useEffect(() => {
    if (activeSession?.id) {
      getAllTerms(activeSession?.id);
    }
  }, [activeSession]);

  useEffect(() => {
    if (active_term_session?.id) {
      setASelectedTerm(active_term_session?.id);
    }
  }, [active_term_session]);
  console.log(activeSession, "activeSession");

  useEffect(() => {
    handleGetOverview();
    handleGetAttendanceMetric();
    getAllSessions();
    getAllTerms();
  }, []);

  // const steps = events?.map((an_event) => ({
  //   key: an_event?.id,
  //   title: an_event?.date,
  //   cardTitle: an_event?.title,
  //   cardSubtitle: an_event?.description
  // }));

  // console.log(steps, "ITEMS");

  const attendanceData = {
    labels: ["Present", "Absent", "Others"],
    datasets: [
      {
        label: "Student Attendance",
        data: [attmetric?.present, attmetric?.absent],
        // , attmetric?.others // Dummy values for present, absent AND others
        backgroundColor: [
          "#0A17A7",
          "#FF2E3B"
          // "#EF9C50"
        ],
        borderColor: "transparent",
        borderWidth: 4,
        borderRadius: 18
      }
    ]
  };

  const options = {
    responsive: true,
    cutout: "90%",
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true
      }
    }
  };

  const centerTextPlugin = {
    id: "centerText",
    beforeDraw(chart) {
      const { width, height, ctx } = chart;
      const totalStudents = attmetric?.no_of_students;
      ctx.restore();

      // Style for "Total Students" text
      ctx.font = `bold ${(height / 14).toFixed(2)}px Arial`;
      ctx.fillStyle = "#C5CAE4";
      ctx.textBaseline = "middle";

      // "Total Students" text
      const labelText = "Total Students";
      const labelX = Math.round((width - ctx.measureText(labelText).width) / 2);
      const labelY = height / 2 - 30;
      ctx.fillText(labelText, labelX, labelY);

      // Style for total number
      ctx.font = `bold ${(height / 7).toFixed(2)}px Arial`;
      ctx.fillStyle = "#171717";

      // Total students number (static value)
      const numberText = `${totalStudents}`;
      const numberX = Math.round((width - ctx.measureText(numberText).width) / 2);
      const numberY = height / 2 + 10;
      ctx.fillText(numberText, numberX, numberY);

      ctx.save();
    }
  };

  const employeeColumns = [
    {
      name: "Name",
      selector: row => row?.user?.name
    },
    {
      name: "Role",
      selector: row => row?.role?.name || "-----------"
    },
    {
      name: "Email",
      selector: row => row?.user?.email || "-----------"
    },
    {
      name: "Created at",
      selector: row => row?.user?.created_at || "-----------"
    }
  ];

  // const LeaveColumns = [
  //   {
  //     name: "Name",
  //     selector: row => row?.name
  //   },
  //   {
  //     name: "Title",
  //     selector: row => row?.role
  //   },
  //   {
  //     name: "Email",
  //     selector: row => row?.email
  //   },
  //   {
  //     name: "Status",
  //     center: "true",
  //     cell: (row) => (<Badge status={row?.leave_status} />)
  //   }
  // ];

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Dashboard"
            subtitle="See the overview of everything going on at your organisation."
            button={
              <>
                {/* <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={() => {
                    setModalIsOpen(true);
                  }}
                  buttonName="Create a class"
                /> */}
                {profile?.branding?.website_url && (
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={() => window.open(profile?.branding?.website_url, "_blank")}
                    buttonName="Visit your website"
                  />
                )}
              </>

            }
          />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Students"
                count={overviewData?.students?.[0]?.total_students || 0}
                icon={<StudentIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overviewData?.students?.[0]?.active_students || 0}
                rightSubValue={overviewData?.students?.[0]?.inactive_students || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Employees"
                count={overviewData?.employees?.[0]?.total_employees || 0}
                icon={<TeacherIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overviewData?.employees?.[0]?.active_employees || 0}
                rightSubValue={overviewData?.employees?.[0]?.inactive_employees || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Number of classes"
                count={overviewData?.classes?.[0]?.total_classes || 0}
                icon={<ClassIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overviewData?.classes?.[0]?.active_classes || 0}
                rightSubValue={overviewData?.classes?.[0]?.inactive_classes || 0}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Subject"
                count={overviewData?.subjects?.[0]?.total_subjects || 0}
                icon={<GraduateIcon />}
                leftSubText="Active :"
                rightSubText="Inactive :"
                leftSubValue={overviewData?.subjects?.[0]?.active_subjects || 0}
                rightSubValue={overviewData?.subjects?.[0]?.inactive_subjects || 0}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Quick Links"
                subtitle="Navigate your app as quickly as possible"
              />
              <div className={classes.quickcardinfo}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#0F973D" }} />} borderColor="#0F973D" title="Employees" backgroundColor="#E7F5EC" to="/users/employees" />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#003E9C" }} />} borderColor="#003E9C" title="Enrollments" backgroundColor="#E6F0FF" to="/enrolments" />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#F3A218" }} />} borderColor="#F3A218" title="Classes" backgroundColor="#FEF6E8" to="/class-activities/classes" />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#003E9C" }} />} borderColor="#003E9C" title="Students" backgroundColor="#E6F0FF" to="/users/students" />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#D42620" }} />} title="Subjects" borderColor="#D42620" backgroundColor="#FBE9E9" to="/class-activities/subjects" />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <QuickCard icon={<StudentIcon style={{ color: "#66440A" }} />} borderColor="#66440A" title="Attendance" backgroundColor="#EEC19B" to="/attendance" />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Attendance"
                subtitle="View Metrics for all student attendance in your school"
              />
              <div className={classes.quickcardinfo}>
                <Grid container spacing={2}>
                  <div className="flex justify-items-space">
                    <div>
                      {
                        attmetric?.session?.id && (
                          <SelectGroup
                            children={
                              <select
                                onChange={handleSessionChange}
                                name="session"
                                defaultValue={attmetric?.session?.id}
                              >
                                <option value="">---Select---</option>
                                {allSessions?.map((sesh) => {
                                  return (
                                    <option key={sesh?.id} value={sesh?.id}>
                                      {sesh?.session}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                        )
                      }
                    </div>
                    <div>
                      {
                        attmetric?.term?.id && (
                          <SelectGroup
                            children={
                              <select name="term" onChange={handleTermChange} value={aselectedTerm}>
                                <option value="">---Select---</option>
                                {selectedSessionTerms?.map((data) => {
                                  return (
                                    <option key={data?.id} value={data?.id}>
                                      {data?.term}
                                    </option>
                                  );
                                })}
                              </select>
                            }
                          />
                        )
                      }
                    </div>
                  </div>
                  <div className="width-full margin-top">
                    <Grid container spacing={2}>
                      <Grid item lg={9} md={9} sm={9} xs={12}>
                        {attmetric?.no_of_students ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "200px",
                              width: "100%"
                            }}
                          >
                            <Doughnut data={attendanceData} options={options} plugins={[centerTextPlugin]} />
                          </div>
                        ) : (
                          <NoData
                            img={NoAttendance}
                            title="No Attendance"
                            subtitle="No attendance available for this session and term"
                          />
                        )}
                      </Grid>
                      <Grid item lg={3} md={3} sm={3} xs={12}>
                        <div>
                          <AttendanceCard icon={<Icon icon="tabler:square-filled" width="15" height="15" style={{ color: "#0A17A7" }} />} type="Present" total={attmetric?.present} />
                        </div>
                        <div>
                          <AttendanceCard icon={<Icon icon="tabler:square-filled" width="15" height="15" style={{ color: "#D42620" }} />} type="Absent" total={attmetric?.absent} />
                        </div>
                        {/* <div>
                          <AttendanceCard icon={<Icon icon="tabler:square-filled" width="15" height="15" style={{ color: "#EF9C50" }} />} type="Others" total={attmetric?.others} />
                        </div> */}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <WidgetTitle
            title="Upcoming Events"
            subtitle="See all the events in your organisation"
          />
          <div className={classes.events}>
            <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/events")}>View all</h3>
            <hr />
            <div className={classes.chrono}>
              {events?.length > 0 ? (
                <Stepper activeStep={1} alternativeLabel={!mobile} orientation={mobile ? "vertical" : "horizontal"}>
                  {events.map((label) => (
                    <Step key={label.id}>
                      <StepLabel>
                        <div>
                          <span>{label?.title}</span>
                          <br />
                          <span>{label?.date}</span>
                          <br />
                          <span>{label?.description}</span>
                        </div>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              ) : (
                <NoData
                  img={NoEvent}
                  title="No Upcoming Event"
                  subtitle="No events have been added"
                />
              )}
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Notice Board"
                subtitle="View important information for your school"
              />
              <div className={classes.notice}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/manage/notice-board")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {overviewData?.notice_board?.length > 0 ? (
                    overviewData.notice_board.map((notice) => (
                      <div key={notice.id}>
                        <NoticeBoardCard
                          viewClick={() => handleViewNoticeModal(notice)}
                          title={notice.title}
                          date={notice.created_at}
                          teacher={notice.added_by?.name}
                          priority={notice.priority}
                        />
                      </div>
                    ))
                  ) : (
                    <div className={classes.notfound}>
                      <NoData
                        img={NoNotice}
                        title="Empty Notice Board"
                        subtitle="No Information added"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Pending Leave Requests"
                subtitle="View Metrics for all student attendance in your school"
              />
              <div className={classes.leave}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/manage/leave")}>View all</h3>
                  {/* <hr /> */}
                </div>
              </div>
              <div className={classes.leavedetails}>
                <div className={classes.pendingleaves}>
                  {overviewData?.length > 0 ? (
                    overviewData?.pending_leaves?.map((leaves) => (
                      <AdminLeaveCard
                        key={leaves?.id}
                        to={`/manage/leave/${leaves?.id}`}
                        t_name={leaves?.leave_requester?.name}
                        t_role={leaves?.role?.name}
                        start_date={leaves?.start_date}
                        end_date={leaves?.end_date}
                        approveClick={() => {
                          setCurrentLeave(leaves);
                          setActionType("approve");
                          setApproveModal(true);
                        }}
                        declineClick={() => {
                          setCurrentLeave(leaves);
                          setActionType("decline");
                          setApproveModal(true);
                        }}
                      />
                    ))
                  ) : (
                    <div className={classes.notfound}>
                      <NoData
                        img={NoPendingLeave}
                        title="No Pending Leave"
                        subtitle="No leave request is pending"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <WidgetTitle
            title="Recent Users"
            subtitle="View recent onboarding on new users"
            // link={<Link className={classes.widgetlink} to="/teachers">See all employees</Link>}
          />
          {splitData?.length > 0 ? (
            <Table data={splitData} columns={employeeColumns} />
          ) : (
            <div className={classes.notfound}>
              <NoData
                img={NoRecentUsers}
                title="No Recent User"
                subtitle="No recent activity"
              />
            </div>
          )}
          <Modal
            title="Add a class"
            subtitle="You can add a new class and assign a teacher to the class."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Name"
                      margin="normal"
                      placeHolder="Enter Class Name"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Class Teacher"
                      margin="normal"
                      placeHolder="Select Class Teacher"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Subjects"
                      inputType="text"
                    />
                  </Grid>
                </Grid>
                <div className={`${classes.extrasubject}`}>
                  <p className={classes.subject_add}>Add another Subject</p>
                  <AddCircle className={classes.addicon} />
                </div>
              </>
            }
            modalFooter={
              <>
                <Button variant="primary" buttonName="Add Class" buttonSize="full" color="btndefault" />
              </>
            }
            isopen={modalisopen}
            closeModal={() => {
              setModalIsOpen(false);
            }}
          />
          {/* View Notice */}
          <Modal
            title="View a Notice"
            subtitle={
              <div>
                View a notice set for your employees & students to have access to.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {currentNotice?.image?.file_url && (
                      <div
                        className={classes.viewBanner}
                        style={{
                          backgroundImage: `url(${currentNotice?.image?.file_url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat"
                        }}
                      >
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      disabled="disabled"
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.title}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      disabled="disabled"
                      // onChange={handleEditChange}
                      inputType="text"
                      required
                      value={currentNotice?.end_date}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Description"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice Description"
                      inputType="text"
                      inputName="description"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.description}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Priority"
                      required
                      children={
                        <select
                          // onChange={handleEditChange}
                          name="priority"
                          defaultValue={currentNotice?.priority}
                          disabled
                        >
                          <option value="">---Select Labels ---</option>
                          {Priority?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Type"
                      required
                      children={
                        <select
                          // onChange={handleEditChange}
                          name="type"
                          defaultValue={currentNotice?.status}
                          disabled
                        >
                          <option value="">---Select Labels ---</option>
                          {NoticeInfo?.map((data) => {
                            return (
                              <option value={data.key} key={data?.key}>
                                {data?.text}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonName="Cancel"
                    buttonSize="full"
                    color="btnfontprimary"
                    onClick={handleCloseViewNoticeModal}
                  />
                </div>
              </>
            }
            isopen={viewNoticeModal}
            closeModal={handleCloseViewNoticeModal}
          />
          {/* Handle Leave Request Modal */}
          <Modal
            title="Handle Leave Request"
            subtitle={`Approve Leave of ${currentLeave?.leave_requester?.name}`}
            modalContent={
              <>
                <div className="margin-top-30">
                  <Grid container spacing={1}>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Role" subtitle={currentLeave?.role?.name} />
                      {/* <h3>Role: {currentLeave?.role?.name}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Start Date:" subtitle={currentLeave?.start_date} />
                      {/* <h3>Start Date: {currentLeave?.start_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="End Date:" subtitle={currentLeave?.end_date} />
                      {/* <h3>End Date: {currentLeave?.end_date}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Number of Days" subtitle={currentLeave?.number_of_days} />
                      {/* <h3>Number of Days: {currentLeave?.number_of_days}</h3> */}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <DetailsText title="Leave Type:" subtitle={currentLeave?.leave_type?.name} />
                      {/* <h3>Leave Type: {currentLeave?.leave_type?.name}</h3> */}
                    </Grid>

                  </Grid>
                </div>
              </>
            }
            modalFooter={
              <>
                {actionType === "approve" ? (
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Approve Leave"
                    onClick={() => handleApproveLeave(currentLeave?.id)}
                    isLoading={isApproving}
                  />
                ) : (
                  <Button
                    variant="danger"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Decline Leave"
                    onClick={() => handleDeclineLeave(currentLeave?.id)}
                    isLoading={isDeclining}
                  />
                )}
              </>
            }
            isopen={approveModal}
            closeModal={() => setApproveModal(false)}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default AdminDashboard;
