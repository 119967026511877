import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    maxHeight: 70,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20,
    marginTop: 7
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: "#CB1A14",
    width: 120,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    gap: 5,
    color: "white",
    position: "relative",
    zIndex: 50,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 0,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBottom: 0,
    marginTop: 0
  },
  remove: {
    color: "red",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "6px 8px",
    boxSizing: "border-box",
    marginLeft: 3,
    zIndex: 50,
    backgroundColor: "white",
    border: "1px solid #98A2B3",
    borderRadius: 4,
    display: "flex",
    fontWeight: "bold"
  },
  deleteBanner: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "30px",
    height: "80px",
    width: "100%",
    borderRadius: "4px"
  },
  deleteButton: {
    backgroundColor: "#CB1A14",
    color: "white",
    position: "relative",
    alignItems: "center",
    gap: 4,
    padding: "4px 8px",
    boxSizing: "border-box",
    zIndex: 50,
    height: 40,
    // border: "1px solid #98A2B3",
    borderRadius: 6,
    display: "flex",
    fontWeight: "bold"
  },
  viewBanner: {
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: "30px",
    height: "300px",
    width: "100%",
    borderRadius: "20px"
  },
  quelabel: {
    textAlign: "left",
    display: "flex",
    color: "#545962",
    fontSize: "14px",
    fontWeight: "700"
  },
  textandimg: {
    position: "relative",
    borderRadius: "6px",
    border: "1px solid hsl(0, 0%, 80%)",
    backgroundColor: "white",
    height: "150px",
    "& textarea": {
      width: "100%",
      height: "150px",
      boxSizing: "border-box",
      background: "none",
      border: "0px",
      paddingBlock: "10px",
      paddingLeft: "10px",
      paddingRight: "35px",
      color: "black"
    }
  }
}));
