/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Table from "../../components/Table/Table";
import { Grid } from "@mui/material";
import { TableSearch } from "../../components/Table/TableActions";
import { useState, useEffect } from "react";
import Modal from "../../components/Modal";
// import SelectGroup from "../../components/FormComponent/SelectGroup";
import ButtonCount from "../../components/ButtonCount";
import { Icon } from "@iconify/react";
import Badge from "../../components/Badge";
import { useNavigate, Link } from "react-router-dom";
import InputGroup from "../../components/FormComponent/InputGroup";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import Select from "react-select";
import ReactPlayer from "react-player";
// import { Calendar } from "@fullcalendar/core";

const LeaveMgt = () => {
  const [leaveOptionModal, setLeaveOptionModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [optionsCount, setOptionsCount] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const [allLeave, setAllLeave] = useState([]);
  const navigate = useNavigate();

  const LEAVE_OPTIONS_ENDPOINT = "/api/v1/leaveoptions/";
  const LEAVE_MGT_ENDPOINT = "/api/v1/leave";
  const ROLES_ENDPOINT = "/api/v1/roles";

  const [optionsValidator, showOptionsValidator] = useValidator();
  const [multipleAccess, setMultipleAccess] = useState([]);
  const [helpModal, setHelpModal] = useState(false);
  const [leaveOptions, setLeaveOptions] = useState({
    type: "",
    duration: "",
    access: "",
    status: "active"

  });

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaveOptions({ ...leaveOptions, [name]: value });
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setLeaveOptions({ ...leaveOptions, access: selectedValue });
  };

  const getAllRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      const access = res?.data?.data?.filter((res) => res?.role_type !== "school_admin" && res?.role_type !== "guardian");
      setMultipleAccess(access?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAlLeaveOptions = () => {
    httprequest.get(LEAVE_OPTIONS_ENDPOINT).then((res) => {
      setOptionsCount(res?.data?.data?.length);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const handleCloseApproveModal = () => {
    setApproveModal(false);
    setCurrentRow({});
  };

  const getAlLeave = () => {
    httprequest.get(LEAVE_MGT_ENDPOINT, leaveOptions).then((res) => {
      setAllLeave(res?.data?.data);
      // setLeaveStatus(res?.data?.data?.filter(leavetype => leavetype?.leave_status === "approved"));
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  // eslint-disable-next-line no-console
  console.log(allLeave, "allLeave");

  const handleApproveLeave = () => {
    setIsApproving(true);
    httprequest.patch(`${LEAVE_MGT_ENDPOINT}/status/${currentRow?.leave_id}`, { leave_status: "approved" }).then((res) => {
      setIsApproving(false);
      handleCloseApproveModal();
      toast.success(res?.data?.message);
      getAlLeave();
    }).catch((err) => {
      setIsApproving(false);
      toast.error(err?.response?.data?.message);
    });
  };
  useEffect(() => {
    getAlLeaveOptions();
    getAlLeave();
    getAllRoles();
  }, []);

  const handleApproveModal = (row) => {
    setApproveModal(true);
    setCurrentRow(row);
  };

  const handleLeaveOptionModal = () => {
    setLeaveOptionModal(!leaveOptionModal);
  };

  const handleCreateLeaveOption = () => {
    if (optionsValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(LEAVE_OPTIONS_ENDPOINT, leaveOptions).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);

        // --| Reset Leave Options to original state
        setLeaveOptions({ ...leaveOptions, type: "", duration: "", access: "", status: "active" });
        handleLeaveOptionModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showOptionsValidator(true);
    }
  };

  // --| Filter Leave Options table using type, number of days, who can access
  const handleSearchLeaveOptions = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allLeave?.filter((data) => valArray?.every(
      (word) => data?.first_name?.toLowerCase().includes(word.toLowerCase()) ||
          data?.last_name?.toLowerCase().includes(word.toLowerCase()) ||
              data?.leave_type?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Role",
      selector: (row) => row?.role_name || "------------"
    },
    {
      name: "Leave Type",
      selector: (row) => row?.type || "------------"
    },
    {
      name: "Date Created",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Date Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "Leave Status",
      center: true,
      cell: (row) => (<Badge status={row?.leave_status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/manage/leave/${row?.leave_id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          <button className="table-action-icon" onClick={(() => {
            handleApproveModal(row);
          })}
          >
            {row?.leave_status === "approved" ? <Icon icon="mdi:approve" color="green" />
              : row?.leave_status === "disapproved" ? <Icon icon="flat-color-icons:disapprove" color="red" /> :
                <Icon icon="mdi:checkbox-marked-circle-outline" />}
          </button>
          <button
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
          <button
            className="table-action-icon"
            onClick={(() => navigate(`/manage/leave/leave-calendar/${row?.employee_id}`))}
          >
            <Icon icon="uil:calender" width="20" height="20" />
          </button>
        </div>
      )
    }

  ];

  // eslint-disable-next-line no-console
  console.log("mult", multipleAccess);

  const { type, duration, access } = leaveOptions;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Leave Management"
            subtitle="See the leave status off your employees and team members."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  buttonName="?"
                  onClick={handleHelpModal}
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={() => navigate("/manage/leave/team-calendar")}
                  buttonName="View Team Calender"
                />
                <Button
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  onClick={handleLeaveOptionModal}
                  buttonName="Add Leave Option"
                />
              </div>
            }
          />
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount func={() => navigate("/manage/leaveoptions")} title="Leave Options" count={optionsCount} />
            </div>
            <Table
              data={searchResult}
              columns={columns}
              subHeader={true}
              subHeaderComponent={
                <TableSearch placeholder="Search by name..." searchTableFunc={handleSearchLeaveOptions} />
              }
              pagination
            />
          </div>
          <Modal
            title="Add new leave option for your employees"
            subtitle="Setting leave slots give your employees leave options to choose."
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p>Who can take Leave<span className="input-required">*</span></p>
                      <Select isMulti name="access"
                        closeMenuOnSelect={true} options={multipleAccess} onChange={handleMultipleAccessChange}
                      />
                    </div>
                    {optionsValidator.message("Access", access, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup required inputName="type" type="text" label="Leave Type" placeholder="e.g Annual Leave" value={type}
                      onChange={handleChange}
                    />
                    {optionsValidator.message("Leave Type", type, "required")}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup required inputName="duration" type="number" label="Duration (in days)" value={duration} placeholder="e.g Annual Leave"
                      onChange={handleChange}
                    />
                    {optionsValidator.message("Duration", duration, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="full"
                    color="btnfontprimary"
                    buttonName="Cancel"
                    onClick={handleLeaveOptionModal}
                  />
                  <Button
                    type="submit"
                    onClick={handleCreateLeaveOption}
                    variant="primary"
                    buttonName="Add Leave Option"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isLoading}
                  />
                </div>
              </>
            }
            isopen={leaveOptionModal}
            closeModal={handleLeaveOptionModal}
          />
          <Modal
            title="Approve Leave"
            subtitle={`Approve Leave of ${currentRow?.first_name + " " + currentRow?.last_name}`}
            modalContent={
              <>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="success"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Approve Leave"
                    onClick={handleApproveLeave}
                    isLoading={isApproving}
                  />
                </div>
              </>
            }
            isopen={approveModal}
            closeModal={() => {
              setApproveModal(false);
            }}
          />
          <Modal
            title="Need Help?"
            subtitle="Watch the Video Below on Leave Management"
            modalContent={
              <>
                <ReactPlayer
                  url="https://youtu.be/bXQtkoeIreA"
                  playing={true}
                  controls={true}
                  width="100%"
                />
              </>
            }
            modalFooter={
              <>
                <Button
                  variant="primary"
                  buttonSize="full"
                  color="btndefault"
                  onClick={handleCloseVideoModal}
                  buttonName="Close Video"
                />
              </>}
            isopen={helpModal}
            closeModal={handleCloseVideoModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default LeaveMgt;
