import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import Button from "../../components/Button";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Table";
import Badge from "../../components/Badge";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import useStyles from "./styles";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import PageLoader from "../../components/PageLoader";
// import styles from "./studentApplication.module.css";
import { TableSearch } from "../../components/Table/TableActions";
import CardCount from "../../components/CardCount";

const APPLICATION_ENDPOINT = "/api/v1/enrolment/applications";
const ENROLMENT_ENDPOINT = "/api/v1/enrolment";

const Applications = () => {
  const classes = useStyles();
  const [applicationsData, setApplicationsData] = useState([]);
  const [isApplicationFetching, setIsApplicationFetching] = useState(false);
  const [editEnrolmentModal, setEditEnrolmentModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentEnrolment, setCurrentEnrolment] = useState({});
  const { id } = useParams();
  const [applicantsFilter, setApplicantsFilter] = useState([]);

  const navigate = useNavigate();

  // const handleEditEnrolmentModal = (row) => {
  //   setEditEnrolmentModal(!editEnrolmentModal);
  //   setCurrentEnrolment(row);
  // };

  const handleCloseEditEnrolmentModal = () => {
    setEditEnrolmentModal(false);
    setCurrentEnrolment({});
  };
  const getAllApplications = () => {
    setIsApplicationFetching(true);
    httprequest
      .get(APPLICATION_ENDPOINT)
      .then((res) => {
        setIsApplicationFetching(false);
        setApplicationsData(res?.data?.data || []);
        setApplicantsFilter(res?.data?.data || []); // Update the filter here
      })
      .catch((err) => {
        setIsApplicationFetching(false);
        toast.error(err?.response?.data);
      });
  };

  const columns = [
    {
      name: "Application No",
      selector: row => row?.application_no
    },
    {
      name: "Name",
      selector: row => `${row?.first_name} ${row?.last_name}`
    },
    {
      name: "Email Address",
      selector: row => row?.email
    },
    {
      name: "Class",
      selector: row => row?.class?.name
    },
    {
      name: "Parent/Guardian",
      selector: row => `${row?.guardian_info?.first_name} ${row?.guardian_info?.last_name}`
    },
    {
      name: "Contact",
      selector: row => row?.phone
    },
    {
      name: "Status",
      center: true,
      cell: (row) => (<Badge status={row?.application_status} />)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/enrolments/applications/${row.id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          {/* <button
            className="table-action-icon"
            onClick={() => handleEditEnrolmentModal(row)}
          >
            <Icon icon="lucide:edit" />
          </button> */}
          {/* <button
            // onClick={() => handleDeleteModal(row)}
            className="table-action-icon delete-icon"
          >
            <Icon icon="mingcute:delete-2-line" />
          </button> */}
        </div>
      )
    }
  ];

  // Application information search oprtion
  const handleSearchApply = (e) => {
    const { value } = e.target;

    // If the search box is empty, reset the filter to show all applications
    if (!value) {
      setApplicantsFilter(applicationsData);

      return;
    }

    // Convert the input value to lowercase and split it by spaces
    const searchValue = value.toLowerCase();
    const valArray = searchValue.split(" ");

    // Filter applications based on matches in first name, last name, application number, class name, or status
    const result = applicationsData.filter((data) => valArray.every(
      (word) => (data?.first_name && data.first_name.toLowerCase().includes(word)) ||
          (data?.last_name && data.last_name.toLowerCase().includes(word)) ||
          (data?.application_no && data.application_no.toLowerCase().includes(word)) ||
          (data?.class?.name && data.class.name.toLowerCase().includes(word)) ||
          (data?.email && data.email.toLowerCase().includes(word))
    )
    );

    setApplicantsFilter(result);
  };

  const handleEditEnrolment = () => {
    setIsApplicationFetching(true);
    httprequest
      .patch(`${Applications}/${currentEnrolment?.id}`, {
        open: !currentEnrolment?.open
      })
      .then(() => {
        setIsEditing(false);
        setCurrentEnrolment({});
        setEditEnrolmentModal(false);
        getAllApplications();
      })
      .catch((err) => {
        setIsEditing(false);
        toast.error(err?.response?.data);
      });
  };

  const getApplicantInEnrolment = () => {
    httprequest.get(`${ENROLMENT_ENDPOINT}/applicants/${id}`).then((res) => {
      setApplicationsData(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  const calculateApplicationCounts = () => {
    const counts = {
      accepted: 0,
      rejected: 0,
      inReview: 0,
      pending: 0
    };

    applicationsData.forEach(app => {
      switch (app?.application_status) {
      case "accepted":
        counts.accepted++;
        break;
      case "rejected":
        counts.rejected++;
        break;
      case "in-review":
        counts.inReview++;
        break;
      case "pending":
        counts.pending++;
        break;
      default:
        break;
      }
    });

    return counts;
  };

  const { accepted, rejected, inReview, pending } = calculateApplicationCounts();

  useEffect(() => {
    getAllApplications();
  }, []);

  useEffect(() => {
    if (id) {
      getApplicantInEnrolment();
    }
  }, [id]);
  useEffect(() => {
    setApplicantsFilter(applicationsData); // Set initial filter data after fetching
  }, [applicationsData]);

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Applications"
            subtitle="See all applications in your school."
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={() => {
                    navigate("/enrolments");
                  }}
                  buttonName="View All Enrolments"
                />
              </div>
            }
          />
          <Grid container spacing={4}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Accepted"
                count={accepted}
                icon={<Icon icon="material-symbols-light:handshake" />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Rejected"
                count={rejected}
                icon={<Icon icon="ic:outline-cancel" />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="In Review"
                count={inReview}
                icon={<Icon icon="iconoir:book" />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Pending"
                count={pending}
                icon={<Icon icon="vaadin:hourglass" />}
              />
            </Grid>
          </Grid>
          <div className="page-content margin-top-16">

            {isApplicationFetching ? (
              <PageLoader />
            ) : (
              <Table
                data={applicantsFilter}
                columns={columns}
                pagination
                subHeader={true}
                subHeaderComponent={applicationsData?.length > 0 && (
                  <TableSearch placeholder="Search here..." searchTableFunc={handleSearchApply} />
                )}
              />
            )}

            {/* Edit Enrolment (Close/Open Enrolment) */}
            <Modal
              title="Open or Close the Enrolment Process"
              subtitle={
                <div>
                  Note that opening/closing this manages your users' access to
                  the application page.
                </div>
              }
              modalContent={<></>}
              modalFooter={
                <div className={classes.modalFooterBtn}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      {currentEnrolment?.open ? (
                        <Button
                          variant="danger"
                          buttonSize="full"
                          color="btndefault"
                          disabled={!currentEnrolment?.open}
                          buttonName="Close this Enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      ) : (
                        <Button
                          variant="primary"
                          buttonSize="full"
                          color="btndefault"
                          disabled={currentEnrolment?.open}
                          buttonName="Re-Open this enrolment"
                          isLoading={isEditing}
                          onClick={handleEditEnrolment}
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              }
              isopen={editEnrolmentModal}
              closeModal={handleCloseEditEnrolmentModal}
            />
          </div>
          <ToastContainer />
        </>
      }
    />
  );
};

export default Applications;
