import useStyles from "../../styles";

const NoData = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.erroritems}`}>
        <img src={props.img} alt="Not Found Image" className={classes.errorimg} />
        <div className={`${classes.errorspan}`}>
          <h4>{props.title}</h4>
          <p>{props.subtitle}</p>
        </div>
      </div>
    </>
  );
};

export default NoData;
