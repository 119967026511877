import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Layout from "../../../components/Layout";
import PageLoader from "../../../components/PageLoader";
import ButtonCount from "../../../components/ButtonCount";
import { httprequest } from "../../../data/api";
import ScheduleExamCard from "../component/ScheduleExamCard";
import useValidator from "../../../hooks/useValidator";
import { useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import nosearch from "../../../assets/images/no-search.svg";
import { Grid } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import useStyles from "../styles";
import NotFounditem from "../../error/NotFound";
import InputGroup from "../../../components/FormComponent/InputGroup";
import { toast, ToastContainer } from "react-toastify";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
// import Select from "react-select";

const SCHEDULE_EXAM = "/api/v1/exam-schedule";
const TERM_ENDPOINT = "/api/v1/school-plan/term";
const SESSION_ENDPOINT = "/api/v1/school-plan/session";

const ScheduleExam = () => {
  const [sheduleExamForm, setScheduleExamForm] = useState({
    term_id: "",
    title: ""
  });
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [scheduleExamModal, setSchduleExamModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [allTerm, setAllTerms] = useState([]);
  const [mainTerms, setMainTerms] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  // const [searchResult, setSearchResult] = useState([]);
  const [allscheduleExamData, setAllScheduleExamData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [sheduleExamvalidator, showScheduleExamValidator] = useValidator();
  const [editSheduleExamvalidator, showEditScheduleExamValidator] = useValidator();
  const [aSession, setASession] = useState("");
  const [aEditSession, setAEditSession] = useState("");
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [activeSession, setActiveSession] = useState({});
  const [aselectedTerm, setASelectedTerm] = useState("");
  const [editAselectedTerm, setEditASelectedTerm] = useState("");
  const [editSchedule, setEditSchedule] = useState({
    term_id: "",
    title: ""
  });

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setScheduleExamForm({ ...sheduleExamForm, [name]: value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditSchedule({ ...editSchedule, [name]: value });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScheduleExamModal = () => {
    setSchduleExamModal(!scheduleExamModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleViewModal = (data) => {
    setCurrentRow(data);
    setViewModal(!viewModal);
    setEditSchedule({
      term_id: data?.term?.id,
      title: data?.title
    });
  };

  const handleCloseViewModal = () => {
    setViewModal(false);
    setEditSchedule({
      term_id: "",
      title: ""
    });
  };

  const handleCloseCreateSchdule = () => {
    setSchduleExamModal(false);
    setScheduleExamForm({
      term_id: "",
      title: ""
    });
  };

  // eslint-disable-next-line no-console
  console.log(sheduleExamForm, "sheduleExamForm");

  // get All Sessions
  const getAllSession = () => {
    httprequest
      .get(SESSION_ENDPOINT)
      .then((res) => {
        setSessionData(res?.data?.data);
        setActiveSession(
          res?.data?.data?.find(
            (active_session) => active_session?.current_session === true
          )
        );
      })
      .catch(() => {});
  };

  // handle Get all Terms
  const getAllTerms = (Session_id) => {
    httprequest
      .get(TERM_ENDPOINT)
      .then((res) => {
        setAllTerms(
          res?.data?.data.filter(
            (session_term) => session_term?.session_id === Session_id
          )
        );
        setMainTerms(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const active_term_session = activeSession?.terms?.find(
    (found_active_term) => found_active_term?.is_current === true
  );

  const handleEditSessionChange = (e) => {
    const { value } = e.target;
    setASession(value);
    getAllTerms(value);
  };

  const handleForEditSessionChange = (e) => {
    const { value } = e.target;
    getAllTerms(value);
    setAEditSession(value);
  };

  const getAllSchedule = () => {
    setIsPageFetching(true);
    httprequest
      .get(`${SCHEDULE_EXAM}`)
      .then((res) => {
        setIsPageFetching(false);
        setAllScheduleExamData(res?.data?.data);
        // setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        setIsPageFetching(false);
        toast.error(err?.response?.data);
      });
  };

  // Edit Schedule Exam
  const handleEditScheduleExam = () => {
    if (editSheduleExamvalidator.allValid()) {
      setIsEditLoading(true);
      httprequest
        .patch(`${SCHEDULE_EXAM}/${currentRow?.id}`, editSchedule)
        .then((res) => {
          setIsEditLoading(false);
          toast.success(res?.data?.message);
          getAllSchedule();
          handleCloseViewModal();
        })
        .catch((err) => {
          setIsEditLoading(false);
          toast.error(err?.response?.data);
        });
    } else {
      showEditScheduleExamValidator(true);
    }
  };

  // Delete Schedule Exam
  const handleDeleteScheduleExam = () => {
    setIsDeleteLoading(true);
    httprequest
      .delete(`${SCHEDULE_EXAM}/${currentRow?.id}`)
      .then((res) => {
        setIsDeleteLoading(false);
        toast.success(res?.data?.message);
        getAllSchedule();
        handleCloseDeleteModal();
      })
      .catch((err) => {
        setIsDeleteLoading(false);
        toast.error(err?.response?.data);
      });
  };

  // handle Create Exam Schedule
  const handleCreateExamSchedule = () => {
    if (sheduleExamvalidator.allValid()) {
      setIsCreating(true);
      httprequest
        .post(SCHEDULE_EXAM, sheduleExamForm)
        .then((res) => {
          setIsCreating(false);
          toast.success(res?.data?.message);
          handleCloseCreateSchdule();
          getAllSchedule();
        })
        .catch((err) => {
          setIsCreating(false);
          toast.error(err?.response?.data?.message);
        });
    } else {
      showScheduleExamValidator(true);
    }
  };

  // eslint-disable-next-line no-console
  console.log(currentRow, "currentRow");

  // eslint-disable-next-line no-console
  console.log(editSchedule, "editSchedule");

  useEffect(() => {
    getAllSchedule();
    getAllTerms();
    getAllSession();
  }, []);

  useEffect(() => {
    if (viewModal && editSchedule?.term_id) {
      const matchedTerm = mainTerms?.find(term => term?.id === editSchedule?.term_id);

      // eslint-disable-next-line no-console
      console.log(matchedTerm, "matchedTerm");
      setAEditSession(matchedTerm?.session_id);
      setEditASelectedTerm(matchedTerm?.id);
      getAllTerms(matchedTerm?.session_id);
    }
  }, [viewModal, editSchedule?.term_id]);

  // eslint-disable-next-line no-console
  console.log(mainTerms, "mainTerms");

  useEffect(() => {
    if (activeSession?.id) {
      getAllTerms(activeSession?.id);
    }
  }, [activeSession]);

  // For Session
  useEffect(() => {
    if (activeSession?.id) {
      setASession(activeSession?.id);
    }
  }, [activeSession?.id]);

  useEffect(() => {
    if (active_term_session?.id) {
      setScheduleExamForm({
        ...sheduleExamForm, term_id: active_term_session?.id
      });
      setASelectedTerm(active_term_session?.id);
    }
  }, [active_term_session]);

  const handleTermChange = (event) => {
    const value = event.target.value;
    setASelectedTerm(value);

    // eslint-disable-next-line no-console
    console.log(value, "value");

    setScheduleExamForm({ ...sheduleExamForm, term_id: value });
  };

  // For Term in View/EDit Modal
  const handleEditTermChange = (event) => {
    const value = event.target.value;
    editAselectedTerm(value);

    setEditSchedule({ ...editSchedule, term_id: value });
  };

  const { title, term_id } = sheduleExamForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Examination Schedules"
            subtitle="View all the examination schedule"
            button={
              <div className="flex gap-10">
                <Button
                  onClick={handleScheduleExamModal}
                  variant="primary"
                  buttonSize="fluid"
                  color="btndefault"
                  buttonName="Create Exam Schedule"
                />
              </div>
            }
          />
          <div className="page-content">
            <div className="button-count-container">
              <ButtonCount title="Schedule Exam" count={0} />
            </div>
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label=""
                >
                  <Tab label="All Exam Schdule" value="1" />
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                {isPageFetching ? (
                  <PageLoader />
                ) : allscheduleExamData && allscheduleExamData.length > 0 ? (
                  allscheduleExamData.map((data, index) => (
                    <ScheduleExamCard
                      key={index}
                      SessionTitle={data?.title}
                      SubtitleOne={data?.term?.name}
                      SubtitleTwo={data?.subtitle || " - Schedule"}
                      route_to={`/school-examination/schedule-examination/${data.id}`}
                      deleteClick={() => handleDeleteModal(data)}
                      viewClick={() => handleViewModal(data)}
                    />
                  ))
                ) : (
                  <div className={classes.notfound}>
                    <NotFounditem
                      img={nosearch}
                      title="No Exam Schedule Found"
                      subtitle="No Schedule has been added to the list."
                    />
                  </div>
                )}
              </TabPanel>
            </TabContext>
          </div>
          {/* Schedule Examination */}
          <Modal
            title="Schedule An Examination"
            subtitle={<div>Create an Examination Schedule</div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      margin="normal"
                      inputType="text"
                      required
                      inputName="title"
                      onChange={handleTitleChange}
                    />
                    {sheduleExamvalidator.message("title", title, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Session"
                      required
                      children={
                        <select
                          value={aSession}
                          onChange={handleEditSessionChange}
                        >
                          <option value="">---Select---</option>
                          {sessionData?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Term"
                      required
                      children={
                        <select
                          value={aselectedTerm}
                          name="term_id"
                          onChange={handleTermChange}
                        >
                          <option value="">--- Select ---</option>

                          {allTerm?.map((term) => {
                            return (
                              <option key={term?.id} value={term?.id}>
                                {term?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {sheduleExamvalidator.message(
                      "term_id",
                      term_id,
                      "required"
                    )}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div>
                  {/* <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Add Another Period" /> */}
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isCreating}
                    onClick={handleCreateExamSchedule}
                    buttonName="Create Exam Schedule"
                  />
                </div>
              </>
            }
            isopen={scheduleExamModal}
            closeModal={handleCloseCreateSchdule}
          />

          {/* Edit School Plan */}
          <Modal
            title="Edit a Examination Schedule"
            subtitle={`Edit Examination Schedule - ${currentRow?.title}`}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      margin="normal"
                      required
                      placeHolder="Enter title"
                      inputType="text"
                      onChange={handleEditChange}
                      inputName="title"
                      defaultValue={editSchedule?.title}
                    />
                    {editSheduleExamvalidator.message("Title", editSchedule?.title, "required")}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Session"
                      required
                      children={
                        <select
                          onChange={handleForEditSessionChange}
                          value={aEditSession}
                        >
                          <option value="">---Select---</option>
                          {sessionData?.map((sesh) => {
                            return (
                              <option key={sesh?.id} value={sesh?.id}>
                                {sesh?.session}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <SelectGroup
                      label="Select Term"
                      required
                      children={
                        <select
                          onChange={handleEditTermChange}
                          name="term_id"
                          value={editAselectedTerm}
                        >
                          <option value="">---Select---</option>
                          {allTerm?.map((data) => {
                            return (
                              <option key={data?.id} value={data?.id}>
                                {data?.term}
                              </option>
                            );
                          })}
                        </select>
                      }
                    />
                    {editSheduleExamvalidator.message("Term", editSchedule?.term_id, "required")}
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Edit Schedule"
                    onClick={handleEditScheduleExam}
                    isLoading={isEditLoading}
                  />
                </div>
              </>
            }
            isopen={viewModal}
            closeModal={handleCloseViewModal}
          />
          {/* Delete Modal */}
          <Modal
            title="Delete Assignment"
            subtitle={
              <div>
                Are you sure you want to remove{" "}
                <span className="modal-name-highlight">
                  {currentRow?.title}
                </span>
                from one of your Assignment? Removing it will permanently clear
                this Assignment.
              </div>
            }
            modalContent={<></>}
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="dangeroutline"
                    buttonSize="full"
                    color="btndanger"
                    buttonName="Cancel"
                    onClick={handleCloseDeleteModal}
                  />
                  <Button
                    variant="danger"
                    buttonName="Delete Schedule Exam"
                    buttonSize="full"
                    color="btndefault"
                    onClick={handleDeleteScheduleExam}
                    isLoading={isDeleteLoading}
                  />
                </div>
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default ScheduleExam;
