import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import Table from "../../components/Table/Table";
import { TableSearch } from "../../components/Table/TableActions";
import Modal from "../../components/Modal";
import { Icon } from "@iconify/react";
import Badge from "../../components/Badge";
import Select from "react-select";
import ButtonCount from "../../components/ButtonCount";
import useStyles from "./styles";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import useValidator from "../../hooks/useValidator";
import { useNavigate, Link } from "react-router-dom";
import InputGroup from "../../components/FormComponent/InputGroup";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { useUserState } from "../../context/UserContext";
import { getCurrentRole } from "../../data/roles";

const LABEL_ENDPOINT = "/api/v1/labels";
const RESOURCE_ENDPOINT = "/api/v1/knowledgebase";
const UPLOAD_ENDPOINT = "/api/v1/upload";
const ROLES_ENDPOINT = "/api/v1/roles";

const Resources = () => {
  const { userRole } = useUserState();
  const currentRole = getCurrentRole(userRole);

  const IS_ADMIN = currentRole === "admin";
  const IS_USER = currentRole === "user";

  const classes = useStyles();
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  // const [createLabelModal, setCreateLabel] = useState(false);
  const [addInfoModal, setAddInfoModal] = useState(false);
  const [editInfoModal, setEditInfoModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [labelsData, setLabelsData] = useState([]);
  const [labelsCount, setLabelsCount] = useState(0);
  const [resourcesData, setResourcesData] = useState([]);
  const [resourceValidator, showResourceValidation] = useValidator();
  const [labelsValidator, showLabelsValidator] = useValidator();
  const [editResourceValidator, showEditResourceValidation] = useValidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [uploadForm, setUploadForm] = useState({});
  const [labelModal, setLabelModal] = useState(false);
  const [multipleAccess, setMultipleAccess] = useState([]);
  const navigate = useNavigate();

  const [resourceForm, setResourceForm] = useState({
    title: "",
    access: "",
    label: "",
    upload: {},
    due_date: "",
    status: "active"
  });

  const [editresourceForm, setEditResourceForm] = useState({
    title: "",
    access: "",
    label: "",
    due_date: "",
    upload: {},
    status: "active"
  });

  const [labelForm, setLabelForm] = useState({
    label: ""
  });

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResourceForm({ ...resourceForm, [name]: value });
  };

  const handleLabelChange = (e) => {
    const { name, value } = e.target;
    setLabelForm({ ...labelForm, [name]: value });
  };

  const handleFileChange = (e) => {
    // const { name } = e.target;
    if (e.target.files) setSelectedFile(e.target.files[0]);
    setUploadForm({ ...uploadForm, file: e.target.files[0].name.replaceAll(" ", "-") });
  };

  const handleRemoveFile = () => setSelectedFile("");

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditResourceForm({ ...editresourceForm, [name]: value });
  };

  const handleEditResourceModal = (row) => {
    setEditInfoModal(!editInfoModal);
    setEditResourceForm({
      ...resourceForm,
      title: row?.title,
      access: row?.access,
      label: row?.label,
      due_date: row?.due_date,
      upload_id: row?.upload_id
    });
    setCurrentRow(row);
  };

  const handleLabelModal = () => {
    setLabelModal(!labelModal);
  };

  const handleCloseEditResourceModal = () => {
    setEditInfoModal(false);
  };

  const getAllRoles = () => {
    httprequest.get(ROLES_ENDPOINT).then((res) => {
      setMultipleAccess(res?.data?.data?.map((role) => {
        return {
          label: role?.role_name,
          value: role?.role_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };
  // eslint-disable-next-line no-console
  console.log(multipleAccess, "multipleAccess");

  const handleAddResourceModal = () => {
    setAddInfoModal(!addInfoModal);
  };

  const handleCloseAddInfoModal = () => {
    setAddInfoModal(false);
    setResourceForm({ ...resourceForm, title: "", access: "", label: "", status: "active" });
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const getAllLabels = () => {
    httprequest.get(LABEL_ENDPOINT).then((res) => {
      setLabelsData(res?.data?.data);
      setLabelsCount(res?.data?.data?.length);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCreateLabel = () => {
    if (labelsValidator.allValid()) {
      setIsLoading(true);
      httprequest.post(LABEL_ENDPOINT, { label: [labelForm?.label] }).then((res) => {
        setIsLoading(false);
        toast.success(res?.data?.message);
        getAllLabels();
        setLabelForm({ ...labelForm, label: "" });
        handleLabelModal();
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data);
      });
    } else {
      showLabelsValidator(true);
    }
  };

  const getAllResource = () => {
    httprequest.get(RESOURCE_ENDPOINT).then((res) => {
      setResourcesData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data?.message);
    });
  };

  const handleCreateResource = async () => {
    if (resourceValidator.allValid()) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const response = await httprequest.post(
        `${UPLOAD_ENDPOINT}`, formData
      );
      if (response.status === 200 && response.data.data) {
        const upload_id = response.data.data?.[0]?.upload_id;
        const file_url = response.data.data?.[0]?.file_url;
        const data = { ...resourceForm, upload: {
          upload_id, file_url
        } };

        httprequest.post(RESOURCE_ENDPOINT, data).then(() => {
          setIsLoading(false);
          toast.success("Resource added to your school");
          getAllResource();
          // --| Reset resource state to original state
          setResourceForm({ ...resourceForm, title: "", access: "", label: "", status: "active" });
          handleAddResourceModal();
        }).catch((err) => {
          setIsLoading(false);
          toast.error(err?.response?.data.message);
        });
      } else {
        toast.error("There is a problem uploading the file");
      }
    } else {
      showResourceValidation(true);
    }
  };

  const handleEditResource = () => {
    if (editResourceValidator.allValid()) {
      setIsEditLoading(true);
      httprequest.patch(`${RESOURCE_ENDPOINT}/${currentRow?.id}`, editresourceForm).then(() => {
        setIsEditLoading(false);
        toast.success("Resources Edit");
        getAllResource();
        setEditResourceForm({ ...editresourceForm, title: "", access: "", label: "", due_date: "", upload_id: "", public_id: "" });
        handleCloseEditResourceModal();
      }).catch((err) => {
        setIsEditLoading(false);
        toast.error(err?.response?.data?.message);
        handleCloseEditResourceModal();
      });
    } else {
      showEditResourceValidation(true);
    }
  };

  const handleMultipleAccessChange = (event) => {
    const selectedValue = event.map((item) => item.value);
    setResourceForm({ ...resourceForm, access: selectedValue });
  };

  const handleEditMultipleAccessChange = (event) => {
    const selectedAccess = event.map((option) => ({
      value: option?.id,
      label: option?.name
    }));
    // eslint-disable-next-line no-console
    console.log(selectedAccess, "selectedAccess");

    setEditResourceForm((prevEditResourceForm) => ({
      ...prevEditResourceForm,
      access: selectedAccess
    }));
  };

  // On Page Load, set search result data to be the initial data
  useEffect(() => {
    // The data will be from the knowledgebase get api endpint
    setSearchResult(resourcesData);
    getAllLabels();
    getAllResource();
    getAllRoles();
  }, []);

  const columns = [
    // {
    //   name: "Code",
    //   selector: row => row?.code
    // },
    {
      name: "Title",
      selector: row => row?.title
    },
    {
      name: "Label",
      selector: row => row?.label
    },
    {
      name: "Access",
      selector: row => row?.access?.map((item, i) => <div key={i} style={{ textTransform: "capitalize" }}>{item?.role_name}</div>)
    },
    {
      name: "Expiry date",
      selector: row => row?.due_date?.substring(0, 10)
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.status} />)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <Link to={`/manage/knowledgebase/${row?.id}`} className="table-action-icon">
            <Icon icon="fluent:eye-16-regular" />
          </Link>
          {IS_ADMIN && (
            <>
              <button className="table-action-icon" onClick={() => {
                handleEditResourceModal(row);
              }}
              >
                <Icon icon="heroicons:pencil-square-solid" />
              </button>
              <button
                onClick={() => handleDeleteModal(row)}
                className="table-action-icon delete-icon"
              >
                <Icon icon="mingcute:delete-2-line" />
              </button>
            </>
          )}
        </div>
      )
    }
  ];

  // --| Filter Resource table using title, label, access
  const handleSearchResource = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = resourcesData?.filter((data) => valArray?.every(
      (word) => data?.name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                    data?.title
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                    data?.label
                      ?.toLowerCase()
                      .includes(word.toLowerCase()) ||
                      data?.type
                        ?.toLowerCase()
                        .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { title, label, access, due_date } = resourceForm;

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Resources"
            subtitle={`${(IS_ADMIN) ? "See a list of all the resources that has been shared throughout the organisation." : IS_USER ? "See a list of all the resources made available in your organisation" : ""}`}
            button={
              IS_ADMIN && (
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    buttonName="Create new label"
                    onClick={handleLabelModal}
                  />
                  <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    buttonName="Add new Resource"
                    onClick={handleAddResourceModal}
                  />
                </div>
              )

            }
          />
          <div className="page-content">
            {IS_ADMIN && (
              <div className={classes.buttoncountcontainer}>
                <ButtonCount func={() => navigate("/manage/knowledgebase/labels")} title="Resource Label Count" count={labelsCount} />
              </div>
            )}

            <Table data={searchResult} columns={columns} subHeader={true} pagination subHeaderComponent={
              <TableSearch placeholder="Search here..." searchTableFunc={handleSearchResource} />
            }
            />
            <Modal
              title="Delete Resource"
              subtitle={
                <div>Are you sure you want to delete <span className="modal-name-highlight">{currentRow?.title}</span>. If resource is deleted, employee will no longer have access to this resource.`
                </div>}
              modalContent={
                <>

                </>
              }
              modalFooter={
                <>
                  <Button variant="danger" buttonName="Delete Resource" buttonSize="full" color="btndefault" />
                </>
              }
              isopen={deleteModal}
              closeModal={handleCloseDeleteModal}
            />

            <Modal
              title="Add a New Resource"
              subtitle={
                <div>Add a document to your knowledge base for your employees to have access to.
                </div>}
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Title"
                        required
                        margin="normal"
                        placeHolder="Add Title of Document"
                        inputType="text"
                        inputName="title"
                        value={title}
                        onChange={handleChange}
                      />
                      {resourceValidator.message("Title", title, "required")}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <SelectGroup required label="Labels" children={
                        <select onChange={handleChange} name="label" value={label}>
                          <option value="">---Select Labels ---</option>
                          {labelsData?.map((data) => {
                            return (
                              <option value={data.id} key={data?.id}>{data?.label}</option>
                            );
                          })}
                        </select>
                      }
                      />
                      {resourceValidator.message("Labels", label, "required")}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className="multiple-select">
                        <p>Send Access to<span className="input-required">*</span></p>
                        <Select isMulti name="access"
                          closeMenuOnSelect={true} options={multipleAccess} onChange={handleMultipleAccessChange}
                        />
                      </div>
                      {resourceValidator.message("Access", access, "required")}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <InputGroup
                        label="Due Date"
                        margin="normal"
                        required
                        inputType="date"
                        inputName="due_date"
                        onChange={handleChange}
                        value={due_date}
                      />
                      {resourceValidator.message("Due Date", due_date, "required")}
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <label>
                        <strong>Upload Supporting Document</strong>
                        <span className="input-required">*</span>
                      </label>
                      <div className={classes.pickercontainer}>
                        <input type="file" className={classes.picker} onChange={handleFileChange} name="file" accept="application/pdf" />

                        <div className={classes.uploadflex}>
                          <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                          {selectedFile?.name ? (
                            <div>
                              <h2 className={classes.uploadtitle}>{selectedFile?.name}</h2>
                            </div>
                          ) :
                            <div>
                              <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitle}>PDF| 10MB max.
                              </h6>
                            </div>}
                        </div>
                        <div className={classes.rightpicker}>
                          {selectedFile?.name ? (
                            <div className={classes.uploadbutton} onClick={handleRemoveFile}><Icon icon="zondicons:minus-solid" /> Remove File</div>
                          ) : null}
                        </div>
                      </div>
                      {resourceValidator.message("file", selectedFile, "required")}
                    </Grid>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <Button variant="primary" buttonName="Add Document" buttonSize="full" color="btndefault" isLoading={isLoading} onClick={handleCreateResource} />
                </>
              }
              isopen={addInfoModal}
              closeModal={handleCloseAddInfoModal}
            />

            {/* Edit a Modal */}

            <Modal
              title={`Edit Resource for ${currentRow?.title}`}
              subtitle={
                <div>Edit the knowledge base info for {currentRow?.title}
                </div>}
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <div className="multiple-select">
                        <p>Send Invite to<span className="input-required">*</span></p>
                        <Select
                          isMulti
                          name="Who can access"
                          closeMenuOnSelect={true}
                          options={multipleAccess}
                          onChange={handleEditMultipleAccessChange}
                          defaultValue={editresourceForm?.access}
                          getOptionLabel={(option) => option?.label}
                          getOptionValue={(option) => option?.value}
                        />
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Title"
                        required
                        margin="normal"
                        placeHolder="Add Title of Document"
                        inputType="text"
                        value={editresourceForm?.title}
                        onChange={handleEditChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <SelectGroup required label="Labels" children={
                        <select name="label" onChange={handleEditChange} value={editresourceForm?.label}>
                          <option value="">---Select Labels ---</option>
                          {labelsData?.map((data) => {
                            return (
                              <option value={data.id} key={data?.id}>{data?.label}</option>
                            );
                          })}
                        </select>
                      }
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup
                        label="Due Date"
                        margin="normal"
                        required
                        inputType="date"
                        inpurtName="due_date"
                        value={editresourceForm?.due_date}
                        onChange={handleEditChange}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <label>
                        <strong>Upload Supporting Document</strong>
                        <span className="input-required">*</span>
                      </label>
                      <div className={classes.pickercontainer}>
                        <input type="file" className={classes.picker} />

                        <div className={classes.uploadflex}>
                          <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                          {selectedFile?.name ? (
                            <div>
                              <h2 className={classes.uploadtitle}>File Name</h2>
                              <h6 className={classes.uploadsubtitle}>{selectedFile?.name}</h6>
                            </div>
                          ) :
                            <div>
                              <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.
                                <span className={classes.remove}> <Icon icon="zondicons:minus-solid" /> Remove File</span>
                              </h6>
                            </div>}
                        </div>
                        <div className={classes.rightpicker}>
                          <div className={classes.uploadbutton}>Upload</div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <Button variant="primary" buttonName="Edit Document" buttonSize="full" color="btndefault" isLoading={isEditLoading} onClick={handleEditResource} />
                </>
              }
              isopen={editInfoModal}
              closeModal={handleCloseEditResourceModal}
            />
            <Modal
              title="Add New Label tag "
              subtitle="Adding label tags ensure proper classification on your materials."
              modalContent={
                <>
                  <Grid container spacing={2} className="input-padding">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <InputGroup required inputName="label" type="text" label="Label" onChange={handleLabelChange} />
                      {labelsValidator.message("Label", label, "required")}
                    </Grid>
                  </Grid>
                </>
              }
              modalFooter={
                <>
                  <Button
                    type="submit"
                    onClick={handleCreateLabel}
                    variant="primary"
                    buttonName="Create Label tag"
                    buttonSize="full"
                    color="btndefault"
                    isLoading={isLoading}
                  />
                </>
              }
              isopen={labelModal}
              closeModal={handleLabelModal}
            />
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default Resources;
