import PropTypes from "prop-types";
import useStyles from "../styles";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const ScheduleExamCard = ({ SessionTitle, SubtitleOne, SubtitleTwo, route_to, deleteClick, viewClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.schedulecont}>
      <div className={classes.schdulecard}>
        <div className={classes.selectcont}>
          <h1>{SessionTitle}</h1>
          <p><span className={classes.firstitem}>{SubtitleOne}</span><span>{SubtitleTwo}</span></p>
        </div>
        <div className={`${classes.selectcont} flex gap-10 cursor-pointer`}>
          <Link to={route_to}><h3>View Exam Schedule</h3></Link>
          <Icon onClick={viewClick} icon="fluent:eye-16-regular" />
          <Icon onClick={deleteClick} icon="mingcute:delete-2-line" style={{ color: "#DD514D" }} />
        </div>
      </div>
    </div>
  );
};

ScheduleExamCard.PropTypes = {
  SessionTitle: PropTypes.string,
  SubtitleOne: PropTypes.string,
  SubtitleTwo: PropTypes.string
};

export default ScheduleExamCard;
