import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  buttoncountcontainer: {
    marginTop: 15,
    marginBottom: 15
  },
  pickercontainer: {
    maxWidth: "100%",
    border: "1px dashed #D0D5DD",
    height: 60,
    borderRadius: 10,
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
    padding: "16px 20px",
    marginBottom: 20
  },
  label: {
    fontWeight: "500",
    fontSize: "16px"
  },
  picker: {
    opacity: 0,
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    cursor: "pointer",
    position: "absolute"
  },
  uploadbutton: {
    backgroundColor: theme.palette.primary.main,
    width: 86,
    height: 39,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 500,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  uploadflex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 8
  },
  uploadicon: {
    fontSize: 16,
    color: "#101928"
  },
  uploadcont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  uploadtitle: {
    fontSize: 16,
    color: "#101928",
    marginBottom: 3,
    marginTop: 0
  },
  uploadsubtitle: {
    color: "#98A2B3",
    fontsize: 16,
    marginBlock: 0
  },
  modalFooterBtn: {
    display: "flex",
    width: "100%",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      flexWrap: "wrap"
    }
  },
  mail: {
    paddingLeft: "3%",
    gap: "2px",
    color: "#0065FF",
    fontWeight: "500",
    lineHeight: "17.4px"
    // letterSpacing: "-5px"
  },
  detailstext: {
    textAlign: "left"
  },
  pagedivider: {
    marginTop: 40,
    marginBottom: 40,
    color: "#DFE2E7"
  },
  title: {
    color: "#98A2B3",
    fontSize: 14,
    marginBottom: 3
  },
  subtitle: {
    color: "#40444B",
    fontSize: 14,
    fontWeight: 500
  },
  calendar: {
    border: "1px solid #E4E7EC",
    padding: "16px",
    borderRadius: "16px",
    marginTop: "15px"
  },
  terminfo: {
    maxWidth: "300px",
    marginLeft: "auto",
    marginBottom: "20px",
    padding: "5px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "5px",
    "& p": {
      fontWeight: "500",
      marginBlock: "0px",
      fontSize: "14px",
      color: theme.palette.primary.main
    }
  }
}));
