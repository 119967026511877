import Layout from "../../components/Layout";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import nosearch from "../../assets/images/no-search.svg";
import { useParams } from "react-router-dom";
import { httprequest } from "../../data/api";
import { toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const GET_RESOURCES = "/api/v1/knowledgebase/";

const Resource = () => {
//   const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();
  const [aResourceData, setAResourceData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleGetResourceById = () => {
    setIsLoading(true);
    httprequest.get(`${GET_RESOURCES}${id}`).then((res) => {
      setAResourceData(res?.data?.data?.upload);
      setIsLoading(false);
    }).catch((err) => {
      toast.error(err?.data?.data);
      setIsLoading(false);
    });
  };

  // eslint-disable-next-line no-console
  console.log(aResourceData, "aResourceData");

  useEffect(() => {
    handleGetResourceById();
  }, []);

  return (
    <Layout children={
      <>
        <div className="margin-bottom">
          <BreadCrumb link_one="/manage/knowledgebase" link_one_name="Resources" active="View the pdf of the current resource" />
        </div>
        <div className="page-content padding-block-16">
          <Grid container spacing={4}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {isLoading ? (
                <PageLoader />
              ) : aResourceData?.file_url ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                  <Viewer fileUrl={aResourceData.file_url} />
                </Worker>
              ) : (
                <div>
                  <NotFounditem img={nosearch} title="No Resource Found" subtitle="No resource was added to this Resource File" />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    }
    />
  );
};

export default Resource;
