import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";
import { useState, useEffect } from "react";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TabContext from "@mui/lab/TabContext";
import Table from "../../../components/Table/Table";
import { TableSearch } from "../../../components/Table/TableActions";
// import Badge from "../../../components/Badge";
import Modal from "../../../components/Modal";
import useStyles from "../styles";
import { Grid } from "@mui/material";
import SelectGroup from "../../../components/FormComponent/SelectGroup";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import { useUserState } from "../../../context/UserContext";

const GET_ALL_SUBJECTS = "/api/v1/subjects";
const CLASS_ENDPOINT = "/api/v1/class";
const LESSON_NOTE = "/api/v1/lesson-notes";

const AdminLessonNote = () => {
  const navigate = useNavigate();
  const { profile } = useUserState();
  const [value, setValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [allSubjects, setAllSubjects] = useState([]);
  const [classLevels, setClassLevels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [classValidator, showClassValidator] = useValidator();
  const [allLessonNotes, setAllLessonNotes] = useState([]);
  const [lessonNoteId, setLessonNoteId] = useState("");
  const [lessonNoteForm, setLessonNoteForm] = useState({
    subject_id: "",
    class_id: ""
  });
  const [editLessonNoteForm, setEditLessonNoteForm] = useState({
    subject_id: "",
    class_id: ""
  });
  const classes = useStyles();
  const [lessonNoteToDelete, setLessonNoteToDelete] = useState(null);
  const [editModal, setEditModal] = useState(false);

  // Handle select input change
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    setLessonNoteForm((prev) => ({ ...prev, [name]: value }));
  };

  // Handle select change on the edit modal
  const handleEditSelectChange = (e) => {
    const { name, value } = e.target;
    setEditLessonNoteForm((prev) => ({ ...prev, [name]: value }));
  };

  // Table Tab change
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle createNote Modal trigger
  const handleCreateNoteModal = () => {
    setCreateNoteModal(!createNoteModal);
  };

  // http get all subjects requests
  const getAllSubjects = () => {
    httprequest
      .get(GET_ALL_SUBJECTS)
      .then((res) => {
        setAllSubjects(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // http get all class levels requests
  const getClassLevels = () => {
    httprequest
      .get(CLASS_ENDPOINT)
      .then((res) => {
        setClassLevels(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // http get all lessons requests
  const getAllLessonNotes = () => {
    httprequest
      .get(`${LESSON_NOTE}?class_id=${value}`
      )
      .then((res) => {
        setAllLessonNotes(res?.data?.data);
        setSearchResult(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // http post create lesson notes requests
  const handleCreateLessonNote = () => {
    if (classValidator.allValid()) {
      setIsLoading(true);
      httprequest
        .post(LESSON_NOTE, lessonNoteForm)
        .then((res) => {
          toast.success(res?.data?.message);
          getAllLessonNotes();
          handleCreateNoteModal();
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      showClassValidator(true);
    }
  };

  // Edit modal trigger
  const handleEditModal = (row) => {
    setEditLessonNoteForm({ ...lessonNoteForm, subject_id: row?.subject_id, class_id: row?.class_id });
    setLessonNoteId(row?.id);
    setEditModal(!editModal);
  };

  // Handle Edit Lesson Notes Patch request and Modal
  const closeEditModal = () => {
    setEditLessonNoteForm({ ...lessonNoteForm, subject_id: "", class_id: "" });
    setLessonNoteId("");
    setEditModal(false);
  };

  const handlePatchLessonNote = (lessonNoteId) => {
    httprequest
      .patch(`${LESSON_NOTE}/${lessonNoteId}`, editLessonNoteForm)
      .then((res) => {
        toast.success(res?.data?.message);
        getAllLessonNotes();
        closeEditModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  // Handle Delete Lesson Notes Modal and Delete Request
  const handleDeleteModal = (lessonNoteId) => {
    setLessonNoteToDelete(lessonNoteId);
    setDeleteModal(!deleteModal);
  };

  const deleteLessonNote = (lessonNoteId) => {
    httprequest.delete(`${LESSON_NOTE}/${lessonNoteId}`)
      .then(() => {
        toast.success("Lesson note deleted successfully");
        setAllLessonNotes((prevNotes) => prevNotes.filter((lessonnote) => lessonnote.id !== lessonNoteId));
        setDeleteModal(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };

  const navigateViewLesson = (row) => {
    navigate(`/lesson-notes/${row?.id}?class_id=${row?.class_id}`);
  };

  const columns = [
    {
      name: "Class",
      selector: (row) => row?.class?.name
    },
    {
      name: "Subject",
      selector: (row) => row?.subject?.name
    },
    {
      name: "Note Count",
      selector: (row) => row?.outline_count
    },
    {
      name: "Created at",
      selector: (row) => row?.created_at?.substring(0, 10)
    },
    {
      name: "Last Updated",
      selector: (row) => row?.updated_at?.substring(0, 10)
    },
    {
      name: "",
      center: true,
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            className="table-action-icon"
            onClick={() => navigateViewLesson(row)}
          >
            <Icon icon="fluent:eye-16-regular" />
          </button>
          <button className="table-action-icon" onClick={() => handleEditModal(row)}>
            <Icon icon="heroicons:pencil-square-solid" />
          </button>
          <button
            className="table-action-icon delete-icon"
            onClick={() => handleDeleteModal(row?.id)}
          >
            <Icon icon="mingcute:delete-2-line" />
          </button>
        </div>
      )
    }
  ];

  useEffect(() => {
    // The data will be from the Schools get api endpint
    // setSearchResult(LessonNoteData);
    getAllSubjects();
    getClassLevels();
  }, []);

  useEffect(() => {
    getAllLessonNotes();
  }, [value]);

  // --| Filter Shelf21 table using name, email and class
  const handleLessonNoteSearch = (e) => {
    const { value } = e.target;
    const valArray = value.split(" ");
    // --| Filter data by partial match onchange in the search input box
    const result = allLessonNotes?.filter((data) => valArray?.every(
      (word) => data?.class?.name?.toLowerCase().includes(word.toLowerCase()) ||
      data?.subject?.name?.toLowerCase().includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };

  const { subject_id, class_id } = lessonNoteForm;

  return (
    <div>
      <PageTitle
        title="Lesson Note"
        subtitle="See all your Lesson Note"
        button={
          <div>
            <Button
              variant="primary"
              buttonSize="fluid"
              color="btndefault"
              onClick={() => handleCreateNoteModal()}
              buttonName="Create Lesson Note"
            />
          </div>
        }
      />
      <div className="page-content">
        <TabContext value={value}>
          <div className="tablist-container">
            <Tabs
              indicatorColor="none"
              className="overide-tablist"
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="All Lesson note" value="" data={profile} />
              {classLevels?.map((data) => {
                return (
                  <Tab
                    key={data?.id}
                    value={data?.id}
                    label={data?.class_name}
                  />
                );
              })}
            </Tabs>
          </div>
          <>
            <TabPanel value="" className="tabpanel-overide">
              <Table
                data={searchResult}
                columns={columns}
                subHeader={true}
                pagination
                subHeaderComponent={
                  <TableSearch
                    placeholder="Search here..."
                    searchTableFunc={handleLessonNoteSearch}
                  />
                }
              />
            </TabPanel>
            {classLevels?.map((data) => {
              return (
                <TabPanel key={data?.id} value={data?.id}>
                  <Table
                    data={allLessonNotes}
                    columns={columns}
                    subHeader={true}
                    pagination
                    subHeaderComponent={
                      <TableSearch placeholder="Search here..." />
                    }
                  />
                </TabPanel>
              );
            })}
          </>
        </TabContext>
        <ToastContainer />
      </div>
      <Modal
        title="Create Lesson Note"
        modalContent={
          <>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Subject"
                  required
                  children={
                    <select onChange={handleSelectChange} name="subject_id">
                      <option value="">--- Select ---</option>
                      {allSubjects?.map((subject) => {
                        return (
                          <option key={subject?.id} value={subject?.id}>
                            {subject?.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {classValidator.message("subject_id", subject_id, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Class"
                  required
                  children={
                    <select onChange={handleSelectChange} name="class_id">
                      <option value="">--- Select ---</option>
                      {classLevels?.map((classes) => {
                        return (
                          <option key={classes?.id} value={classes?.id}>
                            {classes?.class_name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {classValidator.message("class_name", class_id, "required")}
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Create Lesson Note"
                onClick={handleCreateLessonNote}
                isLoading={isLoading}
              />
            </div>
          </>
        }
        isopen={createNoteModal}
        closeModal={() => {
          setCreateNoteModal(false);
        }}
      />
      {/* Edit Modal */}
      <Modal
        title="Edit Lesson Note"
        modalContent={
          <>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Subject"
                  required
                  children={
                    <select onChange={handleEditSelectChange} name="subject_id" value={editLessonNoteForm?.subject_id}>
                      <option value="">--- Select ---</option>
                      {allSubjects?.map((subject) => {
                        return (
                          <option key={subject?.id} value={subject?.id}>
                            {subject?.name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {classValidator.message("subject_id", subject_id, "required")}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <SelectGroup
                  label="Class"
                  required
                  children={
                    <select onChange={handleEditSelectChange} name="class_id" value={editLessonNoteForm?.class_id}>
                      <option value="">--- Select ---</option>
                      {classLevels?.map((classes) => {
                        return (
                          <option key={classes?.id} value={classes?.id}>
                            {classes?.class_name}
                          </option>
                        );
                      })}
                    </select>
                  }
                />
                {classValidator.message("class_name", class_id, "required")}
              </Grid>
            </Grid>
          </>
        }
        modalFooter={
          <>
            <div className={classes.modalFooterBtn}>
              <Button
                variant="primary"
                buttonSize="full"
                color="btndefault"
                buttonName="Edit Lesson Note"
                onClick={() => handlePatchLessonNote(lessonNoteId)}
                isLoading={isLoading}
              />
            </div>
          </>
        }
        isopen={editModal}
        closeModal={closeEditModal}
      />
      {/* Delete Modal */}
      <Modal
        title="Delete Lesson Note"
        subtitle="Are you sure you want to permanently delete this Lesson Note?"
        modalContent={<></>}
        modalFooter={
          <>
            <div className="flex gap-10">
              <Button
                variant="dangeroutline"
                buttonSize="full"
                color="btndanger"
                buttonName="Cancel"
                onClick={handleDeleteModal}
              />
              <Button
                variant="danger"
                buttonName="Delete Lesson note"
                buttonSize="full"
                color="btndefault"
                onClick={() => deleteLessonNote(lessonNoteToDelete)}
              />
            </div>
          </>
        }
        isopen={deleteModal}
        closeModal={handleDeleteModal}
      />
    </div>
  );
};

export default AdminLessonNote;
