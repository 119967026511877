import { Grid } from "@mui/material";
import InputGroup from "../../../components/FormComponent/InputGroup";
import styles from "../settings.module.css";
import SelectGroup from "../../../components/FormComponent/SelectGroup";

import { useEffect, useState } from "react";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import PageLoader from "../../../components/PageLoader";
import Button from "../../../components/Button";
import Layout from "../../../components/Layout";
import BreadCrumb from "../../../components/BreadCrumb";
import { useNavigate } from "react-router-dom";

const ROLE_ENDPOINT = "/api/v1/roles";
const ALL_PERMISSIONS = "/api/v1/permissions";
const RolesandPermission = () => {
  const [roles, setRoles] = useState([]);
  const [roleType, setRoleType] = useState("");
  const [roleNameValue, setRoleNameValue] = useState("");
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // const addRole = () => {
  //   navigate("/settings/addRole");
  // };

  // Get All roles
  const getRoles = () => {
    httprequest.get(ROLE_ENDPOINT).then((res) => {
      const roleList = res?.data?.data;
      const filteredList = roleList.filter(user => user.role_type === "teacher");
      setRoles(filteredList);
    }).catch((err) => {
      toast.error(err?.res.data.message);
    });
  };

  // Get All Permissions
  const getPermissions = () => {
    setIsLoading(true);
    httprequest.get(ALL_PERMISSIONS).then((res) => {
      setIsLoading(false);
      const allPermissionsList = res?.data?.data;
      setAllPermissions(allPermissionsList);
    }).catch((err) => {
      setIsLoading(false);
      toast.error(err?.res.data.message);
    });
  };

  // Get All Role Permissions
  const getRolePermissions = (selectedRoleId) => {
    httprequest.get(`${ROLE_ENDPOINT}/${selectedRoleId}`).then((res) => {
      const permissionsList = res?.data?.data?.permissions;
      setSelectedPermission(permissionsList);
    }).catch((err) => {
      toast.error(err?.res?.message);
    });
  };

  useEffect(() => {
    if (roleType) {
      const selectedRole = roles.find((role) => role.role_id === roleType);
      if (selectedRole) {
        setIsLoading(false);
        getRolePermissions(selectedRole.role_id);
      }
    }
  }, [roleType, roles]);

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  const handleRoleChange = (e) => {
    setRoleType(e.target.value);
  };

  const handleRoleNameChange = (e) => {
    setRoleNameValue(e.target.value);
  };

  const handleCheckChange = (permissionName) => {
    setSelectedPermission((prev) => {
      const updatedPermissions = prev.includes(permissionName)
        ? prev.filter((p) => p !== permissionName)
        : [...prev, permissionName];

      return updatedPermissions;
    });
  };

  const isPermissionChecked = (permissionName) => {
    return selectedPermission.includes(permissionName);
  };

  const handleCreateNewRole = () => {
    const permissionForm = {
      role_name: roleNameValue,
      role_type: "teacher",
      permissions: selectedPermission
    };
    httprequest.post(ROLE_ENDPOINT, { ...permissionForm })
      .then((res) => {
        toast.success(res?.data?.message);
        setRoleNameValue("");
        setRoleType("");
        setSelectedPermission([]);
        setTimeout(() => {
          navigate("/settings");
        }, 1500);
      }).catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.message);
      });
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container" style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <BreadCrumb
                link_one="/settings"
                link_one_name="Settings"
                active="Roles and Permissions"
                description={
                  <>
                    <div>Manage Roles and Permissions in your organisation.</div>
                  </>}
              />
            </div>
            {/* <Button onClick={addRole} variant="primary" buttonSize="fluid" color="btndefault" buttonName="Update Role" /> */}
          </div>
          <div className={`${styles.allcontent}`}>
            <div style={{ marginBottom: "1rem", background: "white", padding: "0.6rem 1rem", borderRadius: "8px" }}>
              <Grid className="input-padding" container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <InputGroup
                    label="Role Name"
                    margin="normal"
                    placeHolder="Add the role name"
                    inputType="text"
                    onChange={handleRoleNameChange}
                    value={roleNameValue}
                  />
                </Grid>
              </Grid>
              <Grid className="input-padding margin-bottom" container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <SelectGroup label="Roles" children={
                    <select onChange={handleRoleChange}>
                      <option value="">--- Select ---</option>
                      {roles?.map((label) => {
                        return (
                          <option key={label?.role_name} value={label?.role_id}>{label?.role_name}</option>
                        );
                      })}
                    </select>
                  }
                  />
                </Grid>
              </Grid>
            </div>
            {isLoading ? (<PageLoader />) : (
              <div className="text-align" style={{ background: "white", padding: "1rem 0", borderRadius: "8px" }}>
                {allPermissions?.map((sections, i) => {
                  return (
                    <div className={`${styles.optioninput} margin-bottom`} key={i}>
                      <h4 className={`${styles.people}`}>{sections?.section}</h4>
                      <Grid container spacing={2}>
                        {sections?.permissions?.map((list) => {
                          return (
                            <Grid item lg={3} md={6} sm={6} xs={12} key={list?.permission_name}>
                              <div className={`${styles.picks} flex align-items-center`}>
                                <input type="checkbox" id={list?.permission_name} onChange={() => handleCheckChange(list?.permission_name)} checked={isPermissionChecked(list?.permission_name)} />
                                <p>{list?.permission_description}</p>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  );
                })}
                <div style={{ margin: "1rem" }} className="flex gap-10">
                  <Button variant="primary" buttonSize="fluid" color="btndefault" buttonName="Create new role" onClick={handleCreateNewRole} />
                </div>
                <ToastContainer />
              </div>
            )}
          </div>
        </>}
    />
  );
};

export default RolesandPermission;
