import { createContext, useReducer, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { compactDecrypt } from "jose";
import axios from "axios";
import Logo from "../assets/images/logo.svg";

const UserStateContext = createContext();
const UserDispatchContext = createContext();

const userReducer = (state, action) => {
  switch (action.type) {
  case "LOGIN_SUCCESS":
    return { ...state, isAuthenticated: true };
  case "SIGN_OUT_SUCCESS":
    return { ...state, isAuthenticated: false, profile: {}, userRole: {} };
  case "FETCH_PROFILE":
    return { ...state, isAuthenticated: true, profile: action.payload };
  case "GET_USER_ROLE":
    return { ...state, isAuthenticated: true, userRole: action.payload };
  case "GET_APP_LOGO":
    return { ...state, appLogo: action.payload };
  case "GET_APP_COLOR":
    return { ...state, appColor: action.payload };
  default: {
    throw new Error(`Unhandled action type: ${action.type}`);
  }
  }
};

const UserProvider = ({ children }) => {
  const token = localStorage.getItem("shelf21-auth");
  const brand_color = localStorage.getItem("brand_color");
  const brand_logo = localStorage.getItem("brand_logo");
  const initialState = {
    isAuthenticated: !!localStorage.getItem("shelf21-auth"),
    userRole: {},
    profile: "",
    appColor: brand_color || "#0065FF",
    appLogo: brand_logo || Logo
  };
  // const decoded = token ? decryptToken() : {};
  const [state, dispatch] = useReducer(userReducer, initialState);

  async function pemToCryptoKey(pem) {
    const binaryDer = atob(pem.replace(/(-----(BEGIN|END) PRIVATE KEY-----|\n)/g, ""));
    const binaryDerArray = Uint8Array.from(binaryDer, char => char.charCodeAt(0));

    return crypto.subtle.importKey(
      "pkcs8",
      binaryDerArray,
      {
        name: "RSA-OAEP",
        hash: "SHA-256"
      },
      true,
      ["decrypt"]
    );
  }

  const getUserRole = async () => {
    const privateKey = await pemToCryptoKey(process.env.REACT_APP_JWT_PRIVATE_KEY);
    const encryptedToken = token;
    try {
      const { plaintext } = await compactDecrypt(encryptedToken, privateKey);
      const decodedPayload = new TextDecoder().decode(plaintext);

      dispatch({ type: "GET_USER_ROLE", payload: JSON.parse(decodedPayload) });

      // return JSON.parse(decodedPayload);
    // eslint-disable-next-line no-empty
    } catch (error) { }
  };

  const fetchUserProfile = () => {
    // --| Get User profile and pass it to context
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/v1/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        dispatch({ type: "FETCH_PROFILE", payload: res?.data?.data });
        dispatch({ type: "GET_APP_COLOR", payload: res?.data?.data?.branding?.brand_color });
        dispatch({ type: "GET_APP_LOGO", payload: res?.data?.data?.branding?.brand_logo?.file_url });
        if (res?.data?.data?.branding?.brand_color) {
          localStorage.setItem("brand_color", res?.data?.data?.branding?.brand_color);
        } else {
          localStorage.setItem("brand_color", "#0065FF");
        }
        if (res?.data?.data?.branding?.brand_logo?.file_url) {
          localStorage.setItem("brand_logo", res?.data?.data?.branding?.brand_logo?.file_url);
        } else {
          localStorage.setItem("brand_logo", null);
        }
      })
      .catch(() => {

      });
  };

  useEffect(() => {
    fetchUserProfile();
    getUserRole();
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

const useUserState = () => {
  const context = useContext(UserStateContext);
  // eslint-disable-next-line no-undefined
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }

  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  // eslint-disable-next-line no-undefined
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }

  return context;
};

const handleLogin = async (
  dispatch,
  loginForm,
  navigate,
  setIsLoading,
  showValidationMessage,
  loginValidator
) => {
  if (loginValidator.allValid()) {
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v1/login`, loginForm)
      .then(async (res) => {
        setIsLoading(false);
        localStorage.setItem("shelf21-auth", res?.data?.token);
        toast.success("Successfully Logged in");
        dispatch({ type: "LOGIN_SUCCESS" });
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err?.response?.data?.message);
      });
  } else {
    showValidationMessage(true);
  }
};

const logOut = (dispatch, navigate) => {
  localStorage.removeItem("shelf21-auth");
  setTimeout(() => {
    dispatch({ type: "SIGN_OUT_SUCCESS" });
  }, 1000);
  navigate("/auth/login");
};

export { UserProvider, useUserState, useUserDispatch, handleLogin, logOut };
