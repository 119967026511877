/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Icon } from "@iconify/react";
import InputGroup from "../../components/FormComponent/InputGroup";
import ButtonCount from "../../components/ButtonCount";
import Table from "../../components/Table/Table";
import Modal from "../../components/Modal";
import { Gender } from "../../utils/DropDown";
import SelectGroup from "../../components/FormComponent/SelectGroup";
import { TableSearch } from "../../components/Table/TableActions";
import { useNavigate } from "react-router-dom";
import Badge from "../../components/Badge";
import { httprequest } from "../../data/api";
import { ToastContainer, toast } from "react-toastify";
import PageLoader from "../../components/PageLoader";
import Select from "react-select";
import useValidator from "../../hooks/useValidator";
import ReactQuill from "react-quill";
import { useUpload } from "../../hooks/useUpload";

const GET_GUARDIAN_ENDPOINT = "/api/v1/guardians";
const CREATE_GUARDIAN_ENDPOINT = "/api/v1/guardian";
const STUDENTS_ENDPOINT = "/api/v1/students";

const Guardian = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentRow, setCurrentRow] = useState({});
  const [broadcastModal, setBroadcastModal] = useState(false);
  const [createguardian, setCreateGuardian] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isPageFetching, setIsPageFetching] = useState(false);
  const [guardianData, setGuardianData] = useState([]);
  const [multipleStudents, setMultipleStudents] = useState([]);
  const [guardianValidator, showCreateValidationMessage] = useValidator();
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [mailingSelect, setMailingSelect] = useState([]);
  const [selectedGuardians, setSelectedGuardians] = useState([]);
  const [guardianArr, setGuardianArr] = useState([{
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    contact: "",
    address: "",
    type: "primary"
  }]);
  const [guardianForm, setGuardianForm] = useState({
    wards: multipleStudents, guardians: guardianArr
  });
  const [messageContent, setMessageContent] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [selectedMailProvider, setSelectedMailProvider] = useState(null);
  const [subjects, setSubject] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [senderPrefix, setSenderPrefix] = useState("");
  const [senderDomain, setSenderDomain] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDeleteModal = (row) => {
    setCurrentRow(row);
    setDeleteModal(!deleteModal);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleBroadcastModal = () => {
    setBroadcastModal(!broadcastModal);
  };

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    const allGuardian = [...guardianArr];
    allGuardian[index][name] = value;
    setGuardianArr(allGuardian);
    setGuardianForm({ ...guardianForm, guardians: allGuardian });
  };

  const handleIsType = (e, index) => {
    const { name, checked } = e.target;
    const allGuardian = [...guardianArr];
    const value = checked && "primary";

    // loop through all the array and set types to secondary
    allGuardian[index][name] = value;
    const updatedGuardian = allGuardian.map((obj, ind) => {
      if (ind !== index) {
        return { ...obj, type: "secondary" };
      }

      return obj;
    });

    setGuardianForm({ ...guardianForm, guardians: updatedGuardian });
  };

  const AddAdditionalGuardian = () => {
    const newGuardian = {
      first_name: "",
      last_name: "",
      email: "",
      gender: "",
      contact: "",
      address: "",
      type: "secondary"
    };
    setGuardianArr([...guardianArr, newGuardian]);
    setGuardianForm({ ...guardianForm, guardians: [...guardianArr, newGuardian] });
  };

  const handleDeleteGuardianItem = (e, index) => {
    e.preventDefault();
    const newGuardian = guardianArr.filter((item) => {
      return index !== guardianArr.indexOf(item);
    });

    setGuardianArr(newGuardian);
    setGuardianForm({ ...guardianForm, guardians: newGuardian });
  };

  const handleMultipleWardChange = (event) => {
    const selectedValue = event.map((item) => item.value);

    setGuardianForm({ ...guardianForm, wards: selectedValue });
  };

  const handleCreateGuardianModal = () => {
    setCreateGuardian(!createguardian);
  };

  const handleCloseGuardianModal = () => {
    setCreateGuardian(false);

    // --| reloading the page to clear the fields
    // window.location.reload();
  };

  const getAllGuardian = () => {
    setIsPageFetching(true);
    httprequest.get(GET_GUARDIAN_ENDPOINT).then((res) => {
      setIsPageFetching(false);
      setGuardianData(res?.data?.data);
      setSearchResult(res?.data?.data);
    }).catch((err) => {
      setIsPageFetching(false);
      toast.error(err?.response?.data);
    });
  };

  const getAllStudents = () => {
    httprequest.get(STUDENTS_ENDPOINT).then((res) => {
      setMultipleStudents(res?.data?.data?.map((student) => {
        return {
          label: student?.first_name + " " + student?.last_name,
          value: student.student_id
        };
      }));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const handleCreateGuardian = () => {
    if (guardianValidator.allValid()) {
      setIsCreating(true);
      httprequest.post(CREATE_GUARDIAN_ENDPOINT, guardianForm).then(() => {
        setIsCreating(false);
        toast.success("Guardian Created and Invite sent successfully");
        getAllGuardian();
        handleCloseGuardianModal();
      }).catch((err) => {
        setIsCreating(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      showCreateValidationMessage(true);
    }
  };

  const handleDeleteGuardian = () => {
    setIsDeleting(true);
    httprequest.patch(`${CREATE_GUARDIAN_ENDPOINT}/delete/${currentRow?.guardian_id}`).then((res) => {
      setIsDeleting(false);
      toast.success(res?.data?.message);
      handleCloseDeleteModal();
      getAllGuardian();
    }).catch((err) => {
      setIsDeleting(false);
      toast.error(err?.response?.data?.message);
    });
  };
  const mallingOption = () => {
    httprequest
      .get("/api/v1/integrations/school?category=mail")
      .then((res) => {
        setMailingSelect(res?.data?.data || []);
      })
      .catch(() => {
      });
  };

  // Convert guardian emails into options for the Select component
  const guardianEmailOptions = guardianData?.map((guardian) => ({
    label: `${guardian.first_name} ${guardian.last_name} (${guardian.email})`,
    value: guardian.email,
    name: `${guardian.first_name} ${guardian.last_name}`
  }));

  const handleSelectChange = (selectedOption) => {
    setSelectedGuardians(selectedOption || []);
  };
  useEffect(() => {
    mallingOption();
    getAllGuardian();
    getAllStudents();
  }, []);

  const columns = [
    {
      name: "Guardian Name",
      selector: (row) => row?.first_name + " " + row?.last_name
    },
    {
      name: "Email(s)",
      selector: (row) => row?.email
    },
    {
      name: "Number of wards",
      selector: (row) => row?.no_of_wards
    },
    {
      name: "Contact",
      selector: (row) => row?.contact
    },
    {
      name: "Type",
      center: "true",
      cell: (row) => (<Badge status={row?.type} />)
    },
    {
      name: "Status",
      center: "true",
      cell: (row) => (<Badge status={row?.is_active} />)
    },
    {
      name: "",
      center: "true",
      sortable: false,
      cell: (row) => (
        <div key={row?.id} className="table-action-container">
          <button
            // onClick={() => {
            //   handleAddscoreModal(row);
            // }}
            className="table-action-icon"
          >
            <Icon icon="ic:outline-folder" />
          </button>
          <button onClick={() => navigate(`/users/guardians/${row?.guardian_id}`)} className="table-action-icon">
            <Icon icon="codicon:eye" />
          </button>
          <button
            onClick={() => handleDeleteModal(row)}
            className="table-action-icon"
          >
            {row?.status === "active" ? <div className="delete-icon"><Icon icon="mingcute:delete-2-line" /></div>
              : <div className="approve-icon"><Icon icon="mdi:checkbox-marked-circle-outline" /></div>}
          </button>
        </div>
      )
    }
  ];

  // --| Filter Guardian table using name, email and class
  const handleSearchStudent = (e) => {
    const { value } = e.target;

    const valArray = value.split(" ");
    const filteredByStatus = filterStatus
      ? guardianData.filter((data) => data.status.toLowerCase() === filterStatus.toLowerCase())
      : guardianData;
    // --| Filter data by partial match onchange in the search input box
    const result = filteredByStatus?.filter((data) => valArray?.every(
      (word) => data?.first_name
        ?.toLowerCase()
        .includes(word.toLowerCase()) ||
                          data?.last_name
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                          data?.email
                            ?.toLowerCase()
                            .includes(word.toLowerCase()) ||
                            data?.status
                              ?.toLowerCase()
                              .includes(word.toLowerCase())
    )
    );
    setSearchResult(result);
  };
  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterStatus(value);

    // Filter guardians based on status when the filter is applied
    const filteredByStatus = value
      ? guardianData.filter((data) => data.status.toLowerCase() === value.toLowerCase())
      : guardianData;

    setSearchResult(filteredByStatus);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  const handleMailProviderChange = (selectedProvider) => {
    setSelectedMailProvider(selectedProvider);

    const selectedDomain = mailingSelect.find((option) => option.id === selectedProvider.value)?.domain;

    setSenderDomain(selectedDomain || "");
  };

  const fullSenderEmail = `${senderPrefix}${senderDomain}`;
  const handleQuillChange = (content) => {
    setMessageContent(content);
  };

  const handleSendMessage = async () => {
    if (!selectedGuardians?.length || !selectedMailProvider) {
      toast.error("Please select guardians and a mailing provider.");

      return;
    }
    if (!messageContent.trim()) {
      toast.error("Please enter a message.");

      return;
    }
    const messageForm = {
      to: selectedGuardians.map((guardian) => ({
        email: guardian.value,
        name: guardianEmailOptions.find(option => option.value === guardian.value)?.name // Find the corresponding name
      })),
      integration_id: selectedMailProvider.value,
      from_email: fullSenderEmail,
      from_name: senderPrefix,
      body: messageContent,
      subject: subjects,
      attachment: []
    };

    if (!messageForm.to.length || !messageForm.integration_id || !messageForm.from_email || !messageForm.body || !messageForm.subject) {
      toast.error("Please fill in all required fields.");

      return;
    }

    setIsSendingMessage(true);

    try {
      if (selectedFile) {
        const response = await useUpload(selectedFile);
        if (response.status === 200 && response.data.data) {
          const file_url = response.data.data?.[0]?.file_url;
          messageForm.attachment.push(file_url);
        } else {
          throw new Error("File upload failed.");
        }
      }
      // Send the message
      const result = await httprequest.post("api/v1/message", messageForm, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      toast.success(result?.data?.message);
      handleBroadcastModal();
      setSelectedGuardians([]);
      setSelectedMailProvider(null);
      setSenderPrefix("");
      setMessageContent("");
      setSubject("");
      setSelectedFile(null);
      setIsSendingMessage(false);
    } catch (err) {
      setIsSendingMessage(false);
      toast.error(err?.response?.data?.message || "Failed to send message.");
    }
  };

  const handleEditorDrop = (event) => {
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      // Prevent drop if it's a file (e.g., image)
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      // Check if any pasted item is an image
      if (items[i].type.indexOf("image") !== -1) {
        event.preventDefault(); // Prevent the paste

        return;
      }
    }
  };

  // --| Quill text format is defined explicitly so as to avoid images and videos from being pasted
  const Quillformats = ["background", "bold", "color", "font", "code", "italic", "link", "size",
    "strike", "script", "underline", "blockquote", "header", "indent", "list", "align", "direction", "code-block", "formula"
    // 'image'
    // 'video'
  ];
  // eslint-disable-next-line no-console
  console.log(guardianData, "guardianData");

  return (
    <Layout
      children={
        <>
          <PageTitle
            title="Guardians"
            subtitle="View all the guardians in your school"
            button={
              <div className="flex gap-10">
                <Button
                  variant="primaryoutline"
                  buttonSize="fluid"
                  color="btnfontprimary"
                  onClick={handleBroadcastModal}
                  buttonName="Send Message to parents"
                />
                <Button
                  variant="primary"
                  buttonSize="small"
                  color="btndefault"
                  onClick={handleCreateGuardianModal}
                  buttonName="Create Guardian"
                />
              </div>
            }
          />
          <div className={classes.pageContent}>
            {isPageFetching ? (
              <PageLoader />
            ) : (
              <>
                <div className="button-count-container">
                  <ButtonCount func={() => navigate("")} title="Guardian Count" count={guardianData?.length} />
                </div>
                <Table
                  data={searchResult}
                  columns={columns}
                  subHeader={true}
                  pagination
                  subHeaderComponent={
                    <div className="action_div">
                      <TableSearch
                        placeholder="Search here..."
                        searchTableFunc={handleSearchStudent}
                      />
                      <div className="text-center gap-10 cursor-pointer">
                        {isPageFetching ? <Icon icon="svg-spinners:270-ring-with-bg" width="20" height="20" style={{ color: "#858585" }} />
                          : <Icon onClick={getAllGuardian} icon="hugeicons:reload" width="20" height="20" style={{ color: "#858585" }} />}
                        <div>
                          <SelectGroup
                            children={
                              <select name="type" onChange={handleFilterChange}>
                                <option value="">-- Sort By--</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </>
            )}
          </div>
          {/* Send Broadcast Modal */}
          <Modal
            title="Send Message to parents."
            subtitle={
              <div>Send a message to the parents of the students in this class
              </div>}
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p className="input-picker-title">
                      Parents to Message<span className="input-required">*</span>
                      </p>
                      <Select
                        isMulti
                        value={selectedGuardians}
                        onChange={handleSelectChange}
                        options={guardianEmailOptions}
                        placeholder="Select Parents"
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    </div>

                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p className="input-picker-title">
                            Select Mailing Provider<span className="input-required">*</span>
                      </p>

                      <Select
                        name="mailing_option"
                        value={selectedMailProvider}
                        onChange={handleMailProviderChange}
                        options={mailingSelect.map((option) => ({
                          label: option.provider,
                          value: option.id
                        }))}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputwrapper}>
                      <label style={{ display: "flex",
                        flexDirection: "row" }} htmlFor="senderPrefix"
                      >
                        <p style={{
                          color: "#101928",
                          marginBottom: "0px",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "5px"
                        }}
                        >Sender
                        </p>
                        <span style={{ marginTop: "3px", fontWeight: "500" }} className="input-required">*</span>
                      </label>
                      <div className="gaurdian_input">
                        <input
                          type="text"
                          id="senderPrefix"
                          name="senderPrefix"
                          value={senderPrefix}
                          onChange={(e) => setSenderPrefix(e.target.value.replace(/\s/g, ""))}
                          required
                          placeholder="Sent From"
                        />
                        <input
                          style={{ textAlign: "right" }}
                          value={`${senderDomain}`}
                          disabled
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Enter Mail Subject"
                      inputType="text"
                      name="subject"
                      value={subjects}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 500, fontSize: "14px" }} className="text-align-left no-margin-block">
                      Message
                      <span className="input-required">*</span>
                    </p>
                    <ReactQuill
                      theme="snow"
                      value={messageContent}
                      onDrop={handleEditorDrop}
                      onChange={handleQuillChange}
                      onPaste={handlePaste}
                      formats={Quillformats}
                      modules={{
                        toolbar: [
                          [{ "header": [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          [{ "list": "ordered" }, { "list": "bullet" }],
                          ["clean"] // Remove the image button
                        ]
                      }}
                      style={{
                        borderRadius: 30,
                        textAlign: "left",
                        "& qlContainer": { height: "150px" }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information<span className="input-required">*</span></label>
                    <div className={classes.pickercontainer}>
                      {selectedFile ? (
                        <><img style={{ width: "100%", height: "inherit" }} src={imagePreview} />
                          <div
                            className={classes.uploadbuttons}
                            onClick={() => handleRemoveImage()}
                          >
                            <Icon icon="zondicons:minus-solid" /> Remove
                          </div>
                        </>

                      ) : (
                        <>
                          <input type="file" className={classes.picker} onChange={handleFileChange} />

                          <div className={classes.uploadflex}>
                            <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                            <div>
                              <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitle}>SVG, PNG, JPG, GIF | 10MB max.</h6>
                            </div>
                          </div>

                          <div className={classes.rightpicker}>
                            <div className={classes.uploadbutton}>Upload</div>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button onClick={handleBroadcastModal} variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                  <Button variant="primary" buttonSize="full" color="btndefault" disabled={isSendingMessage}
                    onClick={handleSendMessage}
                    buttonName={isSendingMessage ? "Sending..." : "Send Message"}
                  />
                </div>
              </>
            }
            isopen={broadcastModal}
            closeModal={handleBroadcastModal}
          />
          {/* Create a Guardian Modal */}
          <Modal
            title="Create Guardian"
            subtitle={
              <div>Create Guardian
              </div>}
            modalContent={
              <>
                <div>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <div className="multiple-select">
                      <p>Wards / Children<span className="input-required">*</span> </p>
                      <Select isMulti name="wards"
                        closeMenuOnSelect={true} options={multipleStudents} onChange={handleMultipleWardChange}
                      />
                    </div>
                    {guardianValidator.message(
                      "wards",
                      guardianForm.wards,
                      "required"
                    )}
                  </Grid>
                  {guardianArr?.map((item, i) => {
                    return (
                      <Grid container spacing={2} className="input-padding" key={i}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian First Name"
                            margin="normal"
                            inputType="text"
                            required
                            inputName="first_name"
                            value={item.first_name}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "first_name",
                            guardianArr[i]["first_name"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian Last Name"
                            margin="normal"
                            required
                            inputType="text"
                            inputName="last_name"
                            value={item.last_name}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "last_name",
                            guardianArr[i]["last_name"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <SelectGroup required label="Gender" children={
                            <select name="gender" onChange={(e) => handleChange(e, i)}>
                              <option value="">--- Select Gender ---</option>
                              {Gender?.map((label) => {
                                return (
                                  <option key={label.key}>{label.text}</option>
                                );
                              })}
                            </select>
                          }
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Email"
                            margin="normal"
                            inputType="email"
                            inputName="email"
                            required
                            value={item.email}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "email",
                            guardianArr[i]["email"],
                            "required|email"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Contact"
                            margin="normal"
                            required
                            placeHolder="Enter contact info"
                            inputType="tel"
                            inputName="contact"
                            value={item.contact}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                          {guardianValidator.message(
                            "contact",
                            guardianArr[i]["contact"],
                            "required"
                          )}
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <InputGroup
                            label="Guardian Address"
                            margin="normal"
                            required
                            placeHolder="Enter Address"
                            inputType="address"
                            inputName="address"
                            value={item.address}
                            onChange={(e) => {
                              handleChange(e, i);
                            }}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <>
                            <input type="radio" name="type" defaultChecked={item?.type === "primary"} id="type" onChange={(e) => {
                              handleIsType(e, i);
                            }}
                            />
                            <label className="checkbox-label" htmlFor="current_term">Primary Guardian</label>
                          </>
                          {guardianValidator.message(
                            "Type",
                            guardianArr[i]["type"],
                            "required"
                          )}
                        </Grid>
                        {guardianArr?.length > 1 && (
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <div className={`${classes.remove} flex align-items-center`} onClick={(e) => handleDeleteGuardianItem(e, i)}>
                              <Icon icon="zondicons:minus-solid" width="16" height="16" />
                              <p>Remove this Guardian</p>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                  {guardianArr?.length < 2 && (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={`${classes.addextra} flex align-items-center`} onClick={AddAdditionalGuardian}>
                        <Icon icon="octicon:feed-plus-16" width="16" height="16" />
                        <p>Add Secondary Guardian</p>
                      </div>
                    </Grid>
                  )}
                </div>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Create Guardian" onClick={handleCreateGuardian} isLoading={isCreating} />
                </div>
              </>
            }
            isopen={createguardian}
            closeModal={handleCloseGuardianModal}
          />
          {/* Delete Modal */}
          <Modal
            title={currentRow?.status === "active" ? "Delete Guardian Information" : "Re-activate Guardian information"}
            subtitle={
              currentRow?.status === "active"
                ? (
                  <div>
                    Are you sure you want to delete
                    <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                    Deleting is a permanent action
                  </div>
                )
                : (
                  <div>
                    Are you sure you want to re-activate
                    <span className="modal-name-highlight"> {`${currentRow?.last_name} ${currentRow?.first_name}`}</span>?
                    If re-activated, this Guardian will have access to this platform.
                  </div>
                )
            }
            modalContent={
              <>

              </>
            }
            modalFooter={
              <>
                {currentRow?.status === "active" ? <Button variant="danger" isLoading={isDeleting} onClick={handleDeleteGuardian} buttonName="Delete Guardian" buttonSize="full" color="btndefault" />
                  : <Button variant="primary" buttonName="Re-activate Guardian" buttonSize="full" color="btndefault" />}
              </>
            }
            isopen={deleteModal}
            closeModal={handleCloseDeleteModal}
          />
          <ToastContainer />
        </>
      }
    />
  );
};

export default Guardian;
