import PropTypes from "prop-types";
import useStyles from "../styles";
import { Link } from "react-router-dom";
import Badge from "../../../components/Badge";
import ReactPlayer from "react-player";
import { Icon } from "@iconify/react";

const LibraryItemCard = ({ libLink, libTitle, libDesc, status, link, editClick, deleteClick }) => {
  const classes = useStyles();

  // eslint-disable-next-line no-console
  console.log(link);

  return (
    <div className={`${classes.libcardcont}`}>
      <Link to={`${link}`} className={classes.liblink}>
        <div className={`${classes.imageheader}`}><ReactPlayer width="100%" height="100%" controls={true} url={libLink} style={{ borderRadius: "12px 12px 0px 0px" }} /></div>
      </Link>
      <div className={`${classes.libcard}`}>
        <h6>{libTitle}</h6>
        <div className={classes.videodesccont}>
          <div className={classes.videodesc}>
            <p>{libDesc}</p>
          </div>
        </div>
        <div className={`${classes.loweritems}`}>
          <div className="flex justify-items-space">
            <Badge status={status} />
            <div className={`${classes.iconfooter}`}>
              <div className="table-action-container">
                {/* <button className="table-action-icon" onClick={viewClick}>
                  <Icon icon="fluent:eye-16-regular" />
                </button> */}
                <button className="table-action-icon">
                  <Icon icon="heroicons:pencil-square-solid" onClick={editClick} />
                </button>
                <button
                  className="table-action-icon delete-icon"
                  onClick={deleteClick}
                >
                  <Icon icon="mingcute:delete-2-line" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
LibraryItemCard.PropTypes = {
  libLink: PropTypes.any,
  libTitle: PropTypes.string,
  libDesc: PropTypes.string,
  status: PropTypes.string
};

export default LibraryItemCard;
