/* eslint-disable prefer-template */
import Layout from "../../../components/Layout";
import useStyles from "../styles";
import Button from "../../../components/Button";
// import PageTitle from "../../../components/PageTitle";
import BreadCrumb from "../../../components/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import styles from "./student.module.css";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "../../../components/Modal";
import { Grid } from "@mui/material";
import TextAreaGroup from "../../../components/FormComponent/TextAreaGroup";
import InputGroup from "../../../components/FormComponent/InputGroup";
import AcademicInfo from "../components/AcademicInfo";
import PersonalInfo from "../components/PersonalInfo";
import { httprequest } from "../../../data/api";
import { ToastContainer, toast } from "react-toastify";
import PageLoader from "../../../components/PageLoader";

const STUDENT_ENDPOINT = "/api/v1/students";
const GUARDIAN_ENDPOINT = "/api/v1/guardians";

const StudentInfo = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [addreportModal, setReportModal] = useState(false);
  const [value, setValue] = useState("1");
  const [data, setData] = useState({});
  const [allGuardian, setAllGuardian] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMessageModal = () => {
    setMessageModal(!messageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  const getStudent = () => {
    setIsPageLoading(true);
    httprequest.get(`${STUDENT_ENDPOINT}/${id}`)
      .then((res) => {
        setIsPageLoading(false);
        setData(res?.data?.data);
      }).catch((err) => {
        setIsPageLoading(false);
        toast.error(err?.response?.data);
      });
  };
  // eslint-disable-next-line no-console
  console.log(data, "data");

  const getAllGuardian = () => {
    httprequest.get(GUARDIAN_ENDPOINT).then((res) => {
      setAllGuardian(res?.data?.data);
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  // eslint-disable-next-line no-console
  console.log(allGuardian, "allGuardian");

  useEffect(() => {
    getAllGuardian();
  }, []);

  useEffect(() => {
    getStudent();
  }, []);

  return (
    <Layout
      children={
        <>
          {isPageLoading ? (<PageLoader />) :
            <>
              <div className="breadcrumb-container">
                <div>
                  <BreadCrumb
                    link_one="/users/students"
                    link_one_name="All Students"
                    active={`${data?.first_name + " " + data?.last_name}`}
                    description={
                      <div className={`${styles.footer} flex align-items-center gap-5`}>
                        <p onClick={() => navigate("/class-activities/classes/createclass")} className={`${styles.color}`}>{data?.class?.name}</p>
                        <p>|</p>
                        <p>{data?.gender} </p>
                      </div>
                    }
                  />
                  <div className="flex gap-10">
                    <Button
                      variant="primaryoutline"
                      buttonSize="fluid"
                      color="btnfontprimary"
                      onClick={handleMessageModal}
                      buttonName="Send a message to parents"
                    />
                    <Button
                      variant="primary"
                      buttonSize="fluid"
                      color="btndefault"
                      onClick={handleReportModal}
                      buttonName="Send report to parent"
                    />
                  </div>
                </div>
              </div>
              <div className={classes.pageContent}>
                <TabContext value={value}>
                  <div className="tablist-container">
                    <Tabs indicatorColor="none" className="overide-tablist" onChange={handleChange} aria-label="">
                      <Tab label="Personal Information" value="1" />
                      <Tab label="Academic Information" value="2" />
                      {/* <Tab label="Documents" value="3" /> */}
                    </Tabs>
                  </div>
                  {/* <TabPanel value="3" className="tabpanel-overide">
                    <AcademicInfo />
                  </TabPanel> */}
                  <TabPanel value="1" className="tabpanel-overide"><PersonalInfo data={data} allGuardian={allGuardian} /></TabPanel>
                  <TabPanel value="2" className="tabpanel-overide"><AcademicInfo /></TabPanel>
                </TabContext>
              </div>
              {/* Send Report Modal */}
              <Modal
                title="Send Student Report"
                subtitle={
                  <div>You are about to share the computed result sheets to the parent/guardian of Ojo William.
                  </div>}
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup
                          label="Parent/Guardian Email"
                          margin="normal"
                          placeHolder="Enter guardian mail"
                          inputType="text"
                        />
                      </Grid>
                      <div className={`${classes.mail} flex align-items-center`}>
                        <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                        <p>Use an alternative email</p>
                      </div>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <div className={classes.modalFooterBtn}>
                      <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                      <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Send Report" />
                    </div>
                  </>
                }
                isopen={addreportModal}
                closeModal={handleReportModal}
              />
              {/* Send Broadcast Modal */}
              <Modal
                title="Message Parent/Guardian"
                subtitle={
                  <div>Send a message to the parents of the students in this class
                  </div>}
                modalContent={
                  <>
                    <Grid container spacing={2} className="input-padding">
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup
                          label="Parent Name"
                          margin="normal"
                          placeHolder="Parent Name"
                          inputType="text"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputGroup
                          label="Parent/Guardian Email"
                          margin="normal"
                          placeHolder="Parent Email"
                          inputType="email"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextAreaGroup
                          label="Broadcast Message"
                          margin="normal"
                          placeholder="Enter the message you will like to share with the parents."
                        />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <label className={`${classes.label}`}>Additional Information</label>
                        <div className={classes.pickercontainer}>
                          <input type="file" className={classes.picker} />

                          <div className={classes.uploadflex}>
                            <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                            <div className={classes.uploadcont}>
                              <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitle}>SVG,PNG,JPG,GIF| 10MB max.</h6>
                            </div>
                          </div>
                          <div className={classes.rightpicker}>
                            <div className={classes.uploadbutton}>Upload</div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                }
                modalFooter={
                  <>
                    <div className={classes.modalFooterBtn}>
                      <Button variant="primaryoutline" buttonSize="full" color="btnfontprimary" buttonName="Cancel" />
                      <Button variant="primary" buttonSize="full" color="btndefault" buttonName="Broadcast" />
                    </div>
                  </>
                }
                isopen={messageModal}
                closeModal={handleMessageModal}
              />
              <ToastContainer />
            </>
          }
        </>
      }
    />
  );
};

export default StudentInfo;
