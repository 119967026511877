import BreadCrumb from "../../../components/BreadCrumb";
import Layout from "../../../components/Layout";
import InputGroup from "../../../components/FormComponent/InputGroup";
import useStyles from "./styles";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import ErrorImg from "../../../assets/images/noaccesscode.svg";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Badge from "../../../components/Badge";
import { Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import PageLoader from "../../../components/PageLoader";
import { useState } from "react";
import { httprequest } from "../../../data/api";
import useValidator from "../../../hooks/useValidator";
import ReactPlayer from "react-player";

// Get Access Code Details by Access Code
const ACCESS_CODE_DETAILS_ENDPOINT = "/api/v1/access_code";
const CLOCK_ACCESS_CODE_ENDPOINT = "/api/v1";

const ValidateCode = () => {
  const classes = useStyles();
  const [isAccessFetching, setIsAccessFetching] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const [accessDetails, setAccessDetails] = useState({});
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [noAccessCodeModal, setNoAccessCodeModal] = useState(false);
  const [codeValidator, showCodeValidator] = useValidator();
  const [isLogging, setIsLogging] = useState(false);
  const [helpModal, setHelpModal] = useState(false);

  const handleHelpModal = () => {
    setHelpModal(!helpModal);
  };

  const handleCloseVideoModal = () => {
    setHelpModal(false);
  };

  const handleInputChange = (e) => {
    setInputCode(e.target.value);
  };

  const handleCloseNoAccessCodeMOdal = () => {
    setNoAccessCodeModal(false);
  };

  const handleCheckAccessCode = async () => {
    if (codeValidator.allValid()) {
      try {
        const res = await httprequest.get(`${ACCESS_CODE_DETAILS_ENDPOINT}/${inputCode}`);
        setIsAccessFetching(true);
        if (res.status === 200) {
          setAccessDetails(res?.data?.data);
          setSuccessfulValidation(true);
          setIsAccessFetching(false);
        } else {
          toast.error(res?.data?.message);
          setIsAccessFetching(false);
        }
      } catch (err) {
        toast.error(err?.response?.data?.message);
        setIsAccessFetching(false);
      }
    } else {
      showCodeValidator(true);
    }
  };
  const handleLogAccess = (param) => {
    setIsLogging(true);
    if (accessDetails?.id) {
      httprequest.patch(`${CLOCK_ACCESS_CODE_ENDPOINT}/${param}/${accessDetails?.id}`).then((res) => {
        setIsLogging(false);
        handleCheckAccessCode();
        toast.success(res?.data?.message);
      }).catch((err) => {
        setIsLogging(false);
        toast.error(err?.response?.data?.message);
      });
    } else {
      setIsLogging(false);
      toast.error("Access Code Not Valid");
    }
  };

  const handleClearReset = () => {
    setAccessDetails({});
    setSuccessfulValidation(false);
    setInputCode("");
  };

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/accesscontrol/codes"
                link_one_name="Access Codes"
                active="Validate access code"
                description="Validate/Check in/Check out an access code"
              />
            </div>
            <Button
              variant="primaryoutline"
              buttonSize="fluid"
              color="btnfontprimary"
              buttonName="?"
              onClick={handleHelpModal}
            />
          </div>
          <div className="page-content">
            <div className={classes.forcodeinput}>
              <InputGroup
                inputType="text"
                value={inputCode}
                label="Input Code Here"
                inputMax="8"
                onChange={handleInputChange}
              />
              {codeValidator.message("Code", inputCode, "required")}
              <div className={`${classes.centerbackbutton} gap-10`}>
                <Button
                  variant="primary"
                  color="btndefault"
                  buttonSize="fluid"
                  buttonName="Validate a code"
                  onClick={handleCheckAccessCode}
                />
                <Button
                  variant="warning"
                  color="default"
                  buttonSize="fluid"
                  buttonName="Reset Field"
                  onClick={handleClearReset}
                />
              </div>
            </div>
            {isAccessFetching ? (
              <PageLoader />
            ) : (
              successfulValidation && (
                <div className="padding-top-56">
                  <Grid container spacing={4}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <div className={`${classes.border} left-container`}>
                        <h4>Visitor's Info</h4>
                        <div className={classes.profilecontainer}>
                          <div className={classes.imageviewer} style={{ backgroundImage: `url(${accessDetails?.display_pic?.file_url})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                            {/* <img src={accessDetails?.display_pic?.file_url} alt="" /> */}
                            <div className={classes.pickercontainer}>
                              <input
                                type="file"
                                name="profile_picture"
                                className={classes.profilepicker}
                                // onChange={handleProfileChange}
                              />
                              <CameraAltIcon className={classes.camera} />
                            </div>
                          </div>
                        </div>
                        {/*

                        check if status is active, if not active, show disabled button
                        if action is null, show button as clock in
                        if action is clock in, show button as clock out
                        if action is clock out and type is permanent, show button as clock in
                        if action is clock out and type is one-time, show button as disabled
                        */}
                        {(accessDetails?.status !== "used" && accessDetails?.status !== "revoked") ? (
                          <>
                            {accessDetails?.action === null ?
                              <Button variant="primary" color="btndefault" buttonSize="full" buttonName="Clock In" onClick={() => handleLogAccess("clock-in")} isLoading={isLogging} /> :
                              accessDetails?.action === "clock_in" ?
                                <Button variant="danger" color="btndefault" buttonSize="full" buttonName="Clock Out" onClick={() => handleLogAccess("clock-out")} isLoading={isLogging} /> :
                                (accessDetails?.action === "clock_out" && accessDetails?.type === "permanent") ?
                                  <Button variant="primary" color="btndefault" buttonSize="full" buttonName="Clock In" onClick={() => handleLogAccess("clock-in")} isLoading={isLogging} /> :
                                  (accessDetails?.action === "clock_out" && accessDetails?.type === "one-time") ?
                                    <Button variant="secondary" color="btndefault" buttonSize="full" buttonName="Used" /> : null
                            }
                          </>
                        ) : accessDetails?.status === "revoked" ? <Button variant="secondary" color="btnblack" buttonSize="full" buttonName="Revoked" disabled /> : accessDetails?.status === "used" ? <Button variant="secondary" color="btnblack" buttonSize="full" buttonName="Used" disabled /> : <Button variant="secondary" color="btnblack" buttonSize="full" buttonName="Inactive" disabled /> }
                      </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <div className={`${classes.border} right-container`}>
                        <div className={classes.forcode}>
                          <p>{accessDetails?.access_code}</p>
                        </div>
                        <div className={classes.accesscredentials}>
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Visitor's Name:</p>
                            <p className={classes.accessvalue}>{accessDetails?.visitor_name}</p>
                          </div>
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Status:</p>
                            <div className={classes.accessvalue}>
                              <Badge status={accessDetails?.status} />
                            </div>
                          </div>
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Email Address:</p>
                            <p className={classes.accessvalue}>{accessDetails?.email || "No Email Address"}</p>
                          </div>
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Student Name:</p>
                            <p className={classes.accessvalue}>{accessDetails?.student?.name}</p>
                          </div>
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Access Type:</p>
                            <p className={classes.accessvalue}>{accessDetails?.type}</p>
                          </div>
                          {accessDetails?.type === "one-time" && (
                            <>
                              <div className="flex justify-items-space align-items-center">
                                <p className={classes.accessproperty}>Access Start Date:</p>
                                <p className={classes.accessvalue}>{`${accessDetails?.start_date?.substring(0, 10)} Time: ${accessDetails?.start_date?.substring(11, 16)}`}</p>
                              </div>
                              <div className="flex justify-items-space align-items-center">
                                <p className={classes.accessproperty}>Access End Date:</p>
                                <p className={classes.accessvalue}>{`${accessDetails?.end_date?.substring(0, 10)} Time: ${accessDetails?.end_date?.substring(11, 16)}`}</p>
                              </div>
                            </>
                          )}
                          <div className="flex justify-items-space align-items-center">
                            <p className={classes.accessproperty}>Issued By:</p>
                            <p className={classes.accessvalue}>{accessDetails?.issuer?.name}</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )
            )}
            {/* No Access Code MOdal */}
            <Modal
              title="Oops"
              subtitle=""
              modalContent={
                <>
                  <div>
                    <div className={classes.forerrorimg}>
                      <img src={ErrorImg} alt="Error Image" />
                    </div>
                    <div>
                      <p className={classes.nocodeheader}>No Access Code Found</p>
                      <p className={classes.nocodesentence}>This user does not have any access to any student in this school.</p>
                    </div>
                  </div>
                </>
              }
              modalFooter={
                <>
                </>
              }
              isopen={noAccessCodeModal}
              closeModal={handleCloseNoAccessCodeMOdal}
            />
            <Modal
              title="Need Help?"
              subtitle="Watch the Video Below on Access Logs"
              modalContent={
                <>
                  <ReactPlayer
                    url="https://youtu.be/fccZPQN-sVk"
                    playing={true}
                    controls={true}
                    width="100%"
                  />
                </>
              }
              modalFooter={
                <>
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    onClick={handleCloseVideoModal}
                    buttonName="Close Video"
                  />
                </>}
              isopen={helpModal}
              closeModal={handleCloseVideoModal}
            />
            <ToastContainer />
          </div>
        </>
      }
    />
  );
};

export default ValidateCode;
