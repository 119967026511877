import PropTypes from "prop-types";
import styles from "./SchoolEventCard.module.css";
import { Link } from "react-router-dom";
import PeopleImg from "@mui/icons-material/Groups2";
import Badge from "../../../../components/Badge";
import { Icon } from "@iconify/react";

const SchoolEventCard = ({ eventImage, eventName, eventDesc, viewattendees, attendees, status, link, viewClick, editClick, deleteClick }) => {
  // const { id } = useParams();

  return (
    <div className={`${styles.evntcardcont}`}>
      <Link to={`${link}`} className={styles.eventlink}>
        <div className={`${styles.imageheader}`}><img src={eventImage} alt="" /></div>
      </Link>
      <div className={`${styles.evntcard}`}>
        <h6>{eventName}</h6>
        <p>{eventDesc}</p>
        <div className={`${styles.loweritems}`}>
          <div className={`${styles.evntinfo} flex gap-10 align-items-center margin-bottom-10`}>
            <PeopleImg />
            <Link to={`${viewattendees}`}>{attendees}</Link>
          </div>
          <div className="flex justify-items-space">
            <Badge status={status} />
            <div className={`${styles.iconfooter}`}>
              <div className="table-action-container">
                <button className="table-action-icon" onClick={viewClick}>
                  <Icon icon="fluent:eye-16-regular" />
                </button>
                <button className="table-action-icon">
                  <Icon icon="heroicons:pencil-square-solid" onClick={editClick} />
                </button>
                <button
                  className="table-action-icon delete-icon"
                  onClick={deleteClick}
                >
                  <Icon icon="mingcute:delete-2-line" />
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
SchoolEventCard.PropTypes = {
  eventImage: PropTypes.any,
  eventName: PropTypes.string,
  eventDesc: PropTypes.string,
  population: PropTypes.string,
  status: PropTypes.string
};

export default SchoolEventCard;
