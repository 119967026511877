/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import useStyles from "./styles";
import { Grid } from "@mui/material";
import CardCount from "../../components/CardCount";
import { Group as StudentIcon, Folder, CalendarToday } from "@mui/icons-material";
import PageTitle from "../../components/PageTitle";
import WidgetTitle from "./components/WidgetTitle/WidgetTitle";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import { httprequest } from "../../data/api";
import { toast } from "react-toastify";
import { useUserState } from "../../context/UserContext";
import useWindowSize from "../../hooks/useWindow";
import Calendar from "../../components/Calendar";
import Quote from "inspirational-quotes";
import ClassPeriod from "./components/ClassPeriodCard";
import empty from "../../assets/images/emptyperiod.png";
import NoData from "./components/NotFoundCard/NoData";
import NoNotice from "../../../src/assets/images/nofeatureicons/nonotice.svg";
import Modal from "../../components/Modal/index";
import InputGroup from "../../components/FormComponent/InputGroup";
import Button from "../../components/Button";
import NoticeBoardCard from "./components/NoticeBoardCard/NoticeBoardCard";

const SUBJECT_ENDPOINT = "/api/v1/subjects";
const ASSIGNMENT_ENDPOINT = "/api/v1/student/assignment";
const STUDENT_PERIOD = "/api/v1/period";
const TERM_ENDPOINT = "/api/v1/school-plan/term";

const StudentDashboard = () => {
  const { profile } = useUserState();
  const classes = useStyles();
  const [subjectCount, setSubjectCount] = useState([]);
  const [periodData, setPeriodData] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [activeTerm, setActiveTerm] = useState([]);
  const [overview, setOverview] = useState([]);
  const [currentNotice, setCurrentNotice] = useState({});
  const [viewNoticeModal, setViewNoticeModal] = useState(false);
  const [quote, setQuote] = useState({ text: "", author: "" });

  const CLASS_ID = profile?.class_id;
  // eslint-disable-next-line no-console
  console.log(CLASS_ID, "CLASS_ID");

  const width = useWindowSize();
  const mobile = width < 576;
  const navigate = useNavigate();

  const renderView = () => {
    if (mobile) {
      return "timeGridDay";
    } else {
      return "timeGridWeek";
    }
  };

  const getSubject = () => {
    httprequest.get(`${SUBJECT_ENDPOINT}`)
      .then((res) => {
        setSubjectCount(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  // Get All Terms
  const getAllTerms = () => {
    httprequest.get(TERM_ENDPOINT).then((res) => {
      setActiveTerm(res?.data?.data.find((active_term) => active_term?.status === "active"));
    }).catch((err) => {
      toast.error(err?.response?.data);
    });
  };

  const getAssignments = () => {
    httprequest.get(`${ASSIGNMENT_ENDPOINT}`)
      .then((res) => {
        setAssignment(res?.data?.data);
      }).catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  // eslint-disable-next-line no-console
  console.log(profile, "PROFILE");

  // Get all Period for a School Teacher
  const getAllPeriods = () => {
    httprequest
      .get(`${STUDENT_PERIOD}?class_id=${CLASS_ID}`)
      .then((res) => {
        setPeriodData(res?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getNotification = () => {
    httprequest
      .get("/api/v1/overview/student")
      .then((res) => {
        setOverview(res?.data.data || []);
      })
      .catch(() => {
      // eslint-disable-next-line no-console
        console.log("Failed to fetch overview");
      });
  };

  const handleViewNoticeModal = (notice) => {
    setCurrentNotice(notice);
    setViewNoticeModal(!viewNoticeModal);
  };
  const handleCloseViewNoticeModal = () => {
    setViewNoticeModal(!viewNoticeModal);
  };

  // eslint-disable-next-line no-console
  console.log(periodData, "periodData");

  // Set Start and End Date
  const startDate = new Date(activeTerm?.start_date);
  const EndDate = new Date(activeTerm?.end_date);

  const generateEvents = () => {
    const allEvents = [];
    const currentDate = new Date(startDate);

    while (currentDate <= EndDate) {
      const weekDay = currentDate
        // Get day and convert to lowercase abbreviat
        .toLocaleDateString("en-US", { weekday: "short" })
        .toLowerCase();

      periodData?.data?.days.forEach((day) => {
        if (weekDay === day?.day) {
          day?.periods.forEach((periodItem) => {
            const event = {
              type: periodItem?.type,
              title: periodItem?.subject?.name || periodItem?.title,
              start: `${currentDate.toISOString().split("T")[0]}T${periodItem?.start_time}`,
              end: `${currentDate.toISOString().split("T")[0]}T${periodItem?.end_time}`,
              teacher: periodItem?.teachers?.[0]?.name,
              subject_name: periodItem?.subject?.name,
              subject_id: periodItem?.subject?.id,
              day: day?.day,
              class: periodData?.data?.class?.name,
              class_id: periodData?.data?.class?.id,
              term: periodData?.data?.term?.name,
              term_id: periodData?.data?.term?.id,
              period_id: periodItem?.id,
              className: "general-events"
            };
            allEvents.push(event);
          });
        }
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return allEvents;
  };

  const events = generateEvents();

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.title}</p>
        <p className="no-margin-block padding-inline-10">{eventInfo.event?.extendedProps?.teacher}</p>
      </>
    );
  };

  useEffect(() => {
    getSubject();
    getAssignments();
    getAllTerms();
    getAllPeriods();
    getNotification();
    const fetchedQuote = Quote.getQuote();
    setQuote(fetchedQuote);
  }, []);

  useEffect(() => {
    if (CLASS_ID) {
      getAllPeriods(CLASS_ID);
    }
  }, []);

  // eslint-disable-next-line no-console
  console.log(Quote?.getQuote());

  const assignmentsColumns = [
    {
      name: "Subject",
      selector: row => row?.subject_name
    },
    {
      name: "Instruction",
      selector: row => row?.instruction
    },
    {
      name: "Questions",
      selector: row => row?.questions?.length
    },
    {
      name: "Total Score",
      selector: row => row?.total_score
    },
    {
      name: "Submission Date",
      selector: row => row?.submission_date?.substring(0, 10)
    }
  ];

  return (
    <Layout
      children={
        <>
          <PageTitle
            title={`Hello ${profile?.last_name} ${profile?.first_name},`}
            subtitle={
              <div className={classes.quoteContainer}>
                <h6 className={classes.quoteText}>{quote.text}</h6>
                <h5 className={classes.quoteAuthor}> - {quote.author}</h5>
              </div>
            }
          />
          <Grid container spacing={8}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Total Subject"
                count={subjectCount.length}
                icon={<StudentIcon />}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <CardCount
                title="Learning Materials"
                count={0}
                icon={<Folder />}
              />
            </Grid>
          </Grid>
          {/* <WidgetTitle
            title="My TimeTable"
            subtitle=""
            // link={<Link className={classes.widgetlink} to="/teachers"><CalendarToday /> See full schedule</Link>}
          /> */}

          {/* <div className={classes.calendar}>
            <Calendar events={events} renderEventContent={renderEventContent} defaultView={renderView()} />
          </div> */}

          <WidgetTitle
            title="My Assignments"
            link={<Link style={{ fontSize: "14px", color: "black", fontWeight: "400" }} className={classes.widgetlink} to="/teachers">View all assignments</Link>}
          />
          <Table data={assignment} columns={assignmentsColumns} />
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <WidgetTitle
                title="Today's Classes"
                subtitle="View your schedule for the day"
              />
              <div className={classes.period}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "14px" }} onClick={() => navigate("/class-activities/classes/class-calendar")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.periodclasses}>
                  <div className={classes.preriodflex}>
                    {overview?.period?.length > 0 ? (
                      overview?.period.map((period, index) => (
                        <div key={index} className={classes.perioditem}>
                          <ClassPeriod
                            start_time={period?.start_time}
                            end_time={period?.end_time}
                            period_subject={period?.period_subject}
                            period_class={period?.period_class}
                          />
                        </div>
                      ))
                    ) : (
                      <div className={classes.notfound}>
                        <NoData
                          img={NoNotice}
                          title="Empty Period"
                          subtitle="No periods available"
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <WidgetTitle
                title="Notice Board"
                subtitle="View important information for your school"
              />
              <div className={classes.notice}>
                <div className={classes.static}>
                  <h3 style={{ fontSize: "13px" }} onClick={() => navigate("/student/notice-board")}>View all</h3>
                  <hr />
                </div>
                <div className={classes.pendingleaves}>
                  {overview?.notice_board?.length > 0 ? (
                    overview.notice_board.map((notice) => (
                      <div key={notice.id}>
                        <NoticeBoardCard
                          viewClick={() => handleViewNoticeModal(notice)}
                          title={notice.title}
                          date={notice.created_at}
                          teacher={notice.added_by?.name}
                          priority={notice.priority}
                        />
                      </div>
                    ))
                  ) : (
                    <div className={classes.notfound}>
                      <NoData
                        img={NoNotice}
                        title="Empty Notice Board"
                        subtitle="No Information added"
                      />
                    </div>
                  )}

                </div>
              </div>
            </Grid>
          </Grid>
          <Modal
            title="View a Notice"
            subtitle={
              <div>
                View a notice set from your school
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {currentNotice?.image?.file_url && (
                      <div
                        className={classes.viewBanner}
                        style={{
                          backgroundImage: `url(${currentNotice?.image?.file_url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat"
                        }}
                      >
                      </div>
                    )}
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Title"
                      required
                      disabled="disabled"
                      margin="normal"
                      placeHolder="Add Title of Document"
                      inputType="text"
                      inputName="title"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.title}
                    />
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="End Date"
                      margin="normal"
                      disabled="disabled"
                      // onChange={handleEditChange}
                      inputType="text"
                      required
                      value={currentNotice?.end_date}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Description"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice Description"
                      inputType="text"
                      inputName="description"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.description}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Priority"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice priority"
                      inputType="text"
                      inputName="priority"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.priority}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <InputGroup
                      label="Status"
                      margin="normal"
                      required
                      disabled="disabled"
                      placeHolder="Notice status"
                      inputType="text"
                      inputName="Status"
                      // onChange={handleEditChange}
                      defaultValue={currentNotice?.status}
                    />
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonName="Cancel"
                    buttonSize="full"
                    color="btnfontprimary"
                    onClick={handleCloseViewNoticeModal}
                  />
                </div>
              </>
            }
            isopen={viewNoticeModal}
            closeModal={handleCloseViewNoticeModal}
          />
        </>
      }
    />
  );
};

export default StudentDashboard;
