/* eslint-disable prefer-template */
import Layout from "../../components/Layout";
import useStyles from "./styles";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import BreadCrumb from "../../components/BreadCrumb";
import { useState, useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Icon } from "@iconify/react";
import Tabs from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Modal from "../../components/Modal";
import { Grid } from "@mui/material";
import InputGroup from "../../components/FormComponent/InputGroup";
import PersonalInfo from "./components/PersonalInfo";
import AccessControl from "./components/AccessControl";
import TuitionFees from "./components/TuitionsFees";
import Ward from "./components/Ward";
import { httprequest } from "../../data/api";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import ReactQuill from "react-quill";
import { useUpload } from "../../hooks/useUpload";

const GUARDIAN_ENDPOINT = "/api/v1/guardian";
const STUDENTS_ENDPOINT = "/api/v1/students";

const GuardianDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [messageModal, setMessageModal] = useState(false);
  const [defaultStudents, setDefaultStudent] = useState([]);
  const [multipleStudents, setMultipleStudents] = useState([]);
  const [addreportModal, setReportModal] = useState(false);
  const [guardiandata, setGuardianData] = useState([]);
  const [value, setValue] = useState("1");
  const [mailingSelect, setMailingSelect] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [selectedMailProvider, setSelectedMailProvider] = useState(null);
  const [subjects, setSubject] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [senderPrefix, setSenderPrefix] = useState("");
  const [senderDomain, setSenderDomain] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMessageModal = () => {
    setMessageModal(!messageModal);
  };

  const handleReportModal = () => {
    setReportModal(!addreportModal);
  };

  useEffect(() => {
    httprequest
      .get(`${GUARDIAN_ENDPOINT}/${id}`)
      .then((res) => {
        setGuardianData(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  }, []);

  const getCurrentWard = () => {
    httprequest
      .get(`${GUARDIAN_ENDPOINT}/wards/${id}`)
      .then((res) => {
        setDefaultStudent(
          res?.data?.data?.map((student) => {
            return {
              label: student?.first_name + " " + student?.last_name,
              value: student.student_id
            };
          })
        );
      })
      .catch(() => {});
  };

  const getAllStudents = () => {
    httprequest
      .get(STUDENTS_ENDPOINT)
      .then((res) => {
        setMultipleStudents(
          res?.data?.data?.map((student) => {
            return {
              label: student?.first_name + " " + student?.last_name,
              value: student.student_id
            };
          })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };
  const mallingOption = () => {
    httprequest
      .get("/api/v1/integrations/school?category=mail")
      .then((res) => {
        setMailingSelect(res?.data?.data || []);
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    mallingOption();
    getCurrentWard();
    getAllStudents();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setImagePreview(null);
  };

  const handleMailProviderChange = (selectedProvider) => {
    setSelectedMailProvider(selectedProvider);

    const selectedDomain = mailingSelect.find((option) => option.id === selectedProvider.value)?.domain;

    setSenderDomain(selectedDomain || "");
  };

  const fullSenderEmail = `${senderPrefix}${senderDomain}`;

  const handleQuillChange = (content) => {
    setMessageContent(content);
  };

  const handleSendMessage = async () => {
    if (!selectedMailProvider) {
      toast.error("Please a mailing provider.");

      return;
    }
    if (!messageContent.trim()) {
      toast.error("Please enter a message.");

      return;
    }
    const GaurdianName = `${guardiandata?.first_name + " " + guardiandata?.last_name}`;
    const messageForm = {
      to: [
        {
          email: guardiandata?.email,
          name: GaurdianName
        }
      ],
      integration_id: selectedMailProvider.value,
      from_email: fullSenderEmail,
      from_name: senderPrefix,
      body: messageContent,
      subject: subjects,
      attachment: []
    };

    if (!messageForm.integration_id || !messageForm.from_email || !messageForm.body || !messageForm.subject) {
      toast.error("Please fill in all required fields.");

      return;
    }

    setIsSendingMessage(true);

    try {
      if (selectedFile) {
        const response = await useUpload(selectedFile);
        if (response.status === 200 && response.data.data) {
          const file_url = response.data.data?.[0]?.file_url;
          messageForm.attachment.push(file_url);
        } else {
          throw new Error("File upload failed.");
        }
      }
      // Send the message
      const result = await httprequest.post("api/v1/message", messageForm, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      toast.success(result?.data?.message);
      handleMessageModal();
      setSelectedMailProvider(null);
      setSenderPrefix("");
      setMessageContent("");
      setSubject("");
      setSelectedFile(null);
      setIsSendingMessage(false);
    } catch (err) {
      setIsSendingMessage(false);
      toast.error(err?.response?.data?.message || "Failed to send message.");
    }
  };

  const handleEditorDrop = (event) => {
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      // Prevent drop if it's a file (e.g., image)
      event.preventDefault();
      event.stopPropagation();
    }
  };
  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      // Check if any pasted item is an image
      if (items[i].type.indexOf("image") !== -1) {
        event.preventDefault(); // Prevent the paste

        return;
      }
    }
  };

  // --| Quill text format is defined explicitly so as to avoid images and videos from being pasted
  const Quillformats = ["background", "bold", "color", "font", "code", "italic", "link", "size",
    "strike", "script", "underline", "blockquote", "header", "indent", "list", "align", "direction", "code-block", "formula"
    // 'image'
    // 'video'
  ];

  return (
    <Layout
      children={
        <>
          <div className="breadcrumb-container">
            <div>
              <BreadCrumb
                link_one="/users/guardians"
                link_one_name="Guardians"
                active={`${
                  guardiandata?.first_name + " " + guardiandata?.last_name
                }`}
                description="See the details of the guardian"
              />
            </div>
          </div>
          <div className={classes.pageContent}>
            <PageTitle
              className={classes.inline}
              title={`${
                guardiandata?.first_name + " " + guardiandata?.last_name
              }`}
              subtitle="View details about Guardian"
              button={
                <div className="flex gap-10">
                  <Button
                    variant="primaryoutline"
                    buttonSize="fluid"
                    color="btnfontprimary"
                    onClick={handleMessageModal}
                    buttonName="Send a message to Guardian"
                  />
                  {/* <Button
                    variant="primary"
                    buttonSize="fluid"
                    color="btndefault"
                    onClick={handleReportModal}
                    buttonName="Update Guardian Info"
                  /> */}
                </div>
              }
            />
            <TabContext value={value}>
              <div className="tablist-container">
                <Tabs
                  indicatorColor="none"
                  className="overide-tablist"
                  onChange={handleChange}
                  aria-label=""
                >
                  <Tab label="Personal Information" value="1" />
                  {/* <Tab label="Tuition & Fees" value="2" /> */}
                  <Tab label="Access Control" value="3" />
                  <Tab label="Ward" value="4" />
                </Tabs>
              </div>
              <TabPanel value="1" className="tabpanel-overide">
                <PersonalInfo
                  data={guardiandata}
                  defaultStudents={defaultStudents}
                  multipleStudents={multipleStudents}
                />
              </TabPanel>
              <TabPanel value="2" className="tabpanel-overide">
                <TuitionFees />
              </TabPanel>
              <TabPanel value="3" className="tabpanel-overide">
                <AccessControl />
              </TabPanel>
              <TabPanel value="4" className="tabpanel-overide">
                <Ward />
              </TabPanel>
            </TabContext>
            <ToastContainer />
          </div>
          {/* Send Report Modal */}
          <Modal
            title="Send Student Report"
            subtitle={
              <div>
                You are about to share the computed result sheets to the
                parent/guardian of Ojo William.
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Enter guardian mail"
                      inputType="text"
                    />
                  </Grid>
                  <div className={`${classes.mail} flex align-items-center`}>
                    <Icon icon="fluent:mail-12-filled" width="24" height="24" />
                    <p>Use an alternative email</p>
                  </div>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primaryoutline"
                    buttonSize="full"
                    color="btnfontprimary"
                    buttonName="Cancel"
                  />
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    buttonName="Send Report"
                  />
                </div>
              </>
            }
            isopen={addreportModal}
            closeModal={handleReportModal}
          />
          {/* Send Broadcast Modal */}
          <Modal
            title="Message Parent/Guardian"
            subtitle={
              <div>
                Send a message to the parents of the students in this class
              </div>
            }
            modalContent={
              <>
                <Grid container spacing={2} className="input-padding">
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent Name"
                      margin="normal"
                      placeHolder="Parent Name"
                      inputType="text"
                      disabled
                      value={`${
                        guardiandata?.first_name + " " + guardiandata?.last_name
                      }`}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Parent/Guardian Email"
                      margin="normal"
                      placeHolder="Parent Email"
                      inputType="email"
                      disabled
                      value={`${guardiandata?.email}`}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className="multiple-select">
                      <p className="input-picker-title">
                            Select Mailing Provider<span className="input-required">*</span>
                      </p>

                      <Select
                        name="mailing_option"
                        value={selectedMailProvider}
                        onChange={handleMailProviderChange}
                        options={mailingSelect.map((option) => ({
                          label: option.provider,
                          value: option.id
                        }))}
                      />
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputwrapper}>
                      <label style={{ display: "flex",
                        flexDirection: "row" }} htmlFor="senderPrefix"
                      >
                        <p style={{
                          color: "#101928",
                          marginBottom: "0px",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginTop: "5px"
                        }}
                        >Sender
                        </p>
                        <span style={{ marginTop: "3px", fontWeight: "500" }} className="input-required">*</span>
                      </label>
                      <div className="gaurdian_input">
                        <input
                          type="text"
                          id="senderPrefix"
                          name="senderPrefix"
                          value={senderPrefix}
                          onChange={(e) => setSenderPrefix(e.target.value.replace(/\s/g, ""))}
                          required
                          placeholder="Sent From"
                        />
                        <input
                          value={`${senderDomain}`}
                          disabled
                        />
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <InputGroup
                      label="Subject"
                      margin="normal"
                      placeHolder="Enter Mail Subject"
                      inputType="text"
                      name="subject"
                      value={subjects}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <p style={{ fontWeight: 500, fontSize: "14px" }} className="text-align-left no-margin-block">
                      Message
                      <span className="input-required">*</span>
                    </p>
                    <ReactQuill
                      theme="snow"
                      value={messageContent}
                      onDrop={handleEditorDrop}
                      onChange={handleQuillChange}
                      onPaste={handlePaste}
                      formats={Quillformats}
                      modules={{
                        toolbar: [
                          [{ "header": [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          [{ "list": "ordered" }, { "list": "bullet" }],
                          ["clean"] // Remove the image button
                        ]
                      }}
                      style={{
                        borderRadius: 30,
                        textAlign: "left",
                        "& qlContainer": { height: "150px" }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <label className={`${classes.label}`}>Additional Information<span className="input-required">*</span></label>
                    <div className={classes.pickercontainer}>
                      {selectedFile ? (
                        <><img style={{ width: "100%", height: "inherit" }} src={imagePreview} />
                          <div
                            className={classes.uploadbuttons}
                            onClick={() => handleRemoveImage()}
                          >
                            <Icon icon="zondicons:minus-solid" /> Remove
                          </div>
                        </>

                      ) : (
                        <>
                          <input type="file" className={classes.picker} onChange={handleFileChange} />

                          <div className={classes.uploadflex}>
                            <div><Icon icon="icon-park-outline:upload-two" className={classes.uploadicon} /></div>
                            <div>
                              <h2 className={classes.uploadtitle}>Tap to Upload</h2>
                              <h6 className={classes.uploadsubtitle}>SVG, PNG, JPG, GIF | 10MB max.</h6>
                            </div>
                          </div>

                          <div className={classes.rightpicker}>
                            <div className={classes.uploadbutton}>Upload</div>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </>
            }
            modalFooter={
              <>
                <div className={classes.modalFooterBtn}>
                  <Button
                    variant="primaryoutline"
                    buttonSize="full"
                    color="btnfontprimary"
                    buttonName="Cancel"
                    onClick={handleMessageModal}
                  />
                  <Button
                    variant="primary"
                    buttonSize="full"
                    color="btndefault"
                    disabled={isSendingMessage}
                    onClick={handleSendMessage}
                    buttonName={isSendingMessage ? "Sending..." : "Send Message"}
                  />
                </div>
              </>
            }
            isopen={messageModal}
            closeModal={handleMessageModal}
          />
        </>
      }
    />
  );
};

export default GuardianDetail;
